import React from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { Row, Col } from "react-bootstrap";
import "./../../../css/index.css";
import { EQuestionType } from "../../../utils/Enums";
import MainCard from "../Components/MainCard";
import chartData from "../Components/responses-graph-data";
import Chart from "react-apexcharts";
import { Grid } from "@mui/material";
import CustomPagination from "../../../components/Pagination";

export default function AnalyticsQuestion2(props) {
  const columns = [
    { field: "question_id", headerName: "Question ID", width: 100, hide: true },
    {
      field: "question_index",
      headerName: "QNo.",
      flex: 1,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "correct_answer",
      headerName: "Correct Answer",
      cellClassName: "super-app-theme--cell",
      flex: 2,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "correct",
      headerName: "% Correct",
      flex: 3,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderCell: (el) => {
        return Math.round(el.value * 100) / 100;
      },
    },
    {
      field: "incorrect",
      headerName: "% Incorrect",
      cellClassName: "super-app-theme--cell",
      flex: 3,
      headerClassName: "super-app-theme--header",
      renderCell: (el) => {
        return Math.round(el.value * 100) / 100;
      },
    },
    {
      field: "unattempted",
      headerName: "% Unattempted",
      flex: 3,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderCell: (el) => {
        return Math.round(el.value * 100) / 100;
      },
    },
  ];

  const [selectedRow, setSelectedRow] = React.useState(0);
  const rows = React.useMemo(
    () => props.data["question_stats"],
    [props.data["question_stats"]]
  );

  const mapQuestionIDRowIndex = {};
  for (var i = 0; i < props.data["question_stats"].length; i++) {
    mapQuestionIDRowIndex[props.data["question_stats"][i].question_id] = i;
  }

  let arrayQuestionStats = [];
  props.data["question_stats"][selectedRow].question_type === EQuestionType.MCQ
    ? (arrayQuestionStats = [
        props.data["question_stats"][
          selectedRow
        ].answer_distribution?.A?.toFixed(2) ?? 0,
        props.data["question_stats"][
          selectedRow
        ].answer_distribution?.B?.toFixed(2) ?? 0,
        props.data["question_stats"][
          selectedRow
        ].answer_distribution?.C?.toFixed(2) ?? 0,
        props.data["question_stats"][
          selectedRow
        ].answer_distribution?.D?.toFixed(2) ?? 0,
      ])
    : props.data["question_stats"][selectedRow].question_type ===
      EQuestionType.NUMERICAL
    ? (arrayQuestionStats = [
        props.data["question_stats"][selectedRow].correct?.toFixed(2),
        props.data["question_stats"][selectedRow].incorrect?.toFixed(2),
        props.data["question_stats"][selectedRow].unattempted?.toFixed(2),
      ])
    : (arrayQuestionStats = []);

  let arrayLabels = [];
  props.data["question_stats"][selectedRow].question_type === 0
    ? (arrayLabels = ["A", "B", "C", "D"])
    : props.data["question_stats"][selectedRow].question_type === 2
    ? (arrayLabels = ["Correct", "Incorrect", "Unattempted"])
    : (arrayLabels = []);

  return (
    <Grid
      container
      className="pt-2 px-2 my-0 d-flex align-items-center pb-md-4 pb-0"
    >
      <Grid item md={7} xs={12} className="overflow-auto">
        <Box
          sx={{
            height: "70vh",
            "& .super-app-theme--header": {
              backgroundColor: "#fff",
              color: "#7F828A",
              fontSize: "16px",
              fontWeight: "700",
              border: "0",
            },
            "& .super-app-theme--cell": {
              fontSize: "16px",
              border: "0",
            },
          }}
        >
          <DataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: "question_index", sort: "asc" }],
              },
            }}
            pageSize={10}
            rowsPerPageOptions={[10]}
            components={{
              Pagination: CustomPagination,
            }}
            getRowId={(r) => r.question_id}
            rows={rows}
            columns={columns}
            onRowClick={(i) => {
              setSelectedRow(mapQuestionIDRowIndex[i.id]);
            }}
            style={{ border: "0" }}
          />
        </Box>
      </Grid>
      <Grid
        item
        md={5}
        xs={12}
        className="pb-4 pb-md-0 px-md-2 px-0 mt-4 mt-md-0"
      >
        <div style={{ height: "100%", width: "100%" }}>
          <Box
            borderRadius="2%"
            sx={{
              height: 500,
              backgroundColor: "#22343C",
            }}
          >
            <MainCard
              style={{ width: "100%", height: "55%" }}
              backgroundColor="white"
            >
              <Chart
                {...chartData(
                  arrayQuestionStats,
                  arrayLabels,
                  [
                    "Question " +
                      props.data["question_stats"][selectedRow].question_index,
                  ],
                  250
                )}
                type="bar"
              />
            </MainCard>

            <div
              className="justify-content-center fs-12 p-1 m-1"
              style={{ color: "#9AA0B2", textAlign: "center" }}
            >
              Correct Answer:{" "}
              {props.data["question_stats"][selectedRow].correct_answer}
            </div>

            {props.data["question_stats"][selectedRow].question_type ===
            EQuestionType.MCQ ? (
              <>
                <div
                  className="justify-content-center fs-12 p-1 m-1"
                  style={{ color: "#9AA0B2", textAlign: "center" }}
                >
                  A:{" "}
                  {props.data["question_stats"][
                    selectedRow
                  ].answer_distribution?.A?.toFixed(2) ?? 0}
                  %
                </div>
                <div
                  className="justify-content-center fs-12 p-1 m-1"
                  style={{ color: "#9AA0B2", textAlign: "center" }}
                >
                  B:{" "}
                  {props.data["question_stats"][
                    selectedRow
                  ].answer_distribution?.B?.toFixed(2) ?? 0}
                  %
                </div>
                <div
                  className="justify-content-center fs-12 p-1 m-1"
                  style={{ color: "#9AA0B2", textAlign: "center" }}
                >
                  C:{" "}
                  {props.data["question_stats"][
                    selectedRow
                  ].answer_distribution?.C?.toFixed(2) ?? 0}
                  %
                </div>
                <div
                  className="justify-content-center fs-12 p-1 m-1"
                  style={{ color: "#9AA0B2", textAlign: "center" }}
                >
                  D:{" "}
                  {props.data["question_stats"][
                    selectedRow
                  ].answer_distribution?.D?.toFixed(2) ?? 0}
                  %
                </div>
              </>
            ) : props.data["question_stats"][selectedRow].question_type ===
              EQuestionType.NUMERICAL ? (
              <>
                <div
                  className="justify-content-center fs-12 p-1 m-1"
                  style={{ color: "#9AA0B2", textAlign: "center" }}
                >
                  Correct:{" "}
                  {props.data["question_stats"][selectedRow].correct.toFixed(
                    2
                  ) ?? 0}
                  %
                </div>
                <div
                  className="justify-content-center fs-12 p-1 m-1"
                  style={{ color: "#9AA0B2", textAlign: "center" }}
                >
                  Incorrect:{" "}
                  {props.data["question_stats"][selectedRow].incorrect.toFixed(
                    2
                  ) ?? 0}
                  %
                </div>
                <div
                  className="justify-content-center fs-12 p-1 m-1"
                  style={{ color: "#9AA0B2", textAlign: "center" }}
                >
                  Unattempted:{" "}
                  {props.data["question_stats"][
                    selectedRow
                  ].unattempted?.toFixed(2) ?? 0}
                  %
                </div>
              </>
            ) : (
              <div></div>
            )}
          </Box>
        </div>
      </Grid>
    </Grid>
  );
}
