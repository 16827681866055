import { IconButton, Typography, Menu, MenuItem } from "@mui/material";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useContext, useState, Dispatch, SetStateAction } from "react";
import { Navbar } from "react-bootstrap";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import SettingsIcon from "@mui/icons-material/Settings";
import { AppContext, TAppContext } from "../../../context/AppContext";
import { ERole } from "../../../utils/Enums";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../context/AssessmentContext";
import { fetchAnalyticsResponses, fetchResults } from "../../../api/analytics";
import { CSVLink } from "react-csv";

export default function AnalyticsTopnav() {
  const { selectedAssessment } =
    useContext<TAssessmentContext>(AssessmentContext);
  const { accessToken } = useContext<TAppContext>(AppContext);
  const publishCode = useRouteMatch().params["publishCode"];

  const download = (csv, fileName: string) => {
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const history = useHistory();
  const { role } = useContext<TAppContext>(AppContext);

  const [sectionSettingsAnchor, setSectionSettingsAnchor] =
    useState<null | HTMLElement>(null);
  const handleClose = () => setSectionSettingsAnchor(null);

  const downloadResponses = async () => {
    const result_csv = await fetchAnalyticsResponses(accessToken, publishCode);
    const fileName = selectedAssessment.name + "_responses.csv";
    download(result_csv.data, fileName);
  };

  const downloadReport = async () => {
    const result_csv = await fetchResults(accessToken, publishCode);
    const fileName = selectedAssessment.name + "_results.csv";
    download(result_csv.data, fileName);
  };

  return (
    <Navbar
      className="d-flex bg-primary py-4 px-2"
      style={{
        height: "10%",
        width: "100%",
        zIndex: 15,
      }}
    >
      <div className="mb-1 d-flex justify-content-between align-items-center w-100">
        <IconButton
          onClick={() => {
            history.goBack();
          }}
          className="d-flex align-items-center"
        >
          <IoArrowBackCircleOutline
            size={"30px"}
            color="white"
            cursor={"pointer"}
          />
          <Typography className="ms-2" variant="h5" color="white">
            Analytics
          </Typography>
        </IconButton>
        <div className="me-3">
          {role === ERole.TEACHER ? (
            <div>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setSectionSettingsAnchor(e.currentTarget);
                }}
              >
                <SettingsIcon fontSize="medium" style={{ color: "white" }} />
              </IconButton>
              <Menu
                anchorEl={sectionSettingsAnchor}
                open={Boolean(sectionSettingsAnchor)}
                onClose={() => {
                  handleClose();
                }}
                sx={{
                  ".MuiMenu-paper": { backgroundColor: "#F0F0F0" },
                }}
              >
                <MenuItem
                  sx={{
                    "&:hover": { backgroundColor: "#292F4D", color: "#FFFFFF" },
                  }}
                  onClick={() => {
                    downloadReport();
                    handleClose();
                  }}
                >
                  Download Report
                </MenuItem>
                <MenuItem
                  sx={{
                    "&:hover": { backgroundColor: "#292F4D", color: "#FFFFFF" },
                  }}
                  onClick={() => {
                    downloadResponses();
                    handleClose();
                  }}
                >
                  Download Responses
                </MenuItem>
              </Menu>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    </Navbar>
  );
}
