export enum ERole {
  STUDENT = 0,
  TEACHER = 1,
}

export enum EMarkingScheme {
  CORRECT = 0,
  INCORRECT = 1,
  NOT_ATTEMPTED = 2,
}

export enum EAssessmentStatus {
  DRAFT = 1 /* Still Editing */,
  COMPLETED = 2 /* Editing Complete, Ready to Publish */,
}

export enum EAssessmentType {
  TEST = 1,
  ASSIGNMENT = 2,
}

export enum EAssessmentFormat {
  NTA = 0,
  OMR = 1,
}

export enum EPublishStatus {
  PUBLISHED = 1,
  UNPUBLISHED = 2,
}

export enum EQuestionType {
  MCQ = 0,
  MCMCQ = 1,
  NUMERICAL = 2, // Re-enable when UI support is added
  // TEXT = 3,
  // MATCH_THE_FOLLOWING = 4,
}

export enum EAnswerType {
  UNVISITED = -1,
  VISITED_UNANSWERED = 0,
  VISITED_ANSWERED = 1,
  FLAGGED_UNANSWERED = 2,
  FLAGGED_ANSWERED = 3,
}

export enum EQuestionStatus {
  UNVISITED = 0,
  APPROVED = 1,
  FLAGGED = 2,
}

// Different types of Template For Paper that are supported by ingestion pipeline
export enum ETemplateType {
  IMAGE_TEMPLATE1 = 1, // This template will correspond to a single image with a single question
}

export enum ESourceType {
  PDF = 0,
  DOCX = 1,
  FORM = 2,
  SCREENSHOT = 3,
}

export enum EAccessLevel {
  BATCH = 1,
  PUBLIC = 2,
}

export const getEnumRoleValueFromString = (role: string) => {
  switch (role) {
    case "STUDENT":
      return ERole.STUDENT;
    case "TEACHER":
      return ERole.TEACHER;
    default:
      return null;
  }
};

export const getStringFromEnumRoleValue = (role: ERole | null) => {
  console.log(role);
  switch (role) {
    case ERole.STUDENT:
      return "STUDENT";
    case ERole.TEACHER:
      return "TEACHER";
    default:
      return null;
  }
};

export enum MetricTypes {
  SCALAR = 1,
  SINGLE_DIMENSION = 2,
  MULTI_DIMENSION = 3,
  TABULAR = 4,
  CSV_STRING = 5,
}

export enum BatchUserRole {
  STUDENT = 1,
  EDUCATOR = 2,
}

export enum MembershipStatus {
  APPROVED = 3,
  PENDING = 2,
  REJECTED = 1,
}

export enum EPublishedAssessmentFormat {
  /**
   * FIXED_TIME: A test with a definite start time and end time. Doesn't have a duration.
   * FIXED_DURATION: A test with a definite start time, end time and duration.
        User can attempt any time between the start time and end time for that duration
   * UNTIMED: A test with end time. User can attempt any time.
   * FIXED_DURATION_UNTIMED: A test with start time and duration. 
        User can attempt any time after the start time for a particular duration.
   */

  FIXED_TIME = 0,
  FIXED_DURATION = 1,
  UNTIMED = 2,
  FIXED_DURATION_UNTIMED = 3,
}

export enum EAnalyticsTab {
  OVERVIEW = 0,
  TIMED_ANALYSIS = 1,
  RESPONSE_ANALYSIS = 2,
  STATISTICS = 3,
  STUDENT_STATISTICS = 4,
}

export enum ESalesAnalyticsTab {
  OVERVIEW = 0,
  STUDENT_STATISTICS = 1,
}

export enum EProductLanguage {
  ENGLISH = 0,
  HINDI = 1,
  HINGLISH = 2,
}
