import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import {
  S3Client,
  PutObjectCommand,
  GetObjectCommand,
} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import crypto from "crypto";

// Initialize the S3Client with Amazon Cognito credentials provider and region
const s3 = new S3Client({
  region: process.env.REACT_APP_S3_REGION,
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({
      region: process.env.REACT_APP_S3_REGION,
    }),
    identityPoolId: process.env.REACT_APP_S3_IDENTITY_POOL_ID, // IDENTITY_POOL_ID
  }),
});

// This function is no longer required as all the buckets are public.
// In case a bucket is private and authorization is needed to access a file,
// this function generates a pre-signed URL from s3 to view it temporarily
export async function getFileUrlFromS3(key: string, bucketName?: string) {
  const getObjectParams = {
    Bucket: bucketName ?? process.env.REACT_APP_S3_BUCKET_NAME,
    Key: key,
  };

  const url = await getSignedUrl(s3, new GetObjectCommand(getObjectParams), {
    expiresIn: 3600,
  });
  return url;
}

// Handler for "PUT"ting an file in an S3 bucket
export async function uploadFileToS3(
  file: any,
  bucketFilePath: string = "",
  if_hash: boolean = true
) {
  let fileName: string = file.name;
  if (if_hash) {
    const text = await file.text();
    const extension = "." + file.type.split("/")[1]; // Extract the extension of the image-type file
    fileName = crypto.createHash("md5").update(text).digest("hex") + extension; // Generate hash from filename
  }
  const key = bucketFilePath + fileName;
  const uploadParams = {
    Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
    Key: key,
    Body: file,
  };

  try {
    await s3.send(new PutObjectCommand(uploadParams));
    return encodeURIComponent(key);
  } catch (err) {
    console.log(err);
    return;
  }
}
