import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { FunctionComponent } from "react";
import InsightsIcon from "@mui/icons-material/Insights";
import ShareIcon from "@mui/icons-material/Share";
import AddCircleIcon from "@mui/icons-material/AddCircle";

interface ManageAssessmentBottomNavigationProps {
  handleAnalytics: () => void;
  handleQuestionScreen: () => void;
  handleShareTest: () => void;
  disableAnalytics: boolean;
}

const ManageAssessmentBottomNavigation: FunctionComponent<
  ManageAssessmentBottomNavigationProps
> = ({
  handleAnalytics,
  handleQuestionScreen,
  handleShareTest,
  disableAnalytics,
}) => {
  return (
    <>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          style={{ backgroundColor: "#292F4D" }}
          value={-1}
        >
          <BottomNavigationAction
            disabled={disableAnalytics}
            onClick={handleAnalytics}
            style={{ color: "white" }}
            label="View Analytics"
            icon={<InsightsIcon />}
          />
          <BottomNavigationAction
            onClick={() => handleQuestionScreen()}
            style={{ color: "white" }}
            label={"View/Edit Questions"}
            icon={<AddCircleIcon />}
          />
          <BottomNavigationAction
            onClick={handleShareTest}
            style={{ color: "white" }}
            label="Share Test"
            icon={<ShareIcon />}
          />
        </BottomNavigation>
      </Paper>
    </>
  );
};

export default ManageAssessmentBottomNavigation;
