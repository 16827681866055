import { useContext, Dispatch, SetStateAction } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { AppContext, TAppContext } from "../../../context/AppContext";
import styles from "./CreateCoupon.module.scss";

import {
  displaySuccessToast,
  displayErrorToast,
} from "../../../utils/ToastUtils";
import { createCoupon } from "../../../api/marketplace";
import moment from "moment";

type TProps = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  productCode: string;
};

export default function CreateCouponModal(props: TProps) {
  const { showModal, setShowModal, productCode } = props;

  const { accessToken } = useContext<TAppContext>(AppContext);

  const handleCreateCouponSubmit = async (
    couponCode: string,
    validUpto: string,
    discount: number,
    CouponDescription: string
  ) => {
    setShowModal(false);

    const validUptoDate = new Date(Date.parse(validUpto));
    const validUptoISO = validUptoDate.toISOString().slice(0, -1);
    validUpto = validUptoISO;

    if (accessToken === null || productCode == null) {
      displayErrorToast("An error occured. Coupon creation failed");
      return;
    }

    const createCouponData = await createCoupon(
      accessToken,
      couponCode,
      productCode,
      validUpto,
      discount,
      CouponDescription
    );
    if (createCouponData === null) {
      displayErrorToast("An error occured. Coupon creation failed");
      return;
    }

    displaySuccessToast("Coupon Created Successfully!");
  };

  const schema = yup.object().shape({
    couponCode: yup.string().required("Please enter a coupon Code"),
    validUpto: yup.string().required("Please enter a coupon's validity"),
    discount: yup
      .number()
      .required("Please enter a valid discount percentage for coupon"),
  });

  return (
    <Modal
      contentClassName={styles.modalStyle}
      show={showModal}
      onHide={() => setShowModal(false)}
    >
      <h3 className="ms-2 mt-2">Create Coupon</h3>
      <div className="form-group mt-3 ms-2">
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            handleCreateCouponSubmit(
              values.couponCode,
              values.validUpto.toString(),
              values.discount,
              values.couponDescription
            );
          }}
          initialValues={{
            couponCode: "",
            validUpto: moment.now(),
            discount: 0,
            couponDescription: "",
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
            <Form
              className="mt-4 mx-1 me-2"
              noValidate
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <Form.Group controlId="couponCode">
                <Form.Label>Coupon Code:</Form.Label>
                <Form.Control
                  type="coupon Code"
                  value={values.couponCode}
                  placeholder=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="ml-2 mb-2 bg-light"
                  isInvalid={!!errors.couponCode}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.couponCode}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="validUpto">
                <Form.Label>Validity Date:</Form.Label>
                <Form.Control
                  type="datetime-local"
                  value={values.validUpto}
                  placeholder=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="ml-2 mb-2 bg-light"
                  isInvalid={!!errors.validUpto}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.validUpto}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="discount">
                <Form.Label>Coupon Discount (in %):</Form.Label>
                <Form.Control
                  type="number"
                  value={values.discount}
                  placeholder=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="ml-2 mb-2 bg-light"
                  isInvalid={!!errors.discount}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.discount}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="couponDescription">
                <Form.Label>Coupon Description:</Form.Label>
                <Form.Control
                  type="Coupon Description"
                  value={values.couponDescription}
                  placeholder=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="ml-2 bg-light"
                  isInvalid={!!errors.couponDescription}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.couponDescription}
                </Form.Control.Feedback>
              </Form.Group>

              <Modal.Footer>
                <Button
                  className="border-0 rounded-4 bg-secondary"
                  type="submit"
                >
                  Create Coupon
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
