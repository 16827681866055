import { FunctionComponent, useState } from "react";
import { Navbar } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { MdDownloading } from "react-icons/md";
import { BsPencilSquare } from "react-icons/bs";
import ManageAssessmentBottomDrawer from "../../instructionScreen/ManageAssessmentScreen/Components/BottomDrawer";
interface ManageBatchNavBarProps {
  batchName: string;
  batchCode: string;
  batchId: number
  handleEditBatch: () => void;
  handleDownloadParticipants: () => Array<Object>;
}

const ManageBatchNavBar: FunctionComponent<ManageBatchNavBarProps> = ({
  batchName,
  batchCode,
  batchId,
  handleEditBatch,
  handleDownloadParticipants,
}) => {
  const history = useHistory();
  const isMobile = useMediaQuery("(max-width: 576px)");
  const isDesktopOrLaptop = useMediaQuery("(min-width: 992px)");
  const [batchSettingsAnchor, setBatchSettingsAnchor] =
    useState<null | HTMLElement>(null);
  const handleClose = () => setBatchSettingsAnchor(null);

  return (
    <Navbar
      className="d-flex bg-primary p-3"
      style={{
        height: "8%",
        width: "100%",
        zIndex: 15,
      }}
    >
      <div className="d-flex w-100 flex-row justify-content-between align-items-center mt-2">
        <div className="d-flex flex-row align-items-center">
          <IconButton
            className="mb-2"
            onClick={() => {
              history.replace("/home/dashboard");
            }}
          >
            <IoArrowBackCircleOutline
              size={"35px"}
              color="white"
              cursor={"pointer"}
            />
          </IconButton>
          <Typography variant={isDesktopOrLaptop ? "h4" : "h6"} color="white">
            {batchName}
          </Typography>
          <IconButton
            className="ms-4 mb-2"
            onClick={(e) => {
              setBatchSettingsAnchor(e.currentTarget);
            }}
          >
            <SettingsIcon htmlColor="white" />
          </IconButton>
          {!isMobile && (
            <Menu
              sx={{
                ".MuiMenu-paper": { backgroundColor: "#F0F0F0" },
              }}
              open={Boolean(batchSettingsAnchor)}
              anchorEl={batchSettingsAnchor}
              onClose={() => setBatchSettingsAnchor(null)}
            >
              <MenuItem
                sx={{
                  "&:hover": { backgroundColor: "#292F4D", color: "#FFFFFF" },
                }}
                onClick={() => {
                  handleEditBatch();
                  handleClose();
                }}
              >
                Edit Batch Details
              </MenuItem>
              <MenuItem
                sx={{
                  "&:hover": { backgroundColor: "#292F4D", color: "#FFFFFF" },
                }}
                onClick={() => {
                  history.push(`/home/batch/${batchCode}/analytics/`);
                }}
              >
                View Sales Analytics
              </MenuItem>
              <CSVLink
                data={handleDownloadParticipants()}
                filename={`${batchCode} ${batchName} Participants list`}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                  }}
                  sx={{
                    "&:hover": { backgroundColor: "#292F4D", color: "#FFFFFF" },
                  }}
                >
                  Download Participants
                </MenuItem>
              </CSVLink>
            </Menu>
          )}
        </div>
      </div>
      {isMobile && (
        <ManageAssessmentBottomDrawer
          anchorEl={batchSettingsAnchor}
          setAnchorEl={setBatchSettingsAnchor}
          assessmentName={batchName}
        >
          <MenuItem
            onClick={() => {
              handleEditBatch();
              handleClose();
            }}
          >
            <ListItemIcon>
              <BsPencilSquare size={25} color="success" />
            </ListItemIcon>
            <ListItemText>Rename Batch</ListItemText>
          </MenuItem>
          <CSVLink
            data={handleDownloadParticipants()}
            filename={`${batchCode} ${batchName} Participants list`}
          >
            <MenuItem
              onClick={() => {
                handleClose();
              }}
            >
              <ListItemIcon>
                <MdDownloading color="#22343C" size={25} />
              </ListItemIcon>

              <p className="pt-3">Download Participants</p>
            </MenuItem>
          </CSVLink>
        </ManageAssessmentBottomDrawer>
      )}
    </Navbar>
  );
};

export default ManageBatchNavBar;
