import {
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useMemo,
  useState,
} from "react";
import TabBar from "../../../components/CustomComponent/header-tabs/Tabbar";
import { ESalesAnalyticsTab } from "../../../utils/Enums";
import {
  salesAnalysisAllTimeOptionValue,
  salesAnalysisPeriodOptions,
} from "../salesAnalyticsConstants";

interface SalesAnalyticsHeaderProps {
  batchName: string;
  days: number;
  setDays: Dispatch<SetStateAction<number>>;
  selectedTab: string;
  setSelectedTab: Dispatch<SetStateAction<string>>;
}

const SalesAnalyticsHeader: FunctionComponent<SalesAnalyticsHeaderProps> = (
  props
) => {
  const tabEnumTextMap = useMemo(() => {
    let indexMap = new Map([
      [ESalesAnalyticsTab[ESalesAnalyticsTab.OVERVIEW], "Overview"],
      [
        ESalesAnalyticsTab[ESalesAnalyticsTab.STUDENT_STATISTICS],
        "Students Statistics",
      ],
    ]);

    return indexMap;
  }, []);

  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className="py-2">
      <Grid
        container
        xs={12}
        className="d-flex align-items-center mt-2 px-2 px-sm-4"
      >
        <div className="d-flex align-items-center">
          <Typography variant="h5">
            <b>{props.batchName}</b>
          </Typography>
        </div>

        <Divider className="w-100"></Divider>

        <Grid
          container
          className="d-flex justify-content-between align-items-center mt-2"
        >
          <div className="px-2 px-sm-4">
            <TabBar
              selectedTab={props.selectedTab}
              setSelectedTab={props.setSelectedTab}
              tabEnumTextMap={tabEnumTextMap}
            />
          </div>
          <div>
            <FormControl sx={{ px: 1, width: "100%" }}>
              <Select
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                value={props.days}
                style={{ background: "#00ADB5BA", color: "white" }}
                className="rounded-4"
                sx={{ outline: "0" }}
              >
                {salesAnalysisPeriodOptions.map((el) => {
                  return (
                    <MenuItem onClick={() => props.setDays(el)} value={el}>
                      {el} days
                    </MenuItem>
                  );
                })}
                <MenuItem
                  onClick={() => props.setDays(salesAnalysisAllTimeOptionValue)}
                  value={salesAnalysisAllTimeOptionValue}
                >
                  All time
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SalesAnalyticsHeader;
