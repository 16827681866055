import { Form } from "react-bootstrap";
import { EAnswerType, ERole } from "../../../../../utils/Enums";
import { useContext, useEffect, useState } from "react";
import { AppContext, TAppContext } from "../../../../../context/AppContext";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../../context/AssessmentContext";
import { setResponse } from "../../../../../api/responses";
import { displayErrorToast } from "../../../../../utils/ToastUtils";
import {
  AttemptContext,
  TAttemptContext,
} from "../../../../../context/AttemptContext";
import {
  TQuestionContext,
  QuestionContext,
} from "../../../../../context/QuestionContext";
import { parseMapFromJSON } from "../../../../../utils/hooks/useStickyState";
import moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";

/**
 * Takes allow change as a props which is used to determine if the user can type in asnwers and modify it or not
 */

type TProps = {
  allowChange?: boolean;
};

export default function Numerical({ allowChange }: TProps) {
  const { currentQuestionData, limits, setLimits } =
    useContext<TAssessmentContext>(AssessmentContext);
  const { role, accessToken } = useContext<TAppContext>(AppContext);
  const { currentSession } = useContext<TAttemptContext>(AttemptContext);
  const [answer, setAnswer] = useState<string>("");
  const { assessmentQuestionInfo, setAssessmentQuestionInfo } =
    useContext<TQuestionContext>(QuestionContext);

  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    let ans =
      role === ERole.STUDENT
        ? assessmentQuestionInfo.get(currentQuestionData.question_id)
            ?.questionAnswer
        : currentQuestionData.answer;
    setAnswer(ans ?? "");
  }, [currentQuestionData, assessmentQuestionInfo]);

  useEffect(() => {
    if (!allowChange) setDisabled(false);
    else if (role === ERole.TEACHER) setDisabled(false);
    else setDisabled(limits.get(currentQuestionData.limitId)!!.limit <= 0);
  }, [currentQuestionData.question_id, answer]);

  async function handleOnChange(item) {
    let updatedQuestionInfo = new Map(assessmentQuestionInfo);
    const qInfo = updatedQuestionInfo.get(currentQuestionData.question_id)!!;
    if (
      disabled &&
      qInfo.questionState !== EAnswerType.VISITED_ANSWERED &&
      qInfo.questionState !== EAnswerType.FLAGGED_ANSWERED
    )
      return;
    if (
      item.target.value !== "" &&
      qInfo.questionState === EAnswerType.VISITED_UNANSWERED
    ) {
      let updatedLimits = new Map(limits);
      let updatedLimit = limits.get(currentQuestionData.limitId)!!;
      updatedLimit.limit -= 1;
      updatedLimits.set(currentQuestionData.limitId, updatedLimit);
      setLimits(updatedLimits);
      qInfo.questionState = EAnswerType.VISITED_ANSWERED;
    } else if (
      item.target.value !== "" &&
      qInfo.questionState === EAnswerType.FLAGGED_UNANSWERED
    ) {
      let updatedLimits = new Map(limits);
      let updatedLimit = limits.get(currentQuestionData.limitId)!!;
      updatedLimit.limit -= 1;
      updatedLimits.set(currentQuestionData.limitId, updatedLimit);
      setLimits(updatedLimits);
      qInfo.questionState = EAnswerType.FLAGGED_ANSWERED;
    } else if (
      item.target.value === "" &&
      qInfo.questionState === EAnswerType.VISITED_ANSWERED
    ) {
      let updatedLimits = new Map(limits);
      let updatedLimit = limits.get(currentQuestionData.limitId)!!;
      updatedLimit.limit += 1;
      updatedLimits.set(currentQuestionData.limitId, updatedLimit);
      setLimits(updatedLimits);
      qInfo.questionState = EAnswerType.VISITED_UNANSWERED;
    } else if (
      item.target.value === "" &&
      qInfo.questionState === EAnswerType.FLAGGED_ANSWERED
    ) {
      let updatedLimits = new Map(limits);
      let updatedLimit = limits.get(currentQuestionData.limitId)!!;
      updatedLimit.limit += 1;
      updatedLimits.set(currentQuestionData.limitId, updatedLimit);
      setLimits(updatedLimits);
      qInfo.questionState = EAnswerType.FLAGGED_UNANSWERED;
    }
    qInfo.questionAnswer = item.target.value;
    qInfo.questionLastUpdatedOn = moment
      .utc(moment.now())
      .toISOString()
      .slice(0, -1);
    const success = await setResponse(
      accessToken,
      currentSession.id,
      qInfo,
      parseMapFromJSON(window.localStorage.getItem("unpushedAttempts"))
    );
    if (success) {
      updatedQuestionInfo.set(currentQuestionData.question_id, qInfo);
      setAssessmentQuestionInfo(updatedQuestionInfo);
    } else {
      displayErrorToast(
        "Something went wrong, you might want to check your local time or internet connection"
      );
    }
  }

  return (
    <>
      {disabled &&
        role === ERole.STUDENT &&
        !(
          assessmentQuestionInfo.get(currentQuestionData.question_id)
            ?.questionState === EAnswerType.FLAGGED_ANSWERED ||
          assessmentQuestionInfo.get(currentQuestionData.question_id)
            ?.questionState === EAnswerType.VISITED_ANSWERED
        ) && (
          <div
            className="bg-warning p-2 rounded-4"
            style={{ textAlign: "center" }}
          >{`You have attempted maximum questions allowed in the range ${
            limits.get(currentQuestionData.limitId)?.start_question_idx
          } to ${
            limits.get(currentQuestionData.limitId)?.end_question_idx
          } in this section`}</div>
        )}
      {role === ERole.STUDENT && allowChange ? (
        <Form
          className="mt-4"
          noValidate
          onSubmit={(event) => {
            event.preventDefault();
            //handleSubmit();
          }}
        >
          <Form.Group controlId="answer">
            <Form.Control
              type="number"
              value={
                assessmentQuestionInfo.get(currentQuestionData.question_id)
                  ?.questionAnswer
              }
              placeholder={
                role === ERole.STUDENT ? "Type your answer here" : ""
              }
              onChange={handleOnChange}
              //onBlur={handleBlur}
              className="ml-2 bg-light"
            />
          </Form.Group>
        </Form>
      ) : role === ERole.STUDENT && !allowChange ? (
        <div className="mt-4 ml-2 p-2 bg-light rounded d-flex align-items-center">
          <div>
            {assessmentQuestionInfo.get(currentQuestionData.question_id)
              ?.questionAnswer || "You have not attempted this question"}
          </div>
          <div style={{ marginLeft: "auto" }}>
            {assessmentQuestionInfo.get(currentQuestionData.question_id)
              ?.questionAnswer ? (
              assessmentQuestionInfo.get(currentQuestionData.question_id)
                ?.questionAnswer === currentQuestionData.answer ? (
                <CheckIcon color="success" />
              ) : (
                <ClearIcon color="warning" />
              )
            ) : (
              <CircleOutlinedIcon color="disabled" />
            )}
          </div>
        </div>
      ) : (
        <div className="mt-4">{"Answer: " + currentQuestionData.answer}</div>
      )}
    </>
  );
}
