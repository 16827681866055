import { IconButton, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useContext, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { patchComprehension } from "../../../../api/comprehension";
import CustomButton from "../../../../components/CustomComponent/CustomButton";
import { AppContext, TAppContext } from "../../../../context/AppContext";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../context/AssessmentContext";
import { IQuestionBody } from "../../../../models/common";
import { displaySuccessToast } from "../../../../utils/ToastUtils";
import ComprehensionSupport from "../AddQuestionButton/ComprehensionSupport";
import QuestionText from "../QuestionText/QuestionText";
import styles from "./../../../my-tests/MyTests.module.scss";
import CloseIcon from "@mui/icons-material/Close";

type TProps = {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  selectedComprehensionId: number;
  content: IQuestionBody;
  edit?: boolean;
};

export function ComprehensionModal({
  show,
  setShow,
  selectedComprehensionId,
  content,
  edit = false,
}: TProps) {
  const { accessToken } = useContext<TAppContext>(AppContext);
  const { comprehensionMap, currentQuestionData } =
    useContext<TAssessmentContext>(AssessmentContext);

  const updatedComprehensionBody = useRef<IQuestionBody>(
    comprehensionMap.get(selectedComprehensionId)!!.body
  );

  useEffect(() => {
    if (
      currentQuestionData.comprehension_id &&
      currentQuestionData.comprehension_id !== -1
    ) {
      updatedComprehensionBody.current = comprehensionMap.get(
        currentQuestionData.comprehension_id!!
      )!!.body;
    }
  }, [currentQuestionData]);

  const handleClose = () => {
    setShow(false);
    updatedComprehensionBody.current = content;
  };

  const handleSave = async () => {
    const comprehension = await patchComprehension(
      accessToken,
      selectedComprehensionId,
      updatedComprehensionBody.current
    );
    if (!comprehension) return null;
    setShow(false);
    comprehensionMap.set(selectedComprehensionId, comprehension);
    displaySuccessToast("Succesfully saved the comprehension");
  };

  return (
    <Modal
      size="lg"
      contentClassName={styles.modalStyle}
      centered
      show={show}
      onHide={handleClose}
    >
      <Modal.Header>
        <Typography variant="h5">
          <b>{edit ? "Edit" : "View"} Comprehension</b>
        </Typography>
        <IconButton>
          <CloseIcon onClick={() => setShow(false)} />
        </IconButton>
      </Modal.Header>

      <Modal.Body>
        {edit && (
          <Typography fontSize={20} color="#9AA0B2">
            <b>Edit the following comprehension below</b>
          </Typography>
        )}
        {edit ? (
          <ComprehensionSupport
            updatedComprehensionBody={updatedComprehensionBody}
          />
        ) : (
          <div style={{ backgroundColor: "#CDCDCD" }} className="rounded-4 p-2">
            <QuestionText images={content.images} children={content.text} />
          </div>
        )}
      </Modal.Body>
      {edit && (
        <Modal.Footer>
          <CustomButton className="bg-danger" onClick={handleClose}>
            Discard
          </CustomButton>
          <CustomButton onClick={handleSave}>Save</CustomButton>
        </Modal.Footer>
      )}
    </Modal>
  );
}
