import {
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  Tooltip,
  IconButton,
} from "@mui/material";
import { TAnalyticsStudentTimed } from "../../../models/analyticsmodel";
import chartData from "../Components/chartData";
import Chart from "react-apexcharts";
import { PieChart, Pie, Cell } from "recharts";
import ComparisonCard from "../Components/ComparisonCard";
import { getFormattedDuration } from "../../../utils/dateUtils";
import { BsInfoCircle } from "react-icons/bs";
import {
  ATTEMPTOR_TIME_DISTRIBUTION,
  SPEED_ANALYSIS,
  TIME_SPENT_PER_DIFF,
} from "../../../constants/analyticsInfo";
import { forEach } from "@tiptap/core/dist/packages/core/src/commands";
import { checkIfAllZeroes } from "../../../utils/analyticsUtils";

type TProps = {
  data: TAnalyticsStudentTimed;
  selectedSectionName: string;
  isLoading: boolean;
};

export default function StudentTimedAnalysis(props: TProps) {
  const isMobile = useMediaQuery("(max-width: 576px)");
  let pieData: any[] = [];
  const pieLegendMap: Map<string, string> = new Map([
    ["correct", "#3FB93D"],
    ["incorrect", "#D11919"],
    ["unattempted", "#666666"],
  ]);

  const time_spent_per_difficulty: Map<string, any> = new Map([
    ["easy", { median_time: "NA", topper: "NA", student: "NA", total: 0 }],
    ["medium", { median_time: "NA", topper: "NA", student: "NA", total: 0 }],
    ["hard", { median_time: "NA", topper: "NA", student: "NA", total: 0 }],
  ]);

  if (props.data.time_distribution.length !== 0) {
    pieData = props.data.time_distribution.map((el) => {
      return {
        name: el["evaluation_status"],
        time_taken: el["time_taken"],
      };
    });
  }

  if (props.data.time_spent_per_difficulty.length !== 0) {
    props.data.time_spent_per_difficulty.forEach((el) => {
      let comparisonData = time_spent_per_difficulty.get(
        el["difficulty_level"]
      );
      if (comparisonData !== undefined) {
        comparisonData.median_time = el["median_time"];
        comparisonData.topper = el["topper"];
        comparisonData.student = el["student"];
        comparisonData.total = el["total"];
      }
      time_spent_per_difficulty.set(el["difficulty_level"], comparisonData);
    });
  }

  const iconbgColor = ["#3FB93D87", "#FFBA69DB", "#630718"];
  const iconColor = ["#107a43", "#7d530a", "#EDC9AF"];

  return (
    <div className="px-0">
      <div className="px-2 px-sm-4">
        <div
          className="px-2 px-sm-4 rounded-4 py-2 py-sm-4 w-100"
          style={{ background: isMobile ? "white" : "#F0F0F0" }}
        >
          <div className="d-flex justify-content-between align-items-center ">
            <Typography
              style={{ color: "#75777D", fontWeight: "700" }}
              className="mb-2"
            >
              Time spent per question
            </Typography>
            <Tooltip
              enterTouchDelay={0}
              title={TIME_SPENT_PER_DIFF}
              placement="top-start"
            >
              <IconButton>
                <BsInfoCircle className="mx-2" size={20} />
              </IconButton>
            </Tooltip>
          </div>

          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 2, sm: 4 }}
            sx={{ my: 1 }}
            className="justify-content-center"
          >
            {Array.from(time_spent_per_difficulty).map((el, index) => (
              <Grid item md={4} lg={6} xs={12}>
                <ComparisonCard
                  data={"Time"}
                  icon={
                    <Typography color={iconColor[index]}>
                      {el[1]["total"]}
                    </Typography>
                  }
                  studentData={el[1]["student"]}
                  topperData={el[1]["topper"]}
                  averageData={el[1]["median_time"]}
                  cardTitle={`${el[0]} Questions`}
                  iconbgColor={iconbgColor[index]}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </div>

      <div className="px-2 px-sm-4 pb-4 my-3">
        <div className="mt-2 w-100">
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <div
                className={
                  "rounded-4 px-2 px-sm-4 py-2 h-100 " +
                  (isMobile ? "" : "bg-card")
                }
              >
                <div className="d-flex justify-content-between align-items-center py-2 w-100">
                  <Typography color="#75777D">
                    <b>Total Time Spent</b>
                  </Typography>
                  <Tooltip
                    enterTouchDelay={0}
                    title={ATTEMPTOR_TIME_DISTRIBUTION}
                    placement="top-start"
                  >
                    <IconButton>
                      <BsInfoCircle className="mx-2" size={20} />
                    </IconButton>
                  </Tooltip>
                </div>
                <Divider className="w-100" />
                <div
                  className={
                    "d-flex align-items-center " +
                    (isMobile ? "flex-column " : "") +
                    (checkIfAllZeroes(
                      props.data.time_distribution,
                      "time_taken"
                    )
                      ? "justify-content-center py-5"
                      : "justify-content-between py-3")
                  }
                >
                  {checkIfAllZeroes(
                    props.data.time_distribution,
                    "time_taken"
                  ) ? (
                    <Typography
                      color="#6fafd6"
                      fontSize="18px"
                      fontWeight="800"
                    >
                      No data available
                    </Typography>
                  ) : (
                    <PieChart width={200} height={200}>
                      <Pie
                        data={pieData}
                        dataKey="time_taken"
                        outerRadius={100}
                      >
                        {props.data.time_distribution.map((el) => (
                          <Cell
                            key={el["evaluation_status"]}
                            fill={pieLegendMap.get(el["evaluation_status"])}
                          />
                        ))}
                      </Pie>
                    </PieChart>
                  )}
                  <div>
                    {props.data.time_distribution.map((el) => {
                      return (
                        <Typography>
                          <span
                            style={{
                              color: pieLegendMap.get(el["evaluation_status"]),
                              fontWeight: "700",
                              fontSize: "20px",
                            }}
                          >
                            {getFormattedDuration(el["time_taken"])}
                          </span>
                          <span> on {el["evaluation_status"]}</span>
                        </Typography>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Grid>

            {isMobile && <Divider className="w-100" />}
            <Grid item xs={12} md={7}>
              <div className="h-100">
                <div
                  className={
                    "rounded-4 px-0 px-sm-4 py-2 h-100 " +
                    (isMobile ? "" : "bg-card")
                  }
                >
                  <div className="d-flex justify-content-between align-items-center py-2 w-100">
                    <Typography color="#75777D">
                      <b>Number of Questions/Time taken</b>
                    </Typography>
                    <Tooltip
                      enterTouchDelay={0}
                      title={SPEED_ANALYSIS}
                      placement="top-start"
                    >
                      <IconButton>
                        <BsInfoCircle className="mx-2" size={20} />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <Divider className="w-100" />

                  {!props.isLoading && (
                    <Chart
                      {...chartData(
                        props.data.speed_analysis.data,
                        props.data.speed_analysis.labels,
                        isMobile,
                        1,
                        undefined,
                        ["#ADDDD0", "#87A2FB", "#6F38C5"]
                      )}
                      type="bar"
                    />
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
