import { FunctionComponent } from "react";
import { EQuestionType } from "../../../../utils/Enums";
import Numerical from "./Numerical/Numerical";
import MultipleChoice from "./MultipleChoice/MultipleChoice";

interface QuestionTypeProps {
  questionType: EQuestionType;
  allowChange?: boolean;
}

const QuestionType: FunctionComponent<QuestionTypeProps> = (props) => {
  switch (props.questionType) {
    case EQuestionType.MCMCQ:
    case EQuestionType.MCQ:
      return <MultipleChoice allowChange={props.allowChange} />;
    case EQuestionType.NUMERICAL:
      return <Numerical allowChange={props.allowChange} />;
    default:
      return <div />;
  }
};

QuestionType.defaultProps = {
  allowChange: true,
};

export default QuestionType;
