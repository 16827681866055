import { useState } from "react";
import { Modal, Image } from "react-bootstrap";
import useMediaQuery from "@mui/material/useMediaQuery";
import styles from "./../../QuestionScreen.module.scss";

interface QuestionImageModal {
  src: string;
}

export default function QuestionImageModal(props: QuestionImageModal) {
  const [show, setShow] = useState<boolean>(false);

  return (
    <>
      <Modal
        dialogClassName={styles.questionImageModal}
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header className="justify-content-end" closeButton />
        <Modal.Body className="d-flex justify-content-center">
          <div className="overflow-auto">
            <Image src={props.src}></Image>
          </div>
        </Modal.Body>
      </Modal>
      <Image
        fluid
        src={props.src}
        style={{
          cursor: "pointer",
        }}
        alt="question-image"
        onClick={() => {
          setShow(true);
        }}
      />
    </>
  );
}
