import { Dispatch, FunctionComponent, SetStateAction, useContext } from "react";
import { MembershipStatus } from "../../utils/Enums";

import { IconButton, Typography } from "@mui/material";

import { Button, Col, Modal, Row } from "react-bootstrap";

import { patchMembershipStatus, removeUsersFromBatch } from "../../api/batches";
import { AppContext, TAppContext } from "../../context/AppContext";

import styles from "./MyTests.module.scss";
import { displayErrorToast, displaySuccessToast } from "../../utils/ToastUtils";
import CloseIcon from "@mui/icons-material/Close";
import { auto } from "@popperjs/core";

interface BatchInfoModalProps {
  batchName: string;
  batchCode: string;
  batchId: number;
  batchDescription?: string;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  membershipStatus?: MembershipStatus;
  forceReload: boolean;
  setForceReload: Dispatch<SetStateAction<boolean>>;
}

const BatchInfoModal: FunctionComponent<BatchInfoModalProps> = ({
  batchName,
  batchCode,
  batchId,
  batchDescription,
  show,
  setShow,
  membershipStatus,
  forceReload,
  setForceReload,
}) => {
  const { accessToken, id, email } = useContext<TAppContext>(AppContext);

  // The following two functions can diverge in future
  const handleReRequest = async () => {
    const responseStatus = await patchMembershipStatus(
      accessToken,
      batchId,
      [id],
      MembershipStatus.PENDING
    );
    if (responseStatus === null) {
      displayErrorToast();
    } else {
      displaySuccessToast("Succesfully raised a request again.");
    }
    setShow(false);
    setForceReload(!forceReload);
  };

  const handleLeaveBatch = async () => {
    const responseStatus = await removeUsersFromBatch(accessToken, batchId, [
      { user_id: id, email: email },
    ]);
    if (responseStatus === null) {
      displayErrorToast();
    } else {
      displaySuccessToast(
        "You have left the batch. If you wish to join again, please raise a request again."
      );
    }
    setShow(false);
    setForceReload(!forceReload);
  };

  function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return `<a target="blank" href=${url}>${url}</a>`;
    });
  }

  return (
    <Modal
      contentClassName={styles.modalStyle}
      show={show}
      onHide={() => setShow(false)}
      centered
    >
      <Modal.Header>
        <Typography variant="h4">
          <b>Batch Details</b>
        </Typography>
        <IconButton>
          <CloseIcon onClick={() => setShow(false)} />
        </IconButton>
      </Modal.Header>
      <Modal.Body style={{ overflow: auto }}>
        <Typography fontSize={20} color="#9AA0B2">
          <b>
            <Row className="py-2">
              <Col xs={6}>Batch Name:</Col>
              <Col xs={6}>{batchName}</Col>
            </Row>
            <Row className="py-2">
              <Col xs={6}>Batch Code:</Col>
              <Col xs={6}>{batchCode}</Col>
            </Row>
            <Row className="py-2">
              <Col xs={6}>Membership status:</Col>
              <Col xs={6}>
                {membershipStatus !== undefined
                  ? MembershipStatus[membershipStatus]
                  : "-"}
              </Col>
            </Row>
            {membershipStatus === MembershipStatus.APPROVED &&
              batchDescription && (
                <Row className="py-2">
                  <Col xs={6}>Further Steps:</Col>
                  <Col xs={6}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: urlify(batchDescription),
                      }}
                    ></div>
                  </Col>
                </Row>
              )}
          </b>
        </Typography>
      </Modal.Body>
      {membershipStatus === MembershipStatus.REJECTED && (
        <Modal.Footer>
          <Button
            className="border-0 rounded-2 bg-secondary"
            type="submit"
            onClick={handleReRequest}
          >
            <Typography className="m-0 p-0">Raise request again</Typography>
          </Button>
          <Button
            className="border-0 rounded-2 bg-danger"
            type="submit"
            color="error"
            onClick={handleLeaveBatch}
          >
            <Typography className="m-0 p-0">Leave Batch</Typography>
          </Button>
        </Modal.Footer>
      )}
      {(membershipStatus === MembershipStatus.APPROVED ||
        membershipStatus === MembershipStatus.PENDING) && (
        <Modal.Footer>
          <Button
            className="border-0 rounded-2 bg-danger"
            type="submit"
            color="error"
            onClick={handleLeaveBatch}
          >
            <Typography className="m-0 p-0">Leave Batch</Typography>
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default BatchInfoModal;
