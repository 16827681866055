import {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { AppContext, TAppContext } from "../../context/AppContext";
import {
  TPublishedAssessmentData,
  TPublishInfo,
} from "../../models/assessmentmodel";
import {
  EAccessLevel,
  EPublishStatus,
  MembershipStatus,
} from "../../utils/Enums";
import {
  deleteAssessment,
  duplicateAssessment,
  fetchAssessmentsAttemptedByUser,
  fetchAssessmentsCreatedByUser,
  fetchAssessmentsForStudent,
} from "../../api/assessment";
import { getFormattedDateAndTime } from "../../utils/dateUtils";
import moment from "moment";
import { useStickyState } from "../../utils/hooks/useStickyState";
import { Col, Row } from "react-bootstrap";
import copy from "copy-to-clipboard";

// MUI imports
import {
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ShareIcon from "@mui/icons-material/Share";
import ClearIcon from "@mui/icons-material/Clear";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import EditIcon from "@mui/icons-material/Edit";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import ConfirmationModal from "../../components/ConfirmationModal";
import EditAssessmentNameModal from "../instructionScreen/ManageAssessmentScreen/Components/EditAssessmentNameModal";
import AttemptTestModal from "./AttemptTestModal/AttemptTestModal";
import PublishTestModal from "./PublishTestModal/PublishTestModal";

import { displayErrorToast, displaySuccessToast } from "../../utils/ToastUtils";
import CreateTestModal from "./CreateTestModal/CreateTestModal";
import { BatchCard } from "./BatchCard";
import { HomeContext, THomeContext } from "../../context/HomeContext";
import { fetchAndInitialiseBatches } from "../batch-management/utils/ApiHelper";
import { useHistory } from "react-router-dom";
import { TBatch } from "../../models/batches";

import styles from "./MyTests.module.scss";
import ActionsDrawer from "./ActionsDrawer.tsx/ActionsDrawer";
import JoinOrCreateBatchModal from "../batch-management/components/JoinOrCreateBatchModal";
import Loading from "../../components/Loading/Loading";
import BatchInfoModal from "./BatchInfoModal";
import { ShareAssessment } from "../../constants/inviteLink";
import { getServerTime } from "../../api/user";
import { disableAnalytics } from "../../utils/analyticsUtils";
import { Marquee } from "../../components/CustomComponent/Marquee";
import TabBar from "../../components/CustomComponent/header-tabs/Tabbar";

enum EFilterType {
  ALL = 0,
  CREATED = 1,
  ATTEMPTED = 2,
  SEARCH = 3,
  COMPLETED = 4,
  ONGOING = 5,
  UPCOMING = 6,
}

enum ESearchFilter {
  NAME = 0,
  CODE = 1,
}

const MyTests: FunctionComponent = () => {
  const history = useHistory();

  const isMobile = useMediaQuery("(max-width: 576px)");
  const isDesktopOrLaptop = useMediaQuery("(min-width: 992px)");

  const { accessToken, id, org } = useContext<TAppContext>(AppContext);
  const { batches, setBatches } = useContext<THomeContext>(HomeContext);

  const [unfilteredTests, setUnfilteredTests] = useStickyState<
    Array<TPublishedAssessmentData>
  >([], "dashboard_tests");
  const [tests, setTests] =
    useState<Array<TPublishedAssessmentData>>(unfilteredTests);

  const [searchFilter, setSearchFilter] = useState<ESearchFilter>(
    ESearchFilter.NAME
  );

  const [selectedTestIndex, setSelectedTestIndex] = useState<number>(-1);
  const [selectedBatch, setSelectedBatch] = useState<TBatch>();

  // Modal Handling State Vars
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [showEditAssessmentModal, setShowEditAssessmentModal] =
    useState<boolean>(false);
  const [showEditPublishDetailsModal, setShowEditPublishDetailsModal] =
    useState<boolean>(false);
  const [showAttemptTestModal, setShowAttemptTestModal] =
    useState<boolean>(false);
  const [showCreateTestModal, setShowCreateTestModal] =
    useState<boolean>(false);
  const [showAddBatchModal, setShowAddBatchModal] = useState<boolean>(false);
  const [showBatchInfoModal, setShowBatchInfoModal] = useState<boolean>(false);

  // Causes a re-render which will trigger the fetch tests request again
  const [forceReload, setForceReload] = useState<boolean>(false);
  const [serverTime, setServerTime] = useState<any>(moment.utc());
  const [selectedTab, setSelectedTab] = useState<string>(
    EFilterType[EFilterType.ALL]
  );

  const handleShare = (
    testName: string,
    publishCode: string,
    publishInfo?: TPublishInfo
  ) => {
    if (!isPublished(publishInfo)) {
      return setShowEditPublishDetailsModal(true);
    }
    const success = copy(ShareAssessment(testName, publishCode, publishInfo));
    if (!success) {
      displayErrorToast("Failed to copy link");
      return;
    }
    displaySuccessToast("Link copied to the clipboard");
  };

  const handleTestDelete = async (testId: number) => {
    const deleteTestResponse = await deleteAssessment(accessToken, testId);
    if (deleteTestResponse !== null) {
      displaySuccessToast("Assessment Deleted successfully");
    }
    setShowConfirmationModal(false);
    setForceReload(!forceReload);
  };

  const handleOpenTest = (test: TPublishedAssessmentData) => {
    const testId = test.assessment.id;

    const publishCode = test.publishInfo?.publish_code;
    if (parseInt(test.assessment.created_by) !== id) {
      history.push({
        pathname: `/home/attempt/${publishCode}/instructions`,
      });
    } else {
      history.push({
        pathname: `/home/assessments/${testId}/manage`,
        state: {
          publishInfo: test.publishInfo,
          batchInfo: test.batchInfo,
          previousRoute: "/home/dashboard",
        },
      });
    }
  };

  const handleAnalytics = (test: TPublishedAssessmentData) => {
    const testId = test.assessment.id;
    const publishCode = test.publishInfo?.publish_code;
    if (parseInt(test.assessment.created_by) !== id) {
      history.push(`/home/attempt/${publishCode}/analytics`);
    } else {
      history.push(`/home/assessments/${testId}/analytics/${publishCode}`);
    }
  };

  const handleDuplicateTest = async (testId: number) => {
    const duplicateTestResponse = await duplicateAssessment(
      accessToken,
      testId
    );
    if (duplicateTestResponse !== null) {
      displaySuccessToast("Assessment Duplicated Successfully");
    }
    setForceReload(!forceReload);
  };

  const processPublishedAssessment = (assessment) => {
    let publishedAssessmentInfo = {} as TPublishedAssessmentData;
    publishedAssessmentInfo.assessment = assessment.assessment;
    if (assessment.publish_info) {
      publishedAssessmentInfo.publishInfo = assessment.publish_info;
      publishedAssessmentInfo.batchInfo = assessment.publish_info.batch;
    }
    return publishedAssessmentInfo;
  };

  // state variables and methods controlling the bottom drawer
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const showBottomDrawer = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onPublishModalUpdate = (res: any) => {
    setForceReload(!forceReload);
    if (!res || res.status === EPublishStatus.UNPUBLISHED) return;
    const success = copy(
      `https://app.quriate.com/home/attempt/${res.publish_code}/instructions`
    );
    if (!success) return;
    displaySuccessToast(
      "Link to the published assessment was copied to the clipboard"
    );
  };

  const isPublished = (publishInfo?: TPublishInfo) => {
    return publishInfo && publishInfo?.status === EPublishStatus.PUBLISHED;
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      await fetchAndInitialiseBatches(org, accessToken, setBatches);
    })();
  }, [org, accessToken, setBatches, showAddBatchModal]);

  useEffect(() => {
    (async () => {
      let created_tests: Array<TPublishedAssessmentData>,
        attempted_tests: Array<TPublishedAssessmentData>,
        assigned_tests: Array<TPublishedAssessmentData>;
      setIsLoading(true);

      created_tests = await fetchAssessmentsCreatedByUser(accessToken);
      attempted_tests = await fetchAssessmentsAttemptedByUser(accessToken);
      assigned_tests = await fetchAssessmentsForStudent(accessToken);
      await fetchAndInitialiseBatches(org, accessToken, setBatches);

      if (!created_tests && !attempted_tests && !assigned_tests) return;

      created_tests = created_tests?.map((el) =>
        processPublishedAssessment(el)
      );

      attempted_tests = attempted_tests?.map((el) =>
        processPublishedAssessment(el)
      );

      assigned_tests = assigned_tests?.map((el) =>
        processPublishedAssessment(el)
      );

      setTests([...created_tests, ...assigned_tests, ...attempted_tests]);
      setUnfilteredTests([
        ...created_tests,
        ...assigned_tests,
        ...attempted_tests,
      ]);

      const serverTime = await getServerTime(accessToken);
      setServerTime(serverTime);

      setIsLoading(false);
      setSelectedTab(EFilterType[EFilterType.ALL]);
    })();
  }, [accessToken, forceReload]);

  useEffect(() => {
    filterTests(EFilterType[selectedTab]);
  }, [selectedTab]);

  const columnsDesktop = useMemo(
    () => [
      {
        field: "testName",
        headerName: "Test name",
        flex: 2,
        headerClassName: styles.header,
        renderCell: (cellValues: any) => {
          return (
            <div style={{ width: "300px" }}>
              <Marquee disable={cellValues.value.length < 45}>
                {cellValues.value}
              </Marquee>
            </div>
          );
        },
      },
      {
        field: "accessLevel",
        headerName: "Published To",
        flex: 1,
        headerClassName: styles.header,
        renderCell: (cellValues: any) => {
          return cellValues.value === "PUBLIC" ||
            cellValues.value === "Unpublished" ? (
            <i style={{ color: "grey" }}>{cellValues.value}</i>
          ) : (
            <div style={{ width: "170px" }}>
              <Marquee disable={cellValues.value.length < 25}>
                {cellValues.value}
              </Marquee>
            </div>
          );
        },
      },
      {
        field: "publishCode",
        headerName: "Test Code",
        flex: 1,
        headerClassName: styles.header,
        renderCell: (cellValues: any) => {
          return cellValues.value === "PUBLIC" ||
            cellValues.value === "Unpublished" ? (
            <i style={{ color: "grey" }}>{cellValues.value}</i>
          ) : (
            cellValues.value
          );
        },
      },
      {
        field: "deadline",
        headerName: "Deadline",
        flex: 1,
        headerClassName: styles.header,
        renderCell: (cellValues: any) => {
          return cellValues.value === "PUBLIC" ||
            cellValues.value === "Unpublished" ? (
            <i style={{ color: "grey" }}>
              {cellValues.value === "Invalid date"
                ? "No Deadline"
                : cellValues.value}
            </i>
          ) : (
            <>
              {cellValues.value === "Invalid date"
                ? "No Deadline"
                : cellValues.value}
            </>
          );
        },
      },
      {
        field: "actions",
        type: "actions",
        headerName: "_",
        headerClassName: styles.actionHeader,
        flex: 1,
        getActions: (params) => [
          <GridActionsCellItem
            // disable analytics if assessment is not published, or if not created by current user, the deadline has not ended
            disabled={
              !isPublished(tests[params.id].publishInfo) ||
              (parseInt(tests[params.id].assessment?.created_by!!) !== id &&
                disableAnalytics(
                  tests[params.id].publishInfo?.format,
                  serverTime,
                  tests[params.id].publishInfo?.end_time
                ))
            }
            icon={
              <Tooltip title="Analytics">
                <AnalyticsIcon
                  fontSize={isDesktopOrLaptop ? "large" : "medium"}
                  htmlColor={
                    !isPublished(tests[params.id].publishInfo) ||
                    (parseInt(tests[params.id].assessment?.created_by!!) !==
                      id &&
                      disableAnalytics(
                        tests[params.id].publishInfo?.format,
                        serverTime,
                        tests[params.id].publishInfo?.end_time
                      ))
                      ? ""
                      : "#0089AF"
                  }
                />
              </Tooltip>
            }
            label="Analytics"
            onClick={() => {
              handleAnalytics(tests[params.id]);
            }}
            showInMenu={!isDesktopOrLaptop}
          />,
          <GridActionsCellItem
            label="Share"
            onClick={() => {
              setSelectedTestIndex(params.id);
              handleShare(
                tests[params.id].assessment.name,
                tests[params.id].publishInfo?.publish_code!!,
                tests[params.id].publishInfo
              );
            }}
            icon={
              <Tooltip title="Share">
                <ShareIcon
                  fontSize={isDesktopOrLaptop ? "large" : "medium"}
                  htmlColor="#3F65C8"
                />
              </Tooltip>
            }
            showInMenu={!isDesktopOrLaptop}
          />,
          <GridActionsCellItem
            label="Open"
            icon={
              <Tooltip title="Open">
                <OpenInNewIcon
                  fontSize={isDesktopOrLaptop ? "large" : "medium"}
                  color="success"
                />
              </Tooltip>
            }
            onClick={() => handleOpenTest(tests[params.id])}
            showInMenu={!isDesktopOrLaptop}
          />,
          <GridActionsCellItem
            disabled={
              parseInt(tests[params.id].assessment.created_by) !== id ||
              !tests[params.id].publishInfo
            }
            icon={<EditIcon fontSize="medium" htmlColor="#22343C" />}
            label="Edit Publish Info"
            onClick={() => {
              setSelectedTestIndex(params.id);
              setShowEditPublishDetailsModal(true);
            }}
            showInMenu
          />,
          <GridActionsCellItem
            disabled={parseInt(tests[params.id].assessment.created_by) !== id}
            icon={
              <DriveFileRenameOutlineIcon fontSize="medium" color="primary" />
            }
            label="Rename"
            onClick={() => {
              setSelectedTestIndex(params.id);
              setShowEditAssessmentModal(true);
            }}
            showInMenu
          />,
          <GridActionsCellItem
            disabled={parseInt(tests[params.id].assessment.created_by) !== id}
            icon={<FileCopyIcon fontSize="medium" />}
            label="Duplicate"
            onClick={() => handleDuplicateTest(tests[params.id].assessment.id)}
            showInMenu
          />,
          <GridActionsCellItem
            disabled={
              parseInt(tests[params.id].assessment.created_by) !== id ||
              !!tests[params.id].publishInfo
            }
            icon={<DeleteIcon fontSize="medium" color="error" />}
            label="Delete"
            onClick={() => {
              setSelectedTestIndex(params.id);
              setShowConfirmationModal(true);
            }}
            showInMenu
          />,
        ],
      },
    ],
    [isDesktopOrLaptop, tests]
  );

  const columnsMobile = useMemo(
    () => [
      {
        field: "testName",
        headerName: "Test name",
        headerClassName: styles.header,
        flex: 5,
        renderCell: (cellValues: any) => {
          return (
            <div style={{ width: "200px" }}>
              <Marquee
                disable={cellValues.value.length < 30}
                overridePlay={true}
              >
                {cellValues.value}
              </Marquee>
            </div>
          );
        },
      },

      {
        field: "deadline",
        headerName: "Deadline",
        headerClassName: styles.header,
        flex: 3,
        renderCell: (cellValues: any) => {
          return cellValues.value === "PUBLIC" ||
            cellValues.value === "Unpublished" ? (
            <i style={{ color: "grey" }}>
              {cellValues.value === "Invalid date"
                ? "No Deadline"
                : cellValues.value}
            </i>
          ) : cellValues.value === "Invalid date" ? (
            "No Deadline"
          ) : (
            cellValues.value
          );
        },
      },
      {
        field: "actions",
        headerName: "Options",
        headerClassName: styles.actionHeader,
        width: 25,
        renderCell: (cellValues: any) => (
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={(e) => {
              setSelectedTestIndex(cellValues.id);
              showBottomDrawer(e);
            }}
          >
            <MoreVertIcon />
          </IconButton>
        ),
      },
    ],
    [tests]
  );

  const rows = useMemo(
    () =>
      tests
        .sort(
          (a, b) =>
            moment(b.assessment.created_at).unix() -
            moment(a.assessment.created_at).unix()
        )
        .map((el, idx) => {
          return {
            id: idx,
            accessLevel: isPublished(el.publishInfo)
              ? el.publishInfo?.access_level === EAccessLevel.BATCH
                ? el.batchInfo?.name
                : "PUBLIC"
              : "Unpublished",
            testName: el.assessment.name,
            publishCode: isPublished(el.publishInfo)
              ? el.publishInfo?.publish_code
              : "Unpublished",
            deadline: isPublished(el.publishInfo)
              ? getFormattedDateAndTime(moment.utc(el.publishInfo?.end_time))
              : "Unpublished",
          };
        }),
    [tests]
  );

  const filterTests = (filter: EFilterType, searchString?: string) => {
    switch (filter) {
      case EFilterType.ALL:
        return setTests([...unfilteredTests]);

      case EFilterType.CREATED:
        return setTests([
          ...unfilteredTests.filter(
            (el) => parseInt(el.assessment.created_by) === id
          ),
        ]);

      case EFilterType.ATTEMPTED:
        return setTests([...tests.filter((el) => el !== el)]);

      case EFilterType.SEARCH:
        if (!searchString) return setTests(unfilteredTests);
        return setTests([
          ...tests.filter((el) => {
            if (searchFilter === ESearchFilter.NAME) {
              return el.assessment.name
                .toLowerCase()
                .includes(searchString.toLowerCase());
            } else if (searchFilter === ESearchFilter.CODE) {
              return el.publishInfo?.publish_code.includes(searchString);
            }
          }),
        ]);

      case EFilterType.ONGOING:
        return setTests([
          ...unfilteredTests.filter((el) =>
            moment
              .utc()
              .isBetween(
                moment.utc(el.publishInfo?.start_time),
                moment.utc(el.publishInfo?.end_time)
              )
          ),
        ]);

      case EFilterType.UPCOMING:
        return setTests([
          ...unfilteredTests.filter((el) =>
            moment.utc().isBefore(moment.utc(el.publishInfo?.start_time))
          ),
        ]);

      case EFilterType.COMPLETED:
        return setTests([
          ...unfilteredTests.filter((el) =>
            moment.utc().isAfter(moment.utc(el.publishInfo?.end_time))
          ),
        ]);
    }
  };

  const handleBatchStatus = (batch: TBatch) => {
    switch (batch.membershipStatus) {
      case MembershipStatus.APPROVED:
        return `Batch Code: ${batch.code}`;
      case MembershipStatus.PENDING:
        return `Status: Pending`;
      case MembershipStatus.REJECTED:
      default:
        return `Status: Rejected`;
    }
  };

  const onSearchFilterChange = (e: SelectChangeEvent<ESearchFilter>) => {
    setSearchFilter(e.target.value as ESearchFilter);
  };

  const handleBatchRedirect = (batch: TBatch) => {
    history.push({
      pathname: `/home/batch-management/${batch.code}`,
      batch: batch,
    });
  };

  if (isLoading) return <Loading />;

  let filters = new Map([
    [EFilterType[EFilterType.ALL], "All"],
    [EFilterType[EFilterType.UPCOMING], "Upcoming"],
    [EFilterType[EFilterType.ONGOING], "Ongoing"],
    [EFilterType[EFilterType.COMPLETED], "Completed"],
  ]);

  return (
    <div className="w-100">
      <Typography variant="h4" className="px-2 px-sm-4 px-lg-5 mt-2">
        <b>My Batches</b>
      </Typography>
      <div
        className="px-2 px-sm-4 px-lg-5 py-2 d-flex flex-row"
        style={{ overflowX: "auto" }}
      >
        <div
          className="d-flex flex-row rounded-4 me-2"
          style={{
            backgroundColor: "#F0F0F0",
            height: "90px",
            minWidth: batches ? "75px" : "280px",
          }}
          onClick={() => setShowAddBatchModal(true)}
        >
          <div
            className="rounded-4"
            style={{
              backgroundColor: "#FF5998",
              flex: 1,
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <AddCircleIcon fontSize="large" />
          </div>

          {batches.length !== 0 ? null : (
            <div
              style={{ flex: 2, display: "flex", alignItems: "center" }}
              className="px-2 px-sm-4 px-lg-5"
            >
              Add or Create a batch
            </div>
          )}
        </div>
        {batches.map((batch) => {
          return (
            <Col xs={8} sm={6} lg={4} xl={3} className="pe-2 ps-0">
              <BatchCard
                heading={batch.name}
                summary={handleBatchStatus(batch)}
                isAdmin={batch.adminId === id}
                onClick={() => {
                  setSelectedBatch(batch);
                  if (batch.adminId === id) handleBatchRedirect(batch);
                  else {
                    setShowBatchInfoModal(true);
                  }
                }}
              />
            </Col>
          );
        })}
      </div>
      <Typography className="px-2 px-sm-4 px-lg-5 mt-3" variant="h4">
        <b>My Tests</b>
      </Typography>
      <div className="d-flex flex-row px-2 px-sm-4 px-lg-5 py-2 pb-0">
        <Button
          startIcon={<AddCircleIcon />}
          className="rounded-3 text-white"
          style={{ backgroundColor: "#00ADB5" }}
          onClick={() => setShowCreateTestModal(true)}
        >
          Create Test
        </Button>
        <Button
          startIcon={<AssignmentIcon />}
          style={{ backgroundColor: "#00ADB5" }}
          className="rounded-3 ms-2 text-white"
          onClick={() => setShowAttemptTestModal(true)}
        >
          Attempt Test
        </Button>
      </div>
      <Row className="d-flex px-2 px-sm-4 px-lg-5 mt-0 justify-content-between align-items-center">
        <Col xs={12} md={6} className="d-flex flex-row me-lg-5 my-2">
          <TabBar
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            tabEnumTextMap={filters}
          />
        </Col>
        <Col className="d-flex flex-row align-items-center">
          <Autocomplete
            className="ms-lg-5 ps-lg-6 ps-xl-5 me-2 my-2 flex-grow-1"
            freeSolo
            clearIcon={<ClearIcon color="disabled" />}
            options={tests?.map((option) => {
              return searchFilter === 0
                ? option.assessment.name
                : String(option.publishInfo?.publish_code);
            })}
            onInputChange={(_, value) => {
              if (!value) {
                filterTests(EFilterType.SEARCH);
              }
            }}
            onChange={(_, value) => {
              if (value) {
                filterTests(EFilterType.SEARCH, value);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  searchFilter === ESearchFilter.NAME
                    ? "Search by name"
                    : "Search by code"
                }
                sx={{
                  ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: 20,
                  },
                }}
              />
            )}
          />
          <FormControl>
            <InputLabel>Search by:</InputLabel>
            <Select
              value={searchFilter}
              onChange={onSearchFilterChange}
              startAdornment={<FilterAltIcon color="disabled" />}
              sx={{ borderRadius: "20px" }}
            >
              <MenuItem value={ESearchFilter.NAME}>Name</MenuItem>
              <MenuItem value={ESearchFilter.CODE}>Code</MenuItem>
            </Select>
          </FormControl>
        </Col>
      </Row>

      <div
        className="px-2 px-sm-4 px-lg-5 py-1"
        style={{ height: 500, width: "100%" }}
      >
        {rows && (
          <DataGrid
            sx={{
              border: "none",
              "& .MuiDataGrid-cell": {
                border: "none",
              },
              "& .MuiDataGrid-cell:active": {
                outline: "none",
              },
              "& .MuiDataGrid-cell:hover": {
                outline: "none",
                cursor: "pointer",
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
            }}
            rows={rows}
            columns={isMobile ? columnsMobile : columnsDesktop}
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <h3>No tests found</h3>
                  {unfilteredTests.length === 0 && (
                    <>
                      <Typography color="#9AA0B2">
                        Choose one of the options below to continue
                      </Typography>
                      <div className="d-flex flex-row mt-3">
                        <Button
                          startIcon={<AddCircleIcon />}
                          className="rounded-3 mx-2 text-white"
                          style={{ backgroundColor: "#00ADB5" }}
                          onClick={() => setShowCreateTestModal(true)}
                        >
                          Create Test
                        </Button>
                        <Button
                          startIcon={<AssignmentIcon />}
                          style={{ backgroundColor: "#00ADB5" }}
                          className="rounded-3 mx-2 text-white"
                        >
                          Attempt Test
                        </Button>
                      </div>
                    </>
                  )}
                </Stack>
              ),
            }}
            disableColumnSelector
            disableSelectionOnClick
            onCellClick={(params) => {
              if (params.field !== "actions") {
                handleOpenTest(tests[params.id]);
              }
            }}
          />
        )}
      </div>
      <AttemptTestModal
        showAttemptTestModal={showAttemptTestModal}
        setShowAttemptTestModal={setShowAttemptTestModal}
      />
      <CreateTestModal
        show={showCreateTestModal}
        setShow={setShowCreateTestModal}
      />
      {selectedBatch && (
        <BatchInfoModal
          batchName={selectedBatch.name}
          batchCode={selectedBatch.code}
          batchId={selectedBatch.id}
          batchDescription={selectedBatch.batchDescription}
          membershipStatus={selectedBatch.membershipStatus}
          show={showBatchInfoModal}
          setShow={setShowBatchInfoModal}
          forceReload={forceReload}
          setForceReload={setForceReload}
        />
      )}
      {tests[selectedTestIndex] && (
        <>
          <ConfirmationModal
            showModal={showConfirmationModal}
            setShowModal={setShowConfirmationModal}
            onConfirm={() => {
              handleTestDelete(tests[selectedTestIndex].assessment.id);
            }}
            confirmText={"Delete"}
            modalTitle={"Delete Test"}
            modalText={`Are you sure you want to delete the following test: ${
              selectedTestIndex !== -1 &&
              tests[selectedTestIndex].assessment.name
            }?`}
          />
          <EditAssessmentNameModal
            showEditAssessmentModal={showEditAssessmentModal}
            setShowEditAssessmentModal={setShowEditAssessmentModal}
            assessmentId={tests[selectedTestIndex]?.assessment.id}
            initialAssessmentName={tests[selectedTestIndex]?.assessment.name}
            onUpdate={() => setForceReload(!forceReload)}
          />
          <PublishTestModal
            assessmentId={tests[selectedTestIndex].assessment.id}
            assessmentName={tests[selectedTestIndex].assessment.name}
            showEditPublishDetailsModal={showEditPublishDetailsModal}
            setShowEditPublishDetailsModal={setShowEditPublishDetailsModal}
            publishId={tests[selectedTestIndex].publishInfo?.id!!}
            batchId={tests[selectedTestIndex].batchInfo?.id!!}
            publishType={tests[selectedTestIndex].publishInfo?.access_level}
            startTime={tests[selectedTestIndex].publishInfo?.start_time!!}
            endTime={tests[selectedTestIndex].publishInfo?.end_time!!}
            batchName={tests[selectedTestIndex].batchInfo?.name!!}
            publishStatus={tests[selectedTestIndex].publishInfo?.status!!}
            onUpdate={onPublishModalUpdate}
            format={tests[selectedTestIndex].publishInfo?.format!!}
            duration={tests[selectedTestIndex].publishInfo?.duration!!}
          />
          {isMobile && (
            <ActionsDrawer
              selectedTest={tests[selectedTestIndex]}
              serverTime={serverTime}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              handleOpenTest={handleOpenTest}
              handleShare={handleShare}
              handleAnalytics={handleAnalytics}
              handleDuplicateTest={handleDuplicateTest}
              setShowEditPublishDetailsModal={setShowEditPublishDetailsModal}
              setShowEditAssessmentModal={setShowEditAssessmentModal}
              setShowConfirmationModal={setShowConfirmationModal}
            />
          )}
        </>
      )}
      <JoinOrCreateBatchModal
        show={showAddBatchModal}
        setShow={setShowAddBatchModal}
      />
    </div>
  );
};

export default MyTests;
