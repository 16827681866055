import { Button } from "@mui/material";
import { FunctionComponent, useContext } from "react";
import { useHistory } from "react-router-dom";
import { placeOrder, validateOrder } from "../../../api/marketplace";
import { AppContext, TAppContext } from "../../../context/AppContext";
import {
  displayInfoToast,
  displaySuccessToast,
} from "../../../utils/ToastUtils";

interface PaymentButtonProps {
  productCode: string;
  disabled: boolean;
  discountedPrice?: number;
}

const loadScript = (src: string) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const PaymentButton: FunctionComponent<PaymentButtonProps> = ({
  productCode,
  disabled,
  discountedPrice,
}) => {
  const { accessToken } = useContext<TAppContext>(AppContext);
  const history = useHistory();

  const displayRazorpay = async () => {
    if (!accessToken) {
      displayInfoToast("Please first login to buy this product!");
      history.replace(`/home/marketplace/${productCode}`);
      return;
    }

    const placeOrderRes = await placeOrder(
      accessToken,
      productCode,
      discountedPrice
    );
    if (placeOrderRes === null) return history.replace("/home");
    console.log(placeOrderRes);

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      console.log("There was some error");
      return;
    }

    var options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: placeOrderRes.value * 100, // 100 to convert paisa to rupees
      currency: "INR",
      name: "Quriate",
      description: "Transaction for product of code " + productCode,
      image: "https://app.dev.quriate.com/static/media/Banner.1d800c6b.svg",
      order_id: placeOrderRes.gateway_id,
      handler: async function (response) {
        const validateOrderRes = await validateOrder(
          accessToken,
          placeOrderRes.id,
          response.razorpay_order_id,
          response.razorpay_payment_id,
          response.razorpay_signature
        );
        if (validateOrderRes !== null) {
          displaySuccessToast("Successfully purchased product");
          history.replace("/home");
        }
      },
      theme: {
        color: "#3399cc",
      },
    };
    const _window = window as any;
    var rzp1 = new _window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    rzp1.open();
  };

  return (
    <Button
      variant="contained"
      fullWidth
      onClick={displayRazorpay}
      disabled={disabled}
    >
      Buy now
    </Button>
  );
};

export default PaymentButton;
