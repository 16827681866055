import { Grid, Typography, useMediaQuery } from "@mui/material";
import AnalyticsCard from "./AnalyticsCard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { TAnalyticsStudentOverview } from "../../../models/analyticsmodel";
import StarIcon from "@mui/icons-material/Star";
import GroupIcon from "@mui/icons-material/Group";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import PercentIcon from "@mui/icons-material/Percent";
import FunctionsIcon from "@mui/icons-material/Functions";

type TProps = {
  data: TAnalyticsStudentOverview;
  selectedSectionName: string;
  title: string;
};

export default function StudentStatisticsBox(props: TProps) {
  const isMobile = useMediaQuery("(max-width: 576px)");

  const profileIcon = (
    <AccountCircleIcon style={{ color: "#7d530a" }} fontSize="large" />
  );
  const starIcon = <StarIcon style={{ color: "#107a43" }} fontSize="large" />;
  const totalStudents = (
    <GroupIcon style={{ color: "#1d4273" }} fontSize="large" />
  );
  const rank = (
    <MilitaryTechIcon style={{ color: "#630f5e" }} fontSize="large" />
  );
  const percentile = (
    <PercentIcon style={{ color: "#474347" }} fontSize="large" />
  );
  const average = (
    <FunctionsIcon style={{ color: "#2d066b" }} fontSize="large" />
  );

  return (
    <div
      className="px-2 px-sm-4 rounded-4 py-2 py-sm-4 w-100"
      style={{ background: isMobile ? "white" : "#F0F0F0" }}
    >
      <Typography
        style={{ color: "#75777D", fontWeight: "700" }}
        className="mb-2"
      >
        {props.title}
      </Typography>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 2, sm: 4 }}
        sx={{ my: 1 }}
        className="justify-content-center"
      >
        <Grid item md={4} xs={6}>
          <AnalyticsCard
            icon={profileIcon}
            cardTitle={"Your Score"}
            iconBgColor={"#FFBA69DB"}
            cardValue={Number(Number(props.data.student_score.data).toFixed(2))}
          />
        </Grid>
        <Grid item md={4} xs={6}>
          <AnalyticsCard
            icon={average}
            iconBgColor={"#3120708F"}
            cardTitle={"Average Score"}
            cardValue={Number(Number(props.data.mean_score.data).toFixed(2))}
          />
        </Grid>
        <Grid item md={4} xs={6}>
          <AnalyticsCard
            icon={starIcon}
            iconBgColor={"#3FB93D87"}
            cardTitle={"Highest Score"}
            cardValue={Number(Number(props.data.highest_score.data).toFixed(2))}
          />
        </Grid>
        <Grid item md={4} xs={6}>
          <AnalyticsCard
            icon={totalStudents}
            iconBgColor={"#0089AF7A"}
            cardTitle={"Total Students"}
            cardValue={Number(
              Number(props.data.number_of_students.data).toFixed(2)
            )}
          />
        </Grid>
        <Grid item md={4} xs={6}>
          <AnalyticsCard
            icon={rank}
            iconBgColor={"#FF599882"}
            cardTitle={"Your Rank"}
            cardValue={Number(Number(props.data.student_rank.data).toFixed(2))}
          />
        </Grid>
        <Grid item md={4} xs={6}>
          <AnalyticsCard
            icon={percentile}
            iconBgColor={"#292F4D6E"}
            cardTitle={"Percentile"}
            cardValue={Number(
              Number(props.data.student_percentile.data).toFixed(2)
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
}
