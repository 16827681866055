import { useState, useContext, useEffect } from "react";
import ProductCard from "./Components/ProductCard";
import { Grid } from "@mui/material";
import { TProducts } from "../../models/productmodel";
import { fetchAllProductsData } from "../../api/marketplace";
import Loader from "../../components/Loader";
import { displayInfoToast } from "../../utils/ToastUtils";
import { useHistory } from "react-router-dom";
import { TAppContext, AppContext } from "../../context/AppContext";

const MarketPlace = () => {
  const { id } = useContext<TAppContext>(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [productsData, setProductsData] = useState<Array<TProducts>>();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const productsData = await fetchAllProductsData(id);
      if (!productsData) {
        displayInfoToast("Some error occured");
        history.replace("/home");
        return;
      }
      setProductsData(productsData);
      setIsLoading(false);
    })();
  }, []);

  return isLoading ? (
    <Loader LoadingMessage="Loading......" />
  ) : productsData?.length ?? -1 > 0 ? (
    <Grid
      className="bg-white p-0 m-0 overflow-auto"
      container
      margin={2}
      marginLeft={1}
      spacing={3}
    >
      {productsData?.map((el) => (
        <Grid item xs={11} sm={4} md={3}>
          <ProductCard
            ProductCode={el.code}
            HeaderLine={el.name}
            CardDescription={el.description}
            price={el.price}
            productImage={el.product_image}
          />
        </Grid>
      ))}
    </Grid>
  ) : (
    <Grid container margin={2} marginLeft={1} spacing={3}>
      <Grid item xs={12} sm={4} md={3}>
        <h1>No Products Found</h1>
      </Grid>
    </Grid>
  );
};

export default MarketPlace;
