import { Grid, Box, useMediaQuery } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { FunctionComponent, useMemo } from "react";
import { Marquee } from "../../../components/CustomComponent/Marquee";
import CustomPagination from "../../../components/Pagination";
import { TSalesStudentStat, TTabular } from "../../../models/analyticsmodel";
import Styles from "./../../analytics/Screens/StudentsStatistics.module.scss";

interface SalesStudentStatisticsProps {
  data: {
    student_stats: Array<TSalesStudentStat>;
  };
}

const SalesStudentStatistics: FunctionComponent<SalesStudentStatisticsProps> = (
  props
) => {
  const isMobile = useMediaQuery("(max-width: 576px)");
  const columnsDesktop = [
    {
      field: "user_id",
      headerName: "User ID",
      flex: 1,
      headerClassName: Styles.active,
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "name",
      headerName: "Student Name",
      flex: 3,
      headerClassName: Styles.active,
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
      headerClassName: Styles.active,
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 2,
      headerClassName: Styles.active,
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "correct",
      headerName: "Correct",
      cellClassName: "super-app-theme--cell",
      flex: 2,
      headerClassName: Styles.active,
    },
    {
      field: "incorrect",
      headerName: "Incorrect",
      flex: 2,
      headerClassName: Styles.active,
      cellClassName: "super-app-theme--cell",
    },
  ];

  const columnsMobile = [
    {
      field: "user_id",
      headerName: "ID",
      flex: 1,
      headerClassName: Styles.active,
      cellClassName: "super-app-theme--cell",
      renderCell: (cellValues: any) => {
        return (
          <div style={{ width: "300px" }}>
            <Marquee>{cellValues.value}</Marquee>
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Student Name",
      flex: 4,
      headerClassName: Styles.active,
      cellClassName: "super-app-theme--cell",
      renderCell: (cellValues: any) => {
        return (
          <div style={{ width: "300px" }}>
            <Marquee>{cellValues.value}</Marquee>
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 4,
      headerClassName: Styles.active,
      cellClassName: "super-app-theme--cell",
      renderCell: (cellValues: any) => {
        return (
          <div style={{ width: "300px" }}>
            <Marquee>{cellValues.value}</Marquee>
          </div>
        );
      },
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 4,
      headerClassName: Styles.active,
      cellClassName: "super-app-theme--cell",
      renderCell: (cellValues: any) => {
        return (
          <div style={{ width: "300px" }}>
            <Marquee>{cellValues.value}</Marquee>
          </div>
        );
      },
    },
    {
      field: "correct",
      headerName: "Correct",
      cellClassName: "super-app-theme--cell",
      flex: 1,
      headerClassName: Styles.active,
    },
    {
      field: "incorrect",
      headerName: "Incorrect",
      flex: 1,
      headerClassName: Styles.active,
      cellClassName: "super-app-theme--cell",
    },
  ];

  const rows = useMemo(
    () => props.data.student_stats,
    [props.data.student_stats]
  );

  return (
    <Grid
      container
      className="pt-2 px-2 my-0 d-flex align-items-center pb-md-4 pb-0"
    >
      <Grid item md={12} xs={12} className="overflow-auto">
        <Box
          sx={{
            height: "70vh",
            "& .super-app-theme--header": {
              backgroundColor: "#fff",
              color: "#7F828A",
              fontSize: "16px",
              fontWeight: "700",
              border: "0",
            },
            "& .super-app-theme--cell": {
              fontSize: "16px",
              border: "0",
            },
          }}
        >
          <DataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: "correct", sort: "desc" }],
              },
            }}
            pageSize={10}
            rowsPerPageOptions={[10]}
            components={{
              Pagination: CustomPagination,
            }}
            getRowId={(r) => r.user_id}
            rows={rows}
            columns={isMobile ? columnsMobile : columnsDesktop}
            style={{ border: "0" }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default SalesStudentStatistics;
