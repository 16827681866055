import { Mathfield, MathfieldConfig, MathfieldElement } from "mathlive";
import { useEffect, useRef, useState } from "react";

interface IBaseProps {
  onChange?: (latex: string) => void;

  /**
   * The raw options of mathlive's makeMathField.
   * */
  mathfieldConfig?: Partial<MathfieldConfig>;

  /**
   * The mathfield object returned by makeMathField.
   */
  mathfieldRef?: (mathfieldElement: MathfieldElement) => void;

  latex?: string;

  initialLatex?: string;

  highlight?: boolean;
}

export type TProps = IBaseProps;

export function combineConfig(props: TProps): Partial<MathfieldConfig> {
  const combinedConfiguration: Partial<MathfieldConfig> = {
    ...props.mathfieldConfig,
  };

  const { onChange } = props;

  if (onChange) {
    if (props.mathfieldConfig && props.mathfieldConfig.onContentDidChange) {
      const fromConfig = props.mathfieldConfig.onContentDidChange;
      combinedConfiguration.onContentDidChange = (mf: Mathfield) => {
        onChange(mf.getValue());
        fromConfig(mf);
      };
    } else {
      combinedConfiguration.onContentDidChange = (mf: Mathfield) =>
        onChange(mf.getValue());
    }
  }

  return combinedConfiguration;
}

export const MathfieldComponent = (props: TProps): JSX.Element => {
  const insertElement = useRef<HTMLDivElement | null>(null);
  const [combinedConfiguration] = useState(combineConfig(props));
  const [mathfield] = useState<MathfieldElement>(
    new MathfieldElement(combinedConfiguration)
  );
  const { mathfieldRef, latex, initialLatex } = props;

  // Run on initial component render
  useEffect(() => {
    if (!insertElement) return;
    insertElement.current!.replaceWith(mathfield);
    const initialValue = initialLatex ?? latex;
    mathfield.setValue(initialValue, {
      suppressChangeNotifications: true,
      mode: "latex",
    });
    if (mathfieldRef) {
      mathfieldRef(mathfield);
    }
  }, [insertElement, mathfield, mathfieldRef, initialLatex, latex]);

  useEffect(() => {
    if (!mathfield) {
      throw new Error("Component was not correctly initialized.");
    }

    if (latex === undefined) {
      throw new Error("Cannot change from controlled to uncontrolled state!");
    }

    mathfield.setValue(latex, {
      suppressChangeNotifications: true,
      mode: "latex",
    });

    // hard-code the looks cuz why not?
    mathfield.setAttribute(
      "style",
      `background-color: ${
        props.highlight
          ? "#faf594; margin-left: 10px; margin-right: 10px;"
          : "transparent"
      }; padding: 0px 10px 0px 10px; display: inline-flex; outline: none;`
    );
  }, [mathfield, latex]);

  return <div className="math-field" ref={insertElement} />;
};
