import { Button, Navbar } from "react-bootstrap";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../context/AssessmentContext";
import { ERole } from "../../utils/Enums";
import { AppContext, TAppContext } from "../../context/AppContext";
import { FormControl, MenuItem, Select, useMediaQuery } from "@mui/material";
import ProofRead from "./Components/proofread-mode/ProofRead";
import { IsectionInfo } from "../../models/assessmentmodel";
import NoQuestions from "./Components/EmptyAssessment/NoQuestions";
import QuestionResponse from "../OMR-based-question/Component/QuestionResponse";

export default function StudentOmrResponseScreen() {
  const history = useHistory();
  const [proofRead, setProofRead] = useState<boolean>(false);
  const isDesktopOrLaptop = useMediaQuery("(min-width: 992px)") && !proofRead;
  const [edit, setEdit] = useState<boolean>(false);
  const isMobile = useMediaQuery("(max-width: 576px)");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { setDrawerOpen } = useContext<TAppContext>(AppContext);
  const {
    selectedAssessment,
    sectionMap, // List of sections.
    questionMap,
    currentSectionNumber, // current section to which question belongs to
    setCurrentSectionNumber,
  } = useContext<TAssessmentContext>(AssessmentContext); // Get the data from the context

  const [currentSection, setCurrentSection] = useState<IsectionInfo>({
    id: 0,
    name: "",
    instructions: "",
    question_ids: [],
    assessment_id: 0,
  });

  useEffect(() => {
    setDrawerOpen(false);
    setIsLoading(false);
    setCurrentSection(
      sectionMap.get(selectedAssessment.sections[currentSectionNumber])!!
    );
  }, [
    currentSectionNumber,
    history,
    setDrawerOpen,
    questionMap,
    sectionMap,
    selectedAssessment,
  ]);

  const DropDownContainer = () => {
    return (
      <FormControl className="bg-white m-2 rounded-4 p-1" variant="standard">
        <Select
          disableUnderline
          labelId="current-section-dropdown-label"
          id="current-section-dropdown"
          value={currentSection.id}
          onChange={(e) => {
            setCurrentSection(sectionMap.get(e.target.value as number)!!);
          }}
        >
          {selectedAssessment.sections.map((section, index) => {
            return (
              <MenuItem
                value={section}
                onClick={() => {
                  setCurrentSectionNumber(
                    selectedAssessment.sections.indexOf(section)
                  );
                }}
              >
                <div className="px-4" style={{ display: "flex" }}>
                  {sectionMap.get(section)!.name}
                </div>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <Navbar
        className="d-flex bg-primary py-4 px-2"
        style={{
          height: "8%",
          width: "100%",
          zIndex: 15,
        }}
      >
        <div className="w-100 d-flex flex-row justify-content-between align-items-center">
          {isDesktopOrLaptop && (
            <div className="d-flex align-items-center justify-content-center">
              <Button
                onClick={() => {
                  history.goBack();
                }}
              >
                <IoArrowBackCircleOutline
                  size={"25px"}
                  color="white"
                  cursor={"pointer"}
                />
              </Button>
              {isDesktopOrLaptop && (
                <h2 className="text-white mb-0">{selectedAssessment.name}</h2>
              )}
            </div>
          )}
          <div
            onClick={() => {
              setProofRead(!proofRead);
            }}
            style={{ cursor: "pointer" }}
            className="d-flex fs-4 align-items-center justify-content-center"
          >
            {proofRead ? (
              <BsFillEyeSlashFill color="#FFBA69" cursor={"pointer"} />
            ) : (
              <BsFillEyeFill color="#FFBA69" cursor={"pointer"} />
            )}

            <span style={{ color: "#FFBA69" }} className="mx-2">
              PDF
            </span>
          </div>
        </div>
      </Navbar>
      <ProofRead
        edit={edit}
        setEdit={setEdit}
        proofRead={proofRead}
        setProofRead={setProofRead}
        isViewingResponses
      >
        {!isLoading && (
          <>
            <div
              className="d-flex align-items-center flex-column bg-primary-dark"
              style={{
                height: "100%",
                width: "100%",
                padding: isMobile ? "0px" : "10px",
              }}
            >
              <div
                className="d-flex flex-row align-items-center bg-secondary rounded-top-4 px-4 pt-2 mt-2 text-white"
                style={{
                  justifyContent: "space-between",
                  width: isDesktopOrLaptop ? "55%" : "96%",
                }}
              >
                <DropDownContainer />
              </div>
              <div
                className="bg-white"
                style={{
                  paddingLeft: "0%",
                  height: isDesktopOrLaptop ? "70vh" : "72vh",
                  overflowY: "auto",
                  width: isDesktopOrLaptop ? "55%" : "96%",
                  borderRadius: "0 0 20px 20px",
                }}
              >
                {currentSection.question_ids.length !== 0 ? (
                  currentSection.question_ids.map((questionId, index) => {
                    const currentQuestion = questionMap.get(questionId);

                    if (!currentQuestion) return <></>;

                    return (
                      <div className="d-flex">
                        <QuestionResponse
                          role={ERole.STUDENT}
                          currentQuestion={currentQuestion}
                          index={index}
                          currentSection={currentSection}
                          setCurrentSection={setCurrentSection}
                          response={true}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div className="bg-white">
                    <NoQuestions
                      setEdit={() => {
                        // This is just a dummy function meant to satisfy props
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </ProofRead>
    </div>
  );
}
