import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { FunctionComponent } from "react";
import { IquestionData } from "../../../../models/assessmentmodel";
import TipTap from "../../../../utils/TipTap/TipTap";
import QuestionImageModal from "../QuestionImageModal/QuestionImageModal";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IQuestionBody } from "../../../../models/common";

interface SolutionProps {
  solution?: IQuestionBody;
}

const Solution: FunctionComponent<SolutionProps> = (props) => {
  return props.solution ? (
    <Accordion className="bg-primary text-white rounded-4">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="text-white" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        View Solution
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          {props.solution.text === "" &&
            props.solution.images?.length === 0 &&
            "Solution"}
          {props.solution.text && <TipTap text={props.solution.text} />}
          {props.solution.images?.map((el) => (
            <QuestionImageModal src={el} />
          ))}
        </Typography>
      </AccordionDetails>
    </Accordion>
  ) : (
    <></>
  );
};

export default Solution;
