import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { IoReorderFourOutline } from "react-icons/io5";

type TProps = {
  onDragEnd?: (result) => void;
  reorderableContent: Array<any>;
  headerText?: string;
  initialNumber?: number;
  headerMap?: Map<number, string>;
};

/**
 * 
 * @property onDragEnd; a function that takes a parameter result and doesn't return anything. 
 * result is a object that contains the following properties that should matter:
 * - destination: the destination of the draggable item. It has a property index which is the index of the file
 * - source: the source of the draggable item.
 * Ideally it should form a function as follows
 * const onDragEnd = (result) => {
		if (!result || !result.destination) return;
		const [reorderedItem] = reorderableContent.splice(result.source.index, 1);
		reorderableContent.splice(result.destination.index, 0, reorderedItem);
	}; and will default to this in case of no onDragEnd function is provided.
 * @property reorderableContent; an array of objects has to be reordered. The content inside is assumed to be unique and will be used as an id
 * @property headerText; the text that will be displayed in each of the the reorderable element and will be prefixed with the index of the element.
 * This is not optional and you can opt out of it but in that case you will have to provide header for each of 
 * This can be passed in headermap.
 * @property initialNumber; In case headertext is being used, we can start the indexing from a number other than 0. pass in this if that us the case
 * @property headerMap; a map that maps the id of the element to the text that will be displayed in the header.
 * Note that the id refers to the contents inside reorderableContent and each of the header text has to be mapped to contents in the reorderableContent
 * assuming the contents inside reorderableContent as the ID
 * @returns JSX component
 */

export default function ReorederableComponent({
  reorderableContent,
  onDragEnd = (result) => {
    if (!result || !result.destination) return;
    const [reorderedItem] = reorderableContent.splice(result.source.index, 1);
    reorderableContent.splice(result.destination.index, 0, reorderedItem);
  },
  initialNumber = 0,
  headerMap = new Map(),
  headerText = "",
}: TProps) {
  const [idMap, setIdMap] = useState(new Map());

  useEffect(() => {
    let updatedMap = new Map();
    if (headerMap.size > 0) return; // We already have a map and won't be using the setIdMap then
    reorderableContent.forEach((id, index) => {
      updatedMap.set(id, index + 1);
    });
    setIdMap(updatedMap);
  }, [reorderableContent, headerMap.size]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="sections">
        {(provided) => (
          <Row
            className="sections"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {reorderableContent.map((id, index) => {
              return (
                <Draggable key={`${id}`} draggableId={`${id}`} index={index}>
                  {(provided) => (
                    <Row
                      className="mt-4"
                      style={{
                        justifyContent: "center",
                        flex: 1,
                        flexDirection: "row",
                      }}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div
                        className="d-flex text-align-center"
                        style={{
                          backgroundColor: "#CDCDCD",
                          height: "175%",
                          borderRadius: "5px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <IoReorderFourOutline />
                        {headerMap?.size > 0
                          ? headerMap?.get(id)
                          : `${headerText} ${initialNumber + idMap.get(id)}`}
                        <div />
                      </div>
                    </Row>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </Row>
        )}
      </Droppable>
    </DragDropContext>
  );
}
