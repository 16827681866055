export const TIME_SPENT_PER_DIFF =
  "Median Time Spent on each question in the given category.\
The value in the bubble denotes the total number of questions in each category.\
Questions are self-categorized into easy, medium, and hard based on their accuracy in this particular test.";

export const CREATOR_TIME_DISTRIBUTION =
  "Total time spent by an average student for different outcomes";

export const ATTEMPTOR_TIME_DISTRIBUTION =
  "Total time spent by you on questions with different outcomes";

export const SPEED_ANALYSIS =
  "Number of questions where total time taken to attempt it was in the given range";

export const ACCURACY_PER_DIFF =
  "Number of questions that you got correct & incorrect \
out of total questions in that category. Questions are self-categorized into easy, medium, and hard based on their accuracy \
in this particular test.";

export const ACCURACY = "Distribution of questions for each outcome";

export const ACCURACY_WITH_TIME =
  "The entire duration of the assessment is divided into six equal phases. \
The graph shows the distribution of outcome for questions attempted in each phase.";
