import { useContext, Dispatch, SetStateAction } from "react";
import { Modal } from "react-bootstrap";
import styles from "../../../batch-management/batch-management.module.scss";
import { AppContext, TAppContext } from "../../../../context/AppContext";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../context/AssessmentContext";
import { Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { displaySuccessToast } from "../../../../utils/ToastUtils";
import { patchAssessment } from "../../../../api/assessment";
import ReorederableComponent from "../../../../components/ReorderableComponent";
import CustomButton from "../../../../components/CustomComponent/CustomButton";
import { findFirstSectionNumber } from "../../../../utils/QuestionNavigation";

// section is passed in as a new array and should not be same as original array.
// This is then used to modify and filnally replace in case we press on yes
// If we pass in original array, then it will always be modified in case of onDragEnd and discard will stop working
type TProps = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  sections: Array<number>;
};

export default function ReorderSectionModal({
  showModal,
  setShowModal,
  sections,
}: TProps) {
  const {
    selectedAssessment,
    sectionMap,
    setSelectedAssessment,
    setCurrentSectionNumber,
  } = useContext<TAssessmentContext>(AssessmentContext);
  const { accessToken } = useContext<TAppContext>(AppContext);

  const handleReorderSections = () => {
    const resp = patchAssessment(
      accessToken,
      selectedAssessment.id,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      sections
    );
    if (resp === null) return;
    setSelectedAssessment({ ...selectedAssessment, sections });
    const firstSectionNumber = findFirstSectionNumber(
      { ...selectedAssessment, sections },
      sectionMap
    );
    setCurrentSectionNumber(firstSectionNumber);
    setShowModal(false);
    displaySuccessToast("Successfully reordered sections");
  };

  const headerMap = new Map<number, string>();
  sectionMap.forEach((section, index) => {
    headerMap.set(index, section.name);
  });

  return (
    <Modal
      contentClassName={styles.modalStyle}
      show={showModal}
      centered
      onHide={() => setShowModal(false)}
    >
      <Modal.Header>
        <Typography variant="h4">
          <b>Reorder Section</b>
        </Typography>
        <IconButton>
          <CloseIcon onClick={() => setShowModal(false)} />
        </IconButton>
      </Modal.Header>
      <Modal.Body
        className="form-group mt-3 ms-2"
        style={{ height: "60vh", overflowY: "auto", overflowX: "hidden" }}
      >
        <ReorederableComponent
          reorderableContent={sections}
          headerMap={headerMap}
        />
      </Modal.Body>
      <Modal.Footer style={{ display: "flex", flexDirection: "row" }}>
        <CustomButton
          onClick={() => {
            setShowModal(false);
          }}
          className="mt-1 mx-2 bg-danger"
        >
          Discard
        </CustomButton>
        <CustomButton
          onClick={handleReorderSections}
          className="mt-1 mx-2 bg-success"
        >
          Save
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
}
