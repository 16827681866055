import {
  Button,
  Card,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import AnalyticsCard from "./AnalyticsCard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import GroupIcon from "@mui/icons-material/Group";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import { TAnalyticsTeacherOverview } from "../../../models/analyticsmodel";
import StarIcon from "@mui/icons-material/Star";

type TProps = {
  data: TAnalyticsTeacherOverview;
  selectedSectionName: string;
  title: string;
};

export default function StatisticsBox(props: TProps) {
  const profileIcon = (
    <AccountCircleIcon style={{ color: "#7d530a" }} fontSize="large" />
  );
  const starIcon = <StarIcon style={{ color: "#2d066b" }} fontSize="large" />;
  const groupIcon = <GroupIcon style={{ color: "#00453b" }} fontSize="large" />;
  const isMobile = useMediaQuery("(max-width: 576px)");

  return (
    <div
      className="px-2 px-sm-4 rounded-4 py-2 py-sm-4 w-100"
      style={{ background: isMobile ? "white" : "#F0F0F0" }}
    >
      <Typography
        style={{ color: "#75777D", fontWeight: "700" }}
        className="mb-2"
      >
        {props.title}
      </Typography>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 2, sm: 8, md: 4 }}
        className="justify-content-center"
      >
        <Grid item md={4} xs={12}>
          <AnalyticsCard
            icon={profileIcon}
            cardTitle={"Average Score"}
            iconBgColor={"#FFBA69DB"}
            cardValue={Number(Number(props.data.mean_score.data).toFixed(2))}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <AnalyticsCard
            icon={starIcon}
            iconBgColor={"#3120708F"}
            cardTitle={"Highest Score"}
            cardValue={Number(Number(props.data.highest_score.data).toFixed(2))}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <AnalyticsCard
            icon={groupIcon}
            iconBgColor={"#3FB93D87"}
            cardTitle={"Total Students"}
            cardValue={Number(
              Number(props.data.number_of_students.data).toFixed(2)
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
}
