import { Dispatch, SetStateAction, useContext, useState } from "react";
import { ImSad2 } from "react-icons/im";
import { AppContext, TAppContext } from "../../../../context/AppContext";
import AddQuestionButton from "../AddQuestionButton/AddQuestionButton";
import CustomButton from "../../../../components/CustomComponent/CustomButton";
import { EAssessmentFormat, ERole } from "../../../../utils/Enums";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../context/AssessmentContext";
import { useMediaQuery } from "@mui/material";
import { AddManuallyModal } from "../AddQuestionButton/ManualQuestionModal";

type TProps = {
  setEdit: Dispatch<SetStateAction<boolean>>;
};

export default function NoQuestions(props: TProps) {
  const { role } = useContext<TAppContext>(AppContext);
  const isDesktopOrLaptop = useMediaQuery("(min-width: 576px)");
  const { selectedAssessment } =
    useContext<TAssessmentContext>(AssessmentContext);

  const [showAddManuallyModal, setShowAddManuallyModal] =
    useState<boolean>(false);

  return (
    <div
      className={`${
        selectedAssessment.format === EAssessmentFormat.NTA ? "w-50" : "w-100"
      } 
      ${isDesktopOrLaptop ? "p-5 w-50" : "py-4 px-2 w-100"}`}
    >
      <div className="d-flex flex-column bg-card align-items-center rounded-4 py-4 px-3 mt-0 text-center">
        <div className="d-flex flex-row justify-content-center">
          <ImSad2 size={100} />
        </div>
        <div
          className="pt-5 fs-3 justify-content-center"
          style={{ color: "#9AA0B2" }}
        >
          There are no questions yet
        </div>

        {role === ERole.TEACHER &&
          selectedAssessment.format === EAssessmentFormat.NTA && (
            <AddQuestionButton setEdit={props.setEdit} />
          )}
        {role === ERole.TEACHER &&
          selectedAssessment.format === EAssessmentFormat.OMR && (
            <CustomButton
              style={{
                width: isDesktopOrLaptop ? "50%" : "100%",
                height: "75%",
                maxWidth: "100%",
              }}
              onClick={() => {
                setShowAddManuallyModal(true);
              }}
            >
              Add Question
            </CustomButton>
          )}
      </div>
      <div className="text-center">
        {/* <CustomButton
            onClick={() => {
              history.goBack();
            }}
          >
            <IoArrowBackCircleOutline size={"25px"} /> Back to Instructions
          </CustomButton> */}
      </div>
      <AddManuallyModal
        showModal={showAddManuallyModal}
        setShowModal={setShowAddManuallyModal}
      />
    </div>
  );
}
