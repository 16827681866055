import { useContext, useEffect, useState, useRef } from "react";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../context/AssessmentContext";
import { useHistory } from "react-router-dom";
import { ERole } from "../../utils/Enums";
import { AppContext, TAppContext } from "../../context/AppContext";
import { FormControl, MenuItem, Select, useMediaQuery } from "@mui/material";
import QuestionButtonPanel from "./Component/QuestionButtonPanel";
import { IsectionInfo } from "../../models/assessmentmodel";
import NoQuestions from "../question/Components/EmptyAssessment/NoQuestions";
import ReorederableComponent from "../../components/ReorderableComponent";
import QuestionResponse from "./Component/QuestionResponse";
import { saveResponses } from "../../api/responses";
import { saveQuestionData } from "../../api/question";
import { fetchCreatorData } from "../instructionScreen/ManageAssessmentScreen/ManageAssessmentScreen";
import { parseMapFromJSON } from "../../utils/hooks/useStickyState";
import Proctor from "../../hoc/Proctor";

interface OMRQuestionScreenProps {
  proofRead: boolean;
  dummy?: boolean;
}

export default function OMRQuestionScreen(props: OMRQuestionScreenProps) {
  const history = useHistory();
  const { accessToken, setDrawerOpen, role, setRole, id } =
    useContext<TAppContext>(AppContext);
  const {
    selectedAssessment,
    sectionMap, // List of sections.
    questionMap,
    currentSectionNumber, // current section to which question belongs to
    setCurrentSectionNumber,
    setQuestionMap,
    setSelectedAssessment,
    setSectionMap,
    setUpdateableQuestionMap,
    setComprehensionMap,
  } = useContext<TAssessmentContext>(AssessmentContext); // Get the data from the context

  const isDesktopOrLaptop =
    useMediaQuery("(min-width: 992px)") && !props.proofRead;
  const isMobile = useMediaQuery("(max-width: 576px)");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isReordering, setIsReordering] = useState<boolean>(false);
  const [isSavingChanges, setIsSavingChanges] = useState<boolean>(false);
  const [currentSection, setCurrentSection] = useState<IsectionInfo>({
    id: 0,
    name: "",
    instructions: "",
    question_ids: [],
    assessment_id: 0,
  });

  useEffect(() => {
    if (role === ERole.TEACHER) {
      (async () => {
        setIsLoading(true);
        await fetchCreatorData(
          accessToken,
          selectedAssessment.id,
          setSelectedAssessment,
          setQuestionMap,
          setSectionMap,
          setComprehensionMap,
          setRole,
          history,
          id
        );
        setIsLoading(false);
      })();
    }
  }, []);

  const handleSaveChangesTeacher = async () => {
    let updatedQuestionMapFromStorage = parseMapFromJSON(
      window.localStorage.getItem("updateableQuestionMap") || ""
    );
    if (updatedQuestionMapFromStorage.size === 0) return;
    let unwrappedQuestion: Array<any> = [];
    updatedQuestionMapFromStorage.forEach((question, questionId) => {
      unwrappedQuestion.push({
        question_id: questionId,
        question: question,
      });
    });
    const responses: Array<any> = await Promise.all([
      unwrappedQuestion.map((ele) => {
        return saveQuestionData(accessToken, ele.question);
      }),
    ]);
    if (!responses) return;
    let updatedQuestionMap = new Map(questionMap);
    updatedQuestionMapFromStorage.forEach((question, key) => {
      updatedQuestionMap.set(key, question);
    });
    setQuestionMap(updatedQuestionMap);
    setUpdateableQuestionMap(new Map());
  };
  useEffect(() => {
    setDrawerOpen(false);
    setIsLoading(false);
    setCurrentSection(
      sectionMap.get(selectedAssessment.sections[currentSectionNumber])!!
    );
  }, [currentSectionNumber, history, setDrawerOpen, questionMap, sectionMap]);

  useEffect(
    () => () => {
      if (role === ERole.TEACHER) {
        handleSaveChangesTeacher();
      } else {
        saveResponses(
          accessToken,
          parseMapFromJSON(window.localStorage.getItem("unpushedAttempts"))
        );
      }
    },
    []
  );

  const DropDownContainer = () => {
    return (
      // <Proctor>
      <FormControl className="bg-white m-2 rounded-4 p-1" variant="standard">
        <Select
          disableUnderline
          labelId="current-section-dropdown-label"
          id="current-section-dropdown"
          value={currentSection.id}
          onChange={(e) => {
            setCurrentSection(sectionMap.get(e.target.value as number)!!);
          }}
        >
          {selectedAssessment.sections.map((section, index) => {
            return (
              <MenuItem
                value={section}
                onClick={() => {
                  setCurrentSectionNumber(
                    selectedAssessment.sections.indexOf(section)
                  );
                }}
              >
                <div className="px-4" style={{ display: "flex" }}>
                  {sectionMap.get(section)!.name}
                </div>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      // </Proctor>
    );
  };

  let questionStartingIndex = useRef<number>(0);

  useEffect(() => {
    questionStartingIndex.current = 0;
    selectedAssessment.sections
      .slice(0, selectedAssessment.sections.indexOf(currentSection.id))
      .forEach(
        (sectionId) =>
          (questionStartingIndex.current +=
            sectionMap.get(sectionId)!.question_ids.length)
      );
  }, [currentSection.id, sectionMap, selectedAssessment.sections]);

  return !isLoading ? (
    <div
      className="d-flex align-items-center flex-column bg-primary-dark"
      style={{
        height: "100%",
        padding: isMobile ? "0px" : "10px",
      }}
    >
      <div
        className="d-flex flex-row align-items-center bg-secondary rounded-top-4 px-4 pt-2 mt-2 text-white"
        style={{
          justifyContent: "space-between",
          width: isDesktopOrLaptop ? "55%" : "96%",
        }}
      >
        <DropDownContainer />
        {/* {role === ERole.TEACHER && currentSection.question_ids.length !== 0 && <IoReorderFour  onClick={() => setIsReordering(!isReordering)} />} */}
      </div>
      <div
        className="bg-white"
        style={{
          paddingLeft: isReordering ? "2%" : "0%",
          height: isDesktopOrLaptop ? "70vh" : "72vh",
          overflowY: "auto",
          width: isDesktopOrLaptop ? "55%" : "96%",
          borderRadius: "0 0 20px 20px",
        }}
      >
        {isReordering ? (
          <ReorederableComponent
            reorderableContent={currentSection.question_ids}
            initialNumber={questionStartingIndex.current}
            headerText="Question"
          />
        ) : currentSection.question_ids.length !== 0 ? (
          currentSection.question_ids.map((questionId, index) => {
            const currentQuestion = questionMap.get(questionId);

            if (!currentQuestion) return <></>;

            return (
              <div className="d-flex">
                <QuestionResponse
                  role={role || ERole.STUDENT}
                  currentQuestion={currentQuestion}
                  index={index}
                  currentSection={currentSection}
                  setCurrentSection={setCurrentSection}
                />
              </div>
            );
          })
        ) : (
          <div className="bg-white">
            <NoQuestions
              setEdit={() => {
                //This is just a dummy function meant to satisfy props
              }}
            />
          </div>
        )}
      </div>
      <div style={{ width: isDesktopOrLaptop ? "55%" : "96%" }}>
        <QuestionButtonPanel
          isReordering={isReordering}
          setIsReordering={setIsReordering}
          currentSection={currentSection}
          proofRead={props.proofRead}
          isSavingChanges={isSavingChanges}
          setIsSavingChanges={setIsSavingChanges}
        />
      </div>
    </div>
  ) : (
    <></>
  );
}
