import { FunctionComponent, useContext, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import {
  fetchSalesAnalyticsOverview,
  fetchSalesStudentStatistics,
} from "../../api/analytics";
import { fetchCurrentBatch } from "../../api/batches";
import { AppContext, TAppContext } from "../../context/AppContext";
import {
  BatchManagementContext,
  TBatchManagementContext,
} from "../../context/BatchManagementContext";
import {
  TSalesAnalyticsOverviewData,
  TSalesStudentStatisticsData,
} from "../../models/analyticsmodel";
import { parseAnalyticsData } from "../../utils/analyticsUtils";
import { ESalesAnalyticsTab } from "../../utils/Enums";
import AnalyticsTopnav from "../analytics/Components/AnalyticsTopNav";
import SalesAnalyticsHeader from "./Components/SalesAnalyticsHeader";
import SalesAnalyticsOverview from "./Screens/SalesAnalyticsOverview";
import SalesStudentStatistics from "./Screens/SalesStudentStatistics";

interface SalesAnalyticsProps {}

const SalesAnalytics: FunctionComponent<SalesAnalyticsProps> = () => {
  const { setDrawerOpen, accessToken } = useContext<TAppContext>(AppContext);
  const { selectedBatchName } = useContext<TBatchManagementContext>(
    BatchManagementContext
  );
  const [selectedTab, setSelectedTab] = useState<string>(
    ESalesAnalyticsTab[ESalesAnalyticsTab.OVERVIEW]
  );
  const [salesAnalyticsOverviewData, setSalesAnalyticsOverviewData] =
    useState<TSalesAnalyticsOverviewData>();
  const [salesStudentStatisticsData, setSalesStudentStatisticsData] =
    useState<TSalesStudentStatisticsData>();

  const [loading, setLoading] = useState<boolean>(false);

  const [days, setDays] = useState<number>(2);
  const [batchId, setBatchId] = useState<number>();
  const [batchName, setBatchName] = useState<string>("Your Batch");

  const batchCode = useRouteMatch().params["code"];

  useEffect(() => {
    (async () => {
      const batchResponse = await fetchCurrentBatch(accessToken, batchCode);
      setBatchId(batchResponse.id);
      setBatchName(batchResponse.name);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (batchId === undefined) return;
      setLoading(true)
      const analyticsOverviewResponse = await fetchSalesAnalyticsOverview(
        accessToken,
        batchId,
        days
      );
      const studentStatisticsResponse = await fetchSalesStudentStatistics(
        accessToken,
        batchId,
        days
      );
      setSalesAnalyticsOverviewData(
        parseAnalyticsData(analyticsOverviewResponse)
      );
      setSalesStudentStatisticsData(
        parseAnalyticsData(studentStatisticsResponse)
      );
      setLoading(false)
    })();
  }, [days, batchId]);

  useEffect(() => {
    setDrawerOpen(false);
    return () => {
      setDrawerOpen(true);
    };
  });

  const renderContent = () => {
    switch (ESalesAnalyticsTab[selectedTab]) {
      case ESalesAnalyticsTab.OVERVIEW:
        return salesAnalyticsOverviewData !== undefined ? (
          <SalesAnalyticsOverview
            data={salesAnalyticsOverviewData}
            days={days}
            loading={loading}
          />
        ) : (
          <div>No Analytics Avalaible:</div>
        );
      case ESalesAnalyticsTab.STUDENT_STATISTICS:
        return salesStudentStatisticsData !== undefined ? (
          <SalesStudentStatistics data={salesStudentStatisticsData} />
        ) : (
          <div>No Analytics Avalaible:</div>
        );
    }
  };

  return (
    <div className="h-100 d-flex flex-column flex-grow-1">
      <AnalyticsTopnav />
      <SalesAnalyticsHeader
        batchName={batchName}
        days={days}
        setDays={setDays}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      {renderContent()}
    </div>
  );
};

export default SalesAnalytics;
