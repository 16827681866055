import { useState, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { AppContext, TAppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
import HTTPStatusCode from "../../utils/HTTPStatusCode";
import { displayErrorToast, displaySuccessToast } from "../../utils/ToastUtils";
import { sendResetPasswordEmail } from "../../api/accounts";

const displayResetPasswordAPICallError = (statusCode: HTTPStatusCode) => {
  switch (statusCode) {
    case HTTPStatusCode.NOT_FOUND:
      displayErrorToast("Invalid e-mail. The email was not found");
      break;
    default:
      displayErrorToast();
  }
};

export default function ForgotPassword() {
  const { email, setEmail } = useContext<TAppContext>(AppContext);

  const [passwordResetEmailSent, setPasswordResetEmailSent] =
    useState<boolean>(false);

  const sendPasswordResetEmailHandler = async () => {
    let response = await sendResetPasswordEmail(email);
    if (!response) {
      return;
    }
    displaySuccessToast("A Password reset email was sent to you.");
    setPasswordResetEmailSent(true);
  };

  const forgotPasswordForm = (
    <>
      <Form className="mt-4">
        <Form.Label>
          Enter your email to receive a password reset link
        </Form.Label>
        <Form.Group controlId="email">
          <Form.Control
            className="bg-light"
            autoFocus
            type="email"
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <div className="text-center">
          <Button
            variant="secondary"
            className="mt-4 rounded-4"
            block
            size="lg"
            type="button"
            style={{ color: "white" }}
            onClick={sendPasswordResetEmailHandler}
          >
            Continue
          </Button>
        </div>
      </Form>
    </>
  );

  const forgotPasswordEmailSentScreen = (
    <>
      <p>
        Great! We've sent you an email on <b>{email}</b> with instructions to
        reset your password.
      </p>
      <div className="d-flex justify-content-center">
        <Link to="/login">
          <Button
            variant="secondary"
            className="mt-4 rounded-4"
            block
            size="lg"
            type="button"
            style={{ color: "white" }}
          >
            Log In
          </Button>
        </Link>
      </div>
    </>
  );
  return (
    <div className="container d-flex justify-content-center w-100">
      <div className="col-sm-8 col-md-6 col-lg-6 col-10">
        <div
          className="login shadow-lg rounded-4 bg-light"
          style={{
            marginTop: "10%",
            paddingLeft: "4%",
            paddingRight: "4%",
            paddingTop: "4%",
            paddingBottom: "4%",
          }}
        >
          <h2 className="d-flex justify-content-center">
            <strong>Reset Password</strong>
          </h2>
          <hr className="mt-4 text-primary" style={{ width: "100%" }} />
          {passwordResetEmailSent
            ? forgotPasswordEmailSentScreen
            : forgotPasswordForm}
        </div>
      </div>
    </div>
  );
}
