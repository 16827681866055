import { Grid, Typography, useMediaQuery } from "@mui/material";
import AnalyticsCard from "../../analytics/Components/AnalyticsCard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  TSalesAnalyticsOverviewData,
} from "../../../models/analyticsmodel";
import GroupIcon from "@mui/icons-material/Group";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";

type TProps = {
  data: TSalesAnalyticsOverviewData;
  title: string;
};

export default function SalesStudentStatisticsBox(props: TProps) {
  const isMobile = useMediaQuery("(max-width: 576px)");

  const profileIcon = (
    <AccountCircleIcon style={{ color: "#474347" }} fontSize="large" />
  );
  const totalStudents = (
    <GroupIcon style={{ color: "#1d4273" }} fontSize="large" />
  );
  const rank = (
    <MilitaryTechIcon style={{ color: "#630f5e" }} fontSize="large" />
  );

  return (
    <div
      className="px-2 px-sm-4 rounded-4 py-2 py-sm-4 w-100"
      style={{ background: isMobile ? "white" : "#F0F0F0" }}
    >
      <Typography
        style={{ color: "#75777D", fontWeight: "700" }}
        className="mb-2"
      >
        {props.title}
      </Typography>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 2, sm: 4 }}
        sx={{ my: 1 }}
        className="justify-content-center"
      >
        <Grid item md={4} xs={6}>
          <AnalyticsCard
            icon={totalStudents}
            iconBgColor={"#3120708F"}
            cardTitle={"New Students"}
            cardValue={Number(props.data.number_of_users_added_recently.data)}
          />
        </Grid>
        <Grid item md={4} xs={6}>
          <AnalyticsCard
            icon={rank}
            iconBgColor={"#FF599882"}
            cardTitle={"Questions attempted"}
            cardValue={Number(props.data.number_of_recent_responses.data)}
          />
        </Grid>
        <Grid item md={4} xs={6}>
          <AnalyticsCard
            icon={profileIcon}
            iconBgColor={"#292F4D6E"}
            cardTitle={"Active students"}
            cardValue={Number(props.data.number_of_active_users.data)}
          />
        </Grid>
      </Grid>
    </div>
  );
}
