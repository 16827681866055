import axios from "axios";
import { baseURL } from "../utils/constants";
import HTTPStatusCode from "../utils/HTTPStatusCode";
import { displayErrorToast } from "../utils/ToastUtils";
import { handleUnauthorisedException } from "../utils/apiUtils";

export const fetchOrganization = async (accessToken: string) => {
  try {
    const response = await axios.get<any>(`${baseURL}/api/v1/org`, {
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    // Returns a list of all organizations current user is a part of.
    // NOTE: Currently, we operate under the assumption that there is
    // 1:1 mapping b/w user and organization and hence the list size
    // is always === 1.
    return response.data;
  } catch (error: any) {
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    console.log(error);
    displayErrorToast("Unable to get the organization");
  }
};

export const createOrganization = async (accessToken: string, name: string) => {
  try {
    const response = await axios.post<any>(
      `${baseURL}/api/v1/org`,
      {
        name: name,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    console.log(error);
    displayErrorToast(
      "Failed to create an organization. Please try again later."
    );
    return null;
  }
};

export const addUserToOrganization = async (
  accessToken: string,
  orgCode: string
) => {
  try {
    const createOrgResponse = await axios.post<any>(
      `${baseURL}/api/v1/org/${orgCode}/user`,
      {},
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return createOrgResponse.status;
  } catch (error: any) {
    const status = error?.response?.status;

    if (status === HTTPStatusCode.NOT_FOUND) {
      displayErrorToast(
        "Failed to join organization. Please check org code is correct."
      );
    } else {
      displayErrorToast("Failed to join organization. Please try again later.");
    }
    return null;
  }
};
