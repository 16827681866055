import { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import styles from "../AddQuestionButton/AddQuestionButton.module.scss";

type TProps = {
  idx: number;
  show: boolean;
  setShow: any;
  handleAddImage: any;
};

export default function AddImageModal(props: TProps) {
  //TODO: Specify type of file object
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [files, setFiles] = useState<any>([]);

  const handleClose = () => {
    setFiles([]);
    props.setShow(false);
  };

  document.onpaste = function (event) {
    if (event.clipboardData && props.show) {
      const items = event.clipboardData.files;
      const file = items[0];
      if (file) {
        setFiles([
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ]);
      }
    }
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    // Do something with the files
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    onDrop,
    multiple: false,
  });
  const preview = files.map((file) => (
    <div key={file.name}>
      <div>
        <img
          src={file.preview}
          style={{ display: "block", maxWidth: "100%" }}
          alt="preview_image"
        />
      </div>
    </div>
  ));
  useEffect(
    () => () => {
      // Make sure to revoke the Object URL to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Add Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{ height: "150px" }}
          {...getRootProps({ className: styles.dropzone })}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <div className="text-center">
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
          )}
        </div>
        <aside>{preview}</aside>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          disabled={isAdding}
          onClick={async () => {
            setIsAdding(true);
            await props.handleAddImage(props.idx, files[0]);
            setIsAdding(false);
            handleClose();
          }}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
