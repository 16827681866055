import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { EAssessmentFormat } from "../../../utils/Enums";

// MUI imports
import {
  MenuItem,
  Typography,
  Drawer,
  Divider,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useHistory } from "react-router-dom";

interface StudentStatisticsActionsDrawerProps {
  anchorEl: null | HTMLElement;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLElement>>;
  studentName: string;
  rollNumber: string;
  studentId: number;
  publishCode: string;
  score: number;
  correct: number;
  incorrect: number;
  unattempted: number;
  assessmentFormat: EAssessmentFormat;
}

const StudentStatisticsActionsDrawer: FunctionComponent<
  StudentStatisticsActionsDrawerProps
> = ({
  anchorEl,
  setAnchorEl,
  studentName,
  rollNumber,
  studentId,
  publishCode,
  score,
  correct,
  incorrect,
  unattempted,
  assessmentFormat,
}) => {
  const history = useHistory();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenAnalytics = () => {
    history.push(`/home/attempt/${publishCode}/analytics/student/${studentId}`);
  };

  const handleOpenResponses = () => {
    history.push(
      `/home/response/${publishCode}/student/${EAssessmentFormat[assessmentFormat]}/${studentId}`
    );
  };

  return (
    <Drawer
      PaperProps={{
        elevation: 0,
        style: {
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        },
      }}
      anchor={"bottom"}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <div className="p-3 d-flex flex-row align-items-center">
        <AssignmentIcon fontSize="large" />
        <div className="flex-column ms-3">
          <h5>{studentName}</h5>
          <Typography>Roll number: {rollNumber}</Typography>
        </div>
      </div>
      <Divider />
      <div className="p-3">
        <Typography variant="h6">Score {score}</Typography>
        <Typography variant="h6">Correct attempts {correct}</Typography>
        <Typography variant="h6">Incorrect attempts {incorrect}</Typography>
        <Typography variant="h6">Unattempted {unattempted}</Typography>
      </div>
      <MenuItem
        onClick={() => {
          handleClose();
          handleOpenAnalytics();
        }}
      >
        <ListItemIcon>
          <OpenInNewIcon color="success" />
        </ListItemIcon>
        <ListItemText>Open Analytics</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleClose();
          handleOpenResponses();
        }}
      >
        <ListItemIcon>
          <OpenInNewIcon color="success" />
        </ListItemIcon>
        <ListItemText>View Responses</ListItemText>
      </MenuItem>
      <Divider />
    </Drawer>
  );
};

export default StudentStatisticsActionsDrawer;
