import { Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { RiImageAddFill } from "react-icons/ri";
import TipTap from "../../../../utils/TipTap/TipTap";
import { ImSigma } from "react-icons/im";
import { Dispatch, MutableRefObject, SetStateAction, useRef } from "react";
import { EditImage } from "../EditImage/EditImage";
import { IQuestionBody } from "../../../../models/common";

type QuestionTextEditProps = {
  updatedQuestionData: MutableRefObject<IQuestionBody>;
  handleShow: (idx: number) => void;
  reRender: boolean;
  setReRender: Dispatch<SetStateAction<boolean>>;
  isHuge?: boolean;
};

export default function QuestionTextEdit(props: QuestionTextEditProps) {
  const cursorPosition = useRef<number>(0);

  const handleQuestionEdit = (content: string) => {
    props.updatedQuestionData.current.text = content;
  };

  const handleAddMath = () => {
    let a: string;
    const re: RegExp = /\\\(.*?\\\)/g;
    let latexArray: RegExpMatchArray | null;

    if (cursorPosition === undefined) {
      props.updatedQuestionData.current.text += "\\( math \\)";
      props.setReRender(!props.reRender);
      return;
    }

    latexArray = props.updatedQuestionData.current.text.match(re);

    a = props.updatedQuestionData.current.text.replaceAll(re, "R");

    a = `${a.substring(0, cursorPosition.current)} \\( math \\) ${a.substring(
      cursorPosition.current,
      a.length
    )}`;

    if (latexArray) {
      latexArray.forEach((el) => (a = a.replace("R", el)));
    }

    props.updatedQuestionData.current.text = a;
    props.setReRender(!props.reRender);
  };

  const images = props.updatedQuestionData.current?.images;

  return (
    <Row className="border border-dark rounded-4 mx-1">
      <Col
        xs={props.isHuge ? 12 : 10}
        style={{ display: "flex", overflowX: "hidden", maxHeight: "60vh" }}
      >
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            ...(props.isHuge && { maxWidth: "50vw" }),
          }}
        >
          <TipTap
            editMode
            setCursorPosition={(pos: number) => {
              cursorPosition.current = pos;
            }}
            onChange={(_, content) => handleQuestionEdit(content)}
            text={props.updatedQuestionData.current.text}
            idx={-1}
          />
          <Row>
            {images?.map((image, idx) => (
              <EditImage
                src={image}
                onDelete={() => {
                  props.updatedQuestionData.current.images =
                    props.updatedQuestionData.current.images?.filter(
                      (_, index) => index !== idx
                    );
                  props.setReRender(!props.reRender);
                }}
              />
            ))}
          </Row>
        </div>
      </Col>
      <Col
        xs={2}
        className="d-flex flex-grow-1 align-items-center justify-content-end"
        style={{
          marginLeft: props.isHuge ? "10px" : "0px",
          marginBottom: props.isHuge ? "10px" : "0px",
        }}
      >
        <ButtonGroup>
          <Button variant="light">
            <RiImageAddFill
              size="40px"
              color="#9AA0B2"
              onClick={() => {
                props.handleShow(-1);
              }}
              cursor="pointer"
            />
          </Button>
          <Button variant="light">
            <ImSigma
              size="30px"
              color="#9AA0B2"
              onClick={() => {
                handleAddMath();
              }}
              cursor="pointer"
            />
          </Button>
        </ButtonGroup>
      </Col>
    </Row>
  );
}
