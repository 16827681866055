import { IconButton, Typography } from "@mui/material";
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../../../../components/CustomComponent/CustomButton";
import { TModalProps } from "../AddQuestionButton/ManualQuestionHelpers";
import StudentQuestionLegend from "../QuestionNavigationPanel/StudentQuestionLegend";
import styles from "../../../batch-management/batch-management.module.scss";

interface TProps extends TModalProps {
  handleTestSubmit: () => void;
}

export default function TestSubmitModal(props: TProps) {
  const handleHide = async () => {
    props.setShowModal(false);
  };

  return (
    <div
      className="bg-white w-100"
      style={{ flex: "display", flexDirection: "column" }}
    >
      <Modal
        contentClassName={styles.modalStyle}
        show={props.showModal}
        onHide={handleHide}
        centered
      >
        <Modal.Header>
          <Typography variant="h4">
            <b>Submit</b>
          </Typography>
          <IconButton>
            <CloseIcon onClick={() => props.setShowModal(false)} />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to submit the test? Your attempte details are
            as follows :-{" "}
          </p>
          <div
            style={{
              backgroundColor: "#CDCDCD",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
            className="rounded-4 p-3"
          >
            <p style={{ fontWeight: "bold", fontSize: "20px" }}>
              Your Attempts
            </p>
            <StudentQuestionLegend />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            className="mt-2 mx-3 bg-danger"
            onClick={() => props.setShowModal(false)}
          >
            Cancel
          </CustomButton>
          <CustomButton
            className="mt-2 mx-3"
            onClick={() => props.handleTestSubmit()}
          >
            Submit
          </CustomButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
