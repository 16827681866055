import "bootstrap/dist/css/bootstrap.min.css";
import { useContext } from "react";
import { AppContext, TAppContext } from "../../context/AppContext";
import { useHistory, useLocation } from "react-router-dom";
import { loginUserAPICall } from "../../api/login";
import { displayErrorToast, displaySuccessToast } from "../../utils/ToastUtils";
import { updatePasswordAPICall } from "../../api/user";
import ResetPasswordModal from "./Components/ResetPasswordModal";
import { resetPassword } from "../../api/accounts";
import { Container } from "react-bootstrap";

type TProps = {
  isLoggedIn: boolean;
};

export default function ResetPassword(props: TProps) {
  const { accessToken, email, setAccessToken } =
    useContext<TAppContext>(AppContext);
  const history = useHistory();
  const searchParams = useLocation().search;
  const verificationToken = new URLSearchParams(searchParams).get("token");

  const updatePasswordHandler = async (
    currentPassword: string,
    newPassword: string
  ) => {
    let response;
    if (props.isLoggedIn) {
      response = await updatePasswordAPICall(
        currentPassword,
        newPassword,
        accessToken
      );
    } else {
      if (!verificationToken) {
        displayErrorToast("Please check your verification link.");
        return;
      }
      response = await resetPassword(verificationToken, newPassword);
    }

    if (!response) {
      return;
    }
    displaySuccessToast("Password reset successful!");

    const loginResponse = await loginUserAPICall(email, newPassword);
    if (!loginResponse) {
      return;
    }
    setAccessToken(loginResponse.access_token);

    history.push("/home");
  };

  return (
    <div className="bg-primary-dark w-100">
      <Container className="d-flex justify-content-center">
        <div className="col-sm-8 col-md-6 col-lg-6 col-10">
          <div
            className="login shadow-lg rounded-4 bg-light"
            style={{
              marginTop: "10%",
              paddingLeft: "4%",
              paddingRight: "4%",
              paddingTop: "4%",
              paddingBottom: "4%",
            }}
          >
            <h2 className="d-flex justify-content-center">
              <strong>Reset Password</strong>
            </h2>
            <hr className="mt-4 text-primary" style={{ width: "100%" }} />
            <ResetPasswordModal
              submitHandler={updatePasswordHandler}
              disableCurrentPassword={!props.isLoggedIn}
            />
          </div>
        </div>
      </Container>
    </div>
  );
}
