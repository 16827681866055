import { Node, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import MathWrapperComponent from "./Component";

export default Node.create({
  name: "mathComponent",

  group: "inline",
  inline: true,

  addAttributes() {
    return {
      editmode: {
        default: false,
      },
      math: {
        default: "",
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "math-component",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["math-component", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(MathWrapperComponent);
  },
});
