import axios from "axios";
import { TLimitInfo } from "../models/assessmentmodel";
import { IQuestionMarkingScheme } from "../models/question";
import { handleUnauthorisedException } from "../utils/apiUtils";
import { baseURL } from "../utils/constants";
import {
  EAccessLevel,
  EAssessmentFormat,
  EAssessmentStatus,
  EAssessmentType,
  EPublishedAssessmentFormat,
  EPublishStatus,
} from "../utils/Enums";
import HTTPStatusCode from "../utils/HTTPStatusCode";
import { displayErrorToast } from "../utils/ToastUtils";

export const fetchAssessmentData = async (
  accessToken: string,
  assessmentId: number
) => {
  try {
    const response = await axios.get<any>(
      `${baseURL}/api/v1/assessment/${assessmentId}`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    if (error?.response?.status === HTTPStatusCode.FORBIDDEN) {
      console.error("Forbidden");
      displayErrorToast(
        "You cannot view this assessment since you're not the creator"
      );
      return null;
    }
    console.log(error);
    displayErrorToast("Unable to fetch assessment");
    return null;
  }
};

export const fetchPublishedAssessmentData = async (
  accessToken: string,
  publishCode: string
) => {
  try {
    const response = await axios.get<any>(
      `${baseURL}/api/v1/published_assessment/${publishCode}`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.log(error);
    switch (error?.response?.status) {
      case HTTPStatusCode.UNAUTHORIZED:
        console.error("Unauthorised request");
        handleUnauthorisedException();
        break;
      case HTTPStatusCode.BAD_REQUEST:
        displayErrorToast("Assessment hasn't started");
        break;
      default:
        displayErrorToast("Unable to fetch published assessment");
    }
    return null;
  }
};

export const addQuestionToSection = async (
  accessToken: string,
  assessmentId: number,
  sectionId: number,
  markingScheme: Array<IQuestionMarkingScheme>
) => {
  try {
    const response = await axios.patch(
      `${baseURL}/api/v1/assessment/${assessmentId}/section/${sectionId}/question`,
      markingScheme,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    console.log(error);
    displayErrorToast("Unable to update question marking scheme");
    return null;
  }
};

export const fetchSections = async (
  accessToken: string,
  assessmentId: number
) => {
  try {
    const response = await axios.get<any>(
      `${baseURL}/api/v1/assessment/${assessmentId}/section`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    console.log(error);
    displayErrorToast("Unable to fetch sections");
    return null;
  }
};

export const fetchQuestions = async (
  accessToken: string,
  assessmentId: number
) => {
  try {
    const response = await axios.get<any>(
      `${baseURL}/api/v1/assessment/${assessmentId}/question`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Authorization: "Bearer " + accessToken,
          "x-user-time": new Date().toISOString().slice(0, -1),
        },
      }
    );
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    console.log(error);
    displayErrorToast("Unable to fetch questions");
    return null;
  }
};

export const handleCreateSectionAPI = async (
  accessToken: string,
  sectionName: string,
  AssessmentId: number
) => {
  try {
    const createSectionResponse = await axios.post<any>(
      `${baseURL}/api/v1/assessment/${AssessmentId}/section`,
      {
        name: sectionName,
        instructions: "",
        question_ids: [],
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return createSectionResponse.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    console.log(error);
    displayErrorToast("Failed to create a section");
  }
};

export const handleUpdateSection = async (
  accessToken: string,
  sectionId: number,
  AssessmentId: number,
  sectionName?: string,
  question_ids?: Array<number>,
  optionalAttempts?: Array<TLimitInfo>
) => {
  try {
    await axios.patch<any>(
      `${baseURL}/api/v1/assessment/${AssessmentId}/section/${sectionId}`,
      {
        ...(sectionName && { name: sectionName }),
        ...(question_ids && { question_ids: question_ids }),
        ...(optionalAttempts && { optional_attempts: optionalAttempts }),
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return "success";
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    console.log(error);
    displayErrorToast("Failed to update section");
  }
};

export const handleUpdateSectionInstructions = async (
  accessToken: string,
  sectionInstructions: string,
  sectionId: number,
  AssessmentId: number
) => {
  try {
    await axios.patch<any>(
      `${baseURL}/api/v1/assessment/${AssessmentId}/section/${sectionId}`,
      {
        instructions: sectionInstructions,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return "success";
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    console.log(error);
    displayErrorToast("Failed to update section instructions");
  }
};

export const handleDeleteSection = async (
  accessToken: string,
  sectionId: number,
  AssessmentId: number
) => {
  try {
    await axios.delete<any>(
      `${baseURL}/api/v1/assessment/${AssessmentId}/section/${sectionId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return "success";
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    console.log(error);
    displayErrorToast("Failed to delete section");
  }
};

export const handleCreateAssessment = async (
  accessToken: string,
  type: EAssessmentType,
  format: EAssessmentFormat,
  name: string,
  instructions: string,
  orgID?: number
) => {
  try {
    const response = await axios.post<any>(
      `${baseURL}/api/v1/assessment`,
      {
        type,
        name: name,
        sections: [],
        instructions: instructions,
        status: EAssessmentStatus.DRAFT,
        ...(orgID && { org_id: orgID }),
        format,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    displayErrorToast("Failed to create assessment");
    return null;
  }
};

export const fetchAssessmentsForStudent = async (accessToken: string) => {
  try {
    const response = await axios.get<any>(
      `${baseURL}/api/v1/published_assessment`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    displayErrorToast("Unable to fetch assessments");
    return null;
  }
};

export const fetchAssessmentsForTeacher = async (accessToken: string) => {
  try {
    const response = await axios.get<any>(`${baseURL}/api/v1/assessment`, {
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
        Authorization: "Bearer " + accessToken,
      },
    });
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    displayErrorToast("Unable to fetch assessments");
    return null;
  }
};

export const fetchAssessmentsCreatedByUser = async (accessToken: string) => {
  try {
    const response = await axios.get<any>(`${baseURL}/api/v1/assessment`, {
      headers: { Authorization: "Bearer " + accessToken },
    });
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    displayErrorToast("Unable to fetch assessments");
    return null;
  }
};

export const fetchAssessmentsAttemptedByUser = async (accessToken: string) => {
  try {
    const response = await axios.get<any>(
      `${baseURL}/api/v1/assessment/attempted?public_only=true`,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    displayErrorToast("Unable to fetch assessments");
    return null;
  }
};

export const fetchPastPublishesOfAssessment = async (
  accessToken: string,
  assessmentId: number
) => {
  try {
    const response = await axios.get<any>(
      `${baseURL}/api/v1/assessment/${assessmentId}/publications`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    displayErrorToast("Unable to fetch past published assessments");
    return null;
  }
};

export const deleteAssessment = async (
  accessToken: string,
  assessmentId: number
) => {
  try {
    const response = await axios.delete<any>(
      `${baseURL}/api/v1/assessment/${assessmentId}`,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    return response.data;
  } catch (error: any) {
    if (
      error?.response?.status === HTTPStatusCode.BAD_REQUEST &&
      error?.response?.data?.error?.data.includes("has been published to batch")
    ) {
      displayErrorToast(
        "Failed to delete the assessment as it has already been published"
      );
      return null;
    }
    displayErrorToast("Failed to delete assessment");
    return null;
  }
};

export const duplicateAssessment = async (
  accessToken: string,
  assessmentId: number
) => {
  try {
    const response = await axios.post<any>(
      `${baseURL}/api/v1/assessment/duplicate/${assessmentId}`,
      {},
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    return response.data;
  } catch (error: any) {
    displayErrorToast("Failed to duplicate assessment");
    return null;
  }
};

export const publishAssessment = async (
  accessToken: string,
  assessmentId: number,
  status: EPublishStatus,
  accessLevel: EAccessLevel,
  format: EPublishedAssessmentFormat,
  startTime: string,
  duration?: number,
  endTime?: string,
  batchId?: number
) => {
  try {
    const response = await axios.post<any>(
      `${baseURL}/api/v1/assessment/${assessmentId}/publish`,
      {
        access_level: accessLevel,
        status: status,
        start_time: startTime,
        end_time: endTime,
        format: Number(format),
        duration: duration,
        ...(accessLevel === EAccessLevel.BATCH && { batch_id: batchId }),
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    if (error?.response?.status === HTTPStatusCode.UNPROCESSABLE_ENTITY) {
      displayErrorToast(
        error.response?.data?.error[0]?.msg ?? "Failed to publish assessment"
      );
      return null;
    }
    displayErrorToast("Failed to publish assessment");
    return null;
  }
};

export const patchPublishedAssessment = async (
  publishId: number,
  accessToken: string,
  fields: Object
) => {
  try {
    let response = await axios.patch<any>(
      `${baseURL}/api/v1/published_assessment/${publishId}`,
      fields,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    // TODO: Send a better error message
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    if (error?.response?.status === HTTPStatusCode.UNPROCESSABLE_ENTITY) {
      displayErrorToast(
        error.response?.data?.error[0]?.msg ??
          "Failed to update published assessment"
      );
      return null;
    }
    displayErrorToast("Failed to update published assessment");
    return null;
  }
};

/**
 *
 * A unified patch api for all of assessment related needs. can edit the assessment name, its instructions, if it is a draft, or if it is published.
 * or if it is an assessment or a test
 * #TODO: accessToken really not be passed as a param. Instead start picking it up from cookies whenever that becomes a thing
 */

export const patchAssessment = async (
  accessToken: string,
  assessmentId: number,
  assessmentName?: string,
  no_solution_pdf_url?: string,
  solution_pdf_url?: string,
  teachers_pdf_url?: string,
  assessmentInstructions?: string,
  sections: Array<number> = []
) => {
  const data = {
    ...(assessmentName && { name: assessmentName }),
    ...(no_solution_pdf_url !== undefined && {
      no_solution_pdf_url: no_solution_pdf_url,
    }),
    ...(teachers_pdf_url !== undefined && {
      teachers_pdf_url: teachers_pdf_url,
    }),
    ...(solution_pdf_url !== undefined && {
      solution_pdf_url: solution_pdf_url,
    }),
    ...(assessmentInstructions !== undefined && {
      instructions: assessmentInstructions,
    }),
    ...(sections?.length > 0 && { sections: sections }),
  };
  // no_solution_pdf_url can be an empty string if the url is deleted. That check is bypassed by the && operator
  // thus explicit check is needed for no_solution_pdf_url

  try {
    const response = await axios.patch<any>(
      `${baseURL}/api/v1/assessment/${assessmentId}`,
      data,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }

    console.log(error);
    displayErrorToast("Failed to update assessment information");
  }
};

export const handleTransferAssessment = async (
  accessToken: string,
  assessmentId: number
) => {
  try {
    const response = await axios.post<any>(
      `${baseURL}/api/v1/assessment/${assessmentId}/transfer_assessment`,
      {},
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    displayErrorToast("Failed to transfer assessment");
    return null;
  }
};
