import { Card, Divider, Grid, Typography, useMediaQuery } from "@mui/material";

import { TSalesAnalyticsOverviewData } from "../../../models/analyticsmodel";

interface IScoreCard {
  data: TSalesAnalyticsOverviewData;
}

const ScoreCard = (props: IScoreCard) => {
  const isMobile = useMediaQuery("(max-width: 576px)");

  return (
    <>
      <Card
        sx={{
          border: isMobile ? "0" : "2px solid black",
        }}
        className="py-2 px-2 px-sm-4"
        elevation={0}
      >
        <Typography variant="h6" className="my-2">
          <b>Leaderboard</b>
        </Typography>
        <Divider className="w-100"></Divider>
        <div style={{ height: "30vh", overflowY: "scroll" }}>
          {props.data.leaderboard.map((leaderboard) => {
            return (
              <>
                <Grid
                  container
                  xs={12}
                  sx={{ pr: 2 }}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="subtitle1">
                    {leaderboard.name}
                  </Typography>
                  <Typography variant="subtitle1" color="#3FB93D">
                    {leaderboard.score}
                  </Typography>
                </Grid>
                <Divider sx={{ my: 1 }} />
              </>
            );
          })}
        </div>
      </Card>
    </>
  );
};

export default ScoreCard;
