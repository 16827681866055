import { Button, Dropdown } from "react-bootstrap";
import { EQuestionType } from "../../../../utils/Enums";
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../context/AssessmentContext";
import { findQuestionType } from "../../../../utils/question";
import { IquestionData } from "../../../../models/assessmentmodel";
import { IQuestionBody } from "../../../../models/common";
import { ComprehensionModal } from "../ComprehensionModal/ComprehensionModal";

type TProps = {
  updatedQuestionData: MutableRefObject<IquestionData>;
  reRender: boolean;
  setReRender: Dispatch<SetStateAction<boolean>>;
  proofRead: boolean;
  setProofRead: Dispatch<SetStateAction<boolean>>;
};

export const HeaderEdit = (props: TProps) => {
  const {
    selectedAssessment,
    currentQuestionNumber,
    currentSectionNumber,
    sectionMap,
    currentQuestionData,
    comprehensionMap,
  } = useContext<TAssessmentContext>(AssessmentContext);

  const [questionButtonText, setQuestionButtonText] = useState<number>(1);
  const [showComprehension, setShowComprehension] = useState<boolean>(false);

  useEffect(() => {
    let questionNumber = currentQuestionNumber + 1;
    selectedAssessment.sections
      .slice(0, currentSectionNumber)
      .forEach(
        (sectionId) =>
          (questionNumber += sectionMap.get(sectionId)!.question_ids.length)
      );
    setQuestionButtonText(questionNumber);
  }, [currentQuestionNumber, currentSectionNumber, sectionMap]);

  const handleQuestionTypeChange = (updatedQType: EQuestionType) => {
    // Don't do anything if one hasn't changed the question type
    if (props.updatedQuestionData.current.question_type === updatedQType)
      return;

    // MCQ/MCMCQ -> Numerical. Clear out the options
    if (
      (props.updatedQuestionData.current.question_type === EQuestionType.MCQ ||
        props.updatedQuestionData.current.question_type ===
          EQuestionType.MCMCQ) &&
      updatedQType === EQuestionType.NUMERICAL
    ) {
      props.updatedQuestionData.current.options = [];
    } else if (
      (updatedQType === EQuestionType.MCQ ||
        updatedQType === EQuestionType.MCMCQ) &&
      props.updatedQuestionData.current.options.length < 3
    ) {
      // case when changing from Numerical to MCQ/MCMCQ, fill it
      props.updatedQuestionData.current.options = [
        ...props.updatedQuestionData.current.options,
        ...Array<IQuestionBody>(
          2 - props.updatedQuestionData.current.options.length
        ).fill({
          // repeat the same option for 2 options. We need 2 options for MCQ/MCMCQ
          images: [],
          text: "Add your text here",
        }),
      ];
    }
    props.updatedQuestionData.current.answer = "";
    props.updatedQuestionData.current.question_type = updatedQType;
    props.setReRender(!props.reRender);
  };

  // TODO: Add api for marking scheme and unify displayed question number
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex flex-row align-items-center">
        <h3 className="d-inline mb-0"> Question {questionButtonText}</h3>
        <h5 className="d-inline ms-3 mb-0" style={{ color: "#C4C4C4" }}>
          <Dropdown>
            <Dropdown.Toggle
              className="bg-secondary border border-none rounded-4 p-2 px-4 d-flex justify-content-center align-items-center"
              id="dropdown-basic"
            >
              {findQuestionType(
                props.updatedQuestionData.current.question_type
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {Object.keys(EQuestionType)
                .filter((key) => isNaN(Number(key)))
                .map((key) => (
                  <Dropdown.Item
                    onClick={() => {
                      handleQuestionTypeChange(EQuestionType[key]);
                    }}
                  >
                    {findQuestionType(EQuestionType[key])}
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>
        </h5>
      </div>
      <div>
        {currentQuestionData.comprehension_id && (
          <>
            <Button
              className="bg-secondary border border-none rounded-2 px-4 py-2 mx-2"
              style={{ color: "white", cursor: "pointer" }}
              onClick={() => setShowComprehension(!showComprehension)}
            >
              View/Edit Comprehension
            </Button>

            <ComprehensionModal
              show={showComprehension}
              setShow={setShowComprehension}
              selectedComprehensionId={currentQuestionData.comprehension_id}
              content={{
                ...comprehensionMap.get(currentQuestionData.comprehension_id)!!
                  .body,
              }}
              edit
            />
          </>
        )}
      </div>
    </div>
  );
};
