import { Button, Stack } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";

import QuestionPaperTemplate from "./questionPaperTemplate";
import { AssessmentContext } from "../../context/AssessmentContext";
import { AppContext } from "../../context/AppContext";
import { EQuestionType } from "../../utils/Enums";
import { DataProcessor } from "./utils";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";

const PdfRenderer = () => {
  const { questionMap, sectionMap, selectedAssessment } =
    useContext(AssessmentContext);
  const { setDrawerOpen } = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    if (window.MathJax) {
      window.MathJax.typeset();
      console.log("MathJax was Loaded");
    }
  }, []);
  useEffect(() => {
    setDrawerOpen(false);
    return () => setDrawerOpen(true);
  });

  const location = useLocation();
  const { printPaperWithAns, printPaperWithSolution } =
    location.state.optionsSelected;
  const { instructionData, newRenderContent } = DataProcessor(
    selectedAssessment,
    questionMap,
    sectionMap
  );
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Stack>
      <div
        className="d-flex justify-content-between "
        style={{
          margin: "10px 10px 0px 10px",
          textAlign: "center",
          marginTop: "12px",
        }}
      >
        <Button
          onClick={() => history.goBack()}
          className="me-2"
          style={{
            maxWidth: "800px",
            textAlign: "center",
            backgroundColor: "#292F4D",
            color: "white",
            padding: "18px 9px",
            fontSize: "16px",
            fontWeight: "900",
          }}
        >
          <ArrowBackIcon />
        </Button>
        <Button
          onClick={handlePrint}
          style={{
            maxWidth: "900px",
            textAlign: "center",
            backgroundColor: "#292F4D",
            color: "white",
            padding: "14px 50px",
            fontSize: "17px",
            fontWeight: "900",
          }}
          startIcon={<PrintIcon />}
        >
          Print Paper
        </Button>
      </div>
      <div ref={componentRef}>
        <QuestionPaperTemplate
          renderContent={newRenderContent}
          instructionForSection={instructionData}
          assessmentData={selectedAssessment}
          printPaperWithAns={printPaperWithAns}
          printPaperWithSolution={printPaperWithSolution}
          questionType={EQuestionType}
        />
      </div>
    </Stack>
  );
};

export default PdfRenderer;
