import { useContext, Dispatch, SetStateAction, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import styles from "../../../batch-management/batch-management.module.scss";
import { AppContext, TAppContext } from "../../../../context/AppContext";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../context/AssessmentContext";
import { patchAssessment } from "../../../../api/assessment";
import { displaySuccessToast } from "../../../../utils/ToastUtils";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

type TProps = {
  showEditAssessmentModal: boolean;
  setShowEditAssessmentModal: Dispatch<SetStateAction<boolean>>;
  assessmentId?: number;
  initialAssessmentName?: string;
  onUpdate?: () => void;
};

export default function EditAssessmentNameModal(props: TProps) {
  const { selectedAssessment, setSelectedAssessment } =
    useContext<TAssessmentContext>(AssessmentContext);
  const { accessToken } = useContext<TAppContext>(AppContext);

  let schema = yup.object().shape({
    assessmentName: yup.string().required("Please enter a assessment name"),
  });

  const updateAssessmentName = async (updatedName: string) => {
    const updatedAssessmentResponse = await patchAssessment(
      accessToken,
      props.assessmentId ?? selectedAssessment.id,
      updatedName
    );
    if (updatedAssessmentResponse === null) return;
    setSelectedAssessment({ ...selectedAssessment, name: updatedName });
    displaySuccessToast("Assessment name updated successfully");
    props.setShowEditAssessmentModal(false);
    if (props.onUpdate) props.onUpdate();
  };

  return (
    <Modal
      contentClassName={styles.modalStyle}
      show={props.showEditAssessmentModal}
      onHide={() => props.setShowEditAssessmentModal(false)}
      centered
    >
      <Modal.Header>
        <Typography variant="h4">
          <b>{"Edit Assessment Name"}</b>
        </Typography>
        <IconButton>
          <CloseIcon onClick={() => props.setShowEditAssessmentModal(false)} />
        </IconButton>
      </Modal.Header>

      <Formik
        validationSchema={schema}
        onSubmit={(values) => {
          updateAssessmentName(values.assessmentName);
        }}
        initialValues={{
          assessmentName:
            props.initialAssessmentName ?? selectedAssessment.name,
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
          isSubmitting,
        }) => (
          <>
            <Modal.Body>
              <Form
                noValidate
                onSubmit={(event) => {
                  event.preventDefault();
                  handleSubmit();
                }}
              >
                <Form.Group controlId="assessmentName">
                  <Form.Label>
                    <Typography fontSize={20} color="#9AA0B2">
                      <b>Assesment Name:</b>
                    </Typography>
                  </Form.Label>
                  <Form.Control
                    type="assessment name"
                    value={values.assessmentName}
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ backgroundColor: "#DBDFF1" }}
                    className="ml-2"
                    isInvalid={!!errors.assessmentName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.assessmentName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="border-0 rounded-2 bg-secondary"
                type="submit"
                disabled={isSubmitting}
                onClick={() => updateAssessmentName(values.assessmentName)}
              >
                <Typography className="m-0 p-0">
                  {"Update Assessment"}
                </Typography>
              </Button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
}
