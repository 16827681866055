import axios from "axios";
import { THighlightObject } from "../models/analyticsmodel";
import { IQuestionV2 } from "../models/question";
import { AnalyticsStudentResponse } from "../models/usermodel";
import { handleUnauthorisedException } from "../utils/apiUtils";
import { baseURL } from "../utils/constants";
import HTTPStatusCode from "../utils/HTTPStatusCode";
import { displayErrorToast, displayInfoToast } from "../utils/ToastUtils";

export const fetchResults = async (
  accessToken: string,
  publishCode: string
) => {
  try {
    console.log("Fetching Results");
    const response = await axios.get<any>(
      `${baseURL}/api/v1/analytics/${publishCode}/results/creator`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (response.status === HTTPStatusCode.ACCEPTED) {
      displayInfoToast(
        "Evaluation of responses is still in progress. Please check after a few minutes"
      );
      return null;
    } else if (Object.keys(response.data).length === 0) {
      displayErrorToast(
        "There are no responses to this assessment. No result available"
      );
      return null;
    }
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (axios.isAxiosError(error)) {
      if (error.response?.status === HTTPStatusCode.UNAUTHORIZED) {
        console.error("Unauthorised request");
        handleUnauthorisedException();
        return null;
      }
      console.log(error);
      displayErrorToast("Unable to fetch results");
      return null;
    }
  }
};

export const fetchAnalyticsResponses = async (
  accessToken: string,
  publishCode: string
) => {
  try {
    console.log("Fetching Results");
    const response = await axios.get<any>(
      `${baseURL}/api/v1/analytics/${publishCode}/responses/creator`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (response.status === HTTPStatusCode.ACCEPTED) {
      displayInfoToast(
        "Evaluation of responses is still in progress. Please check after a few minutes"
      );
      return null;
    } else if (Object.keys(response.data).length === 0) {
      displayErrorToast(
        "There are no responses to this assessment. Hence no responses available"
      );
      return null;
    }
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (axios.isAxiosError(error)) {
      if (error.response?.status === HTTPStatusCode.UNAUTHORIZED) {
        console.error("Unauthorised request");
        handleUnauthorisedException();
        return null;
      }
      console.log(error);
      displayErrorToast("Unable to fetch responses");
    }
    return null;
  }
};

export const fetchAttemptorHighlights = async (
  accessToken: string,
  publishCode: string,
  attemptorId: number
): Promise<THighlightObject | null> => {
  try {
    const response = await axios.get<THighlightObject>(
      `${baseURL}/api/v1/analytics/${publishCode}/highlights/attemptor/${attemptorId}`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (response.status === HTTPStatusCode.ACCEPTED) {
      displayInfoToast(
        "Evaluation of responses is still in progress. Please check after a few minutes"
      );
      return null;
    } else if (Object.keys(response.data).length === 0) {
      displayErrorToast(
        "There are no responses to this assessment. No analytics available"
      );
      return null;
    }
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (
      axios.isAxiosError(error) &&
      error.response?.status === HTTPStatusCode.UNAUTHORIZED
    ) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    console.log(error);
    displayErrorToast("Unable to fetch analytics");
    return null;
  }
};

export const fetchAttemptorAnalytics = async (
  accessToken: string,
  publishCode: string,
  type: string,
  attemptorId: number,
  sectionId?: number
) => {
  try {
    console.log("Fetching Teacher Question Stats");
    const sectionQuery =
      sectionId !== undefined ? `?section_id=${sectionId}` : "";
    const response = await axios.get<any>(
      `${baseURL}/api/v1/analytics/${publishCode}/${type}/attemptor/${attemptorId}${sectionQuery}`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (response.status === HTTPStatusCode.ACCEPTED) {
      displayInfoToast(
        "Evaluation of responses is still in progress. Please check after a few minutes"
      );
      return null;
    } else if (Object.keys(response.data).length === 0) {
      displayErrorToast(
        "There are no responses to this assessment. No analytics available"
      );
      return null;
    }
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (axios.isAxiosError(error)) {
      if (error.response?.status === HTTPStatusCode.UNAUTHORIZED) {
        console.error("Unauthorised request");
        handleUnauthorisedException();
        return null;
      }
      console.log(error);
      displayErrorToast("Unable to fetch analytics");
      return null;
    }
  }
};

export const fetchCreatorHighlights = async (
  accessToken: string,
  publishCode: string
): Promise<THighlightObject | null> => {
  try {
    console.log("Fetching Creator Highlights");
    const response = await axios.get<THighlightObject>(
      `${baseURL}/api/v1/analytics/${publishCode}/highlights/creator`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (response.status === HTTPStatusCode.ACCEPTED) {
      displayInfoToast(
        "Evaluation of responses is still in progress. Please check after a few minutes"
      );
      return null;
    } else if (Object.keys(response.data).length === 0) {
      displayErrorToast(
        "There are no responses to this assessment. No analytics available"
      );
      return null;
    }
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (
      axios.isAxiosError(error) &&
      error.response?.status === HTTPStatusCode.UNAUTHORIZED
    ) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    console.log(error);
    displayErrorToast("Unable to fetch analytics");
    return null;
  }
};

export const fetchCreatorAnalytics = async (
  accessToken: string,
  publishCode: string,
  type: string,
  sectionId?: number
) => {
  try {
    console.log("Fetching Teacher Question Stats");
    const sectionQuery =
      sectionId !== undefined ? `?section_id=${sectionId}` : "";
    const response = await axios.get<any>(
      `${baseURL}/api/v1/analytics/${publishCode}/${type}/creator${sectionQuery}`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (response.status === HTTPStatusCode.ACCEPTED) {
      displayInfoToast(
        "Evaluation of responses is still in progress. Please check after a few minutes"
      );
      return null;
    } else if (Object.keys(response.data).length === 0) {
      displayErrorToast(
        "There are no responses to this assessment. No analytics available"
      );
      return null;
    }
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (axios.isAxiosError(error)) {
      if (error.response?.status === HTTPStatusCode.UNAUTHORIZED) {
        console.error("Unauthorised request");
        handleUnauthorisedException();
        return null;
      }
      console.log(error);
      displayErrorToast("Unable to fetch analytics");
      return null;
    }
  }
};

export const fetchStudentFromIds = async (
  accessToken: string,
  publish_code: string,
  student_ids: Array<number>
): Promise<Array<AnalyticsStudentResponse> | null> => {
  try {
    let studentQuery = "";
    student_ids.forEach((id) => {
      studentQuery += `student_id=${id}&`;
    });
    const response = await axios.get<Array<AnalyticsStudentResponse>>(
      `${baseURL}/api/v1/analytics/${publish_code}/student?${studentQuery}`,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    return response.data;
  } catch (error: any) {
    displayErrorToast("Failed to delete question");
    return null;
  }
};

export const fetchQuestionFromIds = async (
  accessToken: string,
  publish_code: string,
  question_ids: Array<number>
): Promise<Array<IQuestionV2> | null> => {
  try {
    let questionQuery = "";
    question_ids.forEach((id) => {
      questionQuery += `question_id=${id}&`;
    });
    console.log(questionQuery);
    const response = await axios.get<Array<IQuestionV2>>(
      `${baseURL}/api/v1/analytics/${publish_code}/question?${questionQuery}`,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    return response.data;
  } catch (error: any) {
    displayErrorToast("Failed to fetch question(s)");
    return null;
  }
};

export const fetchSalesAnalyticsOverview = async (
  accessToken: string,
  batchId: number,
  days?: number
) => {
  try {
    let dayQuery: string = "";
    if (days !== undefined) {
      dayQuery = `?days=${days}`;
    }

    const response = await axios.get<any>(
      `${baseURL}/api/v1/analytics/sales/${batchId}/overview${dayQuery}`,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    return response.data;
  } catch (error: any) {
    displayErrorToast("Failed to fetch sales analytics overview");
    return null;
  }
};

export const fetchSalesStudentStatistics = async (
  accessToken: string,
  batchId: number,
  days?: number
) => {
  try {
    let dayQuery: string = "";
    if (days !== undefined) {
      dayQuery = `?days=${days}`;
    }

    const response = await axios.get<any>(
      `${baseURL}/api/v1/analytics/sales/${batchId}/student-stats${dayQuery}`,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    return response.data;
  } catch (error: any) {
    displayErrorToast("Failed to fetch sales student statistics");
    return null;
  }
};
