import { Typography } from "@mui/material";
import { FunctionComponent, ReactNode, useContext } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { saveClientErrorLog } from "../../api/errorLogs";
import { AppContext, TAppContext } from "../../context/AppContext";

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div className="container d-flex justify-content-center mt-5 w-100 p-0">
      <div className={"col-sm-8 col-md-8 col-lg-6 col-11"}>
        <Typography
          className="shadow-lg rounded-2"
          style={{
            paddingLeft: "4%",
            paddingRight: "4%",
            paddingTop: "4%",
            paddingBottom: "4%",
            backgroundColor: "#eeeeee",
          }}
        >
          <h2 className="d-flex justify-content-center">
            <strong>ERROR!</strong>
          </h2>
          <hr className="mt-4 text-primary" style={{ width: "100%" }} />
          Something went wrong! Try restarting the website or try switching to
          an updated browser (preferably Chrome or Firefox).
        </Typography>
      </div>
    </div>
  );
}

interface CatchErrorProps {
  children: ReactNode;
}

const CatchError: FunctionComponent<CatchErrorProps> = (
  props: CatchErrorProps
) => {
  const { id } = useContext<TAppContext>(AppContext);

  const handleAppError = (error: Error, info: { componentStack: string }) => {
    saveClientErrorLog(
      JSON.stringify({
        name: error.name,
        message: error.message,
        trace: error.stack,
      }),
      id
    );
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleAppError}>
      {props.children}
    </ErrorBoundary>
  );
};

export default CatchError;
