import {
  createContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import {
  TScalarMetric,
  TSingleDimensional,
  TMultiDimension,
  TTabular,
  TLeaderboard,
  TAnalyticsStudentOverview,
  TAnalyticsTeacherOverview,
  TAnalyticsTeacherTimed,
  TAnalyticsStudentQuestion,
  TAnalyticsStudentTimed,
  TAnalyticsCreatorQuestionStats,
  TAnalyticsAttemptorQuestionStats,
  TAnalyticsAllStudentsStatistics,
} from "../models/analyticsmodel";
import { EAnalyticsTab } from "../utils/Enums";

export const scalarMetricInitialValue: TScalarMetric = {
  name: "",
  data: 0,
  type: 1,
};

export const singleDimensionInitialValue: TSingleDimensional = {
  name: "",
  data: [],
  type: 2,
};

export const multiDimensionInitialValue: TMultiDimension = {
  name: "",
  data: [[]],
  labels: [],
  type: 3,
};

export const tabularInitialValue: TTabular = {
  name: "",
  data: "",
  type: 4,
};

export const leaderboardInitialValue: TLeaderboard = {
  name: "",
  roll_no: "",
  score: "",
  user_id: "",
};

export const csvObjectInitialValue: TTabular = {
  name: "",
  data: "",
  type: 5,
};

export const studentAnalyticsOverviewInitialValue: TAnalyticsStudentOverview = {
  number_of_students: scalarMetricInitialValue,
  number_of_questions: scalarMetricInitialValue,
  student_rank: scalarMetricInitialValue,
  student_score: scalarMetricInitialValue,
  mean_score: scalarMetricInitialValue,
  median_score: scalarMetricInitialValue,
  student_percentile: scalarMetricInitialValue,
  highest_score: scalarMetricInitialValue,
  leaderboard: [leaderboardInitialValue],
};

export const teacherAnalyticsOverviewInitialValue: TAnalyticsTeacherOverview = {
  number_of_students: scalarMetricInitialValue,
  number_of_questions: scalarMetricInitialValue,
  mean_score: scalarMetricInitialValue,
  median_score: scalarMetricInitialValue,
  highest_score: scalarMetricInitialValue,
  leaderboard: [leaderboardInitialValue],
};

export const teacherAnalyticsTimedInitialValue:
  | TAnalyticsTeacherTimed
  | undefined = undefined;
export const studentAnalyticsTimedInitialValue:
  | TAnalyticsStudentTimed
  | undefined = undefined;

export const studentAnalyticsQuestionInitialValue:
  | TAnalyticsStudentQuestion
  | undefined = undefined;
export const teacherQuestionStatsInitialValue:
  | TAnalyticsCreatorQuestionStats
  | undefined = undefined;
export const studentQuestionStatsInitialValue:
  | TAnalyticsAttemptorQuestionStats
  | undefined = undefined;
export const allStudentsStatisticsInitialValue: TAnalyticsAllStudentsStatistics =
  {
    student_stats: tabularInitialValue,
  };

type TProps = {
  children: ReactNode;
};

export type TAnalyticsContext = {
  selectedTab: string;
  setSelectedTab: Dispatch<SetStateAction<string>>;
  studentAnalyticsOverview: TAnalyticsStudentOverview | undefined;
  setStudentAnalyticsOverview: Dispatch<
    SetStateAction<TAnalyticsStudentOverview | undefined>
  >;
  teacherAnalyticsOverview: TAnalyticsTeacherOverview | undefined;
  setTeacherAnalyticsOverview: Dispatch<
    SetStateAction<TAnalyticsTeacherOverview | undefined>
  >;
  teacherAnalyticsTimed: TAnalyticsTeacherTimed | undefined;
  setTeacherAnalyticsTimed: Dispatch<
    SetStateAction<TAnalyticsTeacherTimed | undefined>
  >;
  studentAnalyticsTimed: TAnalyticsStudentTimed | undefined;
  setStudentAnalyticsTimed: Dispatch<
    SetStateAction<TAnalyticsStudentTimed | undefined>
  >;
  studentAnalyticsQuestion: TAnalyticsStudentQuestion | undefined;
  setStudentAnalyticsQuestion: Dispatch<
    SetStateAction<TAnalyticsStudentQuestion | undefined>
  >;
  teacherQuestionStats: TAnalyticsCreatorQuestionStats | undefined;
  setTeacherQuestionStats: Dispatch<
    SetStateAction<TAnalyticsCreatorQuestionStats | undefined>
  >;
  studentQuestionStats: TAnalyticsAttemptorQuestionStats | undefined;
  setStudentQuestionStats: Dispatch<
    SetStateAction<TAnalyticsAttemptorQuestionStats | undefined>
  >;
  allStudentsStatistics: TAnalyticsAllStudentsStatistics | undefined;
  setAllStudentsStatistics: Dispatch<
    SetStateAction<TAnalyticsAllStudentsStatistics | undefined>
  >;
};

const initialContext: TAnalyticsContext = {
  selectedTab: EAnalyticsTab[EAnalyticsTab.OVERVIEW],
  setSelectedTab: () => {},
  studentAnalyticsOverview: studentAnalyticsOverviewInitialValue,
  setStudentAnalyticsOverview: () => {},
  teacherAnalyticsOverview: teacherAnalyticsOverviewInitialValue,
  setTeacherAnalyticsOverview: () => {},
  teacherAnalyticsTimed: teacherAnalyticsTimedInitialValue,
  setTeacherAnalyticsTimed: () => {},
  studentAnalyticsTimed: studentAnalyticsTimedInitialValue,
  setStudentAnalyticsTimed: () => {},
  studentAnalyticsQuestion: studentAnalyticsQuestionInitialValue,
  setStudentAnalyticsQuestion: () => {},
  teacherQuestionStats: teacherQuestionStatsInitialValue,
  setTeacherQuestionStats: () => {},
  studentQuestionStats: studentQuestionStatsInitialValue,
  setStudentQuestionStats: () => {},
  allStudentsStatistics: allStudentsStatisticsInitialValue,
  setAllStudentsStatistics: () => {},
};

export const AnalyticsContext =
  createContext<TAnalyticsContext>(initialContext);

export const AnalyticsContextProvider = ({ children }: TProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<string>(
    initialContext.selectedTab
  );

  const [studentAnalyticsOverview, setStudentAnalyticsOverview] = useState<
    TAnalyticsStudentOverview | undefined
  >(initialContext.studentAnalyticsOverview);
  const [teacherAnalyticsOverview, setTeacherAnalyticsOverview] = useState<
    TAnalyticsTeacherOverview | undefined
  >(initialContext.teacherAnalyticsOverview);

  const [teacherAnalyticsTimed, setTeacherAnalyticsTimed] = useState<
    TAnalyticsTeacherTimed | undefined
  >(initialContext.teacherAnalyticsTimed);
  const [studentAnalyticsTimed, setStudentAnalyticsTimed] = useState<
    TAnalyticsStudentTimed | undefined
  >(initialContext.studentAnalyticsTimed);

  const [studentAnalyticsQuestion, setStudentAnalyticsQuestion] = useState<
    TAnalyticsStudentQuestion | undefined
  >(initialContext.studentAnalyticsQuestion);

  const [teacherQuestionStats, setTeacherQuestionStats] = useState<
    TAnalyticsCreatorQuestionStats | undefined
  >(initialContext.teacherQuestionStats);
  const [studentQuestionStats, setStudentQuestionStats] = useState<
    TAnalyticsAttemptorQuestionStats | undefined
  >(initialContext.studentQuestionStats);
  const [allStudentsStatistics, setAllStudentsStatistics] = useState<
    TAnalyticsAllStudentsStatistics | undefined
  >(initialContext.allStudentsStatistics);

  return (
    <AnalyticsContext.Provider
      value={{
        selectedTab,
        setSelectedTab,
        studentAnalyticsOverview,
        setStudentAnalyticsOverview,
        teacherAnalyticsOverview,
        setTeacherAnalyticsOverview,
        teacherAnalyticsTimed,
        setTeacherAnalyticsTimed,
        studentAnalyticsQuestion,
        setStudentAnalyticsQuestion,
        studentAnalyticsTimed,
        setStudentAnalyticsTimed,
        teacherQuestionStats,
        setTeacherQuestionStats,
        studentQuestionStats,
        setStudentQuestionStats,
        allStudentsStatistics,
        setAllStudentsStatistics,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
