import axios from "axios";
import { baseURL } from "../utils/constants";
import { ProctorLog } from "../models/proctorLog";
import { handleUnauthorisedException } from "../utils/apiUtils";
import HTTPStatusCode from "../utils/HTTPStatusCode";
import { displayErrorToast } from "../utils/ToastUtils";

export const saveProctorLogAPICall = async (
  accessToken: string,
  session_id: number,
  proctorLogInput: Array<ProctorLog>
) => {
  try {
    const response = await axios.post<any>(
      `${baseURL}/api/v1/proctor_log/${session_id}`,
      proctorLogInput,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (
      axios.isAxiosError(error) &&
      error.response?.status === HTTPStatusCode.UNAUTHORIZED
    ) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
    }
    console.log(error);
    return null;
  }
};
