import axios from "axios";
import { TIngestProps } from "../models/ingestionmodel";
import { handleUnauthorisedException } from "../utils/apiUtils";
import { baseURL } from "../utils/constants";
import HTTPStatusCode from "../utils/HTTPStatusCode";
import { displayErrorToast, displayInfoToast } from "../utils/ToastUtils";

export const ingestQuestion = async (
  accessToken: string,
  payload: TIngestProps
) => {
  try {
    displayInfoToast("Image Processing in progress. Please wait...");
    await axios.post(`${baseURL}/api/v1/ingestion`, payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return true;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    console.log(error);
    displayErrorToast("Image processing failed. Please try again");
    return null;
  }
};
