import { useState, useContext, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Modal, Row, Col } from "react-bootstrap";
import CustomButton from "../../../../components/CustomComponent/CustomButton";
import styles from "./AddQuestionButton.module.scss";
import {
  EAssessmentFormat,
  EAssessmentType,
  EQuestionType,
} from "../../../../utils/Enums";
import TextField from "@mui/material/TextField";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../context/AssessmentContext";
import { AppContext, TAppContext } from "../../../../context/AppContext";
import {
  TModalProps,
  submitManualQuestion,
  TContextProps,
  QuestionTypeEnumToString,
  QuestionTypeStringToEnumMap,
} from "./ManualQuestionHelpers";
import { useStickyState } from "../../../../utils/hooks/useStickyState";
import { refetchData } from "./AddQuestionHelpers";
import { Checkbox, Typography } from "@mui/material";
import { addComprehension } from "../../../../api/comprehension";
import { TComprehension } from "../../../../models/assessmentmodel";

// Might want to split this file into multiple
export function AddManuallyModal(props: TModalProps) {
  const { showModal, setShowModal, setEdit } = props;
  const {
    sectionMap,
    setSectionMap,
    currentSectionNumber,
    setCurrentSectionNumber,
    setCurrentQuestionNumber,
    selectedAssessment,
    questionMap,
    setQuestionMap,
    comprehensionMap,
    setComprehensionMap,
    updatedableQuestionMap,
  } = useContext<TAssessmentContext>(AssessmentContext);
  const { org, accessToken } = useContext<TAppContext>(AppContext);

  const [questionType, setQuestionType] = useState<EQuestionType>(
    EQuestionType.MCQ
  );
  const [optionCount, setOptionCount] = useStickyState<number>(
    4,
    "optionCount"
  );
  const [questionCount, setQuestionCount] = useState<number>(1);
  // We only want to show section names in the dropdown so this goes with the assumption
  // that all the section names are different.
  const [selectedSectionId, setSelectedSectionId] = useState<number>(
    sectionMap.get(selectedAssessment.sections[currentSectionNumber])!.id
  );
  const [marksIfCorrect, setMarksIfCorrect] = useStickyState<string>(
    "4",
    "marksIfCorrect"
  );
  const [marksIfIncorrect, setMarksIfIncorrect] = useStickyState<string>(
    "-1",
    "marksIfIncorrect"
  );

  const [loading, setLoading] = useState<boolean>(false);

  const contextProps: TContextProps = {
    sectionMap,
    setSectionMap,
    questionMap,
    setQuestionMap,
    setCurrentSectionNumber,
    setCurrentQuestionNumber,
    setComprehensionMap,
    updatedableQuestionMap,
  };

  useEffect(() => {
    setSelectedSectionId(
      sectionMap.get(selectedAssessment.sections[currentSectionNumber])!.id
    );
  }, [currentSectionNumber]);

  //Comprehension
  const [selectedComprehensionId, setSelectedComprehensionId] =
    useState<number>(
      selectedAssessment.format === EAssessmentFormat.NTA
        ? comprehensionMap.keys().next().value || -1
        : -1
    );

  const [isPartComprehension, setIsPartComprehension] =
    useState<boolean>(false);

  const handleHideModal = () => {
    setShowModal(false);
    setIsPartComprehension(false);
    setSelectedComprehensionId(comprehensionMap.keys().next().value || -1);
    setLoading(false);
  };

  const handleAddQuestion = async () => {
    setLoading(true);
    let comprehension: TComprehension = {
      id: selectedComprehensionId,
      assessment_id: -1,
      body: {
        text: "",
      },
    };
    if (isPartComprehension && selectedComprehensionId === -1) {
      comprehension = await addComprehension(
        accessToken,
        selectedAssessment.id,
        {
          text: "",
          images: [],
        }
      );
      if (!comprehension) return;
    }
    const success = await submitManualQuestion({
      marksIfCorrect: marksIfCorrect,
      marksIfIncorrect: marksIfIncorrect,
      selectedSectionId: selectedSectionId,
      selectedAssessment: selectedAssessment,
      questionType: questionType,
      questionCount: questionCount,
      optionCount: optionCount,
      org: org,
      accessToken: accessToken,
      modalProps: props,
      contextProps: contextProps,
      comprehension_id: isPartComprehension ? comprehension.id : undefined,
    });
    if (!success) {
      setLoading(false);
      return;
    }
    await refetchData(
      accessToken,
      selectedAssessment,
      selectedSectionId,
      contextProps,
      questionCount
    );
    handleHideModal();
    if (setEdit) setEdit!(true);
  };

  return (
    <Modal
      show={showModal}
      onHide={handleHideModal}
      contentClassName={styles.modalStyle}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Typography variant="h4">
          <b>Add Question Manually</b>
        </Typography>
      </Modal.Header>

      <Modal.Body>
        {/*Number of questions to add*/}
        <Row>
          <Col className="mt-2" key="questionCount" xs={12} lg={6}>
            <TextField
              required
              value={questionCount}
              label="Number of Questions To Add"
              variant="filled"
              type="number"
              onChange={(e) => setQuestionCount(Number(e.target.value))}
              className="w-100"
            />
          </Col>

          {/* Question type */}
          <Col className="mt-2" key="qtype" xs={12} lg={6}>
            <TextField
              required
              select // Given TextField is a dropdown.
              value={QuestionTypeEnumToString.get(questionType)}
              label="Question Type"
              variant="filled"
              onChange={(e) =>
                setQuestionType(
                  QuestionTypeStringToEnumMap.get(e.target.value)!
                )
              }
              className="w-100"
            >
              <MenuItem value="MCQ">MCQ</MenuItem>
              {/* This is commented since analytics is not supported yet. */}
              <MenuItem value="MCMCQ">Multiple Correct MCQ</MenuItem>
              <MenuItem value="Numerical">Numerical</MenuItem>
            </TextField>
          </Col>
        </Row>
        {/*Number of options if MCQ*/}
        <Row>
          <Col className="mt-2" key="optionCount" xs={12} lg={6}>
            <TextField
              required
              hidden={questionType === EQuestionType.NUMERICAL}
              value={optionCount}
              label="Number of Options"
              variant="filled"
              type="number"
              onChange={(e) => setOptionCount(Number(e.target.value))}
              className="w-100"
            />
          </Col>

          {/* TODO: Can we somehow send sectionId (based on MenuItem key) instead of name to setSetionName)*/}
          {/* Section Name */}

          <Col className="mt-2" key="sname" xs={12} lg={6}>
            <TextField
              required
              select // Given TextField is a dropdown.
              value={sectionMap.get(selectedSectionId)!.name}
              key={selectedSectionId}
              label="Section Name"
              variant="filled"
              className="w-100"
            >
              {selectedAssessment.sections.map((sectionId) => (
                <MenuItem
                  value={sectionMap.get(sectionId)!.name}
                  key={sectionId}
                  onClick={() => setSelectedSectionId(sectionId)}
                >
                  {sectionMap.get(sectionId)!.name}
                </MenuItem>
              ))}
            </TextField>
          </Col>
        </Row>

        {/* Marks if correct */}
        <Row>
          <Col className="mt-2" key="marks" xs={12} lg={6}>
            <TextField
              required
              value={marksIfCorrect}
              label="Marks if correct"
              variant="filled"
              type="text"
              onChange={(e) => setMarksIfCorrect(e.target.value)}
              className="w-100"
            />
          </Col>

          {/* Marks if incorrect */}
          <Col className="mt-2" key="wrongmarks" xs={12} lg={6}>
            <TextField
              required
              value={marksIfIncorrect}
              label="Marks if incorrect"
              variant="filled"
              type="text"
              onChange={(e) => setMarksIfIncorrect(e.target.value)}
              className="w-100"
            />
          </Col>
        </Row>

        {/*Comprehension */}
        {selectedAssessment.format === EAssessmentFormat.NTA && (
          <div
            style={{
              marginTop: "5px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Checkbox
              onChange={(e) => setIsPartComprehension(e.target.checked)}
            />
            <span>Is this question part of comprehension?</span>
          </div>
        )}
        {isPartComprehension && (
          <>
            <p>Select a comprehension from below or create a new one</p>
            <TextField
              required
              select // Given TextField is a dropdown.
              value={selectedComprehensionId}
              label="Comprehension"
              variant="filled"
              onChange={(e) =>
                setSelectedComprehensionId(Number(e.target.value))
              }
              className="w-50"
            >
              {Array.from(comprehensionMap.values()).map((comp, id) => {
                return (
                  <MenuItem value={comp.id}>
                    {comp.body.text.slice(0, 50)}...
                  </MenuItem>
                );
              })}
              <MenuItem value={-1}>Add a comprehension</MenuItem>
            </TextField>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <CustomButton disabled={loading} onClick={handleAddQuestion}>
          Submit
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
}
