import { IconButton, Typography } from "@mui/material";
import moment from "moment";
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Modal } from "react-bootstrap";
import { AppContext, TAppContext } from "../../../context/AppContext";
import { HomeContext, THomeContext } from "../../../context/HomeContext";
import { EAccessLevel, EPublishStatus } from "../../../utils/Enums";
import { fetchAndInitialiseBatches } from "../../batch-management/utils/ApiHelper";
import PublistTest from "../../instructionScreen/Component/PublistTest";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./../MyTests.module.scss";

interface PublishTestModalProps {
  assessmentId: number;
  showEditPublishDetailsModal: boolean;
  setShowEditPublishDetailsModal: Dispatch<SetStateAction<boolean>>;
  assessmentName: string;
  publishId?: number;
  batchId?: number;
  publishType?: EAccessLevel;
  startTime?: Date;
  endTime?: Date;
  batchName?: string;
  publishStatus?: EPublishStatus;
  format?: number;
  duration?: number;
  onUpdate?: (res: any) => void;
}

const PublishTestModal: FunctionComponent<PublishTestModalProps> = (props) => {
  const { accessToken, org } = useContext<TAppContext>(AppContext);
  const { setBatches } = useContext<THomeContext>(HomeContext);
  const [loading, setLoading] = useState(false);
  const [showUploadPdfModal, setShowUploadPdfModal] = useState<boolean>(false);
  const fetchBatchList = async () => {
    setLoading(true);
    await fetchAndInitialiseBatches(org, accessToken, setBatches);
    setLoading(false);
  };
  useEffect(() => {
    fetchBatchList();
  }, []);

  return loading ? (
    <></>
  ) : (
    <Modal
      show={props.showEditPublishDetailsModal}
      onHide={() => props.setShowEditPublishDetailsModal(false)}
      size="xl"
      contentClassName={styles.modalStyle}
      centered
    >
      <div>
        {props.publishId === undefined ? (
          <>
            <Modal.Header>
              <Typography variant="h4">
                <b>Share Test: {props.assessmentName}</b>
              </Typography>
              <IconButton>
                <CloseIcon
                  onClick={() => props.setShowEditPublishDetailsModal(false)}
                />
              </IconButton>
            </Modal.Header>
            <PublistTest
              assessmentId={props.assessmentId}
              callback={(res: any) => {
                props.setShowEditPublishDetailsModal(false);
                if (props.onUpdate) {
                  props.onUpdate(res);
                }
              }}
              format={props.format}
              showPDFModal={showUploadPdfModal}
              setShowPDFModal={setShowUploadPdfModal}
            />
          </>
        ) : (
          <>
            <Modal.Header>
              <Typography variant="h4">
                <b>Edit Publish Info</b>
              </Typography>
              <IconButton>
                <CloseIcon
                  onClick={() => props.setShowEditPublishDetailsModal(false)}
                />
              </IconButton>
            </Modal.Header>
            <PublistTest
              assessmentId={props.assessmentId}
              startDate={moment
                .utc(props.startTime)
                .local()
                .format("YYYY-MM-DD")}
              startTime={moment.utc(props.startTime).local().format("HH:mm")}
              publishType={props.publishType}
              batchId={props.batchId}
              endDate={moment.utc(props.endTime).local().format("YYYY-MM-DD")}
              endTime={moment.utc(props.endTime).local().format("HH:mm")}
              publishId={props.publishId}
              selectedBatchName={props.batchName}
              publishStatus={props.publishStatus}
              format={props.format}
              duration={props.duration}
              callback={(res: any) => {
                props.setShowEditPublishDetailsModal(false);
                if (props.onUpdate) {
                  props.onUpdate(res);
                }
              }}
              showPDFModal={showUploadPdfModal}
              setShowPDFModal={setShowUploadPdfModal}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default PublishTestModal;
