import { useContext, Dispatch, SetStateAction, useState } from "react";
import { Button, Col, Row, Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import styles from "../../../batch-management/batch-management.module.scss";
import { AppContext, TAppContext } from "../../../../context/AppContext";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../context/AssessmentContext";
import {
  handleCreateSectionAPI,
  handleUpdateSection,
} from "../../../../api/assessment";
import { displaySuccessToast } from "../../../../utils/ToastUtils";
import CloseIcon from "@mui/icons-material/Close";
import { Typography, IconButton } from "@mui/material";

type TProps = {
  showCreateSectionModal: boolean;
  setShowCreateSectionModal: Dispatch<SetStateAction<boolean>>;
  isEdit: boolean;
  sectionName: string;
  sectionId: number;
};

export default function CreateNewSectionModal(props: TProps) {
  const {
    showCreateSectionModal,
    setShowCreateSectionModal,
    isEdit,
    sectionName,
  } = props;

  const {
    selectedAssessment,
    setSelectedAssessment,
    sectionMap,
    setSectionMap,
  } = useContext<TAssessmentContext>(AssessmentContext);
  const { accessToken, org } = useContext<TAppContext>(AppContext);

  let schema = yup.object().shape({
    sectionName: yup.string().required("Please enter a section name"),
  });

  const createNewSection = async (
    accessToken: string,
    sectionName: string,
    assessmentID: number
  ) => {
    setShowCreateSectionModal(false);
    const createdSection = await handleCreateSectionAPI(
      accessToken,
      sectionName,
      assessmentID
    );
    if (createdSection) {
      setSectionMap(new Map(sectionMap.set(createdSection.id, createdSection)));
      selectedAssessment.sections.push(createdSection.id);
      setSelectedAssessment({ ...selectedAssessment });
      displaySuccessToast("Section Updated.");
    }
  };

  const updateSectionName = async (
    accessToken: string,
    sectionName: string,
    sectionID: number,
    assessmentID: number
  ) => {
    const status = await handleUpdateSection(
      accessToken,
      sectionID,
      assessmentID,
      sectionName
    );

    if (status === "success") {
      let sectionData = sectionMap.get(sectionID);
      if (sectionData!!) {
        let updatedSectionMap = new Map(sectionMap);
        sectionData.name = sectionName;
        updatedSectionMap.set(sectionID, sectionData);
        setSectionMap(updatedSectionMap);
        displaySuccessToast("Section Updated.");
      }
    }
    setShowCreateSectionModal(false);
  };

  return (
    <Modal
      contentClassName={styles.modalStyle}
      show={showCreateSectionModal}
      centered
      onHide={() => setShowCreateSectionModal(false)}
    >
      <Modal.Header>
        <Typography variant="h4">
          <b>{isEdit ? "Edit Section" : "Add New Section"}</b>
        </Typography>
        <IconButton>
          <CloseIcon onClick={() => setShowCreateSectionModal(false)} />
        </IconButton>
      </Modal.Header>
      <div className="form-group mx-2">
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            isEdit
              ? updateSectionName(
                  accessToken,
                  values.sectionName,
                  props.sectionId,
                  selectedAssessment.id
                )
              : createNewSection(
                  accessToken,
                  values.sectionName,
                  selectedAssessment.id
                );
          }}
          initialValues={{
            sectionName: isEdit ? props.sectionName : "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            isSubmitting,
          }) => (
            <Form
              className="mt-4"
              noValidate
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <Form.Group
                controlId="sectionName"
                as={Row}
                className="align-items-center"
              >
                <Col className="d-flex" xs={12}>
                  <Form.Label>
                    <Typography fontSize={20} color="#9AA0B2">
                      <b>Section Name:</b>
                    </Typography>
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="section name"
                    value={values.sectionName}
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="ml-2"
                    isInvalid={!!errors.sectionName}
                    style={{ backgroundColor: "#DBDFF1" }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.sectionName}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Modal.Footer>
                <Button
                  className="border-0 rounded-2 bg-secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isEdit ? "Update Section" : "Save Section"}
                </Button>{" "}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
