import { CSSProperties, Dispatch, SetStateAction, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { AddManuallyModal } from "./ManualQuestionModal";
import { UploadImageModal } from "./ImageQuestionModal";
import { UploadDocumentModal } from "./DocumentQuestionModal";
import { Typography, useMediaQuery } from "@mui/material";
import QuestionBottomMenuButton from "../QuestionButtonPanel/QuestionBottomMenuButton/QuestionBottomMenuButton";

type TProps = {
  setEdit: Dispatch<SetStateAction<boolean>>;
  style?: CSSProperties | Object;
};

export default function AddQuestionButton(props: TProps) {
  // Hooks for flags that define whether a modal will be shown or not.
  // The flags will be set/unset whenever the relevant button is pressed.
  const [showAddManuallyModal, setShowAddManuallyModal] =
    useState<boolean>(false);
  const [showUploadImageModal, setShowUploadImageModal] =
    useState<boolean>(false);
  const [showUploadDocumentModal, setShowUploadDocumentModal] =
    useState<boolean>(false);
  const isDesktopOrLaptop = useMediaQuery("(min-width: 576px)");

  // When an option is selected in the popup, we change the relevant
  // modal flags and close the popup.
  // We take popupClose which is provided by the PopupState Library to
  // close the menu that's opened in the parent.
  const onClickAddManually = (popupClose: () => void) => {
    setShowAddManuallyModal(true);
    popupClose();
  };

  const onClickUploadImage = (popupClose: () => void) => {
    setShowUploadImageModal(true);
    popupClose();
  };

  return (
    <>
      <PopupState variant="popover" popupId="addquestion-popup-menu">
        {(popupState) => (
          <>
            <QuestionBottomMenuButton
              {...bindTrigger(popupState)}
              className="px-2 my-2"
              style={{
                width: isDesktopOrLaptop ? "50%" : "100%",
                height: "75%",
                maxWidth: "100%",
                background: "rgb(1, 185, 225)",
              }}
            >
              <Typography>Add Question</Typography>
            </QuestionBottomMenuButton>
            <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={() => onClickAddManually(popupState.close)}>
                Add Manually
              </MenuItem>
              <MenuItem onClick={() => onClickUploadImage(popupState.close)}>
                Screenshot To Question
              </MenuItem>
            </Menu>
          </>
        )}
      </PopupState>
      <AddManuallyModal
        showModal={showAddManuallyModal}
        setShowModal={setShowAddManuallyModal}
        setEdit={props.setEdit}
      />
      <UploadImageModal
        showModal={showUploadImageModal}
        setShowModal={setShowUploadImageModal}
      />
      <UploadDocumentModal
        showModal={showUploadDocumentModal}
        setShowModal={setShowUploadDocumentModal}
      />
    </>
  );
}
