import axios from "axios";
import { handleUnauthorisedException } from "../utils/apiUtils";
import { baseURL } from "../utils/constants";
import { MembershipStatus } from "../utils/Enums";
import HTTPStatusCode from "../utils/HTTPStatusCode";
import { displayErrorToast } from "../utils/ToastUtils";

export const fetchBatches = async (accessToken: string, orgId: number) => {
  try {
    const response = await axios.get<any>(
      `${baseURL}/api/v1/batch?org_id=${orgId}`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    displayErrorToast("Failed to get batches");
    return null;
  }
};

export const createBatch = async (
  accessToken: string,
  batchName: string,
  batchDescription: string,
  orgId: number
) => {
  try {
    const response = await axios.post<any>(
      `${baseURL}/api/v1/batch`,
      {
        name: batchName,
        description: batchDescription,
        org_id: orgId,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    displayErrorToast("Failed to create a batch");
    return null;
  }
};

export const fetchCurrentBatch = async (
  accessToken: string,
  batchCode: string
) => {
  try {
    const response = await axios.get<any>(
      `${baseURL}/api/v1/batch/${batchCode}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    displayErrorToast("Failed to get batches");
    return null;
  }
};

export const updateBatch = async (
  accessToken: string,
  batchId: number,
  batchName: string,
  batchDescription: string
) => {
  try {
    const response = await axios.patch<any>(
      `${baseURL}/api/v1/batch/${batchId}`,
      {
        name: batchName,
        description: batchDescription,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.status;
  } catch (error: any) {
    displayErrorToast("Failed to update batch");
    return null;
  }
};

export const fetchStudentsFromBatch = async (
  accessToken: string,
  batchId: number
) => {
  try {
    const response = await axios.get<any>(
      `${baseURL}/api/v1/batch/${batchId}/student`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    displayErrorToast("Failed to fetch students");
    return null;
  }
};

export const fetchTeachersFromBatch = async (
  accessToken: string,
  batchId: number
) => {
  try {
    const response = await axios.get<any>(
      `${baseURL}/api/v1/batch/${batchId}/educator`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    displayErrorToast("Failed to fetch teachers");
    return null;
  }
};

export const fetchStudentsRequestsFromBatch = async (
  accessToken: string,
  batchId: number
) => {
  try {
    const response = await axios.get<any>(
      `${baseURL}/api/v1/batch/${batchId}/pending_student`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    displayErrorToast("Failed to fetch teachers");
    return null;
  }
};

export const approveOrRejectStudentsInBatch = async (
  accessToken: string,
  batchId: number,
  membershipStatus: MembershipStatus,
  studentIds: Array<number>
) => {
  try {
    const url: string = `${baseURL}/api/v1/batch/${batchId}/status`;
    const response = await axios.patch<any>(
      url,
      {
        user_ids: studentIds,
        status: membershipStatus,
      },
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    displayErrorToast("Failed Approve or Reject students");
    return null;
  }
};

export const addUsersToBatch = async (
  accessToken: string,
  batchCode: string,
  requestBody: Array<Object>
) => {
  try {
    const addStudentToBatchResponse = await axios.post<any>(
      `${baseURL}/api/v1/batch/${batchCode}/user`,
      requestBody,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return addStudentToBatchResponse.status;
  } catch (erorr: any) {
    displayErrorToast("Failed to add students to batch. Please try again");
    return null;
  }
};

export const deleteBatch = async (accessToken: string, batchId: number) => {
  try {
    const batchDeleteResponse = await axios.delete<any>(
      `${baseURL}/api/v1/batch/${batchId}`,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    return batchDeleteResponse.data;
  } catch (error: any) {
    displayErrorToast("Failed to delete batch. Please try again");
    return null;
  }
};

export const removeUsersFromBatch = async (
  accessToken: string,
  batchId: number,
  usersToDelete: Array<any>
) => {
  try {
    const response = await axios.delete<any>(
      `${baseURL}/api/v1/batch/${batchId}/user`,
      {
        data: usersToDelete,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status === HTTPStatusCode.NO_CONTENT) {
      return true;
    }
    return false;
  } catch (error: any) {
    displayErrorToast("Failed to remove the selected users from the batch.");
    return null;
  }
};

export const requestJoinBatchCode = async (
  accessToken: string,
  batchCode: string,
  rollNo?: string
) => {
  try {
    const response = await axios.post<any>(
      `${baseURL}/api/v1/batch/${batchCode}/request_membership`,
      {
        roll_no: rollNo,
      },
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.status;
  } catch (error: any) {
    displayErrorToast("Failed to raise request to join batch");
    return null;
  }
};

export const patchMembershipStatus = async (
  accessToken: string,
  batchId: number,
  userIds: number[],
  membershipStatus: MembershipStatus
) => {
  try {
    const response = await axios.patch<any>(
      `${baseURL}/api/v1/batch/${batchId}/status`,
      {
        user_ids: userIds,
        status: membershipStatus,
      },
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.status;
  } catch (error: any) {
    displayErrorToast();
    return null;
  }
};
