import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../context/AssessmentContext";
import { Col, Container, Row } from "react-bootstrap";
import QuestionNavigationPanel from "./Components/QuestionNavigationPanel/QuestionNavigationPanel";
import Header from "./Components/Header/Header";
import { ERole } from "../../utils/Enums";
import QuestionNavigationDrawer from "./Components/QuestionNavigationPanel/QuestionNavigationDrawer";
import { useMediaQuery } from "@mui/material";
import QuestionText from "./Components/QuestionText/QuestionText";
import QuestionType from "./Components/QuestionType/QuestionType";
import {
  QuestionContext,
  TQuestionContext,
} from "../../context/QuestionContext";
import TeacherQuestionButtonPanel from "./Components/QuestionButtonPanel/TeacherQuestionButtonPanel";
import AddQuestionButton from "./Components/AddQuestionButton/AddQuestionButton";
import NoQuestions from "./Components/EmptyAssessment/NoQuestions";
import Solution from "./Components/Solution/Solution";

interface TeacherQuestionScreenProps {
  isLoading: boolean;
  edit: boolean;
  setEdit: Dispatch<SetStateAction<boolean>>;
  proofRead: boolean;
  setProofRead: Dispatch<SetStateAction<boolean>>;
}

// TODO: Fix the alignments and improve the layout to avoid using proofRead prop to set the flex spacing
export default function TeacherQuestionScreen(
  props: TeacherQuestionScreenProps
) {
  const { proofRead, setProofRead, edit, setEdit } = props;
  const {
    sectionMap, // List of sections.
    questionMap,
    currentQuestionNumber, // The current question number
    setCurrentQuestionNumber,
    currentSectionNumber, // current section to which question belongs to
    setCurrentSectionNumber,
    currentQuestionData,
    showQNavDrawer,
    setShowQNavDrawer,
    selectedAssessment,
  } = useContext<TAssessmentContext>(AssessmentContext); // Get the data from the context
  const { assessmentQuestionInfo } =
    useContext<TQuestionContext>(QuestionContext);

  const isDesktopOrLaptop = useMediaQuery("(min-width: 992px)");
  const [empty, setEmpty] = useState<boolean>(true);

  useEffect(() => {
    if (
      sectionMap.get(selectedAssessment.sections[currentSectionNumber])
        ?.question_ids.length === 0
    )
      setCurrentSectionNumber(0);
  }, []);

  // TODO: Add use-effect for setting flag variable
  useEffect(() => {
    if (questionMap.size === 0) {
      setEmpty(true);
      return;
    }
    setEmpty(false);
  }, [questionMap]);

  return props.isLoading ? null : (
    <Container
      fluid
      className="p-0 d-flex flex-row align-items-start"
      style={{ height: "92%", overflowY: "scroll" }}
    >
      <Col
        className={
          "d-flex flex-column align-items-center mt-2 " +
          (empty ? "justify-content-center" : "justify-content-start")
        }
        style={{ height: "100%" }}
      >
        {empty ? (
          <NoQuestions setEdit={setEdit} />
        ) : (
          <>
            <Row className="w-100 d-flex flex-row justify-content-center mx-2">
              <Col
                xs={12}
                lg={proofRead ? 12 : 8}
                className="d-flex flex-column m-2"
              >
                {/* Header */}
                <Header
                  currentQuestionNumber={currentQuestionNumber}
                  setCurrentQuestionNumber={setCurrentQuestionNumber}
                  currentSectionNumber={currentSectionNumber}
                  setCurrentSectionNumber={setCurrentSectionNumber}
                  sectionMap={sectionMap}
                  proofRead={proofRead}
                  setProofRead={setProofRead}
                />
                <div
                  style={{
                    maxHeight: "66vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                  className="white-area d-flex flex-grow-1 flex-column justify-content-between"
                >
                  {/* QuestionText */}
                  <div>
                    <QuestionText images={currentQuestionData.body.images}>
                      {currentQuestionData.body.text}
                    </QuestionText>

                    {/* QuestionType */}
                    <QuestionType
                      questionType={currentQuestionData.question_type}
                    />
                  </div>
                  <Solution solution={currentQuestionData.solution} />
                </div>
              </Col>

              {/* Question Navigation Panel */}
              {isDesktopOrLaptop && !proofRead ? (
                <QuestionNavigationPanel
                  proofRead={proofRead}
                  role={ERole.TEACHER}
                  sectionMap={sectionMap}
                  allQuestionInfo={assessmentQuestionInfo}
                />
              ) : (
                <QuestionNavigationDrawer
                  show={showQNavDrawer}
                  setShowHandler={setShowQNavDrawer}
                  role={ERole.TEACHER}
                  sectionMap={sectionMap}
                  allQuestionInfo={assessmentQuestionInfo}
                  proofRead={proofRead}
                ></QuestionNavigationDrawer>
              )}
            </Row>
            <Row className="w-100 justify-content-center">
              {/* ButtonPanel */}
              <>
                <Col
                  xs={12}
                  md={proofRead ? 12 : 8}
                  className="d-flex flex-column mx-2 mb-2"
                >
                  <TeacherQuestionButtonPanel edit={edit} setEdit={setEdit} />
                </Col>
                <Col
                  xs={12}
                  md={proofRead ? 12 : 3}
                  className="d-flex justify-content-center align-items-end mb-2"
                >
                  <AddQuestionButton
                    style={{ width: "100%" }}
                    setEdit={setEdit}
                  />
                </Col>
              </>
            </Row>
          </>
        )}
      </Col>
    </Container>
  );
}
