import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import DefaultProductImg from "../../../assets/images/DefaultProductImage.jpg";

type TProps = {
  imaUrl?: String;
  HeaderLine?: String;
  CardDescription?: String;
  ProductCode: String;
  price?: number;
  productImage?: string;
};

export default function ProductCard(props: TProps) {
  const history = useHistory();

  const truncate = (str, n) => {
    if (str == null) {
      return;
    }
    return str.length > n ? str.slice(0, n - 1) + "......." : str;
  };

  return (
    <Card sx={{ maxWidth: "200" }}>
      <CardMedia
        component="img"
        alt="This is a product image"
        height="100%"
        width="100%"
        src={
          props.productImage !== null ? props.productImage : DefaultProductImg
        }
      />
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          style={{ color: "#0E1133" }}
          component="div"
        >
          {props.HeaderLine}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {truncate(props.CardDescription, 200)}
        </Typography>
        <Typography paddingTop={1} style={{ color: "#5755D9" }}>
          <span>&#8377;</span> {(props.price ?? 0) / 100}
        </Typography>
      </CardContent>
      <CardActions>
        {/* <Button variant="outlined" size="small">Buy Now</Button> */}
        <Button
          variant="contained"
          fullWidth
          size="small"
          onClick={() => history.push(`/marketplace/${props.ProductCode}`)}
        >
          View Details
        </Button>
      </CardActions>
    </Card>
  );
}
