import {
  createContext,
  ReactNode,
  Dispatch,
  SetStateAction,
  useState,
} from "react";
import { ERole } from "../utils/Enums";
import { useStickyState } from "../utils/hooks/useStickyState";

type TProps = {
  children: ReactNode;
};

export type TOrg = {
  name: string;
  code: string;
  id: number;
};

export type TAppContext = {
  id: number;
  name: string;
  email: string;
  accessToken: string;
  role: ERole | null;
  isLoggedIn: boolean;
  isEmailVerified: boolean;
  isPhoneNumberValid: boolean;
  org: TOrg | null;
  isOrgOwner: boolean;
  isBeta: boolean;
  sideDrawerOpen: boolean;
  drawerOpen: boolean;
  showLoginModal: boolean;
  dummyAssessmentId: number;
  shouldTransferAssessment: boolean;
  setId: Dispatch<SetStateAction<number>>;
  setName: Dispatch<SetStateAction<string>>;
  setEmail: Dispatch<SetStateAction<string>>;
  setAccessToken: Dispatch<SetStateAction<string>>;
  setRole: Dispatch<SetStateAction<ERole | null>>;
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>;
  setIsEmailVerified: Dispatch<SetStateAction<boolean>>;
  setIsPhoneNumberValid: Dispatch<SetStateAction<boolean>>;
  setOrg: Dispatch<SetStateAction<TOrg | null>>;
  setIsOrgOwner: Dispatch<SetStateAction<boolean>>;
  setIsBeta: Dispatch<SetStateAction<boolean>>;
  setSideDrawerOpen: Dispatch<SetStateAction<boolean>>;
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
  setShowLoginModal: Dispatch<SetStateAction<boolean>>;
  setDummyAssessmentId: Dispatch<SetStateAction<number>>;
  setShouldTransferAssessment: Dispatch<SetStateAction<boolean>>;
  clearAppContext: () => void;
};

const initialContext: TAppContext = {
  id: 0,
  name: "USER",
  email: "",
  accessToken: "",
  role: null,
  isLoggedIn: false,
  isEmailVerified: false,
  isPhoneNumberValid: false,
  org: null,
  isOrgOwner: false,
  isBeta: false,
  sideDrawerOpen: false,
  drawerOpen: true,
  showLoginModal: false,
  dummyAssessmentId: -1,
  shouldTransferAssessment: false,
  setId: (): void => {},
  setName: (): void => {},
  setEmail: (): void => {},
  setAccessToken: (): void => {},
  setRole: (): void => {},
  setIsLoggedIn: (): void => {},
  setIsEmailVerified: (): void => {},
  setIsPhoneNumberValid: (): void => {},
  setOrg: (): void => {},
  setIsOrgOwner: (): void => {},
  setIsBeta: (): void => {},
  setSideDrawerOpen: (): void => {},
  setDrawerOpen: (): void => {},
  setShowLoginModal: (): void => {},
  setDummyAssessmentId: (): void => {},
  setShouldTransferAssessment: (): void => {},
  clearAppContext: (): void => {},
};

export const AppContext = createContext<TAppContext>(initialContext);

export const AppContextProvider = ({ children }: TProps): JSX.Element => {
  const [id, setId] = useStickyState<number>(initialContext.id, "id");
  const [name, setName] = useStickyState<string>(initialContext.name, "name");
  const [email, setEmail] = useStickyState<string>(
    initialContext.email,
    "email"
  );
  const [accessToken, setAccessToken] = useStickyState<string>(
    initialContext.accessToken,
    "accessToken"
  );
  const [role, setRole] = useStickyState<ERole | null>(
    initialContext.role,
    "role"
  );
  const [isLoggedIn, setIsLoggedIn] = useStickyState<boolean>(
    initialContext.isLoggedIn,
    "isLoggedIn"
  );
  const [isEmailVerified, setIsEmailVerified] = useStickyState<boolean>(
    initialContext.isEmailVerified,
    "isEmailVerified"
  );
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useStickyState<boolean>(
    initialContext.isPhoneNumberValid,
    "isPhoneNumberValid"
  );
  const [isOrgOwner, setIsOrgOwner] = useStickyState<boolean>(
    initialContext.isOrgOwner,
    "isOrgOwner"
  );
  const [isBeta, setIsBeta] = useStickyState<boolean>(
    initialContext.isBeta,
    "isBeta"
  );
  const [org, setOrg] = useStickyState<TOrg | null>(initialContext.org, "org");

  const [sideDrawerOpen, setSideDrawerOpen] = useState<boolean>(
    initialContext.sideDrawerOpen
  );
  const [drawerOpen, setDrawerOpen] = useState<boolean>(
    initialContext.drawerOpen
  );
  const [showLoginModal, setShowLoginModal] = useState<boolean>(
    initialContext.showLoginModal
  );
  const [shouldTransferAssessment, setShouldTransferAssessment] =
    useState<boolean>(false);
  const [dummyAssessmentId, setDummyAssessmentId] = useState<number>(
    initialContext.dummyAssessmentId
  );
  const clearAppContext = () => {
    setAccessToken(initialContext.accessToken);
    setDrawerOpen(initialContext.drawerOpen);
    setDummyAssessmentId(initialContext.dummyAssessmentId);
    setEmail(initialContext.email);
    setId(initialContext.id);
    setIsBeta(initialContext.isBeta);
    setIsEmailVerified(initialContext.isEmailVerified);
    setIsLoggedIn(initialContext.isLoggedIn);
    setIsOrgOwner(initialContext.isOrgOwner);
    setName(initialContext.name);
    setOrg(initialContext.org);
    setRole(initialContext.role);
    setShouldTransferAssessment(initialContext.shouldTransferAssessment);
    setShowLoginModal(initialContext.showLoginModal);
    setSideDrawerOpen(initialContext.sideDrawerOpen);
  };

  return (
    <AppContext.Provider
      value={{
        id,
        setId,
        name,
        setName,
        email,
        setEmail,
        accessToken,
        setAccessToken,
        isLoggedIn,
        setIsLoggedIn,
        isEmailVerified,
        setIsEmailVerified,
        isPhoneNumberValid,
        setIsPhoneNumberValid,
        role,
        setRole,
        org,
        setOrg,
        isOrgOwner,
        setIsOrgOwner,
        isBeta,
        setIsBeta,
        sideDrawerOpen,
        setSideDrawerOpen,
        drawerOpen,
        setDrawerOpen,
        showLoginModal,
        setShowLoginModal,
        dummyAssessmentId,
        setDummyAssessmentId,
        shouldTransferAssessment,
        setShouldTransferAssessment,
        clearAppContext,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
