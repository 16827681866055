import { Rating, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { Card, Container, Modal } from "react-bootstrap";
import { patchAttemptInfo } from "../../api/attempts";
import CustomButton from "../../components/CustomComponent/CustomButton";
import { TAppContext, AppContext } from "../../context/AppContext";
import { TAttemptContext, AttemptContext } from "../../context/AttemptContext";
import { displaySuccessToast } from "../../utils/ToastUtils";
import styles from "../batch-management/batch-management.module.scss";
import { useHistory } from "react-router-dom";

export default function UserFeedbackModal() {
  const history = useHistory();
  const { accessToken } = useContext<TAppContext>(AppContext);
  const { currentAttempt } = useContext<TAttemptContext>(AttemptContext);
  const [value, setValue] = useState<number>(
    currentAttempt.metadata?.feedback_metadata?.rating || 0
  );
  const [feedback, setFeedback] = useState<string>(
    currentAttempt.metadata?.feedback_metadata?.feedback || ""
  );

  const handleFeedbackSubmit = async () => {
    const res = await patchAttemptInfo(accessToken, currentAttempt.id, {
      metadata: {
        ...currentAttempt.metadata,
        feedback_metadata: {
          feedback,
          rating: value,
        },
      },
    });
    if (!res) return;
    displaySuccessToast("Your feedback has been submitted successfully.");
    history.replace("/home/dashboard");
  };

  return (
    <div className="bg-primary-dark w-100">
      <Container
        className="pt-4 "
        style={{ flex: "display", flexDirection: "column" }}
      >
        <Card className={styles.modalStyle}>
          <Card.Header>
            <Typography variant="h4">
              <b>Feedback</b>
            </Typography>
          </Card.Header>
          <Card.Body>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
              className="rounded-4 p-3"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <p
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  className="mx-3"
                >
                  Rating
                </p>
                <Rating
                  className="my-2 mx-3"
                  size="large"
                  name="simple-controlled"
                  value={value}
                  onChange={(_, newValue) => {
                    setValue(newValue!!);
                  }}
                />
              </div>
              <TextField
                multiline
                id="feedback"
                label="Feedback"
                variant="outlined"
                rows={4}
                className="my-2 mt-2 mx-3"
                value={feedback}
                style={{
                  width: "90%",
                }}
                onChange={(e) => {
                  setFeedback(e.target.value);
                }}
              />
            </div>
          </Card.Body>
          <Modal.Footer>
            <CustomButton
              className="mt-2 mx-3 bg-danger"
              onClick={() => history.replace("/home/dashboard")}
            >
              Cancel
            </CustomButton>
            <CustomButton className="mt-2 mx-3" onClick={handleFeedbackSubmit}>
              Submit
            </CustomButton>
          </Modal.Footer>
        </Card>
      </Container>
    </div>
  );
}
