import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { AppContext, TAppContext } from "../../../../context/AppContext";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../context/AssessmentContext";
import { IsectionInfo } from "../../../../models/assessmentmodel";
import { findQuestionType } from "../../../../utils/question";
import { ERole } from "../../../../utils/Enums";
import EditMarkingScheme from "../EditMarkingScheme/EditMarkingScheme";
import { ComprehensionModal } from "../ComprehensionModal/ComprehensionModal";
import { Button } from "react-bootstrap";
import { Typography, useMediaQuery } from "@mui/material";

interface HeaderProps {
  currentQuestionNumber: number;
  setCurrentQuestionNumber: Dispatch<SetStateAction<number>>;
  currentSectionNumber: number;
  setCurrentSectionNumber: Dispatch<SetStateAction<number>>;
  sectionMap: Map<number, IsectionInfo>;
  proofRead?: boolean;
  setProofRead?: Dispatch<SetStateAction<boolean>>;
}

export default function Header(props: HeaderProps) {
  const isMobile = useMediaQuery("(max-width: 576px)");
  const { role } = useContext<TAppContext>(AppContext);
  const { selectedAssessment, currentQuestionData, comprehensionMap } =
    useContext<TAssessmentContext>(AssessmentContext);
  const [showComprehension, setShowComprehension] = useState<boolean>(false);

  const [questionButtonText, setQuestionButtonText] = useState<number>(1);

  useEffect(() => {
    let questionNumber = props.currentQuestionNumber + 1;
    selectedAssessment.sections
      .slice(0, props.currentSectionNumber)
      .forEach(
        (sectionId) =>
          (questionNumber +=
            props.sectionMap.get(sectionId)!.question_ids.length)
      );
    setQuestionButtonText(questionNumber);
  }, [
    props.currentQuestionNumber,
    props.currentSectionNumber,
    props.sectionMap,
  ]);

  return (
    <div className="d-flex flex-column">
      <div className="mb-2">
        <div className="d-flex flex-row justify-content-between align-items-center">
          {/* Question Number */}
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h5 className="d-inline mb-0" style={{ width: "auto" }}>
              {isMobile
                ? `Q${questionButtonText}`
                : ` Question ${questionButtonText}`}{" "}
            </h5>

            <div
              className={"py-1 " + (isMobile ? "px-1 mx-1" : "px-4 mx-2")}
              style={{ color: "#C4C4C4" }}
            >
              {/* Question Type */}
              {findQuestionType(currentQuestionData.question_type)}
            </div>
          </div>

          <div className="d-flex">
            {currentQuestionData.comprehension_id && (
              <>
                <Button
                  className={
                    "bg-secondary rounded-2 py-1 border border-none " +
                    (isMobile ? "px-1 mx-1" : "px-4 mx-2")
                  }
                  style={{ color: "white", cursor: "pointer" }}
                  onClick={() => setShowComprehension(!showComprehension)}
                >
                  <Typography fontSize={14}>View Comprehension</Typography>
                </Button>

                <ComprehensionModal
                  show={showComprehension}
                  setShow={setShowComprehension}
                  selectedComprehensionId={currentQuestionData.comprehension_id}
                  content={{
                    ...comprehensionMap.get(
                      currentQuestionData.comprehension_id
                    )!!.body,
                  }}
                  edit={role === ERole.TEACHER}
                />
              </>
            )}
            {/* Marking Scheme */}
            <h6
              className="d-flex flex-row align-items-center mb-0"
              style={{ color: "#C4C4C4" }}
            >
              Marks: <br></br>+{currentQuestionData.marks_if_correct} /
              {currentQuestionData.marks_if_incorrect}
              {role === ERole.TEACHER && <EditMarkingScheme />}
            </h6>
          </div>
        </div>
      </div>
      <hr className="mt-1"></hr>
    </div>
  );
}
