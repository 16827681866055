import { forwardRef } from "react";
import { Card, CardContent } from "@mui/material";

interface ImainCardProps {
  border?: boolean;
  boxShadow?: boolean;
  children?: any;
  content?: boolean;
  contentClass?: string;
  secondary?: any;
  shadow?: string;
  title?: string;
  backgroundColor?: string;
  borderColor?: string;
}

const MainCard = forwardRef((props: ImainCardProps, ref: any) => {
  return (
    <Card
      ref={ref}
      sx={{
        border: props.border ? "3px solid #A6A6A4" : "0",
        borderColor: props.borderColor || "grey",
        ":hover": {
          boxShadow: props.boxShadow
            ? props.shadow || "0 2px 14px 0 rgb(32 40 45 / 8%)"
            : "inherit",
        },
        backgroundColor: props.backgroundColor || "grey.700",
        borderRadius: "10px",
      }}
    >
      {props.content /**If content exists then first show the content then render the children if it exists */ && (
        <CardContent className={props.contentClass}>
          {props.children}
        </CardContent>
      )}
      {!props.content && props.children}
    </Card>
  );
});

MainCard.defaultProps = {
  content: true,
  border: true,
  contentClass: "",
};

export default MainCard;
