import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import { boolean, mixed, object, number, string } from "yup";
import { HomeContext, THomeContext } from "../../../context/HomeContext";
import { publishAssessmentHandler } from "../InstructionScreenRequests";
import { AppContext, TAppContext } from "../../../context/AppContext";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../context/AssessmentContext";
import CustomButton from "../../../components/CustomComponent/CustomButton";
import {
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import {
  EAccessLevel,
  EPublishedAssessmentFormat,
  EPublishStatus,
} from "../../../utils/Enums";
import UploadPdfModal from "../ManageAssessmentScreen/Components/UploadPdfModal";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { displayErrorToast } from "../../../utils/ToastUtils";

type TProps = {
  assessmentId: number;
  showPDFModal: boolean;
  setShowPDFModal: Dispatch<SetStateAction<boolean>>;
  publishType?: EAccessLevel;
  batchId?: number;
  startDate?: string;
  endDate?: string;
  startTime?: string;
  endTime?: string;
  publishId?: number;
  publishStatus?: EPublishStatus;
  format?: number;
  duration?: number;
  selectedBatchName?: string;
  callback?: (res) => void; // any callback function after updating the said published assessment
};
const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: 20,
  },
}));
export default function PublistTest(props: TProps) {
  const { batches } = useContext<THomeContext>(HomeContext);
  const { accessToken, id } = useContext<TAppContext>(AppContext);
  const { publishHistory, setPublishHistory, questionMap } =
    useContext<TAssessmentContext>(AssessmentContext);
  publishHistory.sort(function (first, second) {
    return second.id - first.id;
  });

  const InformationRegardingExamTypes = (
    ExamType: EPublishedAssessmentFormat | undefined
  ): string => {
    switch (String(ExamType)) {
      case String(EPublishedAssessmentFormat.UNTIMED):
        return "Only start time is specified without any duration or end time";
      case String(EPublishedAssessmentFormat.FIXED_TIME):
        return "A test with a definite start as well as end time";
      case String(EPublishedAssessmentFormat.FIXED_DURATION_UNTIMED):
        return "A test with a given start time and fixed duration but no end time";
      case String(EPublishedAssessmentFormat.FIXED_DURATION):
        return "A test with a definite start and end time and user can attempt the exam for the given duration between that";
    }
    return "";
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const isPublished = () => {
    return (
      Boolean(props.publishId) &&
      props.publishStatus === EPublishStatus.PUBLISHED
    );
  };

  const handlePublishUnpublishAssessment = async (values) => {
    const res = await publishAssessmentHandler(
      accessToken,
      setPublishHistory,
      props.assessmentId,
      questionMap.size,
      values.access_level!!,
      values.batchId!!,
      values.assessment_format,
      values.duration,
      values.start_date,
      values.start_time,
      values.end_date,
      values.end_time,
      props.publishId,
      values.publish_status
    );
    if (!!res && props.callback !== undefined) props.callback(res);
    setIsSubmitting(false);
  };

  const publishSchema = object({
    access_level: mixed<EAccessLevel>()
      .required("Please select a publish type")
      .oneOf([EAccessLevel.BATCH, EAccessLevel.PUBLIC]),
    assessment_format: mixed<EPublishedAssessmentFormat>()
      .required("You must select an assessment format")
      .test((value) =>
        Object.values(EPublishedAssessmentFormat).includes(
          value as EPublishedAssessmentFormat
        )
      ),
    publish_status: mixed<EPublishStatus>(),
    isBatchEnabled: boolean(),
    batchId: number().when("isBatchEnabled", {
      is: true,
      then: number().required().typeError("Please select a batch"),
    }),
    start_date: string().required("Please select a start date"),
    start_time: string().required("Please select a start time"),
    isEndDateEnabled: boolean(),
    end_date: string().when("isEndDateEnabled", {
      is: true,
      then: string()
        .required("Please select a end date")
        .test(
          "is-greater",
          "End date should be greater than start date",
          function (this: any, value) {
            const { start_date } = this.parent;
            if (!value) return false;
            return start_date <= value!!;
          }
        ),
    }),
    end_time: string().when("isEndDateEnabled", {
      is: true,
      then: string()
        .required("Please select a end time")
        .test(
          "is-greater",
          "End time should be greater than start time",
          function (this: any, value) {
            const { start_time, start_date, end_date } = this.parent;
            if (!value) return false;
            if (end_date > start_date) return true;
            return start_time <= value!!;
          }
        ),
    }),
    isDurationEnabled: boolean(),
    duration: number().when("isDurationEnabled", {
      is: true,
      then: number()
        .required()
        .positive()
        .test(
          "is-greater0",
          "Duration should be between start time and end time",
          function (this: any, value) {
            const {
              start_time,
              start_date,
              end_date,
              end_time,
              assessment_format,
            } = this.parent;
            if (
              assessment_format ===
              EPublishedAssessmentFormat.FIXED_DURATION_UNTIMED
            )
              return true;
            else if (!value) return false;
            const start = new Date(Date.parse(start_date + " " + start_time));
            const end = new Date(Date.parse(end_date + " " + end_time));
            return (
              value > 0 &&
              value <= (end.getTime() - start.getTime()) / (60 * 1000)
            );
          }
        ),
    }),
  });

  const filteredBatches = useMemo(
    () => batches.filter((el) => el.adminId === id),
    [batches, id]
  );

  return (
    <Formik
      validationSchema={publishSchema}
      onSubmit={handlePublishUnpublishAssessment}
      initialValues={{
        access_level: props.publishType || undefined,
        batchId: props.batchId || undefined,
        publish_status: props.publishStatus,
        assessment_format: props.format,
        duration: props.duration || 1,
        // extracting just the date
        start_date: props.startDate || "",
        end_date: props.endDate || "",
        // extracting just the time and then dropping extra digits
        start_time: props.startTime || "",
        end_time: props.endTime || "",
        isBatchEnabled: props.publishType === EAccessLevel.BATCH,
        isDurationEnabled:
          props.format === EPublishedAssessmentFormat.FIXED_DURATION ||
          props.format === EPublishedAssessmentFormat.FIXED_DURATION_UNTIMED,
        isEndDateEnabled:
          props.format === EPublishedAssessmentFormat.FIXED_DURATION ||
          props.format === EPublishedAssessmentFormat.FIXED_TIME,
      }}
    >
      {({
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        errors,
        validateForm,
      }) => (
        <>
          <Modal.Body>
            <Form>
              <Row>
                <Col xs={12} lg={6}>
                  <Form.Group
                    controlId="assessment_format"
                    className="mx-md-1"
                    as={Row}
                  >
                    <Col xs={5} lg={4} className="p-0">
                      <Form.Label
                        className="d-flex justify-content-start"
                        column
                      >
                        <Typography fontSize={20} color="#9AA0B2">
                          <b>Format</b>
                        </Typography>
                      </Form.Label>
                    </Col>
                    <Col xs={7} lg={8} className="p-0">
                      <BootstrapTooltip
                        title={InformationRegardingExamTypes(
                          values.assessment_format
                        )}
                      >
                        <div
                          style={{
                            backgroundColor: "#DBDFF1",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Form.Control
                            style={{ backgroundColor: "#DBDFF1" }}
                            as="select"
                            onChange={(e) => {
                              const format = parseInt(e.target.value);
                              if (
                                format ===
                                  EPublishedAssessmentFormat.FIXED_DURATION ||
                                format === EPublishedAssessmentFormat.FIXED_TIME
                              )
                                setFieldValue("isEndDateEnabled", true);
                              else setFieldValue("isEndDateEnabled", false);
                              if (
                                format ===
                                  EPublishedAssessmentFormat.FIXED_DURATION ||
                                format ===
                                  EPublishedAssessmentFormat.FIXED_DURATION_UNTIMED
                              )
                                setFieldValue("isDurationEnabled", true);
                              else setFieldValue("isDurationEnabled", false);
                              setFieldValue("assessment_format", format);
                            }}
                            onBlur={handleBlur}
                            value={values.assessment_format}
                            // disabled={isPublished()}
                            isInvalid={errors.assessment_format !== undefined}
                          >
                            <option value={undefined}>Select a type</option>
                            <option value={EPublishedAssessmentFormat.UNTIMED}>
                              Assignment
                            </option>
                            <option
                              value={EPublishedAssessmentFormat.FIXED_DURATION}
                            >
                              Fixed duration test
                            </option>
                            <option
                              value={EPublishedAssessmentFormat.FIXED_TIME}
                            >
                              Fixed time test
                            </option>
                            <option
                              value={
                                EPublishedAssessmentFormat.FIXED_DURATION_UNTIMED
                              }
                            >
                              Mock test
                            </option>
                          </Form.Control>
                          <ArrowDropDown />
                        </div>
                      </BootstrapTooltip>
                    </Col>
                  </Form.Group>
                </Col>
                {(values.assessment_format ===
                  EPublishedAssessmentFormat.FIXED_DURATION ||
                  values.assessment_format ===
                    EPublishedAssessmentFormat.FIXED_DURATION_UNTIMED) && (
                  <Col xs={12} lg={6}>
                    <Form.Group
                      controlId="duration"
                      className="mx-md-1"
                      as={Row}
                    >
                      <Col xs={5} lg={4} className="p-0">
                        <Form.Label
                          className="d-flex justify-content-start"
                          column
                        >
                          <Typography fontSize={20} color="#9AA0B2">
                            <b>Duration (mins)</b>
                          </Typography>
                        </Form.Label>
                      </Col>
                      <Col xs={7} lg={8} className="p-0">
                        <Form.Control
                          style={{ backgroundColor: "#DBDFF1" }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.duration}
                          // disabled={isPublished()}
                          isInvalid={errors.duration !== undefined}
                        ></Form.Control>
                      </Col>
                    </Form.Group>
                  </Col>
                )}
              </Row>
              <Row>
                <Col xs={12} lg={6}>
                  <Form.Group
                    controlId="start_date"
                    className="mx-md-1"
                    as={Row}
                  >
                    <Col xs={5} lg={4} className="p-0">
                      <Form.Label
                        className="d-flex justify-content-start"
                        column
                      >
                        <Typography fontSize={20} color="#9AA0B2">
                          <b>Start Date</b>
                        </Typography>
                      </Form.Label>
                    </Col>
                    <Col xs={7} lg={8} className="p-0">
                      <Form.Control
                        type="date"
                        value={values.start_date}
                        placeholder="DD/MM/YYYY"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ backgroundColor: "#DBDFF1" }}
                        isInvalid={errors.start_date !== undefined}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={12} lg={6}>
                  <Form.Group
                    controlId="start_time"
                    className="mx-md-1"
                    as={Row}
                  >
                    <Col xs={5} lg={4} className="p-0">
                      <Form.Label
                        className="d-flex justify-content-start"
                        column
                      >
                        <Typography fontSize={20} color="#9AA0B2">
                          <b>Start Time</b>
                        </Typography>
                      </Form.Label>
                    </Col>
                    <Col xs={7} lg={8} className="p-0">
                      <Form.Control
                        type="time"
                        value={values.start_time}
                        placeholder="HH:MM"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ backgroundColor: "#DBDFF1" }}
                        isInvalid={errors.start_time !== undefined}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              {(values.assessment_format ===
                EPublishedAssessmentFormat.FIXED_TIME ||
                values.assessment_format ===
                  EPublishedAssessmentFormat.FIXED_DURATION) && (
                <Row>
                  <Col xs={12} lg={6}>
                    <Form.Group
                      controlId="end_date"
                      className="mx-md-1"
                      as={Row}
                    >
                      <Col xs={5} lg={4} className="p-0">
                        <Form.Label
                          className="d-flex justify-content-start"
                          column
                        >
                          <Typography fontSize={20} color="#9AA0B2">
                            <b>End Date</b>
                          </Typography>
                        </Form.Label>
                      </Col>
                      <Col xs={7} lg={8} className="p-0">
                        <Form.Control
                          type="date"
                          value={values.end_date}
                          placeholder="DD/MM/YYYY"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            errors.end_date !== undefined ||
                            values.end_date === "Invalid date"
                          }
                          style={{ backgroundColor: "#DBDFF1" }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={6}>
                    <Form.Group
                      controlId="end_time"
                      className="mx-md-1"
                      as={Row}
                    >
                      <Col xs={5} lg={4} className="p-0">
                        <Form.Label
                          className="d-flex justify-content-start"
                          column
                        >
                          <Typography fontSize={20} color="#9AA0B2">
                            <b>End Time</b>
                          </Typography>
                        </Form.Label>
                      </Col>
                      <Col xs={7} lg={8} className="p-0">
                        <Form.Control
                          type="time"
                          value={values.end_time}
                          placeholder="HH:MM"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            errors.end_time !== undefined ||
                            values.end_time === "Invalid date"
                          }
                          style={{ backgroundColor: "#DBDFF1" }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={12} lg={6}>
                  <Form.Group
                    controlId="access_level"
                    className="mx-md-1"
                    as={Row}
                  >
                    <Col xs={5} lg={4} className="p-0">
                      <Form.Label
                        className="d-flex justify-content-start"
                        column
                      >
                        <Typography fontSize={20} color="#9AA0B2">
                          <b>Publish Type</b>
                        </Typography>
                      </Form.Label>
                    </Col>
                    <Col xs={7} lg={8} className="p-0">
                      <div
                        style={{
                          backgroundColor: "#DBDFF1",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Form.Control
                          style={{ backgroundColor: "#DBDFF1" }}
                          as="select"
                          value={values.access_level}
                          // disabled={isPublished()}
                          onChange={(e) => {
                            const access_level = parseInt(e.target.value);
                            if (access_level === EAccessLevel.BATCH)
                              setFieldValue("isBatchEnabled", true);
                            else setFieldValue("isBatchEnabled", false);
                            setFieldValue("access_level", access_level);
                          }}
                          onBlur={handleBlur}
                          isInvalid={errors.access_level !== undefined}
                        >
                          <option value={undefined}>Select Publish Type</option>
                          <option value={EAccessLevel.BATCH}>
                            Publish to a batch
                          </option>
                          <option value={EAccessLevel.PUBLIC}>
                            Publish as a public URL
                          </option>
                        </Form.Control>
                        <ArrowDropDown />
                      </div>
                    </Col>
                  </Form.Group>
                </Col>

                {values.access_level === EAccessLevel.BATCH ? (
                  <Col xs={12} lg={6}>
                    <Form.Group
                      controlId="batchId"
                      className="mx-md-1"
                      as={Row}
                    >
                      <Col xs={5} lg={4} className="p-0">
                        <Form.Label
                          className="d-flex justify-content-start"
                          column
                        >
                          <Typography fontSize={20} color="#9AA0B2">
                            <b>Select a Batch</b>
                          </Typography>
                        </Form.Label>
                      </Col>
                      <Col xs={7} lg={8} className="p-0">
                        <div
                          style={{
                            backgroundColor: "#DBDFF1",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Form.Control
                            style={{ backgroundColor: "#DBDFF1" }}
                            as="select"
                            value={values.batchId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // disabled={isPublished() || props.publishId !== null}
                            isInvalid={errors.batchId !== undefined}
                          >
                            <option value={undefined} selected>
                              Select a batch
                            </option>
                            {filteredBatches.map((el) => (
                              <option value={el.id}>{el.name}</option>
                            ))}
                          </Form.Control>
                          <ArrowDropDown />
                        </div>
                      </Col>
                    </Form.Group>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
            </Form>
          </Modal.Body>
          <UploadPdfModal
            show={props.showPDFModal}
            setShow={props.setShowPDFModal}
          />
          <Modal.Footer>
            <div className="d-flex justify-content-end">
              {isPublished() && (
                <CustomButton
                  className="border-0 rounded-2 bg-secondary mx-2 bg-danger"
                  disabled={isSubmitting}
                  onClick={() => {
                    setIsSubmitting(true);
                    values.publish_status = EPublishStatus.UNPUBLISHED;
                    validateForm().then((errors) => {
                      if (Object.keys(errors).length === 0)
                        handlePublishUnpublishAssessment(values);
                      else setIsSubmitting(false);
                    });
                  }}
                >
                  <Typography className="m-0 p-0">Unpublish</Typography>
                </CustomButton>
              )}

              <CustomButton
                className="border-0 rounded-2 bg-secondary mx-2"
                style={{ minWidth: "150px" }}
                loading={isSubmitting}
                onClick={() => {
                  setIsSubmitting(true);
                  !isPublished() &&
                    (values.publish_status = EPublishStatus.PUBLISHED);
                  validateForm().then((errors) => {
                    if (Object.keys(errors).length === 0)
                      handlePublishUnpublishAssessment(values);
                    else {
                      if (Object.keys(errors)[0] === "access_level")
                        displayErrorToast("Please select a valid publish type");
                      else if (Object.keys(errors)[0] === "assessment_format")
                        displayErrorToast(
                          "Please select a valid assessment format"
                        );
                      else displayErrorToast(errors[Object.keys(errors)[0]]);
                      setIsSubmitting(false);
                    }
                  });
                }}
              >
                <Typography className="m-0 p-0">
                  {isPublished() ? "Save Changes" : "Publish"}
                </Typography>
              </CustomButton>
            </div>
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
}
