import {
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { FunctionComponent } from "react";

const PrivacyPolicy: FunctionComponent = () => {
  return (
    <Container>
      <Card className="mt-2">
        <CardContent>
          <Typography variant="h3">Privacy Policy</Typography>
          <Divider />
          <List>
            <ListItem>
              <Typography className="mt-2" variant="body1">
                We accept payments through RazorPay. Read their Terms and
                Conditions here.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemText>
                <Link href="https://merchant.razorpay.com/policy/KJlpDKBwygGE5E/privacy">
                  Privacy Policy
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Link href="https://merchant.razorpay.com/policy/KJlpDKBwygGE5E/terms">
                  Terms & Conditions
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Link href="https://merchant.razorpay.com/policy/KJlpDKBwygGE5E/refund">
                  Cancellation & Refund
                </Link>
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemText>
                <Link href="https://merchant.razorpay.com/policy/KJlpDKBwygGE5E/shipping">
                  Shipping & Delivery
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Link href="https://merchant.razorpay.com/policy/KJlpDKBwygGE5E/contact_us">
                  Contact us
                </Link>
              </ListItemText>
            </ListItem>
          </List>
        </CardContent>
      </Card>
      ;
    </Container>
  );
};

export default PrivacyPolicy;
