import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import {
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { EAssessmentFormat, ERole } from "../../utils/Enums";
import { AppContext, TAppContext } from "../../context/AppContext";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../context/AssessmentContext";
import StudentQuestionScreen from "./StudentQuestionScreen";
import TeacherQuestionScreen from "./TeacherQuestionScreen";
import TeacherQuestionEditScreen from "./TeacherQuestionEditScreen";
import ProofRead from "./Components/proofread-mode/ProofRead";
import QuestionTopNavBar from "./Components/QuestionTopNavBar/QuestionTopNavBar";
import OMRQuestionScreen from "../OMR-based-question/QuestionScreen";
import { endTest } from "../../api/responses";
import { AttemptContext, TAttemptContext } from "../../context/AttemptContext";
import Loading from "../../components/Loading/Loading";
import TestSubmitModal from "./Components/TestSubmitModal/TestSubmitModal";
import { patchAttemptInfo } from "../../api/attempts";
import { parseMapFromJSON } from "../../utils/hooks/useStickyState";
import { saveProctorLogAPICall } from "../../api/proctorLog";
import { ProctorContext, TProctorContext } from "../../context/ProctorContext";
import { useMediaQuery } from "@mui/material";

export default function QuestionView() {
  const history = useHistory();
  const isDesktopOrLaptop = useMediaQuery("(min-width: 992px)");
  const { role, setDrawerOpen, isLoggedIn, id, setRole, accessToken } =
    useContext<TAppContext>(AppContext);
  const location = useLocation(); // Gets location of current page. Must be part of main function
  const [edit, setEdit] = useState<boolean>(isDesktopOrLaptop);
  const [proofRead, setProofRead] = useState<boolean>(isDesktopOrLaptop);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const { currentAttempt, currentSession } =
    useContext<TAttemptContext>(AttemptContext);
  const { visibilityChanges } = useContext<TProctorContext>(ProctorContext);

  const {
    selectedAssessment,
    sectionMap, // List of sections.
    questionMap,
    currentQuestionNumber, // The current question number
    currentSectionNumber, // current section to which question belongs to
    setCurrentQuestionData,
    showQNavDrawer,
    setShowQNavDrawer,
  } = useContext<TAssessmentContext>(AssessmentContext); // Get the data from the context

  const hasFinishedAttempt = location.state?.hasFinishedAttempt;
  const previousRoute = location.state?.previousRoute;
  const assessmentId = useRouteMatch().params["id"];
  const publishCode = useRouteMatch().params["publishCode"];

  useEffect(() => {
    setIsLoading(true);
    console.log("Checking if user is creator or not");
    if (parseInt(selectedAssessment.created_by) === id) {
      setRole(ERole.TEACHER);
    } else setRole(ERole.STUDENT);
    setIsLoading(false);
  }, []);

  function getCurrentQuestion() {
    if (questionMap.size === 0) {
      console.log("No questions in the list");
      return;
    }
    console.log(
      "getting current question data",
      currentSectionNumber,
      currentQuestionNumber
    );
    const sectionId = selectedAssessment.sections[currentSectionNumber];
    if (
      currentQuestionNumber >= sectionMap.get(sectionId)!.question_ids.length
    ) {
      return;
    }
    const questionId =
      sectionMap.get(sectionId)!.question_ids[currentQuestionNumber];
    console.log("Fetching question with id: ", questionId);
    const questionData = questionMap.get(questionId);
    if (questionData!!) setCurrentQuestionData(questionData);
  }

  const handleRoute = () => {
    if (selectedAssessment.format === EAssessmentFormat.OMR)
      return (
        <ProofRead
          edit={edit}
          setEdit={setEdit}
          proofRead={proofRead}
          setProofRead={setProofRead}
        >
          <OMRQuestionScreen proofRead={proofRead} />
        </ProofRead>
      );
    else if (role === ERole.STUDENT)
      return (
        <ProofRead
          edit={edit}
          setEdit={setEdit}
          proofRead={proofRead}
          setProofRead={setProofRead}
        >
          <StudentQuestionScreen
            isLoading={isLoading}
            proofRead={proofRead}
            setProofRead={setProofRead}
          />
        </ProofRead>
      );
    // if student then redirect to question screen for attempting questions
    else if (!edit)
      return (
        <ProofRead
          edit={edit}
          setEdit={setEdit}
          proofRead={proofRead}
          setProofRead={setProofRead}
        >
          <TeacherQuestionScreen
            isLoading={isLoading}
            edit={edit}
            setEdit={setEdit}
            proofRead={proofRead}
            setProofRead={setProofRead}
          />
        </ProofRead>
      );
    // if not editing move to teacher question screen
    else
      return (
        <ProofRead
          edit={edit}
          setEdit={setEdit}
          proofRead={proofRead}
          setProofRead={setProofRead}
        >
          <TeacherQuestionEditScreen
            edit={edit}
            setEdit={setEdit}
            proofRead={proofRead}
            setProofRead={setProofRead}
            isLoading={isLoading}
          />
        </ProofRead>
      );
  };

  useEffect(() => {
    // get the current question
    setIsLoading(true);
    getCurrentQuestion();
    setIsLoading(false);

    // TODO: Add use-effect if flag variable of current question is changed
  }, [currentQuestionNumber, currentSectionNumber, questionMap, sectionMap]);

  useEffect(() => {
    setDrawerOpen(false);
    return () => {
      setDrawerOpen(true);
    };
  });

  if (isLoading) return <Loading />;

  if (
    role === ERole.STUDENT &&
    (hasFinishedAttempt === undefined || previousRoute === undefined) &&
    isLoggedIn
  ) {
    if (publishCode === undefined) return <Redirect to={`/home/dashboard`} />;
    return <Redirect to={`/home/attempt/${publishCode}/instructions`} />;
  }
  if (role === ERole.TEACHER && previousRoute === undefined && isLoggedIn) {
    if (assessmentId === undefined) return <Redirect to={`/home/dashboard`} />;
    return <Redirect to={`/home/assessments/${assessmentId}/manage`} />;
  }

  const handleSubmit = async () => {
    const res = await endTest(
      accessToken,
      parseMapFromJSON(window.localStorage.getItem("unpushedAttempts")),
      setShowSubmitModal
    );
    // document.exitFullscreen();
    const date = new Date().toISOString().slice(0, -1);
    await patchAttemptInfo(accessToken, currentAttempt.id, {
      last_submitted_on: date,
    });
    await saveProctorLogAPICall(
      accessToken,
      currentSession.id,
      visibilityChanges
    );
    if (res === 1) {
      history.replace(`/home/attempt/${publishCode}/feedback`);
    }
  };

  return (
    <>
      {isSaveLoading && (
        <div
          className="p-0"
          style={{
            backgroundColor: "white",
            position: "absolute",
            opacity: "50%",
            height: "100vh",
            width: "100vw",
            zIndex: 1000,
          }}
        />
      )}
      <Container fluid className="p-0">
        {/* The Question Screen Navbar which contains the Name of the assessment */}
        <QuestionTopNavBar
          role={role || ERole.STUDENT}
          assessmentName={selectedAssessment.name}
          showQNavDrawer={showQNavDrawer}
          setShowQNavDrawer={setShowQNavDrawer}
          proofRead={proofRead}
          setProofRead={setProofRead}
          setShowSubmitModal={setShowSubmitModal}
          edit={edit}
          setEdit={setEdit}
          handleSubmit={handleSubmit}
          loading={isSaveLoading}
          setLoading={setIsSaveLoading}
        />
        {handleRoute()}
        <TestSubmitModal
          showModal={showSubmitModal}
          setShowModal={setShowSubmitModal}
          handleTestSubmit={handleSubmit}
        />
      </Container>
    </>
  );
}
