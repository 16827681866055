import moment from "moment";
import { EPublishedAssessmentFormat, MetricTypes } from "./Enums";

export function getAnalyticsSectionData(analyticsResponse) {
  for (let metric in analyticsResponse) {
    if (analyticsResponse[metric].type === MetricTypes.TABULAR)
      analyticsResponse[metric] = JSON.parse(analyticsResponse[metric].data);
  }
  return analyticsResponse;
}

export function parseAnalyticsData(analyticsResponse) {
  for (let metric in analyticsResponse) {
    // backend is not supposed to send null data
    // we can get rid of this if block ones backend sends the data we need(student related metrics only for students and not for teacher)
    if (
      analyticsResponse[metric] !== null &&
      analyticsResponse[metric].type === MetricTypes.TABULAR
    ) {
      analyticsResponse[metric] = JSON.parse(analyticsResponse[metric].data);
    }
  }
  return analyticsResponse;
}

export const disableAnalytics = (
  assessmentFormat: EPublishedAssessmentFormat | any,
  serverTime?: Date,
  endTime?: Date,
  currentAttemptStartedOn?: Date,
  duration?: number
): boolean => {
  // The buffer before displaying analytics after the test has been ended is 10 minutes
  const analyticsBuffer = 10;
  let hasDeadlineEnded: boolean;
  if (serverTime !== undefined && endTime !== undefined) {
    hasDeadlineEnded = moment
      .utc(serverTime)
      .isAfter(moment.utc(endTime).add(analyticsBuffer, "minutes"));
  } else {
    hasDeadlineEnded = true;
  }

  switch (assessmentFormat) {
    case EPublishedAssessmentFormat.UNTIMED:
      return false;

    case EPublishedAssessmentFormat.FIXED_DURATION_UNTIMED:
      //  Since we dont have an "attempt_started_on" on the dashboard, cannot disable it on dashboard.
      // Solution is to let backend validate and we handle the redirect on our side
      if (duration === undefined || currentAttemptStartedOn === undefined) {
        return false;
      }
      const mocKTestEndTime = moment
        .utc(currentAttemptStartedOn)
        .add(duration + analyticsBuffer, "minutes");
      return !moment.utc(serverTime).isAfter(mocKTestEndTime);

    case EPublishedAssessmentFormat.FIXED_DURATION:
      return !hasDeadlineEnded;

    case EPublishedAssessmentFormat.FIXED_TIME:
      return !hasDeadlineEnded;

    default:
      return true;
  }
};

/**
 * An util that ensures that all the data of a particular metric is not zero
 * @param metric The array of the analytics metric data
 * @param dataKey The key of the dict in which the metric value is stored
 * @returns a boolean indicating all the data in the metric is zero or not
 */
export const checkIfAllZeroes = (metric, dataKey: string) => {
  for (let i = 0; i < metric.length; i++) {
    if (parseInt(metric[i][dataKey]) !== 0) {
      return false;
    }
  }
  return true;
};
