import { useContext, useEffect, useState } from "react";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../context/AssessmentContext";
import { Col, Container, Row } from "react-bootstrap";
import QuestionNavigationPanel from "./Components/QuestionNavigationPanel/QuestionNavigationPanel";
import Header from "./Components/Header/Header";
import { ERole } from "../../utils/Enums";
import QuestionNavigationDrawer from "./Components/QuestionNavigationPanel/QuestionNavigationDrawer";
import { useMediaQuery } from "@mui/material";
import QuestionText from "./Components/QuestionText/QuestionText";
import QuestionType from "./Components/QuestionType/QuestionType";
import {
  QuestionContext,
  TQuestionContext,
} from "../../context/QuestionContext";
import StudentQuestionButtonPanel from "./Components/QuestionButtonPanel/StudentQuestionButtonPanel";
import { AppContext, TAppContext } from "../../context/AppContext";
import QuestionTopNavBar from "./Components/QuestionTopNavBar/QuestionTopNavBar";
import { useHistory, useRouteMatch } from "react-router-dom";
import { fetchCreatorData } from "../instructionScreen/ManageAssessmentScreen/ManageAssessmentScreen";
import { fetchAllResponses } from "../../api/responses";
import { IstudentResponse } from "../../models/responsemodel";
import { questionInfo } from "../../models/assessmentmodel";
import Solution from "./Components/Solution/Solution";
import ProofRead from "./Components/proofread-mode/ProofRead";

type TProps = {
  proofRead: boolean;
  setProofRead: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function StudentNTAResponseScreenTeacher(props: TProps) {
  const { setDrawerOpen, accessToken, setRole, id } =
    useContext<TAppContext>(AppContext);
  const history = useHistory();
  const studentId: number = parseInt(useRouteMatch().params["student_id"]);
  const publishCode = useRouteMatch().params["publishCode"];
  const {
    sectionMap, // List of sections.
    setSectionMap,
    currentQuestionNumber, // The current question number
    setCurrentQuestionNumber,
    currentSectionNumber, // current section to which question belongs to
    setCurrentSectionNumber,
    currentQuestionData,
    showQNavDrawer,
    setShowQNavDrawer,
    selectedAssessment,
    setSelectedAssessment,
    questionMap,
    setQuestionMap,
    setComprehensionMap,
    setCurrentQuestionData,
  } = useContext<TAssessmentContext>(AssessmentContext); // Get the data from the context
  const { assessmentQuestionInfo, setAssessmentQuestionInfo } =
    useContext<TQuestionContext>(QuestionContext);

  const [hasFinishedAttempt, setHasFinishedAttempt] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const isDesktopOrLaptop = useMediaQuery("(min-width: 992px)");
  const [studentResponseMap, setStudentResponseMap] = useState<
    Map<number, Array<IstudentResponse>>
  >(new Map());

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      await fetchCreatorData(
        accessToken,
        selectedAssessment.id,
        setSelectedAssessment,
        setQuestionMap,
        setSectionMap,
        setComprehensionMap,
        setRole,
        history,
        id
      );
      // manually override it
      setRole(ERole.STUDENT);
      // manually setting deadline
      setHasFinishedAttempt(true);
      const allResponses = await fetchAllResponses(accessToken, publishCode);
      let responseMap = new Map<number, Array<IstudentResponse>>();

      allResponses.forEach((response) => {
        if (!responseMap.has(response.student_id)) {
          responseMap.set(response.student_id, [response]);
        }
        responseMap.get(response.student_id)?.push(response);
      });
      setStudentResponseMap(responseMap);
    })();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let currentStudentInfo = studentResponseMap.get(studentId);
    if (currentStudentInfo === undefined) {
      return;
    }
    let allQuestionInfoMap = new Map<number, questionInfo>();
    currentStudentInfo?.forEach((question) => {
      let qInfo: questionInfo = new questionInfo(question.question_id);
      qInfo.questionState = question.answer_type;
      qInfo.questionAnswer = question.student_answer;
      qInfo.questionTimeSpent = question.time_taken;
      allQuestionInfoMap.set(question.question_id, qInfo);
    });

    questionMap.forEach((question) => {
      if (!allQuestionInfoMap.has(question.question_id)) {
        let qInfo: questionInfo = new questionInfo(question.question_id);
        allQuestionInfoMap.set(question.question_id, qInfo);
      }
    });
    setAssessmentQuestionInfo(allQuestionInfoMap);
    setIsLoading(false);
  }, [studentId, setAssessmentQuestionInfo, studentResponseMap]);

  function getCurrentQuestion() {
    if (questionMap.size === 0) {
      console.log("No questions in the list");
      return;
    }
    const sectionId = selectedAssessment.sections[currentSectionNumber];
    if (sectionId === undefined) {
      console.log("Section not fuond");
      return;
    }
    if (currentQuestionNumber >= sectionMap.get(sectionId)!.question_ids.length)
      return;

    const questionId =
      sectionMap.get(sectionId)!.question_ids[currentQuestionNumber];
    const questionData = questionMap.get(questionId);
    if (questionData!!) {
      setCurrentQuestionData(questionData);
    }
  }

  useEffect(() => {
    // get the current question
    setIsLoading(true);
    getCurrentQuestion();
    setIsLoading(false);
    // TODO: Add use-effect if flag variable of current question is changed
  }, [currentQuestionNumber, currentSectionNumber, questionMap, sectionMap]);

  useEffect(() => {
    setDrawerOpen(false);
    return () => setDrawerOpen(true);
  });

  return isLoading ? (
    <div>Loading</div>
  ) : (
    <div className="w-100">
      <QuestionTopNavBar
        role={ERole.STUDENT}
        assessmentName={selectedAssessment.name}
        showQNavDrawer={showQNavDrawer}
        setShowQNavDrawer={setShowQNavDrawer}
        proofRead={props.proofRead}
        setProofRead={props.setProofRead}
        setShowSubmitModal={() => {}}
        edit={false}
        setEdit={() => {}}
        handleSubmit={() => {}}
        hasFinishedAttempt
      />
      <ProofRead proofRead={props.proofRead} setProofRead={props.setProofRead}>
        <Container
          fluid
          className="p-0 d-flex justify-content-center"
          style={{ height: "92%", overflowY: "auto" }}
        >
          <Col className="p-0 d-flex flex-row justify-content-center">
            <Row className="w-100 d-flex flex-row justify-content-center">
              <Col xs={12} lg={8} className="d-flex flex-column m-2 m-lg-4">
                {/* Header */}
                <div className="flex-grow-1">
                  <Header
                    currentQuestionNumber={currentQuestionNumber}
                    setCurrentQuestionNumber={setCurrentQuestionNumber}
                    currentSectionNumber={currentSectionNumber}
                    setCurrentSectionNumber={setCurrentSectionNumber}
                    sectionMap={sectionMap}
                  />
                  <div
                    style={{
                      height: "62vh",
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    {/* QuestionText */}
                    <QuestionText images={currentQuestionData.body.images}>
                      {currentQuestionData.body.text}
                    </QuestionText>

                    {/* QuestionType */}
                    <QuestionType
                      questionType={currentQuestionData.question_type}
                      allowChange={false}
                    />
                  </div>
                  <div className="mt-2 mb-2">
                    Answer : {currentQuestionData.answer}
                  </div>
                  <div>
                    <Solution solution={currentQuestionData.solution} />
                  </div>
                </div>
              </Col>
              <StudentQuestionButtonPanel
                hasFinishedAttempt={hasFinishedAttempt}
              />

              {/* Question Navigation Panel */}
              {isDesktopOrLaptop ? (
                <QuestionNavigationPanel
                  role={ERole.STUDENT}
                  sectionMap={sectionMap}
                  allQuestionInfo={assessmentQuestionInfo}
                />
              ) : (
                <QuestionNavigationDrawer
                  show={showQNavDrawer}
                  setShowHandler={setShowQNavDrawer}
                  role={ERole.STUDENT}
                  sectionMap={sectionMap}
                  allQuestionInfo={assessmentQuestionInfo}
                ></QuestionNavigationDrawer>
              )}
            </Row>
          </Col>
        </Container>
      </ProofRead>
    </div>
  );
}
