import { Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { Marquee } from "../../components/CustomComponent/Marquee";

type TProps = {
  heading: string;
  summary?: string;
  isAdmin: boolean;
  onClick?: () => void;
};

export function BatchCard(props: TProps) {
  const isMobile = useMediaQuery("(max-width: 576px)");
  const [play, setPlay] = useState<boolean>(true);
  return (
    <div
      onMouseOver={() => {
        setPlay(true);
      }}
      onClick={props.onClick}
      className="d-flex flex-row justify-content-between rounded-4 mx-1"
      style={{
        backgroundColor: props.isAdmin ? "#F0F0F0" : "#DBDFF1",
        height: "90px",
        cursor: "pointer",
        overflow: "hidden",
      }}
    >
      <div className="d-flex flex-row">
        {isMobile ? (
          <div></div>
        ) : (
          <div
            className="rounded-4 d-flex"
            style={{
              backgroundColor: "#FFBA69",
              height: "90px",
              width: "90px",
            }}
          ></div>
        )}
        <div className="d-flex flex-column justify-content-center ms-3">
          <div style={{ width: "200px" }}>
            <Marquee
              disable={props.heading.length < 20}
              overridePlay={play || isMobile}
              setOverridePlay={setPlay}
            >
              {props.heading}
            </Marquee>
          </div>
          <Typography
            className="py-2"
            variant="subtitle1"
            fontWeight={"bold"}
            fontSize={"16px"}
            style={{ color: "#A8A8A8" }}
          >
            {props.summary}
          </Typography>
        </div>
      </div>
    </div>
  );
}
