import { Card, CardContent, Typography, IconButton } from "@mui/material";
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { EAssessmentFormat, EAssessmentType } from "../../../utils/Enums";

import styles from "./../MyTests.module.scss";
import classNames from "classnames/bind";
import { useHistory } from "react-router-dom";
import { handleCreateAssessment } from "../../../api/assessment";
import { AppContext, TAppContext } from "../../../context/AppContext";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../context/AssessmentContext";
import { displayErrorToast } from "../../../utils/ToastUtils";
import CloseIcon from "@mui/icons-material/Close";

let cx = classNames.bind(styles);

interface CreateTestModalProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

const CreateTestModal: FunctionComponent<CreateTestModalProps> = (props) => {
  const history = useHistory();
  const [testName, setTestName] = useState<string>("");
  const [testFormat, setTestFormat] = useState<EAssessmentFormat>(
    EAssessmentFormat.OMR
  );
  const { accessToken, org } = useContext<TAppContext>(AppContext);
  const { setSelectedAssessment } =
    useContext<TAssessmentContext>(AssessmentContext);

  const createAssessment = async () => {
    if (org === null) {
      return;
    }
    if (!testName.trim()) {
      return displayErrorToast("Please enter a Test name");
    }
    const createAssessmentResponse = await handleCreateAssessment(
      accessToken,
      EAssessmentType.TEST,
      testFormat,
      testName,
      "",
      org.id
    );
    if (!createAssessmentResponse) return;
    setSelectedAssessment(createAssessmentResponse);
    history.push(`/home/assessments/${createAssessmentResponse.id}/manage`);
  };

  const testFormatTypes = [
    {
      format: EAssessmentFormat.OMR,
      description: "Upload a PDF and an OMR sheet with correct options.",
    },
    {
      format: EAssessmentFormat.NTA,
      description:
        "Start with an empty template and add questions by typing them in or import questions manually.",
    },
  ];

  return (
    <Modal
      contentClassName={styles.modalStyle}
      show={props.show}
      onHide={() => props.setShow(false)}
      centered
    >
      <Modal.Header>
        <Typography variant="h4">
          <b>Create Test</b>
        </Typography>
        <IconButton>
          <CloseIcon onClick={() => props.setShow(false)} />
        </IconButton>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Row} className="align-items-center">
          <Col className="d-flex" xs={12} md={4}>
            <Form.Label className="m-0">
              <Typography fontSize={20} color="#9AA0B2">
                <b>Test Name:</b>
              </Typography>
            </Form.Label>
          </Col>
          <Col>
            <Form.Control
              style={{ backgroundColor: "#DBDFF1" }}
              onChange={(e) => setTestName(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Typography className="mt-4" fontSize={20} color="#9AA0B2">
          <b>Choose Format:</b>
        </Typography>
        <Row>
          {testFormatTypes.map((el) => {
            const activeCardClass = cx({
              active: testFormat === el.format,
            });
            return (
              <Col className="d-flex align-items-stretch">
                <Card
                  className={activeCardClass}
                  sx={{ cursor: "pointer" }}
                  onClick={() => setTestFormat(el.format)}
                >
                  <CardContent>
                    <Typography variant="h5">
                      {EAssessmentFormat[el.format]}
                    </Typography>
                    <Typography>{el.description}</Typography>
                  </CardContent>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="border-0 rounded-2 bg-secondary"
          type="submit"
          onClick={createAssessment}
        >
          <Typography className="m-0 p-0">Create Test</Typography>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateTestModal;
