import { Button, Divider, Typography, useMediaQuery } from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { AppContext, TAppContext } from "../../../context/AppContext";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InsightsIcon from "@mui/icons-material/Insights";
import MenuIcon from "@mui/icons-material/Menu";
import {
  deleteAssessment,
  fetchAssessmentData,
  fetchPastPublishesOfAssessment,
} from "../../../api/assessment";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../context/AssessmentContext";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../utils/ToastUtils";
import { findFirstSectionNumber } from "../../../utils/QuestionNavigation";
import { EAccessLevel, EAssessmentFormat, ERole } from "../../../utils/Enums";
import {
  createIDMapFromArray,
  getQuestionMap,
} from "../InstructionScreenRequests";
import SectionCard from "./Components/SectionCard";
import ManageAssessmentNavBar from "./Components/TopNav";
import ManageAssessmentBottomNavigation from "./Components/BottomNav";
import ReorderSectionModal from "./Components/ReorderSectionModal";
import ConfirmationModal from "../../../components/ConfirmationModal";
import EditAssessmentNameModal from "./Components/EditAssessmentNameModal";
import EditInstructionsModal from "./Components/EditInstructionsModal";
import CreateNewSectionModal from "./Components/CreateNewSectionModal";
import PublishTestModal from "../../my-tests/PublishTestModal/PublishTestModal";
import PrintPDFModal from "../../instructionScreen/ManageAssessmentScreen/Components/PrintQuestionPaperModal";
import { fetchComprehensionInAssessment } from "../../../api/comprehension";
import copy from "copy-to-clipboard";
import moment from "moment";
import Loading from "../../../components/Loading/Loading";
import {
  IquestionData,
  IsectionInfo,
  TAssessmentData,
  TComprehension,
} from "../../../models/assessmentmodel";
import { disableAnalytics } from "../../../utils/analyticsUtils";
import Styles from "./ManageAssessmentScreen.module.scss";

export const fetchCreatorData = async (
  accessToken: string,
  assessmentId: number,
  setSelectedAssessment: Dispatch<SetStateAction<TAssessmentData>>,
  setQuestionMap: Dispatch<SetStateAction<Map<number, IquestionData>>>,
  setSectionMap: Dispatch<SetStateAction<Map<number, IsectionInfo>>>,
  setComprehensionMap: Dispatch<SetStateAction<Map<number, TComprehension>>>,
  setRole: Dispatch<SetStateAction<ERole | null>>,
  history,
  id: number
) => {
  const assessmentData = await fetchAssessmentData(accessToken, assessmentId);
  if (!assessmentData) {
    displayErrorToast("Couldn't fetch assessment. Please try again.");
    return history.push("/home/dashboard");
  }
  if (parseInt(assessmentData.assessment.created_by) === id)
    setRole(ERole.TEACHER);
  else {
    displayErrorToast(
      "You cannot modify this test since you are not the creator"
    );
    return history.push(`/home/dashboard`);
  }
  const comprehension = await fetchComprehensionInAssessment(
    accessToken,
    assessmentId
  );
  setSelectedAssessment(assessmentData.assessment);
  setQuestionMap(getQuestionMap(assessmentData.questions));
  setSectionMap(createIDMapFromArray(assessmentData.sections));
  setComprehensionMap(createIDMapFromArray(comprehension));
};

const ManageAssessmentScreen = () => {
  const history = useHistory();

  const { setDrawerOpen, accessToken, setRole, id } =
    useContext<TAppContext>(AppContext);
  const isDesktopOrLaptop = useMediaQuery("(min-width: 992px)");

  const {
    selectedAssessment,
    setSelectedAssessment,
    setSectionMap,
    setCurrentQuestionNumber,
    setCurrentSectionNumber,
    setQuestionMap,
    sectionMap,
    setComprehensionMap,
  } = useContext<TAssessmentContext>(AssessmentContext);
  const [showDeleteAssessmentModal, setShowDeleteAssessmentModal] =
    useState<boolean>(false);
  const [showEditAssessmentNameModal, setShowEditAssessmentNameModal] =
    useState<boolean>(false);
  const [showEditInstructionsModal, setShowEditInstructionsModal] =
    useState<boolean>(false);
  const [showReorderModal, setShowReorderModal] = useState<boolean>(false);
  const [showCreateSectionModal, setShowCreateSectionModal] =
    useState<boolean>(false);
  const [showEditPublishDetailsModal, setShowEditPublishDetailsModal] =
    useState<boolean>(false);
  const [showPrintAssessmentModal, setShowPrintAssessmentModal] =
    useState<boolean>(false);
  const [forceReload, setForceReload] = useState<boolean>(false);

  const [publishInfo, setPublishInfo] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const assessmentId = useRouteMatch().params["id"];

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await fetchCreatorData(
        accessToken,
        assessmentId,
        setSelectedAssessment,
        setQuestionMap,
        setSectionMap,
        setComprehensionMap,
        setRole,
        history,
        id
      );
      const response = await fetchPastPublishesOfAssessment(
        accessToken,
        assessmentId
      );
      const firstSectionNumber = findFirstSectionNumber(
        selectedAssessment,
        sectionMap
      );
      setCurrentSectionNumber(firstSectionNumber);
      setCurrentQuestionNumber(0);
      // Ideally response should be a single object, but since the dev-db has multiple publications present for a test
      // This is a temp work around to store the first publication as the publishInfo for now.
      // Once there is a one-to-one mapping between published test and a test, response[0] can be simply replaced with response
      setPublishInfo(response[0]);
      setIsLoading(false);
    })();
  }, [forceReload]);

  useEffect(() => {
    setDrawerOpen(false);
    return () => setDrawerOpen(true);
  });

  const handleAssignmentDelete = async () => {
    const deleteAssignmentResponse = await deleteAssessment(
      accessToken,
      selectedAssessment.id
    );
    if (deleteAssignmentResponse != null) {
      displaySuccessToast("Assessment Deleted successfully");
      history.push("/home/dashboard");
    }
    setShowDeleteAssessmentModal(false);
  };

  const handleQuestionScreen = (currentSectionNumber?: number) => {
    if (sectionMap.size === 0) {
      displayErrorToast("Please add atleast one section.");
      return;
    }

    if (currentSectionNumber !== undefined) {
      if (
        selectedAssessment.format === EAssessmentFormat.NTA &&
        sectionMap.get(selectedAssessment.sections[currentSectionNumber])
          ?.question_ids.length === 0
      ) {
        currentSectionNumber = findFirstSectionNumber(
          selectedAssessment,
          sectionMap
        );
      }
      setCurrentSectionNumber(currentSectionNumber);
    }

    history.push({
      pathname: `/home/assessments/${selectedAssessment.id}/question`,
      state: {
        previousRoute: `/home/assessments/${selectedAssessment.id}/manage`,
      },
    });
  };

  const handleAnalytics = () => {
    if (!publishInfo)
      return displayErrorToast("The test has not been published yet");
    if (parseInt(selectedAssessment.created_by) !== id) {
      history.push(`/home/attempt/${publishInfo.publish_code}/analytics`);
    } else {
      history.push(
        `/home/assessments/${selectedAssessment.id}/analytics/${publishInfo.publish_code}`
      );
    }
  };

  const handleShareTest = () => {
    setShowEditPublishDetailsModal(true);
  };

  const handleCopyCode = () => {
    const success = copy(publishInfo.publish_code);
    if (!success) {
      displayErrorToast("Failed to copy Code");
      return;
    }
    displaySuccessToast("Code copied to the clipboard");
  };

  let listSections: any[] = [];
  selectedAssessment.sections.forEach((sectionId, idx) => {
    let section = sectionMap.get(sectionId);
    if (section!!) {
      listSections.push(
        <Col xs={12} md={6} lg={4} xl={3} className="pe-2 ps-0">
          <SectionCard
            section={section}
            onClick={() => handleQuestionScreen(idx)}
          />
        </Col>
      );
    }
  });

  if (isLoading) return <Loading />;
  return (
    <div className="w-100">
      <ManageAssessmentNavBar
        assessmentName={selectedAssessment.name}
        setShowEditAssessmentNameModal={setShowEditAssessmentNameModal}
        setShowDeleteAssessmentModal={setShowDeleteAssessmentModal}
        setShowEditInstructionsModal={setShowEditInstructionsModal}
        setShowPrintAssessmentModal={setShowPrintAssessmentModal}
        handleOpenTest={handleQuestionScreen}
      />
      <div
        className="px-2 px-sm-4 px-lg-5 py-2 pt-4 d-flex justify-content-between flex-column"
        style={{ height: "92%" }}
      >
        <div>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <Typography fontWeight={"bold"} variant={"h4"}>
              Sections
            </Typography>
            <div className="d-flex align-items-center">
              {!isLoading && publishInfo ? (
                <Typography
                  onClick={handleCopyCode}
                  className={Styles.publishCodeStyle}
                >
                  {publishInfo.publish_code}
                </Typography>
              ) : (
                <div></div>
              )}
              <Button
                className="rounded-3 text-white "
                style={{
                  backgroundColor: "#00ADB5",
                }}
                onClick={handleShareTest}
              >
                Publish
              </Button>
            </div>
          </div>
          <Divider />
          <div className="pt-3">
            <Button
              startIcon={<AddCircleIcon />}
              className="rounded-3 text-white"
              style={{ backgroundColor: "#00ADB5" }}
              onClick={() => setShowCreateSectionModal(true)}
            >
              Add Section
            </Button>
            <Button
              startIcon={<MenuIcon />}
              style={{ backgroundColor: "#00ADB5" }}
              className="rounded-3 ms-2 text-white"
              onClick={() => setShowReorderModal(true)}
            >
              Reorder Sections
            </Button>
          </div>
          <Row className="d-flex flex-row mt-5 mx-0">{listSections}</Row>
        </div>
        {isDesktopOrLaptop && (
          <div className="d-flex justify-content-end py-4">
            <Button
              startIcon={<InsightsIcon />}
              className="rounded-3 text-white me-2"
              style={{
                backgroundColor:
                  !publishInfo || disableAnalytics(publishInfo?.format)
                    ? "grey"
                    : "#292F4D",
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
              disabled={!publishInfo || disableAnalytics(publishInfo?.format)}
              onClick={() => {
                handleAnalytics();
              }}
            >
              View Analytics
            </Button>
            <Button
              className="rounded-3 text-white ms-2"
              style={{
                backgroundColor: "#00ADB5",
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
              onClick={() => handleQuestionScreen()}
            >
              View/Edit Questions
            </Button>
          </div>
        )}
      </div>
      {!isDesktopOrLaptop && (
        <ManageAssessmentBottomNavigation
          handleAnalytics={handleAnalytics}
          handleQuestionScreen={handleQuestionScreen}
          handleShareTest={handleShareTest}
          disableAnalytics={
            !publishInfo || disableAnalytics(publishInfo?.format)
          }
        />
      )}
      <ConfirmationModal
        showModal={showDeleteAssessmentModal}
        setShowModal={setShowDeleteAssessmentModal}
        onConfirm={() => handleAssignmentDelete()}
        modalText="Are you sure you want to delete this Assessment?"
        modalTitle="Delete Assessment"
        confirmText="Delete Assessment"
      />
      <PrintPDFModal
        showPrintAssessmentModal={showPrintAssessmentModal}
        setShowPrintAssessmentModal={setShowPrintAssessmentModal}
      />
      <EditAssessmentNameModal
        showEditAssessmentModal={showEditAssessmentNameModal}
        setShowEditAssessmentModal={setShowEditAssessmentNameModal}
      />
      <EditInstructionsModal
        showEditInstructionsModal={showEditInstructionsModal}
        setShowEditInstructionsModal={setShowEditInstructionsModal}
      />
      <ReorderSectionModal
        showModal={showReorderModal}
        setShowModal={setShowReorderModal}
        sections={[...selectedAssessment.sections]}
      />
      <CreateNewSectionModal
        showCreateSectionModal={showCreateSectionModal}
        setShowCreateSectionModal={setShowCreateSectionModal}
        isEdit={false}
        sectionName={""}
        sectionId={-1}
      />
      <PublishTestModal
        assessmentId={selectedAssessment.id}
        assessmentName={selectedAssessment.name}
        showEditPublishDetailsModal={showEditPublishDetailsModal}
        setShowEditPublishDetailsModal={setShowEditPublishDetailsModal}
        publishId={publishInfo?.id}
        batchId={publishInfo?.batch?.id}
        publishType={publishInfo?.access_level}
        startTime={publishInfo?.start_time}
        endTime={publishInfo?.end_time}
        batchName={publishInfo?.batch?.name}
        publishStatus={publishInfo?.status}
        format={publishInfo?.format}
        duration={publishInfo?.duration}
        onUpdate={(res: any) => {
          const success = copy(
            `https://app.quriate.com/home/attempt/${res.publish_code}/instructions`
          );
          if (!success) return;
          displaySuccessToast(
            "Link to the published assessment was copied to the clipboard"
          );
          setForceReload(!forceReload);
        }}
      />
    </div>
  );
};

export default ManageAssessmentScreen;
