import { useMediaQuery } from "@mui/material";
import { CSSProperties, ReactNode } from "react";
import { Button as BootstrapButton, Spinner } from "react-bootstrap";

type TProps = {
  onClick?: any;
  style?: CSSProperties | Object;
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
};

export default function CustomButton({
  onClick,
  style,
  className,
  children,
  disabled = false,
  loading = false,
}: TProps) {
  const isMobile = useMediaQuery("(max-width: 576px)");

  if (isMobile)
    return (
      <BootstrapButton
        className={`bg-secondary mt-2 py-1 px-3 ${className}`}
        size="sm"
        style={{
          fontWeight: "400",
          fontSize: "80%",
          color: "white",
          border: "none",
          borderRadius: "10px",
          ...style,
        }}
        onClick={onClick}
        disabled={loading || disabled || false}
      >
        {loading ? <Spinner animation="border" /> : children}
      </BootstrapButton>
    );

  return (
    <BootstrapButton
      className={`bg-secondary mt-2 px-4 ${className}`}
      size="lg"
      style={{
        fontSize: "100%",
        color: "white",
        border: "none",
        borderRadius: "10px",
        ...style,
      }}
      onClick={onClick}
      disabled={loading || disabled || false}
    >
      {loading ? <Spinner animation="border" /> : children}
    </BootstrapButton>
  );
}
