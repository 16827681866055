import { useContext, Dispatch, SetStateAction, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import styles from "../../../batch-management/batch-management.module.scss";
import { AppContext, TAppContext } from "../../../../context/AppContext";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../context/AssessmentContext";
import { displaySuccessToast } from "../../../../utils/ToastUtils";
import { Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { patchAssessment } from "../../../../api/assessment";

type TProps = {
  showEditInstructionsModal: boolean;
  setShowEditInstructionsModal: Dispatch<SetStateAction<boolean>>;
};

export default function EditInstructionsModal(props: TProps) {
  const { showEditInstructionsModal, setShowEditInstructionsModal } = props;

  const { selectedAssessment, setSelectedAssessment } =
    useContext<TAssessmentContext>(AssessmentContext);
  const { accessToken } = useContext<TAppContext>(AppContext);

  let schema = yup.object().shape({});

  const updateInstructions = async (
    accessToken: string,
    instructions: string
  ) => {
    const updatedAssessmentResponse = await patchAssessment(
      accessToken,
      selectedAssessment.id,
      undefined,
      undefined,
      undefined,
      undefined,
      instructions
    );
    if (updatedAssessmentResponse === null) return;

    setSelectedAssessment(updatedAssessmentResponse);
    displaySuccessToast("Instructions Updated Successfully");
    setShowEditInstructionsModal(false);
  };

  return (
    <Modal
      size="xl"
      contentClassName={styles.modalStyle}
      show={showEditInstructionsModal}
      onHide={() => setShowEditInstructionsModal(false)}
      centered
    >
      <Modal.Header>
        <Typography variant="h4">
          <b>Edit Instructions</b>
        </Typography>
        <IconButton>
          <CloseIcon onClick={() => setShowEditInstructionsModal(false)} />
        </IconButton>
      </Modal.Header>
      <Modal.Body className="form-group ms-2">
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            updateInstructions(accessToken, values.sectionInstructions);
          }}
          initialValues={{
            sectionInstructions: selectedAssessment.instructions,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            isSubmitting,
          }) => (
            <Form
              noValidate
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <Form.Group controlId="sectionInstructions">
                <Form.Label>
                  <Typography fontSize={20} color="#9AA0B2">
                    <b>Instructions:</b>
                  </Typography>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  value={values.sectionInstructions}
                  placeholder=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="ml-2 bg-light"
                  style={{ height: "60vh" }}
                  isInvalid={!!errors.sectionInstructions}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.sectionInstructions}
                </Form.Control.Feedback>
              </Form.Group>

              <Modal.Footer>
                <Button
                  className="border-0 rounded-2 bg-secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Update Instructions
                </Button>{" "}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
