import { Dispatch, SetStateAction } from "react";
import {
  IquestionData,
  IsectionInfo,
  TAssessmentData,
} from "../models/assessmentmodel";

export const handleOnClickNext = (
  selectedAssessment: TAssessmentData,
  currentQuestionData: IquestionData,
  sectionMap: Map<number, IsectionInfo>,
  currentQuestionNumber: number,
  setCurrentQuestionNumber: Dispatch<SetStateAction<number>>,
  currentSectionNumber: number,
  setCurrentSectionNumber: Dispatch<SetStateAction<number>>
) => {
  /**
   * Returns a boolean suggesting if we were successful to move to next question or not
   */

  const lastQuestionId = findLastQuestionId(selectedAssessment, sectionMap);

  // Return early if the current question is the last question
  if (currentQuestionData.question_id === lastQuestionId) return false;

  let currentSection = sectionMap.get(
    selectedAssessment.sections[currentSectionNumber]
  );

  // If the current question is not the last question of its section, then current question + 1
  // will be the new question number and section won't change
  if (currentQuestionNumber !== currentSection!.question_ids.length - 1) {
    setCurrentQuestionNumber(currentQuestionNumber + 1);
    return true;
  }

  // If it is the last question in the current section, find the next non-empty section
  let updatedSectionNumber = currentSectionNumber + 1;
  while (updatedSectionNumber < selectedAssessment.sections.length) {
    let section = sectionMap.get(
      selectedAssessment.sections[updatedSectionNumber]
    );
    if (section!.question_ids.length !== 0) break;
    updatedSectionNumber++;
  }

  // Once the next non-empty section is found, set the current section to that value and
  // current question number to 0
  setCurrentQuestionNumber(0);
  setCurrentSectionNumber(updatedSectionNumber);
  return true;
};

export const handleOnClickPrevious = (
  selectedAssessment: TAssessmentData,
  currentQuestionData: IquestionData,
  sectionMap: Map<number, IsectionInfo>,
  currentQuestionNumber: number,
  setCurrentQuestionNumber: Dispatch<SetStateAction<number>>,
  currentSectionNumber: number,
  setCurrentSectionNumber: Dispatch<SetStateAction<number>>
) => {
  /**
   * Returns a boolean suggesting if we were successful to move to previous question or not
   */

  const firstQuestionId = findFirstQuestionId(selectedAssessment, sectionMap);

  // return early if the current question is the first question
  if (currentQuestionData.question_id === firstQuestionId) return false;

  // If the current question is not the first question of its section, then current question - 1
  // will be the new question number and section won't change
  if (currentQuestionNumber !== 0) {
    setCurrentQuestionNumber(currentQuestionNumber - 1);
    return true;
  }

  // If it is the first question in the current section, find the previous non-empty section
  let section;
  let updatedSectionNumber = currentSectionNumber - 1;
  while (updatedSectionNumber >= 0) {
    section = sectionMap.get(selectedAssessment.sections[updatedSectionNumber]);
    if (section!.question_ids.length !== 0) break;
    updatedSectionNumber--;
  }

  // Once the previous non-empty section is found, set the current section to that value and
  // current question number to 0
  setCurrentQuestionNumber(section!.question_ids.length - 1);
  setCurrentSectionNumber(updatedSectionNumber);
  return true;
};

export const findLastQuestionId = (
  selectedAssessment: TAssessmentData,
  sectionMap: Map<number, IsectionInfo>
) => {
  let updatedSectionNumber = selectedAssessment.sections.length - 1;
  while (updatedSectionNumber >= 0) {
    let section = sectionMap.get(
      selectedAssessment.sections[updatedSectionNumber]
    );
    if (section!.question_ids.length > 0) {
      return section!.question_ids[section!.question_ids.length - 1]!!;
    }
    updatedSectionNumber--;
  }
  return 0;
};

export const findFirstQuestionId = (
  selectedAssessment: TAssessmentData,
  sectionMap: Map<number, IsectionInfo>
) => {
  let updatedSectionNumber = 0;
  while (updatedSectionNumber < selectedAssessment.sections.length) {
    let section = sectionMap.get(
      selectedAssessment.sections[updatedSectionNumber]
    );
    if (section!.question_ids.length > 0) {
      return section!.question_ids[0];
    }
    updatedSectionNumber++;
  }
  return 0;
};

export const findFirstSectionNumber = (
  selectedAssessment: TAssessmentData,
  sectionMap: Map<number, IsectionInfo>
) => {
  let updatedSectionNumber = 0;
  while (updatedSectionNumber < selectedAssessment.sections.length) {
    let section = sectionMap.get(
      selectedAssessment.sections[updatedSectionNumber]
    );
    if (section === null || section === undefined) {
      updatedSectionNumber++;
      continue;
    }
    if (section!.question_ids.length > 0) {
      return updatedSectionNumber;
    }
    updatedSectionNumber++;
  }
  return 0;
};
