import React, { Dispatch, SetStateAction, useContext } from "react";
import { Modal } from "react-bootstrap";
import { Button, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useHistory } from "react-router-dom";

import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../context/AssessmentContext";

import styles from "../../../batch-management/batch-management.module.scss";
import PrintIcon from "@mui/icons-material/Print";

type TProps = {
  showPrintAssessmentModal: boolean;
  setShowPrintAssessmentModal: Dispatch<SetStateAction<boolean>>;
};

export default function PrintPDFModal(props: TProps) {
  const history = useHistory();
  const { selectedAssessment } =
    useContext<TAssessmentContext>(AssessmentContext);
  const [printingOptions, setPrintingOptions] = React.useState({
    printPaperWithAns: false,
    printPaperWithSolution: false,
  });
  const handleChange = (event) => {
    setPrintingOptions({
      ...printingOptions,
      [event.target.name]: event.target.checked,
    });
  };

  const { printPaperWithAns, printPaperWithSolution } = printingOptions;
  const handleClick = () => {
    history.push({
      pathname: `/home/assessments/${selectedAssessment.id}/print`,
      state: {
        optionsSelected: printingOptions,
      },
    });
  };
  return (
    <Modal
      contentClassName={styles.modalStyle}
      show={props.showPrintAssessmentModal}
      onHide={() => props.setShowPrintAssessmentModal(false)}
      centered
    >
      <Modal.Header>
        <Typography variant="h4">
          <b>Print PDF Paper</b>
        </Typography>
        <IconButton>
          <CloseIcon onClick={() => props.setShowPrintAssessmentModal(false)} />
        </IconButton>
      </Modal.Header>
      <Box sx={{ display: "flex" }}>
        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
          <FormLabel component="legend">Choose The Options</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={printPaperWithAns}
                  onChange={handleChange}
                  name="printPaperWithAns"
                />
              }
              label="Include Answer"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={printPaperWithSolution}
                  onChange={handleChange}
                  name="printPaperWithSolution"
                />
              }
              label="Include Solution"
            />
          </FormGroup>
        </FormControl>
      </Box>
      <Button
        style={{
          textAlign: "center",
          backgroundColor: "#292F4D",
          color: "white",
          fontSize: "17px",
          fontWeight: "900",
        }}
        startIcon={<PrintIcon />}
        fullWidth={false}
        onClick={handleClick}
      >
        Print Paper
      </Button>
    </Modal>
  );
}
