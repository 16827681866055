import { utc } from "moment";

export function ShareAssessment(testName, publishCode, publishInfo) {
  return `
Test Name: ${testName}
Test Code: ${publishCode}
Start Time: ${utc(publishInfo?.start_time).local().format("HH:mm")} 
End Time: ${utc(publishInfo?.end_time).local().format("HH:mm")} 
        
To attempt the test: 
Click on this link: https://app.quriate.com/home/attempt/${publishCode}/instructions 
(If you are not logged in, it'll first ask you to log in / sign up")

Alternatively,
Login on the app (app.quriate.com) -> Home -> Click on Attempt Test -> Enter the Test Code`;
}

export function InviteBatchlink(currentBatch) {
  return `
To join "${currentBatch?.name}",
1) Open Quriate's App (app.quriate.com) and login (or sign up if you don't have an account)
2) On the home screen, Click on plus-sign button on top left. Choose 'Join Batch'
3) Enter the Batch Code: ${currentBatch.code}
    `;
}
