import axios from "axios";
import { baseURL } from "../utils/constants";
import HTTPStatusCode from "../utils/HTTPStatusCode";
import { displayErrorToast } from "../utils/ToastUtils";

export const loginUserAPICall = async (email: string, password: string) => {
  const encodedEmailValue = encodeURIComponent(email);
  const encodedPasswordValue = encodeURIComponent(password);

  try {
    let response = await axios.post<any>(
      `${baseURL}/api/v1/accounts/login`,
      `username=${encodedEmailValue}&password=${encodedPasswordValue}`,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.log(error);
    switch (error?.response?.status) {
      case HTTPStatusCode.UNAUTHORIZED:
        displayErrorToast(
          "Invalid password. Please enter the correct password."
        );
        break;
      case HTTPStatusCode.NOT_FOUND:
        displayErrorToast(
          "The entered email is not registered with us. Please sign up"
        );
        break;
      default:
        displayErrorToast("Something went wrong. Please try again.");
    }
    return null;
  }
};

export const socialLogin = async (socialToken: string) => {
  try {
    let response = await axios.post<any>(
      `${baseURL}/api/v1/accounts/social`,
      {
        social_token: socialToken,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.log(error);
    switch (error?.response?.status) {
      case HTTPStatusCode.UNAUTHORIZED:
        displayErrorToast(
          "Invalid password. Please enter the correct password."
        );
        break;
      case HTTPStatusCode.NOT_FOUND:
        displayErrorToast("Invalid e-mail. This email is not registered.");
        break;
      default:
        displayErrorToast("Something went wrong. Please try again.");
    }
    return null;
  }
};
