import moment, { Moment } from "moment";
import { EPublishedAssessmentFormat } from "./Enums";

export const getFormattedDateAndTime = (date?: Date | Moment) => {
  return moment(date?.toString()).local().format("DD/MM/YYYY, h:mm a");
};

export const pauseExecution = (delay: number) => {
  return new Promise((res) => setTimeout(res, delay));
};

/**
 * This function formats seconds in MM:SS format
 * and also ensures to add an extra 0 infront if either of minutes or seconds is a single digit
 * @param seconds amount of time in seconds
 * @returns formats time given in seconds to MM:SS format.
 */
export const getFormattedDuration = (seconds) => {
  const duration = moment.duration(seconds * 1000);
  const min = parseInt(duration.asMinutes().toFixed(0));
  const sec = duration.seconds();
  return `${min !== 0 ? (min < 10 ? "0" + min : min) : "00"}:${
    sec !== 0 ? (sec < 10 ? "0" + sec : sec) : "00"
  } min`;
};

export const checkAttemptEnded = async (
  serverTime: Date | Moment | null,
  format: EPublishedAssessmentFormat,
  endTime?: Date,
  startedOn?: Date,
  duration?: number
) => {
  const hasDeadlineEnded: boolean =
    !!endTime && moment.utc(serverTime).isAfter(moment.utc(endTime));
  const hasDurationEnded: boolean =
    !!startedOn &&
    !!duration &&
    moment
      .utc(serverTime)
      .isAfter(moment.utc(startedOn).add(duration, "minutes"));
  switch (format) {
    case EPublishedAssessmentFormat.UNTIMED:
      return false;
    case EPublishedAssessmentFormat.FIXED_DURATION:
      return hasDeadlineEnded || hasDurationEnded;
    case EPublishedAssessmentFormat.FIXED_TIME:
      return hasDeadlineEnded;
    case EPublishedAssessmentFormat.FIXED_DURATION_UNTIMED:
      return hasDurationEnded;
    default: {
      return true;
    }
  }
};
