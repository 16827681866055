// Creates a blank question in the section, updates it with the

import { displayErrorToast } from "../../../../utils/ToastUtils";

import crypto from "crypto";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import {
  IquestionData,
  IsectionInfo,
  TAssessmentData,
} from "../../../../models/assessmentmodel";
import { fetchQuestions, fetchSections } from "../../../../api/assessment";
import {
  getQuestionMap,
  createIDMapFromArray,
} from "../../../instructionScreen/InstructionScreenRequests";
import { fetchComprehensionInAssessment } from "../../../../api/comprehension";
import { TContextProps } from "./ManualQuestionHelpers";

/**
 * Upload a list of files onto S3.
 * For now, we assume that the list contains exactly one file.
 * @param {object} files A dictionary containing a list of files against the key - files
 * @returns {str}        MD5 hash of the file contents
 */
export const uploadFiles = async function (files: any) {
  console.log("Uploading files to S3");
  if (files === undefined || files.length === 0) {
    displayErrorToast("Please select an image first.");
    return null;
  }

  // Assuming only one file will be upload for now.
  // Can extend it to upload multiple files
  const file = files[0];
  const text = await file.text();
  const hash = crypto.createHash("md5").update(text).digest("hex"); // Generate hash from filename
  const extension = "." + file.type.split("/")[1]; // Extract the extension of the image-type file

  const success = await uploadImageToS3(file, hash, hash + extension);
  if (success === null) {
    displayErrorToast("Image upload failed");
    console.log("Image upload failed");
    return null;
  }
  return hash;
};

export const validateMarks = function (marksIfCorrect, marksIfIncorrect) {
  if (isNaN(Number(marksIfCorrect)) || isNaN(Number(marksIfIncorrect))) {
    displayErrorToast("Please enter a valid number for marks.");
    return false;
  }
  return true;
};

export const validateSection = function (sectionMap, sectionId) {
  const selectedSection: IsectionInfo | undefined = sectionMap.get(sectionId);
  if (selectedSection === undefined) {
    console.log("Selected section did not work");
    displayErrorToast("There was an error");
    return false;
  }
  return true;
};

export const validateOrg = function (org) {
  if (org === null) {
    displayErrorToast("There was an error");
    console.log("org details missing");
    return false;
  }
  return true;
};

/**
 * Refetches all data corresponding to an assessment - sections & questions
 * Usually called after some update to an assessment
 * @param accessToken unique access token for user to make any API query
 * @param selectedAssessment details of the currently selected assessment
 * @param selectedSectionId id of the section currently selected
 * @param contextProps contains variables defined in context mostly used to set contextual state variables
 * @returns void
 */
export const refetchData = async function (
  accessToken: string,
  selectedAssessment: TAssessmentData,
  selectedSectionId: number,
  contextProps: TContextProps,
  questionCount = 1
) {
  const assessmentId = selectedAssessment.id;
  const sections = await fetchSections(accessToken, assessmentId);
  if (!sections) return null;
  const sectionMap = createIDMapFromArray(sections);

  const questions = await fetchQuestions(accessToken, assessmentId);
  const comprehensions = await fetchComprehensionInAssessment(
    accessToken,
    assessmentId
  );
  if (!questions || comprehensions === null) return null;
  const questionMap = getQuestionMap(questions);
  contextProps.setSectionMap(sectionMap);
  contextProps.setComprehensionMap(createIDMapFromArray(comprehensions));

  const sectionNumber = selectedAssessment.sections.findIndex(
    (sectionId) => sectionId === selectedSectionId
  );
  const questionNumber =
    sectionMap.get(selectedSectionId)!.question_ids.length - questionCount;
  contextProps.setCurrentSectionNumber(sectionNumber);
  contextProps.setCurrentQuestionNumber(questionNumber);

  // In OMR Screen, if a question is addded without saving the previous questions (and their answers)
  // Refetching data will cause a loss of the selected answers to a question
  // (atleast visually on the screen, in the db, it might have been saved and on reopening, user will get that data again)
  // To avoid that, we set the old question map will all the changes again to the refetched question map
  // so the "unsaved" changes don't disappear on refetching
  contextProps.updatedableQuestionMap?.forEach(
    (question: IquestionData, id: number) => {
      questionMap.set(id, question);
    }
  );
  contextProps.setQuestionMap(questionMap);
  return;
};

// Initialize the S3Client with Amazon Cognito credentials provider and region
const s3 = new S3Client({
  region: process.env.REACT_APP_S3_REGION,
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({
      region: process.env.REACT_APP_S3_REGION,
    }),
    identityPoolId: process.env.REACT_APP_S3_IDENTITY_POOL_ID, // IDENTITY_POOL_ID
  }),
});

export function getS3URL(key) {
  const baseURL: String =
    "https://" +
    process.env.REACT_APP_S3_BUCKET_NAME +
    ".s3." +
    process.env.REACT_APP_S3_REGION +
    ".amazonaws.com/";
  return baseURL + key;
}

// Handler for "PUT"ting an image in an S3 bucket
// A clone of the function in s3utils, with small modifications.
// Modifications:
//  none over new file.
//  The bucket might change.
// TODO see if it should be merged back in.
export async function uploadImageToS3(
  file: any,
  folderName: String,
  fileName: String
) {
  console.log("Trying to upload image of type: ", file.type);
  const fileKey = folderName + "/" + fileName;
  const uploadParams = {
    Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
    Key: fileKey,
    Body: file,
  };

  try {
    await s3.send(new PutObjectCommand(uploadParams));
    return fileKey;
  } catch (err) {
    console.log(err);
    return null;
  }
}
