import { createContext, ReactNode, Dispatch, SetStateAction } from "react";
import { questionInfo } from "../models/assessmentmodel";
import { useStickyState } from "../utils/hooks/useStickyState";

type TProps = {
  children: ReactNode;
};

export type TQuestionContext = {
  assessmentQuestionInfo: Map<number, questionInfo>;
  setAssessmentQuestionInfo: Dispatch<
    SetStateAction<Map<number, questionInfo>>
  >;
};

const initialContext: TQuestionContext = {
  assessmentQuestionInfo: new Map(),
  setAssessmentQuestionInfo: (): void => {},
};

export const QuestionContext = createContext<TQuestionContext>(initialContext);

export const QuestionContextProvider = ({ children }: TProps): JSX.Element => {
  const [assessmentQuestionInfo, setAssessmentQuestionInfo] = useStickyState<
    Map<number, questionInfo>
  >(initialContext.assessmentQuestionInfo, "assessmentQuestionInfo");

  return (
    <QuestionContext.Provider
      value={{
        assessmentQuestionInfo,
        setAssessmentQuestionInfo,
      }}
    >
      {children}
    </QuestionContext.Provider>
  );
};
