import axios from "axios";
import { ClientErrorLog } from "../models/errorLogmodel";
import { baseURL } from "../utils/constants";
import { displayErrorToast } from "../utils/ToastUtils";

export const saveClientErrorLog = async (
  log: string,
  user_id?: number
): Promise<ClientErrorLog | null> => {
  try {
    const response = await axios.post<ClientErrorLog>(
      `${baseURL}/api/v1/client_error_log`,
      {
        log: log,
        time: new Date().toISOString().slice(0, -1),
        ...(user_id !== undefined && { user_id: user_id }),
      },
      {}
    );
    return response.data;
  } catch (error: any) {
    displayErrorToast("Failed to fetch question(s)");
    return null;
  }
};
