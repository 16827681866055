import { useContext, useEffect, useState } from "react";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../context/AssessmentContext";
import { Col, Container, Row } from "react-bootstrap";
import QuestionNavigationPanel from "./Components/QuestionNavigationPanel/QuestionNavigationPanel";
import { EAnswerType, ERole } from "../../utils/Enums";
import { AppContext, TAppContext } from "../../context/AppContext";
import QuestionNavigationDrawer from "./Components/QuestionNavigationPanel/QuestionNavigationDrawer";
import { useMediaQuery } from "@mui/material";
import Header from "./Components/Header/Header";
import QuestionText from "./Components/QuestionText/QuestionText";
import QuestionType from "./Components/QuestionType/QuestionType";
import StudentQuestionButtonPanel from "./Components/QuestionButtonPanel/StudentQuestionButtonPanel";
import {
  QuestionContext,
  TQuestionContext,
} from "../../context/QuestionContext";
import { AttemptContext, TAttemptContext } from "../../context/AttemptContext";
import { setResponse } from "../../api/responses";
import { displayErrorToast } from "../../utils/ToastUtils";
import moment from "moment";
import Loading from "../../components/Loading/Loading";
import { parseMapFromJSON } from "../../utils/hooks/useStickyState";
import { IresponseBackend } from "../../models/responsemodel";
import { useHistory, useRouteMatch } from "react-router-dom";
import Proctor from "../../hoc/Proctor";

export default function StudentQuestionScreen(props) {
  const { proofRead, setProofRead } = props;
  const history = useHistory();
  const { accessToken } = useContext<TAppContext>(AppContext);
  const {
    sectionMap, // List of sections.
    currentQuestionNumber, // The current question number
    setCurrentQuestionNumber,
    setCurrentQuestionData,
    currentSectionNumber, // current section to which question belongs to
    setCurrentSectionNumber,
    currentQuestionData,
    showQNavDrawer,
    setShowQNavDrawer,
    setIsQuestionScreenActive,
  } = useContext<TAssessmentContext>(AssessmentContext); // Get the data from the context
  const { assessmentQuestionInfo, setAssessmentQuestionInfo } =
    useContext<TQuestionContext>(QuestionContext);
  const { currentSession } = useContext<TAttemptContext>(AttemptContext);

  const [loading, setLoading] = useState(false);
  const publishCode = useRouteMatch().params["publishCode"];
  const isDesktopOrLaptop = useMediaQuery("(min-width: 992px)");

  async function handleFirstVisit() {
    let updatedQuestionInfo = new Map(assessmentQuestionInfo);
    const qInfo = updatedQuestionInfo.get(currentQuestionData.question_id)!!;
    qInfo.questionState = EAnswerType.VISITED_UNANSWERED;

    const unpushedAttemptsCopy: Map<number, IresponseBackend> =
      parseMapFromJSON(window.localStorage.getItem("unpushedAttempts")!!);

    const success = await setResponse(
      accessToken,
      currentSession.id,
      qInfo,
      unpushedAttemptsCopy
    );
    if (success) {
      // set the assessmentQuestionInfo with the updatedQuestionInfo
      updatedQuestionInfo.set(currentQuestionData.question_id, qInfo);
      setAssessmentQuestionInfo(updatedQuestionInfo);
    }
  }

  const handleSaveTimeSpentOnQuestion = async (
    questionId,
    startTime,
    endTime
  ) => {
    // A threshold has been set to prevent pushing uneccessary attempts in unpushedAttempts
    // Example: Say the user is on Question 5 in an assessment and closes the assessment.
    // When the user re-opens the same or any other assessment, the currentQuestionNumber will switch to 5 -> 1
    // Which will capture the minimal amount of time spent on question 5 and add an attempt to the unpushedAttempts
    const timeTaken = (endTime - startTime) / 1000;
    if (timeTaken < 1) return;

    // Make a copy of assessmentQuestionInfo, then get the qInfo for the current questionId
    let updatedQuestionInfo = new Map(assessmentQuestionInfo);
    const qInfo = updatedQuestionInfo.get(questionId)!!;
    if (!qInfo) return;

    // If the user is visiting the question for the first time, first set time spent to zero
    if (qInfo.questionTimeSpent === null || isNaN(qInfo.questionTimeSpent))
      qInfo.questionTimeSpent = 0;
    // Increment the time spent on the question
    qInfo.questionTimeSpent += timeTaken;

    // Set the unpushed attempts in the local storage

    const unpushedAttempts: Map<number, IresponseBackend> = parseMapFromJSON(
      window.localStorage.getItem("unpushedAttempts")!!
    );
    const success = await setResponse(
      accessToken,
      currentSession.id,
      qInfo,
      unpushedAttempts
    );
    if (success) {
      // set the assessmentQuestionInfo with the updatedQuestionInfo
      updatedQuestionInfo.set(questionId, qInfo);
      setAssessmentQuestionInfo(updatedQuestionInfo);
      return parseMapFromJSON(
        window.localStorage.getItem("unpushedAttempts")!!
      );
    } else {
      displayErrorToast(
        "Something went wrong, you might want to check your local time or internet connection"
      );
      return;
    }
  };

  // The following useEffect captures the time spent on the current question.
  // Since we are setting state in a cleanup function (which we're ideally not supposed to)
  // We need to pass ```unpushedAttempts``` in the deps array, or while calling the function, the unpushedAttempts
  // array might not contain all the attempts while cleaning up the component.
  // Passing unpushedAttempts in the cleanup function won't cause an infinite loop as after the cleanup function runs,
  // the component will dismount and thus the next useEffect won't be triggered the next time
  useEffect(() => {
    const startTime = moment.now();
    if (
      assessmentQuestionInfo.get(currentQuestionData.question_id)
        ?.questionState === EAnswerType.UNVISITED
    ) {
      handleFirstVisit();
    }
    return () => {
      const isQuestionScreenActive: boolean = parseMapFromJSON(
        window.localStorage.getItem("isQuestionScreenActive")
      );
      if (Boolean(isQuestionScreenActive)) {
        handleSaveTimeSpentOnQuestion(
          currentQuestionData.question_id,
          startTime,
          moment.now()
        );
      }
    };
  }, [currentQuestionData]);

  useEffect(() => {
    setIsQuestionScreenActive(true);

    return () => {
      (async () => {
        setIsQuestionScreenActive(false);
        window.localStorage.setItem("isQuestionScreenActive", "false");
      })();
    };
  }, []);

  return loading ? (
    <Loading />
  ) : (
    // <Proctor>
    <Container
      fluid
      className="p-0 d-flex flex-column align-items-center"
      style={{
        height: "100%",
      }}
    >
      <Row
        className="d-flex flex-row justify-content-center w-100"
        style={{
          height: "84%",
          width: "100vw",
        }}
      >
        <Col
          className="d-flex flex-column mt-2 m-lg-4 h-100 justify-content-center"
          xs={12}
          lg={proofRead ? 12 : 8}
        >
          {/* Header */}
          <Header
            currentQuestionNumber={currentQuestionNumber}
            setCurrentQuestionNumber={setCurrentQuestionNumber}
            currentSectionNumber={currentSectionNumber}
            setCurrentSectionNumber={setCurrentSectionNumber}
            sectionMap={sectionMap}
            proofRead={proofRead}
            setProofRead={setProofRead}
          />

          <div
            style={{
              maxHeight: "100%",
              overflowY: "auto",
              overflowX: "hidden",
            }}
            className="flex-grow-1"
          >
            {/* QuestionText */}
            <QuestionText images={currentQuestionData.body.images}>
              {currentQuestionData.body.text}
            </QuestionText>

            {/* QuestionType */}
            <QuestionType questionType={currentQuestionData.question_type} />
          </div>
        </Col>
        {/* QuestionNavigationPanel */}
        {/* TODO: need to rethink why we're passing around proofread everywhere even where it isn't necessary */}
        {isDesktopOrLaptop && !proofRead ? (
          <QuestionNavigationPanel
            role={ERole.STUDENT}
            proofRead={proofRead}
            sectionMap={sectionMap}
            allQuestionInfo={assessmentQuestionInfo}
          />
        ) : (
          <QuestionNavigationDrawer
            show={showQNavDrawer}
            setShowHandler={setShowQNavDrawer}
            role={ERole.STUDENT}
            sectionMap={sectionMap}
            allQuestionInfo={assessmentQuestionInfo}
            proofRead={proofRead}
          />
        )}
      </Row>
      <Row
        style={{
          height: "8%",
        }}
      >
        <StudentQuestionButtonPanel proofRead={proofRead} />
      </Row>
    </Container>
    // </Proctor>
  );
}
