import { Dispatch, SetStateAction, useContext, useState } from "react";
import { Modal, Row, Form, Button, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { Typography, IconButton } from "@mui/material";
import { AppContext, TAppContext } from "../../../context/AppContext";
import {
  BatchManagementContext,
  TBatchManagementContext,
} from "../../../context/BatchManagementContext";

import { addUsersToBatch } from "../../../api/batches";
import { displaySuccessToast } from "../../../utils/ToastUtils";
import HTTPStatusCode from "../../../utils/HTTPStatusCode";
import { TBatch } from "../../../models/batches";
import styles from "../../my-tests/MyTests.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { BatchUserRole } from "../../../utils/Enums";

type TProps = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  selectedBatch: TBatch;
};

export default function AddUserToBatchModal(props: TProps) {
  const { showModal, setShowModal, selectedBatch } = props;
  const { accessToken } = useContext<TAppContext>(AppContext);
  const { setForceReRender, forceReRender } =
    useContext<TBatchManagementContext>(BatchManagementContext);

  const formSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Please enter an email"),
  });

  const handleAddStudentSubmit = async (values: any) => {
    const requestBody: Array<Object> = [
      { email: values.email, role: BatchUserRole.STUDENT },
    ];

    const status = await addUsersToBatch(
      accessToken,
      selectedBatch.code,
      requestBody
    );
    if (status === HTTPStatusCode.OK) {
      setForceReRender(!forceReRender);
      displaySuccessToast("Student added successfully!");
    }
  };

  return (
    <Modal
      contentClassName={styles.modalStyle}
      show={showModal}
      onHide={() => setShowModal(false)}
      centered
    >
      <Modal.Header>
        <Typography variant="h4">
          <b>Add User to Batch</b>
        </Typography>
        <IconButton>
          <CloseIcon onClick={() => setShowModal(false)} />
        </IconButton>
      </Modal.Header>

      <div>
        <Formik
          validationSchema={formSchema}
          onSubmit={(values) => {
            handleAddStudentSubmit(values);
            setShowModal(false);
          }}
          initialValues={{
            email: "",
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
            <Form
              className="mt-2"
              noValidate
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <Modal.Body>
                <Typography fontSize={20}>
                  <b>Add Details</b>
                </Typography>
                <Row>
                  <Col className="col-4">
                    <Typography className="mt-1" fontSize={20} color="#9AA0B2">
                      <b>Email:</b>
                    </Typography>
                  </Col>
                  <Col>
                    <Form.Group controlId="email">
                      <Form.Control
                        type="student email"
                        value={values.email}
                        placeholder="abc@xyz.com"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="ml-2"
                        isInvalid={!!errors.email}
                        style={{ backgroundColor: "#DBDFF1" }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  className="mt-1 rounded-4"
                  block
                  size="lg"
                  type="submit"
                  style={{ color: "white" }}
                >
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
