import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { Button } from "react-bootstrap";
import { displaySuccessToast, displayErrorToast } from "../../utils/ToastUtils";
import { AppContext, TAppContext } from "../../context/AppContext";
import { Link, Redirect, useHistory } from "react-router-dom";
import { fetchUser } from "../../api/user";
import { sendVerificationEmail } from "../../api/accounts";
import { EAuthMode } from "./Components/AuthScreen";

// We need to secure this route, ideally no person should just enter this route in the URL bar.
// And hence, this shouldn't open if email is "".

interface VerifyEmailProps {
  setAuthMode?: Dispatch<SetStateAction<EAuthMode>>;
}

export default function VerifyEmailPlaceholder(props: VerifyEmailProps) {
  const history = useHistory();
  const { email, accessToken, showLoginModal } =
    useContext<TAppContext>(AppContext);
  const { setIsEmailVerified, setIsLoggedIn } =
    useContext<TAppContext>(AppContext);

  const sendVerificationEmailHandler = async () => {
    let response = await sendVerificationEmail(email);
    if (!response) {
      return;
    }
    displaySuccessToast(
      "A verification email was sent to you. Please complete the account verification process."
    );
  };

  const isEmailVerifiedHandler = async () => {
    try {
      const userInfoResponse = await fetchUser(accessToken);
      if (userInfoResponse.is_verified === false) {
        displayErrorToast(
          "Your email is not verified yet. Please make sure to click on the link sent to your email and try again."
        );
        return;
      }

      setIsEmailVerified(true);
      setIsLoggedIn(true);
      displaySuccessToast("Email Verification Successful");

      if (props.setAuthMode === undefined) {
        history.push("/home");
      }
      return;
    } catch (error) {
      displayErrorToast();
    }
  };

  // Send a Verification Email whenever this modal is mounted
  useEffect(() => {
    sendVerificationEmailHandler();
  }, []);

  if (!email) return <Redirect to="/login" />;
  return (
    <div className="container d-flex justify-content-center w-100 p-0">
      <div
        className={
          "col-sm-8 col-md-8 col-lg-6 col-11 " +
          (showLoginModal ? "w-100" : null)
        }
      >
        <div
          className="login shadow-lg rounded-2"
          style={{
            marginTop: showLoginModal ? 0 : "10%",
            paddingLeft: "4%",
            paddingRight: "4%",
            paddingTop: "4%",
            paddingBottom: "4%",
            backgroundColor: "#eeeeee",
          }}
        >
          <h2 className="d-flex justify-content-center">
            <strong>Verify your email</strong>
          </h2>
          <hr className="mt-1 text-primary" style={{ width: "100%" }} />
          <ul>
            <li>
              We've sent a verification email on {email}. Make sure your inbox
              is not full or please check your SPAM folder if you don't see it
              in your inbox.
            </li>
            <li>
              If you are still unable to find the email , please try login in
              through Google, or reach out to us at quriate@gmail.com{" "}
            </li>
            <li>Please click on the link sent to verify your email address.</li>
            <li>Once verified, click on Continue to proceed.</li>
          </ul>
          <div className="text-center">
            <Button
              variant="secondary"
              className="row-md-6 mt-0 mx-3 rounded-2"
              block
              size="lg"
              type="button"
              style={{ color: "white" }}
              onClick={isEmailVerifiedHandler}
            >
              Continue
            </Button>
            <div className="mt-2 d-flex justify-content-center">
              <div>
                Haven't received any email?&nbsp;
                <Link to="#" onClick={sendVerificationEmailHandler}>
                  Resend
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
