import moment from "moment";
import { CircularProgress, LinearProgress, Typography } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import { AppContext, TAppContext } from "../../../context/AppContext";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../context/AssessmentContext";
import {
  AttemptContext,
  TAttemptContext,
} from "../../../context/AttemptContext";
import {
  QuestionContext,
  TQuestionContext,
} from "../../../context/QuestionContext";

import {
  EAssessmentFormat,
  EAssessmentType,
  EPublishedAssessmentFormat,
  ERole,
} from "../../../utils/Enums";
import { findFirstSectionNumber } from "../../../utils/QuestionNavigation";
import {
  createIDMapFromArray,
  evaluateLimits,
  getQuestionMap,
  getSession,
  storeAndFetchResponses,
} from "../InstructionScreenRequests";
import {
  getFormattedDateAndTime,
  checkAttemptEnded,
} from "../../../utils/dateUtils";
import CustomButton from "../../../components/CustomComponent/CustomButton";
import { fetchPublishedAssessmentData } from "../../../api/assessment";
import EditRollModal from "../Component/EditRollModal";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { getServerTime } from "../../../api/user";
import { displayErrorToast } from "../../../utils/ToastUtils";
import { TAttempt, TSession } from "../../../models/responsemodel";
import { fetchComprehensionInAssessment } from "../../../api/comprehension";
import { IsectionInfo } from "../../../models/assessmentmodel";
import {
  parseJSONFromMap,
  parseMapFromJSON,
} from "../../../utils/hooks/useStickyState";
import { saveResponses } from "../../../api/responses";
import { disableAnalytics } from "../../../utils/analyticsUtils";

export default function AttemptInstructionScreen() {
  const history = useHistory();

  const { accessToken, setRole, id } = useContext<TAppContext>(AppContext);
  const {
    selectedAssessment,
    setSelectedAssessment,
    publishInfo,
    setPublishInfo,
    setSectionMap,
    setCurrentQuestionNumber,
    setCurrentSectionNumber,
    questionMap,
    sectionMap,
    setQuestionMap,
    setLimits,
    setComprehensionMap,
  } = useContext<TAssessmentContext>(AssessmentContext); // Get the data from the context
  const { setAssessmentQuestionInfo } =
    useContext<TQuestionContext>(QuestionContext);
  const {
    currentSession,
    setCurrentSession,
    setCurrentAttempt,
    currentAttempt,
    clearAttemptContext,
  } = useContext<TAttemptContext>(AttemptContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [alreadyAttempted, setAlreadyAttempted] = useState<boolean>(false);
  const [hasFinishedAttempt, setHasFinishedAttempt] = useState<boolean>(false);
  const [hasAssessmentStarted, setHasAssessmentStarted] =
    useState<boolean>(false);
  const [showEditRollModal, setShowEditRollModal] = useState<boolean>(false);
  const [redirectToQuestionScreen, setRedirectToQuestionScreen] =
    useState<boolean>(false);
  const [serverTime, setServerTime] = useState<any>();

  const publishCode = useRouteMatch().params["publishCode"];
  const randomTime = Math.floor(Math.random() * 10000); // Additional random time between 1 and 20 secs
  const [hasDeadlinePassed, setHasDeadlinePassed] = useState<boolean>(false);
  const fetchAttemptorData = async function () {
    const assessmentData = await fetchPublishedAssessmentData(
      accessToken,
      publishCode
    );

    if (!assessmentData) {
      displayErrorToast("Couldn't fetch assessment. Please try again.");
      return history.replace("/home/dashboard");
    }
    if (parseInt(assessmentData.assessment.created_by) !== id)
      setRole(ERole.STUDENT);
    else {
      displayErrorToast(
        "You cannot attempt this test since you are the creator"
      );
      return history.replace(`/home/dashboard`);
    }

    const serverTime = await getServerTime(accessToken);
    setServerTime(serverTime);

    setSelectedAssessment(assessmentData.assessment);
    setPublishInfo(assessmentData.published_assessment);
    setHasAssessmentStarted(
      moment
        .utc(serverTime)
        .isSameOrAfter(
          moment.utc(assessmentData.published_assessment.start_time)
        )
    );

    if (
      moment
        .utc(serverTime)
        .isBefore(moment.utc(assessmentData.published_assessment.start_time))
    ) {
      return assessmentData;
    }

    let updatedQuestionMap = getQuestionMap(assessmentData.questions);
    setQuestionMap(updatedQuestionMap);

    const handleSetQInfo = async (val) => {
      let limits = evaluateLimits(
        assessmentData.sections,
        updatedQuestionMap,
        setQuestionMap,
        val
      );
      setLimits(limits);
      setAssessmentQuestionInfo(val);
    };

    const comprehension = await fetchComprehensionInAssessment(
      accessToken,
      assessmentData.assessment.id
    );
    setComprehensionMap(createIDMapFromArray(comprehension));

    setSectionMap(createIDMapFromArray(assessmentData.sections));

    const firstSectionNumber = findFirstSectionNumber(
      assessmentData.assessment,
      createIDMapFromArray(assessmentData.sections)
    );
    setCurrentSectionNumber(firstSectionNumber);
    setCurrentQuestionNumber(0);

    const publishId = assessmentData.published_assessment.id;

    setHasDeadlinePassed(
      !!assessmentData.published_assessment?.end_time &&
        moment
          .utc(serverTime)
          .isAfter(moment.utc(assessmentData.published_assessment?.end_time))
    );
    const newSession = await getSession(
      accessToken,
      publishCode,
      setAlreadyAttempted,
      setCurrentSession,
      false
    );
    if (newSession) {
      const currentAttempt: TAttempt = {
        id: newSession.attempt_id,
        published_assessment_id: publishId,
        last_submitted_on: newSession.last_submitted_on,
        metadata: newSession.attempt_metadata,
        sessions: Array<TSession>(1).fill({
          id: newSession.session_id,
          attempt_id: newSession.attempt_id,
          started_on: newSession.started_on,
          ended_on: newSession.ended_on,
        }),
      };
      setCurrentAttempt(currentAttempt);

      const attemptEnded = await checkAttemptEnded(
        serverTime,
        assessmentData.published_assessment?.format,
        assessmentData.published_assessment?.end_time,
        newSession?.started_on || undefined,
        assessmentData.published_assessment?.duration
      );
      setHasFinishedAttempt(attemptEnded);

      const fetchResponseSuccess = await storeAndFetchResponses(
        accessToken,
        newSession.session_id,
        getQuestionMap(assessmentData.questions),
        handleSetQInfo
      );

      if (!fetchResponseSuccess) {
        displayErrorToast();
        return history.push(`/home/attempt/${publishCode}/instructions`);
      }
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);

      // Clear any unpushed attempts from the previous sessions
      console.log(
        "Checking for any unpushed attempts from previous session..."
      );
      let unpushedAttempts = parseMapFromJSON(
        window.localStorage.getItem("unpushedAttempts")
      );
      if (!!unpushedAttempts && unpushedAttempts.size > 0) {
        console.log(
          "Unpushed attempts from previous session present, Saving those..."
        );
        await saveResponses(accessToken, unpushedAttempts);
      }
      console.log("No unpushed attempts from previous session...");
      window.localStorage.setItem(
        "unpushedAttempts",
        parseJSONFromMap(new Map())
      );

      // Clear the attempt and session details from the context
      clearAttemptContext();

      await fetchAttemptorData();
      setLoading(false);
    })();
  }, [accessToken]);

  const handleQuestionScreen = async () => {
    setRole(ERole.STUDENT);
    if (questionMap.size === 0 || sectionMap.size === 0) {
      return displayErrorToast(
        "This assessment has no questions. Please contact the creator of the test."
      );
    }
    history.push({
      pathname: `/home/attempt/${publishCode}/question`,
      state: {
        hasFinishedAttempt: hasFinishedAttempt,
        randomTime: randomTime,
        previousRoute: `/home/attempt/${publishCode}/instructions`,
      },
    });
  };

  const handleResponseScreen = async () => {
    setRole(ERole.STUDENT);
    if (questionMap.size === 0 || sectionMap.size === 0) {
      return displayErrorToast("This assessment has no questions");
    }
    if (selectedAssessment.format === EAssessmentFormat.NTA)
      history.push(`/home/attempt/${publishCode}/response`);
    else history.push(`/home/attempt/${publishCode}/OMR/response`);
  };

  const handleEditRollModal = () => {
    if (!currentAttempt.metadata.roll_no || !currentAttempt.metadata.name) {
      setRedirectToQuestionScreen(true);
      return setShowEditRollModal(true);
    }
    handleQuestionScreen();
  };

  const handleAnalyticsScreen = async () => {
    history.push(`/home/attempt/${publishCode}/analytics`);
  };

  const handleStartTest = async () => {
    // document.documentElement.requestFullscreen();

    if (currentAttempt.id) {
      handleEditRollModal();
      return;
    }
    const serverTime = await getServerTime(accessToken);
    const newSession = await getSession(
      accessToken,
      publishCode,
      setAlreadyAttempted,
      setCurrentSession
    );
    if (newSession) {
      const currentAttempt: TAttempt = {
        id: newSession.attempt_id,
        published_assessment_id: publishInfo.id,
        last_submitted_on: newSession.last_submitted_on,
        metadata: newSession.attempt_metadata,
        sessions: Array<TSession>(1).fill({
          id: newSession.session_id,
          attempt_id: newSession.attempt_id,
          started_on: newSession.started_on,
          ended_on: newSession.ended_on,
        }),
      };
      setHasDeadlinePassed(
        !!publishInfo.end_time &&
          moment.utc(serverTime).isAfter(moment.utc(publishInfo.end_time))
      );
      setCurrentAttempt(currentAttempt);

      let sectionList: Array<IsectionInfo> = [];

      sectionMap.forEach((section) => sectionList.push(section));

      const handleSetQInfo = async (val) => {
        let limits = evaluateLimits(
          sectionList,
          questionMap,
          setQuestionMap,
          val
        );
        setLimits(limits);
        setAssessmentQuestionInfo(val);
      };

      const fetchResponseSuccess = await storeAndFetchResponses(
        accessToken,
        newSession.session_id,
        questionMap,
        handleSetQInfo
      );

      if (!fetchResponseSuccess) {
        displayErrorToast();
        return history.push(`/home/attempt/${publishCode}/instructions`);
      }
      handleEditRollModal();
    } else if (newSession === 0) {
      setHasFinishedAttempt(true);
    }
  };

  const getAnalyticsAvailablility = () => {
    switch (publishInfo.format) {
      case EPublishedAssessmentFormat.UNTIMED:
        return "Analytics are available";
      case EPublishedAssessmentFormat.FIXED_DURATION_UNTIMED:
        return `Analytics will be available after ${getFormattedDateAndTime(
          moment
            .utc(
              currentAttempt.sessions[currentAttempt.sessions.length - 1]
                .started_on
            )
            .add(publishInfo.duration, "minutes")
        )}`;
      default:
        return `Analytics will be available
        after \n
        ${getFormattedDateAndTime(
          moment.utc(publishInfo.end_time).add(10, "minutes")
        )}
        `;
    }
  };

  const getTestStartTime = () => {
    if (currentAttempt.id === 0) {
      return `${getFormattedDateAndTime(moment.utc(publishInfo.start_time))}`;
    }
    switch (publishInfo.format) {
      case EPublishedAssessmentFormat.FIXED_DURATION_UNTIMED:
        return `${getFormattedDateAndTime(
          moment.utc(
            currentAttempt.sessions[currentAttempt.sessions.length - 1]
              .started_on
          )
        )}`;
      case EPublishedAssessmentFormat.FIXED_DURATION:
        return `${getFormattedDateAndTime(
          moment.utc(
            currentAttempt.sessions[currentAttempt.sessions.length - 1]
              .started_on
          )
        )}`;
      default:
        return `${getFormattedDateAndTime(moment.utc(publishInfo.start_time))}`;
    }
  };

  return (
    <div className="p-3 w-100">
      <div className="d-flex flex-row align-items-center mb-2">
        <IoArrowBackCircleOutline
          className="me-3"
          onClick={() => {
            history.push("/home/dashboard");
          }}
          size={"35px"}
          color="black"
          cursor={"pointer"}
        />
        <h1 className="mb-0">
          {loading ? <CircularProgress /> : selectedAssessment.name}
        </h1>
      </div>
      <h4>
        {!hasAssessmentStarted || currentAttempt.id === 0 ? (
          <></>
        ) : (
          <div>
            <div className="d-flex justify-content-between">
              <div className="d-flex justify-content-start">
                <p style={{ color: "#9AA0B2" }}>Name:</p>&nbsp;
                <p>
                  {loading ? (
                    <CircularProgress />
                  ) : currentAttempt.metadata?.name ? (
                    currentAttempt.metadata.name
                  ) : (
                    ""
                  )}
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="d-flex justify-content-start">
                <p style={{ color: "#9AA0B2" }}>Roll Number:</p>&nbsp;
                <p>
                  {loading ? (
                    <CircularProgress />
                  ) : currentAttempt.metadata?.roll_no ? (
                    currentAttempt.metadata.roll_no
                  ) : (
                    "NA"
                  )}
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="d-flex justify-content-start">
                <p style={{ color: "#9AA0B2" }}>Last Submitted on :</p>&nbsp;
                <p>
                  {loading ? (
                    <CircularProgress />
                  ) : currentAttempt?.last_submitted_on ? (
                    getFormattedDateAndTime(
                      moment.utc(currentAttempt.last_submitted_on)
                    )
                  ) : (
                    "NA"
                  )}
                </p>
              </div>
              <CustomButton
                className="me-md-2"
                disabled={!hasAssessmentStarted}
                onClick={() => {
                  setRedirectToQuestionScreen(false);
                  setShowEditRollModal(true);
                }}
              >
                Edit
              </CustomButton>
            </div>
          </div>
        )}
        <hr />
        <Row>
          <Col md={2} style={{ color: "#9AA0B2" }}>
            Start Time:
          </Col>
          <Col>{getTestStartTime()}</Col>
        </Row>
        <Row>
          <Col md={2} style={{ color: "#9AA0B2" }}>
            Deadline :
          </Col>
          <Col>
            {getFormattedDateAndTime(moment.utc(publishInfo.end_time)) ===
            "Invalid date"
              ? "No Deadline"
              : getFormattedDateAndTime(moment.utc(publishInfo.end_time))}
          </Col>
        </Row>
        <Row>
          <Col md={2} style={{ color: "#9AA0B2" }}>
            Duration :
          </Col>
          <Col>
            {publishInfo.duration
              ? String(publishInfo.duration).concat(" mins")
              : publishInfo.format === EPublishedAssessmentFormat.FIXED_TIME
              ? String(
                  moment
                    .utc(publishInfo.end_time)
                    .diff(moment.utc(publishInfo.start_time), "minutes")
                ).concat(" mins")
              : "No Duration"}
          </Col>
        </Row>
        {!hasAssessmentStarted && (
          <Row className="mt-2 d-flex justify-content-center">
            <div
              className="bg-warning p-2 rounded-4 px-4"
              style={{ textAlign: "center", width: "auto" }}
            >
              <Typography color="red" variant="h6">
                The Test hasn't started yet. Please refresh the page at{" "}
                <br></br>
                {getFormattedDateAndTime(moment.utc(publishInfo.start_time))} to
                start the test.
              </Typography>
            </div>
          </Row>
        )}
        {hasFinishedAttempt && !!publishInfo.end_time && !hasDeadlinePassed ? (
          <Row className="mt-2 d-flex justify-content-center">
            <div
              className="bg-warning p-2 rounded-4 px-4"
              style={{ textAlign: "center", width: "auto" }}
            >
              <Typography color="red" variant="h6">
                You have already attempted the test. Analytics will be available
                at <br></br>
                {getFormattedDateAndTime(
                  moment.utc(publishInfo.end_time).add(10, "minutes")
                )}
              </Typography>
            </div>
          </Row>
        ) : (
          currentAttempt.id !== 0 &&
          !(hasDeadlinePassed && hasFinishedAttempt) && (
            <Row className="mt-2 d-flex justify-content-center">
              <div
                className="bg-warning p-2 rounded-4 px-4"
                style={{ textAlign: "center", width: "auto" }}
              >
                <Typography color="red" variant="h6">
                  {getAnalyticsAvailablility()}
                </Typography>
              </div>
            </Row>
          )
        )}
        {hasFinishedAttempt && hasDeadlinePassed && (
          <Row className="mt-2 d-flex justify-content-center">
            <div
              className="bg-warning p-2 rounded-4 px-4"
              style={{ textAlign: "center", width: "auto" }}
            >
              <Typography color="red" variant="h6">
                The Test has already ended. Analytics will be available at{" "}
                <br></br>
                {getFormattedDateAndTime(
                  moment.utc(publishInfo.end_time).add(10, "minutes")
                )}
              </Typography>
            </div>
          </Row>
        )}
        {hasDeadlinePassed && currentAttempt.id === 0 && (
          <Row className="mt-2 d-flex justify-content-center">
            <div
              className="bg-warning p-2 rounded-4 px-4"
              style={{ textAlign: "center", width: "auto" }}
            >
              <Typography color="red" variant="h6">
                Analytics are not available since the test was not attempted.
                <br></br>
              </Typography>
            </div>
          </Row>
        )}

        {loading && <LinearProgress />}
        <div className="d-flex flex-md-row flex-column justify-content-sm-between">
          <div className="d-flex flex-md-row flex-column justify-content-end align-items-between">
            {/* If assessment has started and attempt hasn't finished yet, display the start test button */}
            {!hasDeadlinePassed && !hasFinishedAttempt && (
              <CustomButton
                disabled={!hasAssessmentStarted}
                onClick={handleStartTest}
              >
                {currentAttempt.id ? "Continue" : "Start"}
              </CustomButton>
            )}

            {/* If assessment has started and If attempt has ended, display analytics and view responses buttons, 
            but they will be disabled based on the publish formats */}
            {hasFinishedAttempt ||
            hasDeadlinePassed ||
            (publishInfo.format === EPublishedAssessmentFormat.UNTIMED &&
              currentAttempt.id) ? (
              <>
                <CustomButton
                  className="me-md-2 mx-2"
                  onClick={handleResponseScreen}
                  disabled={
                    loading ||
                    currentAttempt.id === 0 ||
                    disableAnalytics(
                      publishInfo.format,
                      serverTime,
                      publishInfo.end_time,
                      currentAttempt?.sessions[
                        currentAttempt.sessions.length - 1
                      ].started_on,
                      publishInfo.duration
                    )
                  }
                >
                  View Responses
                </CustomButton>

                <CustomButton
                  onClick={handleAnalyticsScreen}
                  disabled={
                    loading ||
                    currentAttempt.id === 0 ||
                    disableAnalytics(
                      publishInfo.format,
                      serverTime,
                      publishInfo.end_time,
                      currentAttempt?.sessions[
                        currentAttempt.sessions.length - 1
                      ].started_on,
                      publishInfo.duration
                    )
                  }
                >
                  View Analytics
                </CustomButton>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="my-4">Instructions:</div>
      </h4>
      <div
        className="rounded-4 border border-dark w-100 mb-2"
        style={{ minHeight: "200px" }}
      >
        <div style={{ padding: 10 }}>
          {selectedAssessment.instructions.split("\n").map((instruction) => {
            instruction && <li>{instruction}</li>;
          })}
        </div>
      </div>
      <EditRollModal
        show={showEditRollModal}
        setShow={setShowEditRollModal}
        redirectToQuestionScreen={redirectToQuestionScreen}
        rollNo={
          currentAttempt.metadata?.roll_no
            ? currentAttempt.metadata.roll_no
            : ""
        }
        handleQuestionScreen={handleQuestionScreen}
        name={currentAttempt.metadata?.name ? currentAttempt.metadata.name : ""}
      />
    </div>
  );
}
