// material-ui
import { Card, Typography, useMediaQuery } from "@mui/material";
import { FunctionComponent } from "react";

interface AnalyticsCardProps {
  cardTitle: string;
  cardValue?: number | string;
  icon?: JSX.Element;
  iconBgColor?: string;
}

/**
 * Analytics Card Component to render the name and value of a metric
 * @param cardTitle The text `string` that will be rendered as the card title.
 * Name of the analytics metric can be the card title
 * @param cardValue can be a `number` or `string`. cardValue will be rendered as the card value.
 * Value of the analytics metric can be the card value
 * @param icon [Optional]: The Card Icon
 * @param iconBgColor [Optional]: The background color of the Card Icon, On mobile screen.
 * This will also act as the background color of the card
 */
export const AnalyticsCard: FunctionComponent<AnalyticsCardProps> = (props) => {
  const isMobile = useMediaQuery("(max-width: 576px)");

  return isMobile ? (
    <AnalyticsCardMobile {...props} />
  ) : (
    <AnalyticsCardDesktop {...props} />
  );
};

export default AnalyticsCard;

export const AnalyticsCardMobile: FunctionComponent<AnalyticsCardProps> = ({
  cardTitle,
  iconBgColor,
  cardValue,
}) => {
  return (
    <Card
      className="d-flex align-items-center py-2 ps-2 ps-sm-4 rounded-3"
      style={{ border: "2px solid #84868D", background: iconBgColor }}
    >
      <div className="d-flex flex-column ps-2">
        <Typography
          sx={{ color: "black", fontSize: "16px", textTransform: "capitalize" }}
        >
          <b>{cardTitle}</b>
        </Typography>
        <Typography sx={{ color: "grey", mt: 0.25, fontSize: "16px" }}>
          {cardValue}
        </Typography>
      </div>
    </Card>
  );
};

export const AnalyticsCardDesktop: FunctionComponent<AnalyticsCardProps> = ({
  cardTitle,
  cardValue,
  icon,
  iconBgColor,
}) => {
  return (
    <Card
      className="d-flex align-items-center py-2 ps-2 ps-sm-4 rounded-3"
      style={{ border: "2px solid #84868D" }}
    >
      <div
        style={{
          width: "60px",
          height: "60px",
          borderRadius: "50%",
          background: iconBgColor,
        }}
        className="d-flex justify-content-center align-items-center"
      >
        {icon}
      </div>

      <div className="d-flex flex-column ps-2">
        <Typography
          sx={{ color: "black", fontSize: "18px", textTransform: "capitalize" }}
        >
          <b>{cardTitle}</b>
        </Typography>
        <Typography sx={{ color: "grey", mt: 0.25, fontSize: "16px" }}>
          {cardValue}
        </Typography>
      </div>
    </Card>
  );
};
