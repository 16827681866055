import React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import "./../../../css/index.css";
import { Grid } from "@mui/material";
import CustomPagination from "../../../components/Pagination";

export default function StudentQuestionStats(props) {
  const columns = [
    { field: "question_id", headerName: "Question ID", width: 100, hide: true },
    {
      field: "question_index",
      headerName: "QNo.",
      flex: 1,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "student_answer",
      headerName: "Your Answer",
      flex: 2,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "correct_answer",
      headerName: "Correct Answer",
      cellClassName: "super-app-theme--cell",
      flex: 2,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "correct",
      headerName: "% Correct",
      flex: 3,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderCell: (el) => {
        return Math.round(el.value * 100) / 100;
      },
    },
    {
      field: "incorrect",
      headerName: "% Incorrect",
      cellClassName: "super-app-theme--cell",
      flex: 3,
      headerClassName: "super-app-theme--header",
      renderCell: (el) => {
        return Math.round(el.value * 100) / 100;
      },
    },
    {
      field: "unattempted",
      headerName: "% Unattempted",
      flex: 3,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderCell: (el) => {
        return Math.round(el.value * 100) / 100;
      },
    },
  ];

  const [selectedRow, setSelectedRow] = React.useState(0);
  const rows = React.useMemo(
    () => props.data["student_question_stats"],
    [props.data["student_question_stats"]]
  );

  const mapQuestionIDRowIndex = {};
  for (var i = 0; i < props.data["student_question_stats"].length; i++) {
    mapQuestionIDRowIndex[props.data["student_question_stats"][i].question_id] =
      i;
  }

  return (
    <Grid
      container
      className="pt-2 px-2 my-0 d-flex align-items-center pb-md-4 pb-0"
    >
      <Grid item md={12} xs={12} className="overflow-auto">
        <Box
          sx={{
            height: "70vh",
            "& .super-app-theme--header": {
              backgroundColor: "#fff",
              color: "#7F828A",
              fontSize: "16px",
              fontWeight: "700",
              border: "0",
            },
            "& .super-app-theme--cell": {
              fontSize: "16px",
              border: "0",
            },
          }}
        >
          <DataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: "question_index", sort: "asc" }],
              },
            }}
            pageSize={10}
            rowsPerPageOptions={[10]}
            components={{
              Pagination: CustomPagination,
            }}
            getRowId={(r) => r.question_id}
            rows={rows}
            columns={columns}
            onRowClick={(i) => {
              setSelectedRow(mapQuestionIDRowIndex[i.id]);
            }}
            style={{ border: "0" }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
