import { MetricTypes } from "../utils/Enums";

export type TScalarMetric = {
  name: string;
  data: string | number;
  type: MetricTypes;
};

export type TSingleDimensional = {
  name: string;
  data: Array<string | number>;
  type: MetricTypes;
};

export type TMultiDimension = {
  name: string;
  data: Array<Array<string | number>>;
  labels: Array<string>;
  type: MetricTypes;
};

export type TTabular = {
  name: string;
  data: string; // JSON represntation of data
  type: MetricTypes;
};

export type TLeaderboard = {
  name: string;
  roll_no: string;
  score: string;
  user_id: string;
};

export type TCSVObject = {
  name: string;
  data: string;
  type: MetricTypes;
};

export interface TAnalyticsOverview {
  number_of_students: TScalarMetric;
  number_of_questions: TScalarMetric;
  mean_score: TScalarMetric;
  median_score: TScalarMetric;
  highest_score: TScalarMetric;
  leaderboard: Array<TLeaderboard>;
}

export interface TAnalyticsStudentOverview extends TAnalyticsOverview {
  student_rank: TScalarMetric;
  student_score: TScalarMetric;
  student_percentile: TScalarMetric;
}

export interface TAnalyticsTeacherOverview extends TAnalyticsOverview {}

export interface TAnalyticsTimed {
  time_distribution: any[];
  speed_analysis: TMultiDimension;
  time_spent_per_difficulty: any[];
}

export interface TAnalyticsStudentTimed extends TAnalyticsTimed {}

export interface TAnalyticsTeacherTimed extends TAnalyticsTimed {}

export interface TAnalyticsAllStudentsStatistics {
  student_stats: TTabular;
}

export type TAnalyticsStudentQuestion = {
  accuracy: any[];
  accuracy_per_difficulty: any[];
  accuracy_level_with_time: TMultiDimension;
};

export type TAnalyticsCreatorQuestionStats = {
  question_stats: TScalarMetric;
};

export type TAnalyticsAttemptorQuestionStats = {
  student_question_stats: TScalarMetric;
};

export type THighlight = {
  name: string;
  type: ERecommendationType;
  message: string;
  valid: boolean;
  sense: ERecommendationSense;
  ids: Array<number>;
};

export type THighlightObject = Map<string, THighlight>;

export enum ERecommendationType {
  QUESTION = 0,
  STUDENT = 1,
}

export enum ERecommendationSense {
  POSITIVE = 0,
  NEGATIVE = 1,
}

export type TSalesAnalyticsOverviewData = {
  number_of_users_added_recently: TScalarMetric;
  number_of_recent_responses: TScalarMetric;
  number_of_active_users: TScalarMetric;
  leaderboard: Array<TLeaderboard>;
  number_of_users_added_over_time: TMultiDimension;
};

export type TSalesStudentStatisticsData = {
  student_stats: Array<TSalesStudentStat>
};

export type TSalesStudentStat = {
  user_id: string;
  name: string;
  email: string;
  phone: string;
  correct: number;
  incorrect: number;
};
