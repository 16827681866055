import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import "./styles.scss";
import ReactComponent from "./Extension";

type TProps = {
  idx?: number;
  text: string;
  editMode?: boolean;
  onChange?: (idx: number, content: string) => void;
  setCursorPosition?: (cursorPosition: number) => void;
} & typeof defProps;

// Initialize certain props as their default values are required down the line
const defProps = {
  img: [],
  editMode: false,
};

export default function TipTap(props: TProps) {
  const content = latexToHTML(props.text);

  function latexToHTML(latex: string) {
    if (!latex) {
      return "";
    }
    // TipTap editor needs its input to be in HTML format. This function converts Latex to HTML
    const html: string = latex
      // Ex: Say we have the following Latex:
      // Einstein's equation is: <br>\\(E=mc^2$\\)
      .replaceAll("\\[", `<math-component editmode=${props.editMode} math="$$`)
      .replaceAll("\\(", `<math-component editmode=${props.editMode} math="$`)
      // Convert all the opening delimiters of latex to <math-component> opening tags along with 2 attributes:
      // 1) math: It will contain the latex to be rendered.
      // 2) editmode: It is an indicator whether the TipTap is in edit or display mode
      // Einstein's equation is: <br><math-component editmode=${props.editMode} math="$E=mc^2\\)
      .replaceAll("\\]", '$$"></math-component>')
      .replaceAll("\\)", '$"></math-component>');
    // Convert all the closing delimiters of latex with the closing tag of math-component
    // Einstein's equation is: <br><math-component editmode=${props.editMode} math="$E=mc^2$"></math-component>

    return html;
  }

  function latexify(html: string) {
    // TipTap editor returns its content in HTML format. This function converts the TipTap HTML output to Latex
    const latex: string = html
      // Ex: Say TipTap exported the following HTML:
      // <p>Einstein's equation is: </p><p><math-component editMode="true" math="$E=mc^2$></math-component></p>
      .replaceAll(`<math-component editmode="true" math="$$`, "\\[")
      .replaceAll(`<math-component editmode="true" math="$`, "\\(")
      .replaceAll(`<math-component editmode="true" math="`, " \\(")
      // <p>Einstein's equation is: </p><p>\\(E=mc^2$></math-component></p>
      .replaceAll('$$"></math-component>', "\\]")
      .replaceAll('$"></math-component>', "\\)")
      .replaceAll('"></math-component>', "\\)")
      // Convert all the closing tags of the math component to latex delimiter \\)
      // <p>Einstein's equation is: </p><p>\\(E=mc^2\\)</p>
      .replaceAll("&amp;", "&")
      // Convert the HTML specific character entity (&amp;) to Latex (&)
      .slice(3, -4)
      // Remove <p> and </p> tags from the start and end
      // Einstein's equation is: </p><p>\\(E=mc^2\\)
      .replaceAll("</p>", "<br>")
      .replaceAll("<p>", "");
    // Remove the remaining <p></p> tags and replace with <br> for rendering line-breaks
    // Einstein's equation is: <br>\\(E=mc^2\\)

    return latex;
  }

  const editor = useEditor(
    {
      extensions: [StarterKit, ReactComponent],
      content: content,
      onUpdate: ({ editor }) => {
        if (props.onChange && props.idx !== undefined) {
          props.onChange(props.idx, latexify(editor.getHTML()));
        }
      },
      editable: props.editMode || false,
      onFocus: ({ transaction }) => {
        if (props.setCursorPosition) {
          props.setCursorPosition(transaction.selection.$anchor.pos - 1);
        }
      },
      onTransaction: ({ editor, transaction }) => {
        if (props.setCursorPosition && editor.isFocused) {
          props.setCursorPosition(transaction.selection.$anchor.pos - 1);
        }
      },
    },
    [content]
  );

  return <EditorContent editor={editor} className="d-flex flex-grow-1" />;
}

TipTap.defaultProps = defProps;
