import axios from "axios";
import { Dispatch, SetStateAction } from "react";
import { questionInfo } from "../models/assessmentmodel";
import { IresponseBackend } from "../models/responsemodel";
import { handleUnauthorisedException } from "../utils/apiUtils";
import { baseURL } from "../utils/constants";
import { parseJSONFromMap } from "../utils/hooks/useStickyState";
import HTTPStatusCode from "../utils/HTTPStatusCode";
import { displayErrorToast, displaySuccessToast } from "../utils/ToastUtils";

const UPLOAD_THRESH = 5;

export const fetchResponsesMap = async (
  accessToken: string,
  sessionId: number
) => {
  try {
    const responses = await axios.get<any>(
      `${baseURL}/api/v1/response?session_id=${sessionId}`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    let responseMap = new Map();
    for (let i = 0; i < responses.data.length; i++)
      responseMap.set(responses.data[i].question_id, responses.data[i]);
    return responseMap;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    // TODO: Send a better error message
    displayErrorToast("Unable to get the responses");
    console.log(error);
  }
};

export const fetchAllResponses = async (
  accessToken: string,
  publish_code: string
) => {
  try {
    const responses = await axios.get<any>(
      `${baseURL}/api/v1/response/${publish_code}/creator`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return responses.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    // TODO: Send a better error message
    displayErrorToast("Unable to get the responses");
    console.log(error);
  }
};

// Rename to pushResponses
export const saveResponses = async (
  accessToken: string,
  unpushedAttempts: Map<number, IresponseBackend>,
  displayToast: boolean = true
) => {
  console.log("Saving Response");
  try {
    if (unpushedAttempts.size === 0) {
      console.log("No attempts to save");
      return 1;
    }
    console.log(Array.from(unpushedAttempts.entries()));
    window.localStorage.setItem(
      "unpushedAttempts",
      parseJSONFromMap(new Map())
    );
    await axios.post<any>(
      `${baseURL}/api/v1/response`,
      Array.from(unpushedAttempts.values()),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
          "x-user-time": new Date().toISOString().slice(0, -1),
        },
      }
    );
    console.log("Successfully Saved Responses");
    return 1;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    console.error(error?.response?.message);
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      handleUnauthorisedException();
      return null;
    } else if (
      error?.response?.status >= 400 &&
      error?.response?.status < 500
    ) {
      return null;
    }
    window.localStorage.setItem(
      "unpushedAttempts",
      parseJSONFromMap(unpushedAttempts ?? new Map())
    );
    if (displayToast) {
      displayErrorToast("Something went wrong, please try again");
    }
    return 0;
  }
};

// Rename to saveResponse
export const setResponse = async (
  accessToken: string,
  sessionId: number,
  questionInfo: questionInfo,
  unpushedAttempts: Map<number, IresponseBackend>
) => {
  // TODO: Add attribute to map called session_id instead of fetching all values
  if (!!unpushedAttempts && unpushedAttempts.size > 0) {
    const firstAttempt = unpushedAttempts.values().next().value;
    if (sessionId !== firstAttempt.session_id) {
      const success = await saveResponses(accessToken, unpushedAttempts);
      if (!success) {
        displayErrorToast();
        return 0;
      }
    }
  }
  // TODO: Move the extra logic to a helper function. State variables shouldn't be
  // set in an api function.
  const responseObj: IresponseBackend = {
    session_id: sessionId,
    question_id: questionInfo.questionId,
    answer: questionInfo.questionAnswer,
    answer_type: questionInfo.questionState,
    time_taken: questionInfo.questionTimeSpent,
    last_updated_on: questionInfo.questionLastUpdatedOn,
  };

  let updatedUnpushedAttempts = new Map(unpushedAttempts);
  updatedUnpushedAttempts.set(questionInfo.questionId, responseObj);
  window.localStorage.setItem(
    "unpushedAttempts",
    parseJSONFromMap(updatedUnpushedAttempts ?? new Map())
  );
  if (updatedUnpushedAttempts.size >= UPLOAD_THRESH) {
    await saveResponses(accessToken, updatedUnpushedAttempts, false);
  }
  return 1;
};

export const endAttempt = async (accessToken: string, attemptId: number) => {
  try {
    await axios.post(
      `${baseURL}/api/v1/assessment_attempt/${attemptId}/submit`,
      {},
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    displaySuccessToast("Test submitted successfully");
    return 1;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    displayErrorToast("Unable to submit the test");
    console.log(error);
    return 0;
  }
};

export async function endTest(
  accessToken: string,
  unpushedAttempts: Map<number, IresponseBackend>,
  setShowModal: Dispatch<SetStateAction<boolean>>
) {
  let success = await saveResponses(accessToken, unpushedAttempts);
  if (!success) {
    displayErrorToast("Something went wrong, please try again");
    return 0;
  }
  // Temporarily disabling the feature of ending attempt as educator might extend the deadline
  // but if attempt is already ended, student cannot continue attempting
  // success = await endAttempt(accessToken, attemptId);
  setShowModal(false);
  displaySuccessToast("The test was submitted successfully");
  return 1;
}
