import axios from "axios";
import { baseURL } from "../utils/constants";
import HTTPStatusCode from "../utils/HTTPStatusCode";
import { displayErrorToast } from "../utils/ToastUtils";

export const signupUserAPICall = async (email: string, password: string) => {
  try {
    const response = await axios.post<any>(
      `${baseURL}/api/v1/accounts/signup`,
      {
        email: email,
        password: password,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.log(error);
    switch (error?.response?.status) {
      case HTTPStatusCode.CONFLICT:
        displayErrorToast(
          "Email already in use. Please try a different email."
        );
        break;
      case HTTPStatusCode.UNPROCESSABLE_ENTITY:
        displayErrorToast("Password should be atleast 8 characters.");
        break;
      default:
        displayErrorToast("Signup failed. Please try again.");
    }
    return null;
  }
};

export const sendVerificationEmail = async (email: string) => {
  try {
    const response = await axios.post<any>(
      `${baseURL}/api/v1/accounts/verify_email`,
      {
        email: email,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.log(error);
    displayErrorToast(
      "Could not send verification email. Please try again later"
    );
    return null;
  }
};

export const verifyEmail = async (token: string) => {
  try {
    console.log(token);
    const response = await axios.get<any>(
      `${baseURL}/api/v1/accounts/verify_email/${token}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.log(error);
    displayErrorToast("Failed to Verify Your Email. Please try again.");
    return null;
  }
};

export const sendResetPasswordEmail = async (email: string) => {
  try {
    const response = await axios.post<any>(
      `${baseURL}/api/v1/accounts/forgot_password`,
      {
        email: email,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.log(error);
    switch (error?.response?.status) {
      case HTTPStatusCode.NOT_FOUND:
        displayErrorToast("Invalid e-mail. The email was not found");
        break;
      default:
        displayErrorToast();
    }
    return null;
  }
};

export const resetPassword = async (token: string, newPassword: string) => {
  try {
    let response = await axios.patch<any>(
      `${baseURL}/api/v1/accounts/forgot_password`,
      {
        token: token,
        password: newPassword,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.log(error);
    displayErrorToast();
    return null;
  }
};

export const fetchDummyUserAPICall = async () => {
  try {
    const response = await axios.get<any>(
      `${baseURL}/api/v1/accounts/dummy_user_login`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.log(error);
    displayErrorToast("Something went wrong. Come again later");
    return null;
  }
};
