import {
  FunctionComponent,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { RiPencilFill } from "react-icons/ri";
import { AppContext, TAppContext } from "../../../../context/AppContext";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../context/AssessmentContext";
import { editMarkingScheme } from "../../../../api/question";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../../utils/ToastUtils";
import { IquestionData } from "../../../../models/assessmentmodel";

interface EditMarkingSchemeProps {
  currentQuestionDataProps?: IquestionData;
  isOMR?: boolean;
  indexForColor?: number;
}

const EditMarkingScheme: FunctionComponent<EditMarkingSchemeProps> = (
  props
) => {
  const { accessToken } = useContext<TAppContext>(AppContext);
  const {
    currentQuestionData,
    setCurrentQuestionData,
    selectedAssessment,
    questionMap,
    setQuestionMap,
  } = useContext<TAssessmentContext>(AssessmentContext);

  const [show, setShow] = useState<boolean>(false);
  const [marksIfCorrect, setMarksIfCorrect] = useState<number>(
    currentQuestionData.marks_if_correct
  );
  const [marksIfIncorrect, setMarksIfIncorrect] = useState<number>(
    currentQuestionData.marks_if_incorrect
  );
  const [marksIfUnattempted, setMarksIfUnattempted] = useState<number>(
    currentQuestionData.marks_if_unattempted
  );

  const handleClose = () => setShow(false);

  let updatedMarksIfCorrect = marksIfCorrect;
  let updatedMarksIfIncorrect = marksIfIncorrect;
  let updatedMarksIfUnattempted = marksIfUnattempted;

  const handleMarkingSchemeEdit = () => {
    if (
      isNaN(updatedMarksIfCorrect) ||
      isNaN(updatedMarksIfIncorrect) ||
      isNaN(updatedMarksIfUnattempted)
    ) {
      displayErrorToast("Marks should be a number");
      return;
    }

    const success = editMarkingScheme(
      accessToken,
      selectedAssessment.id,
      currentQuestionData.question_id,
      updatedMarksIfCorrect,
      updatedMarksIfIncorrect,
      updatedMarksIfUnattempted
    );
    if (!success) return null;

    setMarksIfCorrect(updatedMarksIfCorrect);
    setMarksIfIncorrect(updatedMarksIfIncorrect);
    setMarksIfUnattempted(updatedMarksIfUnattempted);
    currentQuestionData.marks_if_correct = updatedMarksIfCorrect;
    currentQuestionData.marks_if_incorrect = updatedMarksIfIncorrect;
    currentQuestionData.marks_if_unattempted = updatedMarksIfUnattempted;
    let updatedQuestionMap = new Map(questionMap);
    updatedQuestionMap.set(
      currentQuestionData.question_id,
      currentQuestionData
    );
    setQuestionMap(updatedQuestionMap);
    displaySuccessToast("Marking Scheme updated successfully");
  };

  const handleClick = () => {
    setShow(true);
    if (props.currentQuestionDataProps) {
      setCurrentQuestionData(props.currentQuestionDataProps);
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Edit Marking Scheme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="my-2">
              <Form.Label>Marks if Correct:</Form.Label>
              <Form.Control
                type="marks_if_correct"
                className="bg-light"
                defaultValue={currentQuestionData.marks_if_correct}
                onChange={(e) =>
                  (updatedMarksIfCorrect = Number(e.target.value))
                }
              />
            </div>
            <div className="my-2">
              <Form.Label>Marks if Incorrect:</Form.Label>
              <Form.Control
                type="marks_if_incorrect"
                className="bg-light"
                defaultValue={currentQuestionData.marks_if_incorrect}
                onChange={(e) =>
                  (updatedMarksIfIncorrect = Number(e.target.value))
                }
              />
            </div>
            <div className="my-2">
              <Form.Label>Marks if Unattempted:</Form.Label>
              <Form.Control
                type="marks_if_unattempted"
                className="bg-light"
                defaultValue={currentQuestionData.marks_if_unattempted}
                onChange={(e) =>
                  (updatedMarksIfUnattempted = Number(e.target.value))
                }
              />
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleMarkingSchemeEdit();
              handleClose();
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Button
        className={
          props.isOMR
            ? "bg-transparent"
            : "bg-white rounded-circle border border-none ms-1"
        }
        onClick={handleClick}
        style={{ borderColor: props.indexForColor ? "#fff" : "#e5e5e5" }}
      >
        <RiPencilFill
          color="black"
          size={props.currentQuestionDataProps ? "18px" : "20px"}
        ></RiPencilFill>
      </Button>
    </>
  );
};

export default EditMarkingScheme;
