import { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { AppContext, TAppContext } from "../../context/AppContext";
import Logo from "../../assets/images/Banner.svg";
import { Container, Navbar } from "react-bootstrap";
import styles from "./TopNavigationBar.module.scss";
import { Divider, Menu, MenuItem, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { VERSION_NUMBER } from "../../utils/constants";

export default function TopNavigationBar(props) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { isLoggedIn, name, org, clearAppContext, email } =
    useContext<TAppContext>(AppContext);

  const logoutHandler = () => {
    window.localStorage.clear();
    clearAppContext();
    history.push("/");
  };

  const isMobile = useMediaQuery("(max-width: 576px)");
  const firstName = name?.split(" ")[0];

  const button = isLoggedIn ? (
    <Container fluid className="d-flex justify-content-end">
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ color: "white" }}
      >
        {isMobile ? firstName : name}
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            history.push("/home/reset-password");
            handleClose();
          }}
        >
          Reset Password
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open(
              "https://www.youtube.com/channel/UCv12_w54zkDsfzqozmchqoA",
              "_blank"
            );
          }}
        >
          Help/FAQs
        </MenuItem>
        <MenuItem
          onClick={() => {
            history.push("/additional-info");
            handleClose();
          }}
        >
          Update Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            logoutHandler();
            handleClose();
          }}
        >
          Log out
        </MenuItem>
        <Divider />
        <MenuItem disabled onClick={handleClose}>
          Email: {email}
        </MenuItem>
        <MenuItem disabled onClick={handleClose}>
          Version: {VERSION_NUMBER}
        </MenuItem>
      </Menu>
    </Container>
  ) : (
    <div></div>
  );

  return (
    <>
      <Navbar
        className="d-flex bg-primary ps-4"
        style={{
          width: "100%",
          zIndex: 15,
          minHeight: "10%",
          maxHeight: "10%",
        }}
      >
        {/* {!isDesktopOrLaptop && isLoggedIn && (
          <Button onClick={() => setSideDrawerOpen(!sideDrawerOpen)}>
            <IoIosMenu size={"30px"} />
          </Button>
        )} */}
        <Navbar.Brand className="ms-2" href="/home">
          <img src={Logo} alt="Quriate" style={{ height: 60 }} />
        </Navbar.Brand>
        {button}
      </Navbar>
    </>
  );
}
