import { Dispatch, SetStateAction, useContext, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { AppContext, TAppContext } from "../../../context/AppContext";
import {
  BatchManagementContext,
  TBatchManagementContext,
} from "../../../context/BatchManagementContext";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../utils/ToastUtils";
import styles from "../batch-management.module.scss";
import { addUsersToBatch } from "../../../api/batches";
import { TBatch } from "../../../models/batches";
import CustomButton from "../../../components/CustomComponent/CustomButton";
import { BatchUserRole } from "../../../utils/Enums";

type TProps = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  selectedBatch: TBatch;
};
export default function AddMultipleUsersToBatchModal(props: TProps) {
  const { showModal, setShowModal, selectedBatch } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<{
    files: FileList;
  } | null>();
  const [headers, setHeaders] = useState<Array<any>>([]);
  const [rows, setRows] = useState<Array<any>>([]);
  const [emailMappingOption, setEmailMappingOption] = useState<string>("");

  const { accessToken } = useContext<TAppContext>(AppContext);
  const { forceReRender, setForceReRender } =
    useContext<TBatchManagementContext>(BatchManagementContext);

  const onDrop = (files: any) => {
    setSelectedFiles({ ...files, files: files });
  };

  const onClose = () => {
    setShowModal(false);
    setSelectedFiles(null);
    setRows([]);
  };

  function upload(file: File) {
    setLoading(true);
    const reader = new FileReader();

    reader.onload = () => {
      const binaryStr = reader.result;
      const splitText = (binaryStr as string).split(/[\r\n]+/);
      const fileHeaders = splitText[0].split(",");
      const headerList: Array<any> = [];
      fileHeaders.map((header) => {
        const headerMap = Object();
        const joinedHeader = header.replace(/ /g, "_");
        headerMap["joined"] = joinedHeader;
        headerMap["original"] = header;
        headerList.push(headerMap);
      });
      setHeaders(headerList);
      if (splitText.length > 1) {
        const fileRows = splitText.splice(1);
        const rowList: Array<any> = [];
        fileRows.map((row) => {
          const rowMap = Object();
          const splitRow = row.split(",");
          for (var i: number = 0; i < fileHeaders.length; i++) {
            rowMap[fileHeaders[i]] = splitRow[i];
          }
          rowList.push(rowMap);
        });
        setRows(rowList);
      }
    };

    reader.readAsText(file);
    setLoading(false);
  }

  const tableHeaders =
    rows.length > 0
      ? headers.map((header) => (
          <th className="text-center">{header["original"]}</th>
        ))
      : null;

  function generateTableBody() {
    return rows.slice(0, 3).map((row) => {
      var cells = headers.map((header) => {
        return <td className="text-center">{row[header["original"]]}</td>;
      });
      return <tr>{cells}</tr>;
    });
  }

  async function uploadUsers() {
    setLoading(true);
    if (!emailMappingOption) {
      displayErrorToast("Please select a column for email.");
      return;
    }

    const APIBodyArray: Array<Object> = [];

    for (var i: number = 0; i < rows.length; i++) {
      const userObject = Object();
      userObject["email"] = rows[i][emailMappingOption];
      userObject["role"] = BatchUserRole.STUDENT;
      if (userObject["email"] != "") {
        APIBodyArray.push(userObject);
      }
    }
    const responseStatusCode = await addUsersToBatch(
      accessToken,
      selectedBatch.code,
      APIBodyArray
    );

    if (responseStatusCode === 200) {
      setForceReRender(!forceReRender);
      displaySuccessToast("Added users successfully!");
      onClose();
    }
    setLoading(false);
  }

  const tableBody = rows.length > 0 ? generateTableBody() : null;

  const submitButton =
    rows.length > 0 ? (
      <div className="text-center">
        <CustomButton
          loading={loading}
          onClick={uploadUsers}
          className="rounded-4 bg-secondary border-0"
        >
          Submit
        </CustomButton>
      </div>
    ) : null;

  function dropdownOptions() {
    return headers.map((header) => (
      <option value={header["joined"]}>{header["original"]}</option>
    ));
  }

  const selectEmailMapping = (e: any) => {
    let idx = e.target.selectedIndex;
    let dataset = e.target.options[idx];
    setEmailMappingOption(dataset.text);
  };

  const columnMappings =
    rows.length > 0 ? (
      <>
        <div className="m-4">
          <h4>Column Mappings</h4>
        </div>
        <Row className="ms-4 mt-4">
          <Col className="col-2">
            Email<span style={{ color: "red" }}>*</span>
          </Col>
          <Col className="col-5">
            <select
              className="ms-2 p-2 rounded-4"
              onChange={(e) => selectEmailMapping(e)}
            >
              <option value="select">Select Column Name</option>
              {dropdownOptions()}
            </select>
          </Col>
        </Row>
      </>
    ) : null;

  const table =
    rows.length > 0 ? (
      <>
        <hr />
        <div className="m-4">
          <h3> File Preview </h3>
          <table
            className="w-100"
            style={{ backgroundColor: "gainsboro", borderRadius: "7px" }}
          >
            <thead>
              <tr style={{ borderBottom: "1px solid grey" }}>{tableHeaders}</tr>
            </thead>
            <tbody>{tableBody}</tbody>
          </table>
        </div>
      </>
    ) : null;

  return (
    <Modal show={showModal} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add multiple users to batch</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="ms-4 mt-3">
          <Dropzone
            accept=".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"
            onDrop={onDrop}
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps({ className: styles.dropzone })}>
                  <input {...getInputProps()} />
                  {selectedFiles && selectedFiles["files"][0]?.name ? (
                    <div className={styles.selectedFile}>
                      {selectedFiles["files"][0]?.name}
                    </div>
                  ) : (
                    "Drag and drop file here, or click to select file"
                  )}
                </div>
                <aside className={styles.selectedFileWrapper}>
                  <CustomButton
                    className="btn btn-success"
                    disabled={!selectedFiles}
                    loading={loading}
                    onClick={() => upload(selectedFiles?.files[0]!!)}
                  >
                    Upload
                  </CustomButton>
                </aside>
              </section>
            )}
          </Dropzone>
        </div>
        {table}
        {columnMappings}
      </Modal.Body>
      <Modal.Footer>{submitButton}</Modal.Footer>
    </Modal>
  );
}
