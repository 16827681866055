import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Tooltip, Link, useMediaQuery, IconButton } from "@mui/material";
import { Modal } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { patchAssessment } from "../../../../api/assessment";
import { AppContext, TAppContext } from "../../../../context/AppContext";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../context/AssessmentContext";
import { TAssessmentData } from "../../../../models/assessmentmodel";
import { uploadFileToS3 } from "../../../../utils/s3Utils";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../../utils/ToastUtils";
import styles from "./upload-pdf-modal.module.scss";
import { BsInfoCircle } from "react-icons/bs";
import CustomButton from "../../../../components/CustomComponent/CustomButton";

interface UploadPdfModalProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

const UploadPdfModal: FunctionComponent<UploadPdfModalProps> = (props) => {
  const { show, setShow } = props;
  const { accessToken } = useContext<TAppContext>(AppContext);
  const { selectedAssessment, setSelectedAssessment } =
    useContext<TAssessmentContext>(AssessmentContext);
  const [solutionFile, setSolutionFile] = useState<File>();
  const [noSolutionFile, setNoSolutionFile] = useState<File>();
  const [loading, setLoading] = useState<boolean>(false);
  const [noSolutionPdfUrl, setNoSolutionPdfUrl] = useState<string>(
    selectedAssessment.no_solution_pdf_url
  );
  const [solutionPdfUrl, setSolutionPdfUrl] = useState<string>(
    selectedAssessment.solution_pdf_url
  );

  // uploads the selected pdf file to the s3 bucket
  // returns a string i.e. the url of the uploaded file in the s3 bucket or an empty string if there was an error
  const uploadPdfToS3 = async (pdf: File): Promise<string> => {
    // if no pdf is selected, display an error toast and call an early return
    if (pdf === undefined) {
      displayErrorToast(
        "No file selected. Please select or drag & drop a file to continue"
      );
      return "";
    }

    // disable the save button in order to prevent unnecessary multiple API calls
    setLoading(true);

    const fileKey = await uploadFileToS3(
      pdf,
      `raw-assessment-files/assessment_pdf/${selectedAssessment.id}/`,
      false
    );

    // handle the error if there was an error in uploading the file to s3
    if (!fileKey) {
      displayErrorToast("Error in uploading assessment PDF");
      return "";
    }

    displaySuccessToast("Successfully uploaded PDF");
    setLoading(false);

    return `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_S3_REGION}.amazonaws.com/${fileKey}`;
  };

  // handles the on drop event of the Dropzone.
  // sets the selectedFiles state variable the file that was dropped into the dropzone
  const onDrop = (files: any, hasSolution: boolean) => {
    if (!files || files.length === 0) {
      displayErrorToast("No file selected. Please select a file to continue");
      return;
    }
    if (hasSolution) {
      setSolutionFile(files[0]);
    } else {
      setNoSolutionFile(files[0]);
    }
    console.log("hasSolution", hasSolution);
  };

  // handles the patch assessment request when the solution_pdf_url and the no_solution_pdf_url is changed
  const handleDelete = async (hasSolution) => {
    const response: TAssessmentData = await patchAssessment(
      accessToken,
      selectedAssessment.id,
      undefined,
      !hasSolution ? "" : noSolutionPdfUrl,
      hasSolution ? "" : solutionPdfUrl
    );
    if (hasSolution) {
      setSolutionFile(undefined);
    } else {
      setNoSolutionFile(undefined);
    }
    // update the selected assessment present in the local storage
    setSelectedAssessment({ ...response });
    displaySuccessToast("Uploaded PDF was successfully deleted");
  };
  const saveChanges = async () => {
    // if there is no upload type, call an early return and display an error toast
    if (!(solutionFile || noSolutionFile))
      return displayErrorToast(
        "Please upload atleast one of the Solution or No solution PDF to continue"
      );

    // initialize two static vars with the current no_solution and solution pdf urls
    let no_solution_pdf_url: string = "",
      solution_pdf_url: string = "";
    if (noSolutionFile !== undefined) {
      no_solution_pdf_url = await uploadPdfToS3(noSolutionFile);
      setNoSolutionFile(undefined);
    }
    if (solutionFile !== undefined) {
      solution_pdf_url = await uploadPdfToS3(solutionFile);
      setSolutionFile(undefined);
    }

    // call the patch assessment API and pass the no_solution_pdf_url and solution_pdf_url args
    const response: TAssessmentData = await patchAssessment(
      accessToken,
      selectedAssessment.id,
      undefined,
      no_solution_pdf_url !== "" ? no_solution_pdf_url : undefined,
      solution_pdf_url !== "" ? solution_pdf_url : undefined
    );

    // set the new no_solution_pdf_url and solution_pdf_url into the state and cause a re-render
    // update the selected assessment present in the local storage
    setSelectedAssessment({ ...response });
    setLoading(false);
    displaySuccessToast("All Changes saved!");
  };

  useEffect(() => {
    setNoSolutionPdfUrl(selectedAssessment.no_solution_pdf_url);
    setSolutionPdfUrl(selectedAssessment.solution_pdf_url);
  }, [selectedAssessment]);

  return (
    <>
      <div style={{ color: "#84868D " }}>
        <Modal.Header>
          <Modal.Title>
            PDF with students
            <Tooltip
              enterTouchDelay={0}
              title="This is the PDF that would be visible for the students. Please make sure you upload 2 PDFs, one with the solutions and one without the solutions."
              placement="top-start"
            >
              <IconButton>
                <BsInfoCircle className="mx-2" size={20} />
              </IconButton>
            </Tooltip>
            {!noSolutionPdfUrl && !solutionPdfUrl && (
              <React.Fragment>
                <div
                  style={{
                    display: "inline",
                    background: "none",
                    fontSize: "22px",
                    color: "#84868D ",
                  }}
                  className={`px-2`}
                >
                  Some files missing
                </div>
              </React.Fragment>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <b>Note:</b> This file would be available for students to download, so
          do not upload solutions unless desired
          <br />
          <br /> */}
          {/* conditionally render dropzone if any one of the pdfs is not uploaded */}
          Without solutions
          {noSolutionPdfUrl ? (
            <React.Fragment>
              <div
                style={{
                  display: "inline",
                  color: "#84868D ",
                  background: "none",
                  fontSize: "22px",
                }}
                className={`px-2`}
              >
                <Link href={noSolutionPdfUrl} target="_blank">
                  {decodeURIComponent(noSolutionPdfUrl).split("/").pop()}
                </Link>
                <RiDeleteBin2Fill
                  color="red"
                  size={30}
                  cursor="pointer"
                  style={{
                    position: "relative",
                    top: "0px",
                    left: "5px",
                  }}
                  onClick={() => {
                    handleDelete(false);
                  }}
                />
              </div>
            </React.Fragment>
          ) : (
            <>
              {/* conditionally render the delete selected file button when a file is selected */}
              {noSolutionFile && (
                <RiDeleteBin2Fill
                  color="red"
                  size={30}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    right: "20px",
                    top: "50px",
                  }}
                  onClick={() => {
                    handleDelete(false);
                  }}
                />
              )}
              {noSolutionPdfUrl === "" && (
                <div style={{ maxWidth: "700px", margin: "0 auto" }}>
                  <Dropzone
                    accept=".pdf"
                    onDrop={(e) => onDrop(e, false)}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps({ className: styles.dropzone })}>
                          <input {...getInputProps()} />
                          {noSolutionFile?.name ? (
                            <div className={styles.selectedFile}>
                              {noSolutionFile.name}
                            </div>
                          ) : (
                            "Drag and drop file here, or click to select file"
                          )}
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Body>
          With solutions
          {solutionPdfUrl ? (
            <React.Fragment>
              <div
                style={{
                  display: "inline",
                  color: "#84868D ",
                  background: "none",
                  fontSize: "22px",
                }}
                className={`px-2`}
              >
                <Link href={solutionPdfUrl} target="_blank">
                  {decodeURIComponent(solutionPdfUrl).split("/").pop()}
                </Link>
                <RiDeleteBin2Fill
                  color="red"
                  size={30}
                  cursor="pointer"
                  style={{
                    position: "relative",
                    top: "0px",
                    left: "5px",
                  }}
                  onClick={() => {
                    handleDelete(true);
                  }}
                />
              </div>
            </React.Fragment>
          ) : (
            <>
              {/* conditionally render the delete selected file button when a file is selected */}
              {solutionFile && (
                <RiDeleteBin2Fill
                  color="red"
                  size={30}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    right: "20px",
                    top: "50px",
                  }}
                  onClick={() => {
                    handleDelete(true);
                  }}
                />
              )}
              {!solutionPdfUrl && (
                <div style={{ maxWidth: "700px", margin: "0 auto" }}>
                  <Dropzone
                    accept=".pdf"
                    onDrop={(e) => onDrop(e, true)}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps({ className: styles.dropzone })}>
                          <input {...getInputProps()} />
                          {solutionFile?.name ? (
                            <div className={styles.selectedFile}>
                              {solutionFile?.name}
                            </div>
                          ) : (
                            "Drag and drop file here, or click to select file"
                          )}
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
          {(noSolutionPdfUrl === "" || solutionPdfUrl === "") && (
            <CustomButton
              className="border border-none bg-primary rounded-4"
              onClick={saveChanges}
              loading={loading}
              disabled={
                solutionFile === undefined && noSolutionFile === undefined
              }
            >
              Upload
            </CustomButton>
          )}
        </Modal.Footer>
      </div>
    </>
  );
};

export default UploadPdfModal;
