import { Drawer, Divider } from "@mui/material";
import { Dispatch, FunctionComponent, SetStateAction } from "react";

import AssignmentIcon from "@mui/icons-material/Assignment";

interface ManageAssessmentBottomDrawerProps {
  anchorEl: null | HTMLElement;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLElement>>;
  assessmentName: string;
}

const ManageAssessmentBottomDrawer: FunctionComponent<
  ManageAssessmentBottomDrawerProps
> = ({ anchorEl, setAnchorEl, assessmentName, children }) => {
  const handleClose = () => setAnchorEl(null);
  return (
    <Drawer
      PaperProps={{
        elevation: 0,
        style: {
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        },
      }}
      anchor={"bottom"}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <div className="p-3 d-flex flex-row align-items-center">
        <AssignmentIcon fontSize="large" />
        <div className="flex-column ms-3">
          <h5>{assessmentName}</h5>
        </div>
      </div>
      <Divider />
      {children}
    </Drawer>
  );
};

export default ManageAssessmentBottomDrawer;
