import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./FirebaseConfig";
import { AppContext, TAppContext } from "./context/AppContext";
import TopNavigationBar from "./components/navigation-bars/TopNavigationBar";
import ForgotPassword from "./views/login/ForgotPassword";
import GetStartedPage from "./views/get-started/GetStarted";
import { AssessmentContextProvider } from "./context/AssessmentContext";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AttemptContextProvider } from "./context/AttemptContext";
import { useMediaQuery } from "@mui/material";
import { useContext } from "react";
import HomeView from "./views/HomeView";
import { QuestionContextProvider } from "./context/QuestionContext";
import AdditionalInfo from "./views/login/AdditionalInfo";
import "./css/index.scss";
import ResetPassword from "./views/login/ResetPassword";
import VerifyEmail from "./views/login/VerifyEmail";
import dotenv from "dotenv";
import PrivacyPolicy from "./views/marketplace/PrivacyPolicy";
import ProductPage from "./views/marketplace/ProductPage/ProductPage";
import MarketPlace from "./views/marketplace/Marketplace";
import { GoogleOAuthProvider } from "@react-oauth/google";
import CatchError from "./hoc/ErrorBoundary/ErrorBoundary";
import "./polyfills";

dotenv.config();
export default function App() {
  initializeApp(firebaseConfig);
  const { drawerOpen } = useContext<TAppContext>(AppContext);
  const isDesktopOrLaptop = useMediaQuery("(min-width: 992px)");
  return (
    <CatchError>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <div
          className={`d-flex ${
            !isDesktopOrLaptop && "bg-light"
          } flex-column w-100 ml-0 mr-0`}
          style={{ height: "100vh" }}
        >
          <BrowserRouter>
            {drawerOpen && <TopNavigationBar />}
            <div
              className="d-flex"
              style={{ height: drawerOpen ? "90%" : "100%" }}
            >
              <Switch>
                <Route exact path="/">
                  <Redirect to="/home" />
                </Route>
                <Route exact path="/home">
                  <Redirect to="/home/dashboard" />
                </Route>
                <Route exact path="/verify">
                  <VerifyEmail />
                </Route>
                <Route path="/login">
                  <Redirect to="/home" />
                </Route>
                <Route path="/signup">
                  <Redirect to="/home" />
                </Route>
                <Route path="/forgot-password">
                  <ForgotPassword />
                </Route>
                <Route path="/reset-password">
                  <ResetPassword isLoggedIn={false} />
                </Route>
                <Route path="/additional-info">
                  <AdditionalInfo />
                </Route>
                <Route path="/verify-email">
                  <Redirect to="/home" />
                </Route>
                <Route path="/privacy-policy">
                  <PrivacyPolicy />
                </Route>
                <Route exact path="/marketplace/">
                  <MarketPlace />
                </Route>
                <Route exact path="/marketplace/:code">
                  <ProductPage />
                </Route>
                <AssessmentContextProvider>
                  <QuestionContextProvider>
                    <AttemptContextProvider>
                      <Route path="/home">
                        <HomeView />
                      </Route>
                      <Route path="/get-started">
                        <GetStartedPage />
                      </Route>
                    </AttemptContextProvider>
                  </QuestionContextProvider>
                </AssessmentContextProvider>
              </Switch>
            </div>
            <ToastContainer transition={Slide} theme="colored" limit={1} />
          </BrowserRouter>
        </div>
      </GoogleOAuthProvider>
    </CatchError>
  );
}
