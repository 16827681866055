import axios from "axios";
import { handleUnauthorisedException } from "../utils/apiUtils";
import { baseURL } from "../utils/constants";
import HTTPStatusCode from "../utils/HTTPStatusCode";
import { displayErrorToast } from "../utils/ToastUtils";

export const fetchOrCreateLatestSession = async (
  accessToken: string,
  publishCode: string,
  createNew: boolean
) => {
  try {
    const attempts = await axios.get<any>(
      `${baseURL}/api/v1/published_assessment/${publishCode}/latest_session?create_new=${createNew}`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    return attempts.data;
  } catch (error: any) {
    if (
      error?.response?.status === HTTPStatusCode.BAD_REQUEST &&
      error?.response?.data?.error === "Assessment has already ended"
    ) {
      displayErrorToast(
        "You haven't attempted this assessment and it has now ended"
      );
      return 0;
    }
    if (!createNew && error?.response?.status === HTTPStatusCode.NOT_FOUND)
      return null;
    displayErrorToast("Error fetching your attempt sessions");
    return null;
  }
};

export const fetchAssessmentsForStudent = async (accessToken: string) => {
  try {
    const response = await axios.get<any>(
      `${baseURL}/api/v1/published_assessment`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    displayErrorToast("Failed to get assessments");
    return null;
  }
};

export const fetchAssessmentsForTeacher = async (accessToken: string) => {
  try {
    const response = await axios.get<any>(`${baseURL}/api/v1/assessment`, {
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
        Authorization: "Bearer " + accessToken,
      },
    });
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    displayErrorToast("Failed to get assessments");
    return null;
  }
};

export const patchAttemptInfo = async (
  accessToken: string,
  assessmentAttemptId: number,
  patchData: Object
) => {
  try {
    let response = await axios.patch<any>(
      `${baseURL}/api/v1/assessment_attempt/${assessmentAttemptId}`,
      {
        ...patchData,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    displayErrorToast("Failed to update assessment details");
    return null;
  }
};
