import { Dispatch, SetStateAction, useContext, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { loginUserAPICall, socialLogin } from "../../api/login";
import { AppContext, TAppContext } from "../../context/AppContext";
import * as yup from "yup";
import { Formik } from "formik";
import { signupUserAPICall } from "../../api/accounts";
import Divider from "@mui/material/Divider";
import { GoogleLogin } from "@react-oauth/google";
import { displayErrorToast, displaySuccessToast } from "../../utils/ToastUtils";
import { fetchUser, updateUserAdditionalInfoAPICall } from "../../api/user";
import { EAuthMode } from "./Components/AuthScreen";
import { useHistory } from "react-router-dom";
import { handleTransferAssessment } from "../../api/assessment";
import { VERSION_NUMBER } from "../../utils/constants";

interface SignupProps {
  setAuthMode?: Dispatch<SetStateAction<EAuthMode>>;
}

export default function Signup(props: SignupProps) {
  const history = useHistory();
  const {
    setEmail,
    role,
    setName,
    setId,
    setAccessToken,
    setIsLoggedIn,
    isEmailVerified,
    setIsEmailVerified,
    shouldTransferAssessment,
    dummyAssessmentId,
    showLoginModal,
  } = useContext<TAppContext>(AppContext);

  const [isLoading, setIsLoading] = useState(false);

  let schema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Please enter a email"),
    password: yup.string().required("Please enter your password"),
    name: yup.string().required("Please enter a name"),
    city: yup.string().required("Please enter a city"),
    phone: yup
      .string()
      .required("Please enter your number")
      .matches(/^[0-9]+$/, "Phone must be a number")
      .test("len", "Must be exactly 10 digits", (val) => val?.length === 10),
    dob: yup.date().required("Please enter a date"),
  });

  const handleAdditionalInfo = async (values: any, accessToken: string) => {
    let response = await updateUserAdditionalInfoAPICall(
      values.name,
      values.city,
      values.phone,
      values.dob,
      accessToken
    );
    if (!response) {
      return 0;
    }
    displaySuccessToast("Successfully Updated Your Profile");
    return 1;
  };

  const setUserDetails = async (access_token) => {
    setAccessToken(access_token);
    const userInfoResponse = await fetchUser(access_token);
    if (userInfoResponse === null) {
      return;
    }
    setEmail(userInfoResponse.email);
    setIsEmailVerified(userInfoResponse.is_verified);
    setId(userInfoResponse.id);
    setIsLoggedIn(true);
    displaySuccessToast("Successfully logged in!");
  };

  const handleEmailPasswordSignupV2 = async (values: any) => {
    setIsLoading(true);
    let response = await signupUserAPICall(values.email, values.password);
    if (!response) {
      setIsLoading(false);
      return;
    }

    let loginResponse = await loginUserAPICall(values.email, values.password);
    if (!loginResponse) {
      setIsLoading(false);
      return;
    }
    response = await handleAdditionalInfo(values, loginResponse.access_token);
    await setUserDetails(loginResponse.access_token);

    if (!response) {
      displayErrorToast(
        "Failed to store additional info. Please update them in UPDATE PROFILE section"
      );
    }
    setIsLoading(false);

    if (shouldTransferAssessment)
      await handleTransferAssessment(
        loginResponse.access_token,
        dummyAssessmentId
      );

    if (props.setAuthMode === undefined) {
      if (!isEmailVerified) return history.push("/verify-email");
      if (role === null) return history.push("/additional-info");
    } else {
      props.setAuthMode(EAuthMode.VERIFY_EMAIL);
    }
  };

  const handleGoogleSignupSuccess = async (res) => {
    setIsLoading(true);
    let loginResponse = await socialLogin(res.credential);
    if (!loginResponse) {
      setIsLoading(false);
      return;
    }
    await setUserDetails(loginResponse.access_token);
    if (shouldTransferAssessment)
      await handleTransferAssessment(
        loginResponse.access_token,
        dummyAssessmentId
      );
    setIsLoading(false);
    if (props.setAuthMode === undefined) {
      history.push("/additional-info");
    }
  };

  const handleGoogleSignupFailure = () => {
    displayErrorToast();
  };

  return (
    <div className="container d-flex justify-content-center w-100 p-0">
      <div
        className={
          "col-sm-8 col-md-8 col-lg-6 col-11 " +
          (showLoginModal ? "w-100" : null)
        }
      >
        <div
          className="login shadow-lg rounded-2"
          style={{
            marginTop: showLoginModal ? 0 : "10%",
            paddingLeft: "4%",
            paddingRight: "4%",
            paddingTop: "4%",
            paddingBottom: "4%",
            backgroundColor: "#eeeeee",
          }}
        >
          <h2 className="d-flex justify-content-center">
            <strong>Register on Quriate</strong>
          </h2>
          <hr className="mt-4 text-primary" style={{ width: "100%" }} />
          <Formik
            validationSchema={schema}
            onSubmit={(values) => {
              handleEmailPasswordSignupV2(values);
            }}
            initialValues={{
              email: "",
              password: "",
              name: "",
              city: "",
              phone: "",
              dob: "",
            }}
          >
            {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
              <Form
                className="mt-4"
                noValidate
                onSubmit={(event) => {
                  event.preventDefault();
                  handleSubmit();
                }}
              >
                <Form.Group className="ml-4" controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    value={values.email}
                    placeholder="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="ml-2 bg-light"
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="password" className="mt-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={values.password}
                    placeholder="Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.password}
                    className="bg-light"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Form.Group
                    className="mt-2 mx-2"
                    controlId="name"
                    style={{ flex: 1 }}
                  >
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="name"
                      className="bg-light"
                      value={values.name}
                      placeholder="John Doe"
                      onChange={(e) => {
                        setName(e.target.value);
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="valid">
                      Looks Good!
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    controlId="city"
                    className="mt-2 mx-2"
                    style={{ flex: 1 }}
                  >
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="city"
                      className="bg-light"
                      value={values.city}
                      placeholder="New Delhi"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.city}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.city}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="valid">
                      Looks Good!
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Form.Group
                    className="mt-2 mx-2"
                    controlId="phone"
                    style={{ flex: 1 }}
                  >
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="phone"
                      className="bg-light"
                      value={values.phone}
                      placeholder="1234567890"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="valid">
                      Looks Good!
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    controlId="dob"
                    className="mt-2 mx-2"
                    style={{ flex: 1 }}
                  >
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control
                      type="date"
                      className="bg-light"
                      value={values.dob}
                      placeholder="dob"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.dob}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.dob}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="valid">
                      Looks Good!
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="text-center">
                  <Button
                    variant="secondary"
                    className="mt-4 rounded-2 p-2"
                    block
                    size="lg"
                    type="submit"
                    disabled={isLoading}
                    style={{ color: "white", width: "150px" }}
                  >
                    {isLoading ? (
                      <Spinner animation="border" role="status" />
                    ) : (
                      "Sign Up"
                    )}
                  </Button>
                </div>
                <Divider className="mt-2">or</Divider>
                <div className="d-flex flex-column align-items-center justify-content-center mt-2">
                  <GoogleLogin
                    onSuccess={handleGoogleSignupSuccess}
                    onError={handleGoogleSignupFailure}
                    text={"signup_with"}
                  />
                </div>
                <div className="mt-4 d-flex justify-content-center">
                  Already Registered?&nbsp;
                  <b
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (props.setAuthMode === undefined) {
                        return history.replace("/login");
                      }
                      props.setAuthMode(EAuthMode.LOGIN);
                    }}
                  >
                    Log In
                  </b>
                </div>
                <br></br>
                <span className="fw-light d-flex justify-content-center">
                  Version: {VERSION_NUMBER}
                </span>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
