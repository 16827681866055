import { Dispatch, SetStateAction } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import styles from "../views/batch-management/batch-management.module.scss";
import { Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

/**
 * This is the confirmation modal one sees upon performing varity of tasks like submitting the test
 * or deleting a batch.
 * @param props
 * showModal: boolean, Defines if the modal is visible or not
 * setShowModal: Dispatch<SetStateAction<boolean>>, Defines the function to set the showModal state.
 * onConfirm: Function, Defines the function to be called when the user confirms the action. or clicks on confirm text.
 * 				Like in case of submitting test it is submit test.
 * modal text: string, Defines the text to be displayed in the modal. This is modal body
 * cancel text: string, Defines the text to be displayed in the cancel button. This is optional and defaults to "cancel"
 *
 * Note: This modal assumes a two button state. That is it can have only a submit button/confirm button or a cancel button
 * Hence it can be used only for states where we need to ask for confirmation
 *
 * @author Pranav Nedungadi
 */

type TProps = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  onConfirm: any;
  confirmText: string;
  modalTitle: string;
  modalText: string;
  cancelText?: string;
};

export default function ConfirmationModal(props: TProps) {
  return (
    <Modal
      contentClassName={styles.modalStyle}
      show={props.showModal}
      onHide={() => props.setShowModal(false)}
      centered
    >
      <Modal.Header>
        <Typography variant="h4">
          <b>{props.modalTitle}</b>
        </Typography>
        <IconButton>
          <CloseIcon onClick={() => props.setShowModal(false)} />
        </IconButton>
      </Modal.Header>
      <Modal.Body>
        <Formik onSubmit={props.onConfirm} initialValues={{}}>
          {({ handleSubmit }) => (
            <Form
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <Form.Group controlId="sectionName">
                <Form.Label>
                  <Typography fontSize={20} color="#9AA0B2">
                    {props.modalText}
                  </Typography>
                </Form.Label>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="border-0 rounded-2 bg-secondary"
          onClick={() => {
            props.setShowModal(false);
          }}
        >
          <Typography className="m-0 p-0">
            {props.cancelText || "Cancel"}
          </Typography>
        </Button>
        <Button
          className={"border-0 rounded-2 bg-danger"}
          type="submit"
          onClick={props.onConfirm}
        >
          <Typography className="m-0 p-0">{props.confirmText}</Typography>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
