import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Button,
  Card,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Modal } from "react-bootstrap";
import { AppContext, TAppContext } from "../../../context/AppContext";
import { IQuestionV2 } from "../../../models/question";
import { AnalyticsStudentResponse } from "../../../models/usermodel";
import {
  ERecommendationType,
  THighlight,
  THighlightObject,
} from "../../../models/analyticsmodel";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import {
  fetchQuestionFromIds,
  fetchStudentFromIds,
} from "../../../api/analytics";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../context/AssessmentContext";
import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";

type THighlightProps = {
  highlights: THighlightObject | null;
};

export default function Highlights(props: THighlightProps) {
  const isMobile = useMediaQuery("(max-width: 576px)");
  const [showHighlightDetailModal, setShowHighlightDetailModal] =
    useState<boolean>(false);
  const [selectedHighlight, setSelectedHighlight] = useState<THighlight>();

  const publishCode = useRouteMatch().params["publishCode"];

  if (props.highlights === null) return <></>;
  const validHighlights: Array<THighlight> = Object.values(
    props.highlights
  ).filter((el) => el !== null && el.valid === true);
  return (
    <>
      <Card
        sx={{
          border: isMobile ? "" : "2px solid black",
        }}
        elevation={0}
        className={"py-2 " + (isMobile ? "px-1" : "px-3")}
      >
        <Typography variant="h6" className="my-2">
          <b>Highlights</b>
        </Typography>
        <Divider className="w-100" />
        <div
          style={{ height: isMobile ? "" : "30vh", overflowY: "scroll" }}
          className="white-area d-flex flex-column justify-content-start align-items-start p-2 pt-3 ps-1"
        >
          {validHighlights.map((el: THighlight) => {
            return (
              <div className="d-flex flex-row align-items-center justify-content-between w-100 mb-3">
                <div className="d-flex flex-row align-items-start justify-content-start">
                  <Typography fontSize={isMobile ? "94%" : "100%"}>
                    {el.message}
                  </Typography>
                </div>
                <Button
                  size="small"
                  variant="outlined"
                  disableElevation
                  onClick={() => {
                    setShowHighlightDetailModal(true);
                    setSelectedHighlight(el);
                  }}
                >
                  <Typography
                    variant="button"
                    fontSize={isMobile ? "94%" : "100%"}
                  >
                    View Details
                  </Typography>
                </Button>
              </div>
            );
          })}
          {validHighlights.length === 0 && (
            <Typography color="#6fafd6" fontSize="18px" fontWeight="900">
              No specific highlights
            </Typography>
          )}
        </div>
        {selectedHighlight !== undefined && (
          <HighlightDetailModal
            show={showHighlightDetailModal}
            setShow={setShowHighlightDetailModal}
            publishCode={publishCode}
            type={selectedHighlight.type}
            ids={selectedHighlight.ids}
          />
        )}
      </Card>
    </>
  );
}

interface HighlightDetailModalProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  type: ERecommendationType;
  publishCode: string;
  ids: Array<number>;
}

export const HighlightDetailModal: FunctionComponent<
  HighlightDetailModalProps
> = ({ show, setShow, type, publishCode, ids }) => {
  const history = useHistory();
  const location = useLocation();
  const { accessToken } = useContext<TAppContext>(AppContext);
  const [studentHighlightDetailsData, setStudentHighlightDetailsData] =
    useState<Array<AnalyticsStudentResponse>>([]);
  const [questionHighlightDetailsData, setQuestionHighlightDetailsData] =
    useState<Array<IQuestionV2>>([]);

  const { sectionMap, selectedAssessment } =
    useContext<TAssessmentContext>(AssessmentContext);
  let questionArray: Array<number> = [];
  Array.from(selectedAssessment.sections).forEach((el) => {
    const section = sectionMap.get(el);
    if (section !== undefined) {
      questionArray = questionArray.concat([...section.question_ids]);
    }
  });

  useEffect(() => {
    (async () => {
      if (!show) return;
      if (type === ERecommendationType.QUESTION) {
        const questionResponse: Array<IQuestionV2> | null =
          await fetchQuestionFromIds(accessToken, publishCode, ids);
        if (questionResponse === null) {
          return;
        }
        setQuestionHighlightDetailsData(questionResponse);
      } else if (type === ERecommendationType.STUDENT) {
        const studentResponse = await fetchStudentFromIds(
          accessToken,
          publishCode,
          ids
        );
        if (studentResponse === null) {
          return;
        }
        setStudentHighlightDetailsData(studentResponse);
      }
    })();
  }, [show, ids]);

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Header>
        <Typography variant="h4">{/* <b>{props.modalTitle}</b> */}</Typography>
        <IconButton>
          <CloseIcon onClick={() => setShow(false)} />
        </IconButton>
      </Modal.Header>
      <Modal.Body>
        {type === ERecommendationType.QUESTION ? (
          questionHighlightDetailsData.map((el) => (
            <Button
              className="m-1"
              variant="contained"
              onClick={() =>
                history.push({
                  pathname: `${location.pathname}/question/${el.id}/preview`,
                  state: { questionData: el },
                })
              }
            >
              Q. {questionArray.indexOf(el.id) + 1}
            </Button>
          ))
        ) : (
          <ol>
            {/* Only display unique names and not for all the attempts */}
            {Array.from(
              new Set(studentHighlightDetailsData.map((el) => el.name))
            ).map((el) => (
              <li>{el}</li>
            ))}
          </ol>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="border-0 rounded-2 bg-danger text-white"
          onClick={() => {
            setShow(false);
          }}
        >
          <Typography className="m-0 p-0">Hide Details</Typography>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
