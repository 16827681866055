import { useContext, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { questionInfo } from "../../../../models/assessmentmodel";
import QuestionButton from "../QuestionButton/QuestionButton";
import { EAnswerType } from "../../../../utils/Enums";
import {
  QuestionContext,
  TQuestionContext,
} from "../../../../context/QuestionContext";

function getResponseStatusStudent(answer_type?: EAnswerType) {
  switch (answer_type) {
    case EAnswerType.VISITED_UNANSWERED:
      return "#EC2323"; // RED
    case EAnswerType.VISITED_ANSWERED:
      return "#3FB93D"; // GREEN
    case EAnswerType.FLAGGED_UNANSWERED:
      return "#3521B6"; // DARK BLUE
    case EAnswerType.FLAGGED_ANSWERED:
      return "#3521B6"; // DARK BLUE
  }
  return "#FFFFFF";
}

function getResponseTextStudent(answer_type?: EAnswerType) {
  switch (answer_type) {
    case EAnswerType.VISITED_UNANSWERED:
      return "Visited but unanswered"; // RED
    case EAnswerType.VISITED_ANSWERED:
      return "Answered"; // GREEN
    case EAnswerType.FLAGGED_ANSWERED:
      return "Answered and marked for review"; // DARK BLUE
    case EAnswerType.FLAGGED_UNANSWERED:
      return "Marked for review"; // LIGHT BLUE
  }
  return "Unvisited";
}

export default function StudentQuestionLegend() {
  const { assessmentQuestionInfo } =
    useContext<TQuestionContext>(QuestionContext);

  const calculatedAttempts = useMemo(() => {
    let attempts: Array<number> = Array(
      Object.keys(EAnswerType).length / 2
    ).fill(0);

    assessmentQuestionInfo.forEach((questionInfo: questionInfo) => {
      if (questionInfo.questionState != EAnswerType.UNVISITED)
        attempts[questionInfo.questionState]++;
      else attempts[attempts.length - 1]++;
    });

    return attempts;
  }, [assessmentQuestionInfo]);

  const calculateAttemptedQuestionType = (answerType: EAnswerType) => {
    if (answerType === EAnswerType.UNVISITED)
      return String(calculatedAttempts[calculatedAttempts.length - 1]);
    return String(calculatedAttempts[answerType]);
  };

  return (
    <>
      {Array(Object.keys(EAnswerType).length / 2)
        .fill(0)
        .map((val, idx) => {
          const showBadge = idx === EAnswerType.FLAGGED_ANSWERED;
          return (
            <Row className="mt-4 px-4">
              <Col md={3} xs={3}>
                <QuestionButton
                  color={getResponseStatusStudent(idx)}
                  text={calculateAttemptedQuestionType(idx)}
                  showBadge={showBadge}
                />
              </Col>
              <Col xs={9} className="align-self-center">
                {getResponseTextStudent(idx)}
              </Col>
            </Row>
          );
        })}
    </>
  );
}
