import {
  createContext,
  ReactNode,
  Dispatch,
  SetStateAction,
  useState,
} from "react";
import { IresponseBackend, TAttempt, TSession } from "../models/responsemodel";
import { useStickyState } from "../utils/hooks/useStickyState";

type TProps = {
  children: ReactNode;
};

export type TAttemptContext = {
  currentAttempt: TAttempt;
  setCurrentAttempt: Dispatch<SetStateAction<TAttempt>>;
  currentSession: TSession;
  setCurrentSession: Dispatch<SetStateAction<TSession>>;
  clearAttemptContext: () => void;
};

const initialContext: TAttemptContext = {
  currentAttempt: {
    id: 0,
    published_assessment_id: 0,
    last_submitted_on: undefined,
    sessions: [],
    metadata: {
      roll_no: "0",
      name: "",
    },
  },
  setCurrentAttempt: () => {},
  currentSession: {
    attempt_id: 0,
    started_on: new Date(),
    id: 0,
  },
  setCurrentSession: () => {},
  clearAttemptContext: (): void => {},
};

export const AttemptContext = createContext<TAttemptContext>(initialContext);

export const AttemptContextProvider = ({ children }: TProps): JSX.Element => {
  const [currentSession, setCurrentSession] = useStickyState<TSession>(
    initialContext.currentSession,
    "currentSession"
  );

  const [currentAttempt, setCurrentAttempt] = useStickyState<TAttempt>(
    initialContext.currentAttempt,
    "currentAttempt"
  );

  const clearAttemptContext = () => {
    setCurrentSession(initialContext.currentSession);
    setCurrentAttempt(initialContext.currentAttempt);
  };

  return (
    <AttemptContext.Provider
      value={{
        currentAttempt,
        setCurrentAttempt,
        currentSession,
        setCurrentSession,
        clearAttemptContext,
      }}
    >
      {children}
    </AttemptContext.Provider>
  );
};
