export const DataProcessor = (selectedAssessment, questionMap, sectionMap) => {
  let newRenderContent = {},
    instructionData = {};

  selectedAssessment.sections.map((section) => {
    const individualSection = sectionMap.get(section);
    newRenderContent[individualSection.name] = [];
    instructionData[individualSection.name] = [];
    const questionIDS = individualSection.question_ids;

    questionIDS.map((questionId) => {
      const questionData = questionMap.get(questionId);
      newRenderContent[individualSection.name].push(questionData);
    });

    sectionMap.forEach((key) => {
      instructionData[key.name] = key.instructions;
    });
  });
  return { newRenderContent, instructionData };
};
