import { useContext, useEffect, useState } from "react";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../context/AssessmentContext";
import { Col, Container, Row } from "react-bootstrap";
import QuestionNavigationPanel from "./Components/QuestionNavigationPanel/QuestionNavigationPanel";
import Header from "./Components/Header/Header";
import { ERole } from "../../utils/Enums";
import QuestionNavigationDrawer from "./Components/QuestionNavigationPanel/QuestionNavigationDrawer";
import { useMediaQuery } from "@mui/material";
import QuestionText from "./Components/QuestionText/QuestionText";
import QuestionType from "./Components/QuestionType/QuestionType";
import {
  QuestionContext,
  TQuestionContext,
} from "../../context/QuestionContext";
import StudentQuestionButtonPanel from "./Components/QuestionButtonPanel/StudentQuestionButtonPanel";
import { AppContext, TAppContext } from "../../context/AppContext";
import QuestionTopNavBar from "./Components/QuestionTopNavBar/QuestionTopNavBar";
import { checkAttemptEnded } from "../../utils/dateUtils";
import { AttemptContext, TAttemptContext } from "../../context/AttemptContext";
import { getServerTime } from "../../api/user";
import Solution from "./Components/Solution/Solution";
import ProofRead from "./Components/proofread-mode/ProofRead";

type TProps = {
  proofRead: boolean;
  setProofRead: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function StudentResponseScreen(props: TProps) {
  const { setDrawerOpen, accessToken } = useContext<TAppContext>(AppContext);
  const {
    publishInfo,
    sectionMap, // List of sections.
    currentQuestionNumber, // The current question number
    setCurrentQuestionNumber,
    currentSectionNumber, // current section to which question belongs to
    setCurrentSectionNumber,
    currentQuestionData,
    showQNavDrawer,
    setShowQNavDrawer,
    selectedAssessment,
    questionMap,
    setCurrentQuestionData,
  } = useContext<TAssessmentContext>(AssessmentContext); // Get the data from the context
  const { assessmentQuestionInfo } =
    useContext<TQuestionContext>(QuestionContext);
  const { currentSession } = useContext<TAttemptContext>(AttemptContext);

  const [hasFinishedAttempt, setHasFinishedAttempt] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const isDesktopOrLaptop = useMediaQuery("(min-width: 992px)");

  const fetchDeadline = async () => {
    const serverTime = await getServerTime(accessToken);
    const attemptEnded = await checkAttemptEnded(
      serverTime,
      publishInfo.format,
      publishInfo?.end_time,
      currentSession?.started_on,
      publishInfo?.duration
    );
    setHasFinishedAttempt(attemptEnded);
  };

  useEffect(() => {
    fetchDeadline();
  }, []);

  function getCurrentQuestion() {
    if (questionMap.size === 0) {
      console.log("No questions in the list");
      return;
    }
    console.log(
      "getting current question data",
      currentSectionNumber,
      currentQuestionNumber
    );
    const sectionId = selectedAssessment.sections[currentSectionNumber];
    if (
      currentQuestionNumber >= sectionMap.get(sectionId)!.question_ids.length
    ) {
      return;
    }
    const questionId =
      sectionMap.get(sectionId)!.question_ids[currentQuestionNumber];
    const questionData = questionMap.get(questionId);
    if (questionData!!) setCurrentQuestionData(questionData);
  }

  useEffect(() => {
    // get the current question
    setIsLoading(true);
    getCurrentQuestion();
    setIsLoading(false);
    // TODO: Add use-effect if flag variable of current question is changed
  }, [currentQuestionNumber, currentSectionNumber, questionMap, sectionMap]);

  useEffect(() => {
    setDrawerOpen(false);
    return () => setDrawerOpen(true);
  });

  return isLoading ? (
    <div></div>
  ) : (
    <div className="w-100">
      <QuestionTopNavBar
        role={ERole.STUDENT}
        assessmentName={selectedAssessment.name}
        showQNavDrawer={showQNavDrawer}
        setShowQNavDrawer={setShowQNavDrawer}
        proofRead={props.proofRead}
        setProofRead={props.setProofRead}
        setShowSubmitModal={() => {}}
        edit={false}
        setEdit={() => {}}
        handleSubmit={() => {}}
        hasFinishedAttempt
      />
      <ProofRead proofRead={props.proofRead} setProofRead={props.setProofRead}>
        <Container
          fluid
          className="p-0 d-flex justify-content-center"
          style={{ height: "92%" }}
        >
          <Col className="p-0 d-flex flex-row justify-content-center">
            <Row className="w-100 d-flex flex-row justify-content-center">
              <Col xs={12} lg={8} className="d-flex flex-column m-2 m-lg-4">
                {/* Header */}
                <div className="flex-grow-1">
                  <Header
                    currentQuestionNumber={currentQuestionNumber}
                    setCurrentQuestionNumber={setCurrentQuestionNumber}
                    currentSectionNumber={currentSectionNumber}
                    setCurrentSectionNumber={setCurrentSectionNumber}
                    sectionMap={sectionMap}
                  />

                  <div
                    style={{
                      height: "62vh",
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    {/* QuestionText */}
                    <QuestionText images={currentQuestionData.body.images}>
                      {currentQuestionData.body.text}
                    </QuestionText>

                    {/* QuestionType */}
                    <QuestionType
                      questionType={currentQuestionData.question_type}
                      allowChange={false}
                    />
                  </div>
                  <div className="mt-2 mb-2">
                    Answer : {currentQuestionData.answer}
                  </div>
                  <div>
                    <Solution solution={currentQuestionData.solution} />
                  </div>
                </div>
              </Col>

              <StudentQuestionButtonPanel
                hasFinishedAttempt={hasFinishedAttempt}
              />
              {/* Question Navigation Panel */}
              {isDesktopOrLaptop && !props.proofRead ? (
                <QuestionNavigationPanel
                  proofRead={props.proofRead}
                  role={ERole.STUDENT}
                  sectionMap={sectionMap}
                  allQuestionInfo={assessmentQuestionInfo}
                />
              ) : (
                <QuestionNavigationDrawer
                  show={showQNavDrawer}
                  setShowHandler={setShowQNavDrawer}
                  role={ERole.STUDENT}
                  sectionMap={sectionMap}
                  allQuestionInfo={assessmentQuestionInfo}
                  proofRead={props.proofRead}
                />
              )}
            </Row>
          </Col>
        </Container>
      </ProofRead>
    </div>
  );
}
