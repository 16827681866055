import { useState, useContext, useCallback } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Checkbox } from "@mui/material";
import { Row, Modal, Spinner } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import CustomButton from "../../../../components/CustomComponent/CustomButton";
import styles from "./AddQuestionButton.module.scss";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../context/AssessmentContext";
import { AppContext, TAppContext } from "../../../../context/AppContext";
import {
  EAssessmentFormat,
  EQuestionType,
  ESourceType,
  ETemplateType,
} from "../../../../utils/Enums";
import { TContextProps, TModalProps } from "./ManualQuestionHelpers";
import {
  QuestionTypeEnumToString,
  QuestionTypeStringToEnumMap,
} from "./ManualQuestionHelpers";
import { useDropzone } from "react-dropzone";

import {
  getS3URL,
  refetchData,
  uploadFiles,
  validateMarks,
  validateOrg,
  validateSection,
} from "./AddQuestionHelpers";
import { ingestQuestion } from "../../../../api/ingestion";
import { useStickyState } from "../../../../utils/hooks/useStickyState";
import { addComprehension } from "../../../../api/comprehension";
import { TComprehension } from "../../../../models/assessmentmodel";

export function UploadImageModal(props: TModalProps) {
  const { showModal, setShowModal } = props;
  const { org, accessToken } = useContext<TAppContext>(AppContext);

  const {
    sectionMap,
    setSectionMap,
    currentSectionNumber,
    setCurrentSectionNumber,
    setCurrentQuestionNumber,
    selectedAssessment,
    questionMap,
    setQuestionMap,
    comprehensionMap,
    setComprehensionMap,
  } = useContext<TAssessmentContext>(AssessmentContext);

  const [questionType, setQuestionType] = useState<EQuestionType>(
    EQuestionType.MCQ
  );
  // We only want to show section names in the dropdown so this goes with the assumption
  // that all the section names are different.
  const [selectedSectionId, setSelectedSectionId] = useState<number>(
    sectionMap.get(selectedAssessment.sections[currentSectionNumber])!.id
  );
  const [marksIfCorrect, setMarksIfCorrect] = useStickyState<string>(
    "4",
    "marksIfCorrect"
  );
  const [marksIfIncorrect, setMarksIfIncorrect] = useStickyState<string>(
    "-1",
    "marksIfIncorrect"
  );
  const [loading, setLoading] = useState<boolean>(false);

  const [files, setFiles] = useState<any>([]);

  const contextProps: TContextProps = {
    sectionMap,
    setSectionMap,
    questionMap,
    setQuestionMap,
    setCurrentSectionNumber,
    setCurrentQuestionNumber,
    setComprehensionMap,
  };

  //Comprehension
  const [selectedComprehensionId, setSelectedComprehensionId] =
    useState<number>(
      selectedAssessment.format === EAssessmentFormat.NTA
        ? comprehensionMap.keys().next().value || -1
        : -1
    );
  const [isPartComprehension, setIsPartComprehension] =
    useState<boolean>(false);

  const closeModal = () => {
    setFiles([]);
    setIsPartComprehension(false);
    setSelectedComprehensionId(comprehensionMap.keys().next().value || -1);
    setShowModal(false);
  };

  document.onpaste = function (event) {
    if (event.clipboardData && props.showModal) {
      const items = event.clipboardData.files;
      const file = items[0];
      if (file) {
        setFiles([
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ]);
      }
    }
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    // Do something with the files
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    onDrop,
    multiple: false,
  });
  const preview = files.map((file) => (
    <div key={file.name}>
      <div>
        <img
          src={file.preview}
          style={{ display: "block", width: "100%", height: "auto" }}
          alt="preview_image"
        />
      </div>
    </div>
  ));

  // TODO: Add a loading screen while image is being uploaded
  return (
    <Modal
      show={showModal}
      onHide={() => closeModal()}
      contentClassName={styles.modalStyle}
    >
      <Modal.Header closeButton>
        <Modal.Title>Screenshot To Question</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Question type */}
        <Row key="qtype">
          <TextField
            required
            select // Given TextField is a dropdown.
            value={QuestionTypeEnumToString.get(questionType)}
            label="Question Type"
            variant="filled"
            onChange={(e) =>
              setQuestionType(QuestionTypeStringToEnumMap.get(e.target.value)!)
            }
            className="w-50"
          >
            <MenuItem value="MCQ">MCQ</MenuItem>
            {/* This is commented since analytics is not supported yet. */}
            <MenuItem value="MCMCQ">Multiple Correct MCQ</MenuItem>
            <MenuItem value="Numerical">Numerical</MenuItem>
          </TextField>
        </Row>

        {/* Section Name */}
        <Row className="mt-2" key="sname">
          <TextField
            required
            select // Given TextField is a dropdown.
            value={sectionMap.get(selectedSectionId)!.name}
            label="Section Name"
            variant="filled"
            className="w-50"
          >
            {Array.from(sectionMap.values()).map((section) => (
              <MenuItem
                value={section.name}
                key={section.id}
                onClick={() => setSelectedSectionId(section.id)}
              >
                {section.name}
              </MenuItem>
            ))}
          </TextField>
        </Row>

        {/* Marks if correct */}
        <Row className="mt-2" key="marks">
          <TextField
            required
            value={marksIfCorrect}
            label="Marks if correct"
            variant="filled"
            type="text"
            inputMode="numeric"
            onChange={(e) => setMarksIfCorrect(e.target.value)}
            className="w-50"
          />
        </Row>

        {/* Marks if incorrect */}
        <Row className="mt-2" key="wrongmarks">
          <TextField
            required
            value={marksIfIncorrect}
            label="Marks if incorrect"
            variant="filled"
            type="text"
            onChange={(e) => setMarksIfIncorrect(e.target.value)}
            className="w-50"
          />
        </Row>

        {/* Image selection */}
        <Row className="mt-2" key="image">
          <div
            style={{ height: "150px" }}
            {...getRootProps({ className: styles.dropzone })}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <div className="text-center">
                <p>
                  Drag 'n' drop or click to select image.
                  <br /> <br />
                  Click Submit and wait while we convert your screenshot into an
                  editable question.
                </p>
              </div>
            )}
          </div>
          <aside>{preview}</aside>
        </Row>
        {/*Comprehension */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Checkbox
            onChange={(e) => setIsPartComprehension(e.target.checked)}
          />
          Is this question part of comprehension?
        </div>
        {isPartComprehension &&
          selectedAssessment.format === EAssessmentFormat.NTA && (
            <>
              <p>Select a comprehension from below or create a new one</p>
              <TextField
                required
                select // Given TextField is a dropdown.
                value={selectedComprehensionId}
                label="Comprehension"
                variant="filled"
                onChange={(e) =>
                  setSelectedComprehensionId(Number(e.target.value))
                }
                className="w-50"
              >
                {Array.from(comprehensionMap.values()).map((comp, id) => {
                  return (
                    <MenuItem value={comp.id}>
                      {comp.body.text.slice(0, 50)}...
                    </MenuItem>
                  );
                })}
                <MenuItem value={-1}>Add a comprehension</MenuItem>
              </TextField>
            </>
          )}
      </Modal.Body>
      <Modal.Footer>
        <CustomButton
          loading={loading}
          onClick={async () => {
            setLoading(true);
            const fileHash = await uploadFiles(files);
            if (!fileHash) {
              setLoading(false);
              return;
            }
            if (
              !validateMarks(marksIfCorrect, marksIfIncorrect) ||
              !validateSection(contextProps.sectionMap, selectedSectionId) ||
              !validateOrg(org)
            ) {
              setLoading(false);
              return;
            }
            let comprehension: TComprehension = {
              id: selectedComprehensionId,
              assessment_id: -1,
              body: {
                text: "",
              },
            };
            if (isPartComprehension && selectedComprehensionId === -1) {
              comprehension = await addComprehension(
                accessToken,
                selectedAssessment.id,
                {
                  text: "",
                  images: [],
                }
              );
              if (!comprehension) {
                setLoading(false);
                return;
              }
            }
            const success = await ingestQuestion(accessToken, {
              template: ETemplateType.IMAGE_TEMPLATE1,
              expected_question_num: 1,
              hash: fileHash,
              org_id: org!!.id,
              name: org!!.name,
              source_type: ESourceType.SCREENSHOT,
              object_store_url: getS3URL(fileHash + "/"), // string from question Hash
              question_metadata: [
                {
                  start: 1,
                  end: 1,
                  type: questionType,
                  subject: "undefined",
                },
              ],
              marking_scheme: [
                {
                  marks_if_correct: Number(marksIfCorrect),
                  marks_if_incorrect: Number(marksIfIncorrect),
                  marks_if_unattempted: 0,
                  partial_marking: false,
                },
              ],
              assessment_id: selectedAssessment.id,
              section_id: selectedSectionId,
              comprehension_id: isPartComprehension
                ? comprehension.id
                : undefined,
            });
            if (!success) {
              setLoading(false);
              return;
            }
            refetchData(
              accessToken,
              selectedAssessment,
              selectedSectionId,
              contextProps
            );
            setLoading(false);
            closeModal();
          }}
        >
          {loading ? <Spinner animation="border" role="status" /> : "Submit"}
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
}
