import styles from "../../../QuestionScreen.module.scss";
import classNames from "classnames/bind";
import { EAnswerType, EQuestionType, ERole } from "../../../../../utils/Enums";
import { useContext, useEffect, useState } from "react";
import { AppContext, TAppContext } from "../../../../../context/AppContext";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../../context/AssessmentContext";
import {
  TAttemptContext,
  AttemptContext,
} from "../../../../../context/AttemptContext";
import { setResponse } from "../../../../../api/responses";
import { displayErrorToast } from "../../../../../utils/ToastUtils";
import {
  TQuestionContext,
  QuestionContext,
} from "../../../../../context/QuestionContext";
import TipTap from "../../../../../utils/TipTap/TipTap";
import QuestionImageModal from "../../QuestionImageModal/QuestionImageModal";

import "katex/dist/katex.min.css";
import { findSelectedOptions } from "../../../../../utils/assessment";
import useMediaQuery from "@mui/material/useMediaQuery";
import { parseMapFromJSON } from "../../../../../utils/hooks/useStickyState";
import moment from "moment";
let cx = classNames.bind(styles);

/**
 * Takes allow change as a props which is used to determine if the user can type in answers and modify it or not
 */

type TProps = {
  allowChange?: boolean;
};

export default function MultipleChoice({ allowChange }: TProps) {
  const isDesktopOrLaptop = useMediaQuery("(min-width: 992px)");

  const { role, accessToken } = useContext<TAppContext>(AppContext);
  const { currentQuestionData, limits, setLimits } =
    useContext<TAssessmentContext>(AssessmentContext);
  const [answer, setAnswer] = useState<string>("");
  const { assessmentQuestionInfo, setAssessmentQuestionInfo } =
    useContext<TQuestionContext>(QuestionContext);

  const { currentSession } = useContext<TAttemptContext>(AttemptContext);

  const [selectedOptions, setSelectedOptions] = useState<Array<number>>([]);
  const [correctOptions, setCorrectOptions] = useState<Array<number>>([]);
  const [disabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    let ans =
      role === ERole.STUDENT
        ? assessmentQuestionInfo.get(currentQuestionData.question_id)
            ?.questionAnswer
        : currentQuestionData.answer;
    setAnswer(ans ?? "");
  }, [assessmentQuestionInfo, currentQuestionData, role]);

  useEffect(() => {
    setSelectedOptions(findSelectedOptions(answer));
    setCorrectOptions(findSelectedOptions(currentQuestionData.answer));
    if (!allowChange) {
      setIsDisabled(false);
    } else if (role === ERole.TEACHER) setIsDisabled(false);
    else setIsDisabled(limits.get(currentQuestionData.limitId)!!.limit <= 0);
  }, [currentQuestionData.question_id, answer]);

  async function handleOptionClick(option: string) {
    let updatedQuestionInfo = new Map(assessmentQuestionInfo);
    const qInfo = updatedQuestionInfo.get(currentQuestionData.question_id)!!;
    if (
      disabled &&
      qInfo.questionState !== EAnswerType.VISITED_ANSWERED &&
      qInfo.questionState !== EAnswerType.FLAGGED_ANSWERED
    )
      return;
    const stateChangeMap = new Map([
      [EAnswerType.UNVISITED, EAnswerType.VISITED_ANSWERED],
      [EAnswerType.FLAGGED_ANSWERED, EAnswerType.FLAGGED_ANSWERED],
      [EAnswerType.VISITED_ANSWERED, EAnswerType.VISITED_ANSWERED],
      [EAnswerType.FLAGGED_UNANSWERED, EAnswerType.FLAGGED_ANSWERED],
      [EAnswerType.VISITED_UNANSWERED, EAnswerType.VISITED_ANSWERED],
    ]);
    /*
    If the question is a MCQ type, just set answer to selected option
    Otherwise the question is MCMCQ type.
      Now if the option is already seleted, remove it from the answer. Otherwise add it to the answer.
      We also need to update the selected options array to reflect the necessary changes

      While adding the option, lets say option C we append C, to the answer and add C to the array
    */
    if (currentQuestionData.question_type === EQuestionType.MCQ) {
      qInfo.questionAnswer = option;
    } else if (qInfo.questionAnswer?.includes(option)) {
      qInfo.questionAnswer = qInfo.questionAnswer?.replace(option + ",", "");
    } else {
      qInfo.questionAnswer = qInfo.questionAnswer + option + ",";
    }

    if (
      qInfo.questionState === EAnswerType.UNVISITED ||
      qInfo.questionState === EAnswerType.FLAGGED_UNANSWERED ||
      qInfo.questionState === EAnswerType.VISITED_UNANSWERED
    ) {
      let updatedLimits = new Map(limits);
      let updatedLimit = limits.get(currentQuestionData.limitId)!!;
      updatedLimit.limit -= 1;
      updatedLimits.set(currentQuestionData.limitId, updatedLimit);
      setLimits(updatedLimits);
    }

    setSelectedOptions(findSelectedOptions(qInfo.questionAnswer));
    qInfo.questionState = stateChangeMap.get(qInfo.questionState)!!;
    if (
      qInfo.questionAnswer === "" &&
      qInfo.questionState === EAnswerType.VISITED_ANSWERED
    ) {
      qInfo.questionState = EAnswerType.VISITED_UNANSWERED;
      let updatedLimits = new Map(limits);
      let updatedLimit = limits.get(currentQuestionData.limitId)!!;
      updatedLimit.limit += 1;
      updatedLimits.set(currentQuestionData.limitId, updatedLimit);
      setLimits(updatedLimits);
    } else if (
      qInfo.questionAnswer === "" &&
      qInfo.questionState === EAnswerType.FLAGGED_ANSWERED
    ) {
      qInfo.questionState = EAnswerType.FLAGGED_UNANSWERED;
      let updatedLimits = new Map(limits);
      let updatedLimit = limits.get(currentQuestionData.limitId)!!;
      updatedLimit.limit += 1;
      updatedLimits.set(currentQuestionData.limitId, updatedLimit);
      setLimits(updatedLimits);
    }
    qInfo.questionLastUpdatedOn = moment
      .utc(moment.now())
      .toISOString()
      .slice(0, -1);
    const success = await setResponse(
      accessToken,
      currentSession.id,
      qInfo,
      parseMapFromJSON(window.localStorage.getItem("unpushedAttempts"))
    );
    if (success) {
      updatedQuestionInfo.set(currentQuestionData.question_id, qInfo);
      setAssessmentQuestionInfo(updatedQuestionInfo);
    } else {
      displayErrorToast(
        "Something went wrong, you might want to check your local time or internet connection"
      );
    }
  }

  return (
    <>
      {disabled &&
        role === ERole.STUDENT &&
        !(
          assessmentQuestionInfo.get(currentQuestionData.question_id)
            ?.questionState === EAnswerType.FLAGGED_ANSWERED ||
          assessmentQuestionInfo.get(currentQuestionData.question_id)
            ?.questionState === EAnswerType.VISITED_ANSWERED
        ) && (
          <div
            className="bg-warning p-2 rounded-4"
            style={{ textAlign: "center" }}
          >{`You have attempted maximum questions allowed in the range ${
            limits.get(currentQuestionData.limitId)?.start_question_idx
          } to ${
            limits.get(currentQuestionData.limitId)?.end_question_idx
          } in this section`}</div>
        )}
      {currentQuestionData.options.map((option, index) => {
        //Following piece of code sets the bgcolor of the selected option or the correct option as per the role of the user
        let isCorrectAnswer = correctOptions.includes(index);
        let optionContainerClass = cx({
          active: selectedOptions.includes(index),
          correct:
            (role === ERole.TEACHER && selectedOptions.includes(index)) ||
            (role === ERole.STUDENT &&
              allowChange !== true &&
              selectedOptions.includes(index) &&
              isCorrectAnswer === true),
          incorrect:
            role === ERole.STUDENT &&
            allowChange !== true &&
            selectedOptions.includes(index) &&
            isCorrectAnswer === false,
        });

        return (
          <div
            className={`d-flex align-items-center my-2 ${optionContainerClass}`}
            onClick={() =>
              role === ERole.STUDENT && allowChange
                ? handleOptionClick(String.fromCharCode(65 + index))
                : {}
            }
          >
            <div
              className={`${styles.optionName} text-center d-flex align-items-center justify-content-center me-2 p-4`}
              style={{
                cursor: role === ERole.STUDENT ? "pointer" : "default",
                height: isDesktopOrLaptop ? "80px" : "60px",
                minWidth: isDesktopOrLaptop ? "80px" : "60px",
              }}
            >
              {String.fromCharCode(65 + index)}
            </div>
            <div
              className={`d-flex flex-column flex-grow-1 ${styles.OptionCard}`}
              style={{
                cursor: role === ERole.STUDENT ? "pointer" : "default",
                overflowX: "auto",
              }}
            >
              <div>
                {option.text === "" &&
                  option.images?.length === 0 &&
                  `Option ${String.fromCharCode(65 + index)}`}
                {option.text && <TipTap text={option.text} />}
                {currentQuestionData.options[index].images?.map((el) => (
                  <QuestionImageModal src={el} />
                ))}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
