import { Dispatch, FunctionComponent, SetStateAction, useContext } from "react";
import {
  TPublishedAssessmentData,
  TPublishInfo,
} from "../../../models/assessmentmodel";
import { EAccessLevel, EPublishStatus } from "../../../utils/Enums";
import { AppContext, TAppContext } from "../../../context/AppContext";

// MUI imports
import {
  MenuItem,
  Typography,
  Drawer,
  Divider,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import EditIcon from "@mui/icons-material/Edit";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { disableAnalytics } from "../../../utils/analyticsUtils";

interface ActionsDrawerProps {
  selectedTest: TPublishedAssessmentData;
  serverTime: any;
  anchorEl: null | HTMLElement;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLElement>>;
  handleOpenTest: (test: TPublishedAssessmentData) => void;
  handleShare: (
    testsName: string,
    publishCode: string,
    publishInfo?: TPublishInfo
  ) => void;
  handleAnalytics: (test: TPublishedAssessmentData) => void;
  handleDuplicateTest: (testId: number) => void;
  setShowEditPublishDetailsModal: Dispatch<SetStateAction<boolean>>;
  setShowEditAssessmentModal: Dispatch<SetStateAction<boolean>>;
  setShowConfirmationModal: Dispatch<SetStateAction<boolean>>;
}

const ActionsDrawer: FunctionComponent<ActionsDrawerProps> = ({
  selectedTest,
  serverTime,
  anchorEl,
  setAnchorEl,
  handleOpenTest,
  handleAnalytics,
  handleShare,
  handleDuplicateTest,
  setShowConfirmationModal,
  setShowEditAssessmentModal,
  setShowEditPublishDetailsModal,
}) => {
  const { id } = useContext<TAppContext>(AppContext);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isPublished = (publishInfo?: TPublishInfo) => {
    return publishInfo && publishInfo?.status === EPublishStatus.PUBLISHED;
  };

  return (
    <Drawer
      PaperProps={{
        elevation: 0,
        style: {
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        },
      }}
      anchor={"bottom"}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <div className="p-3 d-flex flex-row align-items-center">
        <AssignmentIcon fontSize="large" />
        <div className="flex-column ms-3">
          <h5>{selectedTest.assessment.name}</h5>
          <>
            {selectedTest.publishInfo ? (
              <Typography variant="subtitle2">
                <b className="ms-1">{selectedTest.publishInfo?.publish_code}</b>{" "}
                <i>
                  (
                  {selectedTest.publishInfo?.access_level === EAccessLevel.BATCH
                    ? selectedTest.batchInfo?.name
                    : "PUBLIC"}
                  )
                </i>
              </Typography>
            ) : (
              <Typography>Unpublished</Typography>
            )}
          </>
        </div>
      </div>
      <Divider />
      <MenuItem
        onClick={() => {
          handleClose();
          handleOpenTest(selectedTest);
        }}
      >
        <ListItemIcon>
          <OpenInNewIcon color="success" />
        </ListItemIcon>
        <ListItemText>Open Test</ListItemText>
      </MenuItem>
      <MenuItem
        disabled={
          !isPublished(selectedTest.publishInfo) ||
          (parseInt(selectedTest.assessment?.created_by!!) !== id &&
            disableAnalytics(
              selectedTest.publishInfo?.format,
              serverTime,
              selectedTest.publishInfo?.end_time
            )) ||
          selectedTest.publishInfo?.status === EPublishStatus.UNPUBLISHED
        }
        onClick={() => {
          handleClose();
          handleAnalytics(selectedTest);
        }}
      >
        <ListItemIcon>
          <AnalyticsIcon htmlColor="#0089AF" />
        </ListItemIcon>
        <ListItemText>View Analytics</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleShare(
            selectedTest.assessment.name,
            selectedTest.publishInfo?.publish_code!!,
            selectedTest.publishInfo
          );
          handleClose();
        }}
      >
        <ListItemIcon>
          <ShareIcon htmlColor="#3F65C8" />
        </ListItemIcon>
        <ListItemText>Share</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem
        disabled={
          parseInt(selectedTest.assessment.created_by) !== id ||
          !selectedTest.publishInfo
        }
        onClick={() => {
          setShowEditPublishDetailsModal(true);
          handleClose();
        }}
      >
        <ListItemIcon>
          <EditIcon htmlColor="#22343C" />
        </ListItemIcon>
        <ListItemText>Edit Publish Info</ListItemText>
      </MenuItem>
      <MenuItem
        disabled={parseInt(selectedTest.assessment.created_by) !== id}
        onClick={() => {
          setShowEditAssessmentModal(true);
          handleClose();
        }}
      >
        <ListItemIcon>
          <DriveFileRenameOutlineIcon color="primary" />
        </ListItemIcon>
        <ListItemText>Rename</ListItemText>
      </MenuItem>
      <MenuItem
        disabled={parseInt(selectedTest.assessment.created_by) !== id}
        onClick={() => {
          handleDuplicateTest(selectedTest.assessment.id);
          handleClose();
        }}
      >
        <ListItemIcon>
          <FileCopyIcon />
        </ListItemIcon>
        <ListItemText>Duplicate</ListItemText>
      </MenuItem>
      <MenuItem
        disabled={parseInt(selectedTest.assessment.created_by) !== id}
        onClick={() => {
          setShowConfirmationModal(true);
          handleClose();
        }}
      >
        <ListItemIcon>
          <DeleteIcon color="error" />
        </ListItemIcon>
        <ListItemText>Delete</ListItemText>
      </MenuItem>
    </Drawer>
  );
};

export default ActionsDrawer;
