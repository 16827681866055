import { Route, Switch } from "react-router-dom";
import QuestionView from "../question/QuestionView";
import IngestionView from "../ingestion/IngestionView";
import ManageAssessmentScreen from "../instructionScreen/ManageAssessmentScreen/ManageAssessmentScreen";
import TeacherAnalytics from "../analytics/TeacherAnalytics";
import { AnalyticsContextProvider } from "../../context/AnalyticsContext";
import PrintPDF from "../PrintPDF/printPDF";
import "../PrintPDF/index.scss";
import AnalyticsQuestionPreviewScreen from "../analytics/Screens/AnalyticsQuestionPreviewScreen";

export default function MyLibraryView() {
  return (
    <Switch>
      <Route exact path="/home/assessments/:id/manage">
        <ManageAssessmentScreen />
      </Route>

      <Route exact path="/home/assessments/:id/question">
        <QuestionView />
      </Route>
      <Route exact path="/home/assessments/:id/print">
        <PrintPDF />
      </Route>
      <AnalyticsContextProvider>
        <Route exact path="/home/assessments/:id/analytics/:publishCode">
          <TeacherAnalytics />
        </Route>
        <Route
          exact
          path="/home/assessments/:id/analytics/:publishCode/question/:id/preview"
        >
          <AnalyticsQuestionPreviewScreen />
        </Route>
      </AnalyticsContextProvider>

      <Route exact path="/home/assessments/:id/ingestion">
        <IngestionView />
      </Route>
    </Switch>
  );
}
