import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DataGrid } from "@mui/x-data-grid";
import "./../../../css/index.css";
import { Grid, IconButton } from "@mui/material";
import CustomPagination from "../../../components/Pagination";
import { Button, useMediaQuery } from "@mui/material";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import Styles from "./StudentsStatistics.module.scss";
import {
  AnalyticsContext,
  TAnalyticsContext,
} from "./../../../context/AnalyticsContext";
import { EAnalyticsTab, EAssessmentFormat } from "../../../utils/Enums";
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../context/AssessmentContext";
import StudentStatisticsActionsDrawer from "../Components/StudentStatisticsActionDrawer";

export default function StudentStatistics(props) {
  const { setSelectedTab } = useContext<TAnalyticsContext>(AnalyticsContext);
  const { selectedAssessment } =
    useContext<TAssessmentContext>(AssessmentContext);
  const history = useHistory();
  const isMobile = useMediaQuery("(max-width: 576px)");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [studentData, setStudentData] = useState<any>();

  const showBottomDrawer = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const columnsDesktop = [
    {
      field: "student_id",
      headerName: "Students Statistics",
      width: 100,
      hide: true,
    },
    {
      field: "name",
      headerName: "Student Name",
      flex: 3,
      headerClassName: Styles.active,
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "roll_no",
      headerName: "Roll Number",
      flex: 2,
      headerClassName: Styles.active,
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "score",
      headerName: "Score",
      flex: 2,
      headerClassName: Styles.active,
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "correct",
      headerName: "Correct",
      cellClassName: "super-app-theme--cell",
      flex: 2,
      headerClassName: Styles.active,
    },
    {
      field: "incorrect",
      headerName: "Incorrect",
      flex: 2,
      headerClassName: Styles.active,
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "unattempted",
      headerName: "Unattempted",
      flex: 2,
      headerClassName: Styles.active,
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Analytics",
      flex: 2,
      headerClassName: Styles.active,
      renderCell: (el) => {
        return (
          <Tooltip title={`Open ${el.row.name}'s Analytics`}>
            <Button
              onClick={() => {
                setSelectedTab(EAnalyticsTab[EAnalyticsTab.OVERVIEW]);
                history.push(
                  `/home/attempt/${
                    props.publishCode
                  }/analytics/student/${Number(el.id)}`
                );
              }}
            >
              <AnalyticsIcon fontSize="large" />
            </Button>
          </Tooltip>
        );
      },
    },
    {
      field: "action2",
      type: "actions",
      headerName: "Responses",
      flex: 2,
      headerClassName: Styles.active,
      renderCell: (el) => {
        return (
          <Tooltip title={`View responses`}>
            <Button
              onClick={() => {
                history.push(
                  `/home/response/${props.publishCode}/student/${
                    EAssessmentFormat[selectedAssessment.format]
                  }/${Number(el.id)}`
                );
              }}
            >
              <DescriptionIcon fontSize="large" />
            </Button>
          </Tooltip>
        );
      },
    },
  ];

  const columnsMobile = [
    {
      field: "student_id",
      headerName: "Students Statistics",
      width: 100,
      hide: true,
    },
    {
      field: "name",
      headerName: "Student Name",
      flex: 3,
      headerClassName: Styles.active,
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "roll_no",
      headerName: "Roll Number",
      flex: 2,
      headerClassName: Styles.active,
      cellClassName: "super-app-theme--cell",
    },
    {
      field: "actions",
      headerName: "Options",
      headerClassName: Styles.active,
      flex: 2,
      renderCell: (cellValues: any) => (
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={Boolean(anchorEl) ? "long-menu" : undefined}
          aria-expanded={Boolean(anchorEl) ? "true" : undefined}
          aria-haspopup="true"
          onClick={(e) => {
            setStudentData(
              rows.find((el) => el.student_id === Number(cellValues.id))
            );
            console.log(
              rows.find((el) => el.student_id === Number(cellValues.id))
            );
            showBottomDrawer(e);
          }}
        >
          <MoreVertIcon />
        </IconButton>
      ),
    },
  ];

  const rows = React.useMemo(
    () => props.data.student_stats,
    [props.data.student_stats]
  );

  return (
    <Grid
      container
      className="pt-2 px-2 my-0 d-flex align-items-center pb-md-4 pb-0"
    >
      <Grid item md={12} xs={12} className="overflow-auto">
        <Box
          sx={{
            height: "70vh",
            "& .super-app-theme--header": {
              backgroundColor: "#fff",
              color: "#7F828A",
              fontSize: "16px",
              fontWeight: "700",
              border: "0",
            },
            "& .super-app-theme--cell": {
              fontSize: "16px",
              border: "0",
            },
          }}
        >
          <DataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: "roll_no", sort: "asc" }],
              },
            }}
            pageSize={10}
            rowsPerPageOptions={[10]}
            components={{
              Pagination: CustomPagination,
            }}
            getRowId={(r) => r.student_id}
            rows={rows}
            columns={isMobile ? columnsMobile : columnsDesktop}
            style={{ border: "0" }}
          />

          {isMobile && (
            <StudentStatisticsActionsDrawer
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              studentName={studentData?.name}
              rollNumber={studentData?.roll_no}
              score={studentData?.score}
              correct={studentData?.correct}
              incorrect={studentData?.incorrect}
              studentId={studentData?.student_id}
              publishCode={props.publishCode}
              unattempted={studentData?.unattempted}
              assessmentFormat={selectedAssessment.format}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
