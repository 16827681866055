import { FunctionComponent } from "react";
import { IquestionData } from "../../../models/assessmentmodel";
import { EQuestionType } from "../../../utils/Enums";
import MultipleChoice from "./MultipleChoice/MultipleChoice";
import Numerical from "./Numerical/Numerical";

interface QuestionTypeProps {
  currentQuestion: IquestionData;
  lockStatus?: boolean;
  disabled?: boolean;
  response?: boolean;
  isCorrect?: boolean;
}

const QuestionType: FunctionComponent<QuestionTypeProps> = ({
  currentQuestion,
  lockStatus = false,
  disabled = false,
  response = false,
  isCorrect = false,
}) => {
  switch (currentQuestion.question_type) {
    case EQuestionType.MCMCQ:
    case EQuestionType.MCQ:
      return (
        <MultipleChoice
          response={response}
          isCorrect={isCorrect}
          currentQuestion={currentQuestion}
          lockStatus={lockStatus}
          disabled={disabled}
        />
      );
    case EQuestionType.NUMERICAL:
      return (
        <Numerical
          currentQuestion={currentQuestion}
          lockStatus={lockStatus}
          disabled={disabled}
        />
      );
    default:
      return <div />;
  }
};

export default QuestionType;
