import {
  Dispatch,
  FunctionComponent,
  MutableRefObject,
  SetStateAction,
  useRef,
} from "react";
import { Button, ButtonGroup, Col, Form, Row } from "react-bootstrap";
import { ImSigma } from "react-icons/im";
import { RiImageAddFill } from "react-icons/ri";
import { IquestionData } from "../../../../models/assessmentmodel";
import TipTap from "../../../../utils/TipTap/TipTap";
import { EditImage } from "../EditImage/EditImage";

interface SolutionEditProps {
  updatedQuestionData: MutableRefObject<IquestionData>;
  reRender: boolean;
  setReRender: Dispatch<SetStateAction<boolean>>;
  handleShow: Function;
}

const SolutionEdit: FunctionComponent<SolutionEditProps> = (props) => {
  const cursorPosition = useRef<number>(0);
  const images = props.updatedQuestionData.current.solution?.images;

  const handleQuestionEdit = (content: string) => {
    if (props.updatedQuestionData.current.solution) {
      props.updatedQuestionData.current.solution.text = content;
    }
  };

  const handleAddMath = () => {
    if (props.updatedQuestionData.current.solution) {
      let a: string;
      const re: RegExp = /\\\(.*?\\\)/g;
      let latexArray: RegExpMatchArray | null;

      if (cursorPosition === undefined) {
        props.updatedQuestionData.current.solution.text += "\\( math \\)";
        props.setReRender(!props.reRender);
        return;
      }

      latexArray = props.updatedQuestionData.current.solution.text.match(re);

      a = props.updatedQuestionData.current.solution.text.replaceAll(re, "R");

      a = `${a.substring(0, cursorPosition.current)} \\( math \\) ${a.substring(
        cursorPosition.current,
        a.length
      )}`;

      if (latexArray) {
        latexArray.forEach((el) => (a = a.replace("R", el)));
      }

      props.updatedQuestionData.current.solution.text = a;
      props.setReRender(!props.reRender);
    }
  };

  return (
    <Row className="border border-dark rounded-4 mx-1">
      <Col xs={10}>
        <TipTap
          editMode
          setCursorPosition={(pos: number) => {
            cursorPosition.current = pos;
          }}
          onChange={(_, content) => handleQuestionEdit(content)}
          text={props.updatedQuestionData.current.solution?.text ?? ""}
          idx={-2}
        />
        <Row>
          {images?.map((image, idx) => (
            <EditImage
              src={image}
              onDelete={() => {
                if (props.updatedQuestionData.current.solution) {
                  props.updatedQuestionData.current.solution.images =
                    props.updatedQuestionData.current.solution.images?.filter(
                      (_, index) => index !== idx
                    );
                  props.setReRender(!props.reRender);
                }
              }}
            />
          ))}
        </Row>
      </Col>
      <Col xs={2} className="d-flex align-items-center justify-content-end">
        <ButtonGroup>
          <Button variant="light">
            <RiImageAddFill
              size="40px"
              color="#9AA0B2"
              onClick={() => {
                props.handleShow(-2);
              }}
              cursor="pointer"
            />
          </Button>
          <Button variant="light">
            <ImSigma
              size="30px"
              color="#9AA0B2"
              onClick={() => {
                handleAddMath();
              }}
              cursor="pointer"
            />
          </Button>
        </ButtonGroup>
        {/* add callback */}
      </Col>
    </Row>
  );
};

export default SolutionEdit;
