import { useMediaQuery } from "@mui/material";
import { CSSProperties, ReactNode } from "react";
import { Button as BootstrapButton } from "react-bootstrap";

type TProps = {
  onClick?: any;
  style?: CSSProperties | Object;
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
};

export default function QuestionBottomMenuButton(props: TProps) {
  const isMobile = useMediaQuery("(max-width: 576px)");

  if (isMobile)
    return (
      <BootstrapButton
        className={`rounded-2 py-1 px-4 ${props.className}`}
        size="sm"
        style={{
          fontWeight: "400",
          fontSize: "100%",
          color: "white",
          border: "none",
          ...props.style,
        }}
        onClick={props.onClick}
        disabled={props.disabled || false}
      >
        {props.children}
      </BootstrapButton>
    );

  return (
    <BootstrapButton
      className={`rounded-2 px-4 ${props.className}`}
      size="lg"
      style={{
        fontSize: "100%",
        color: "white",
        border: "none",
        ...props.style,
      }}
      onClick={props.onClick}
      disabled={props.disabled || false}
    >
      {props.children}
    </BootstrapButton>
  );
}
