import { Dispatch, SetStateAction, useContext, useState } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { IsectionInfo, questionInfo } from "../../../../models/assessmentmodel";
import QuestionButton from "../QuestionButton/QuestionButton";
import { IoReorderFourOutline } from "react-icons/io5";
import { EAnswerType, EQuestionStatus, ERole } from "../../../../utils/Enums";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../context/AssessmentContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { QuestionNavigationPanelReorder } from "./QuestionNavigationReorderPanel";
import { AppContext, TAppContext } from "../../../../context/AppContext";
import StudentQuestionLegend from "./StudentQuestionLegend";

let currentQuestionIndex = 0;
let selectedSectionId = 0;

type TNavigationPanelProps = {
  role: ERole;
  sectionMap: Map<number, IsectionInfo>;
  allQuestionInfo: Map<number, questionInfo>;
  proofRead?: boolean;
};

function getResponseStatusStudent(answer_type?: EAnswerType) {
  switch (answer_type) {
    case EAnswerType.VISITED_UNANSWERED:
      return "#EC2323"; // RED
    case EAnswerType.VISITED_ANSWERED:
      return "#3FB93D"; // GREEN
    case EAnswerType.FLAGGED_UNANSWERED:
      return "#3521B6"; // DARK BLUE
    case EAnswerType.FLAGGED_ANSWERED:
      return "#3521B6"; // DARK BLUE
  }
  return "#FFFFFF";
}

function getResponseStatusTeacher(
  answer_type: EQuestionStatus | EAnswerType | undefined
) {
  switch (answer_type) {
    // TODO: Apply it green when approved becomes a thing
    case EQuestionStatus.FLAGGED:
      return "#3521FF"; // LIGHT BLUE
  }
  return "#FFFFFF";
}

export default function QuestionNavigationPanel(props: TNavigationPanelProps) {
  const isDesktopOrLaptop = useMediaQuery("(min-width: 992px)");

  const role = props.role;
  const sectionMap = props.sectionMap;
  const { selectedAssessment } =
    useContext<TAssessmentContext>(AssessmentContext);
  const [isReorder, setIsReorder] = useState<boolean>(false);

  const TeacherQuestionLegend = (
    <>
      <Row className="mt-4 px-4">
        <Col md={3} xs={3}>
          <QuestionButton
            color={getResponseStatusTeacher(EQuestionStatus.FLAGGED)}
            text="1"
          />
        </Col>
        <Col xs={9} className="align-self-center">
          Flagged
        </Col>
      </Row>
    </>
  );

  // TODO: Fix the alignments and improve the layout to avoid using proofRead prop to set the flex spacing
  return (
    <>
      {isReorder ? (
        <QuestionNavigationPanelReorder
          setIsReorder={setIsReorder}
          sectionId={selectedSectionId}
          currentQuestionIndex={currentQuestionIndex}
        />
      ) : (
        <Col
          lg={props.proofRead ? 11 : 3}
          className="rounded-4 mx-3 my-4 bg-card px-2"
          style={{
            height: isDesktopOrLaptop
              ? role === ERole.TEACHER
                ? "72vh"
                : "100%"
              : "100vh",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {/* Question legend */}
          {role === ERole.TEACHER ? (
            TeacherQuestionLegend
          ) : (
            <StudentQuestionLegend />
          )}

          {/* Navigation Panel */}
          <div className="flex-grow-1 flex-row px-1">
            {selectedAssessment.sections.map((sectionId, index) => {
              if (index === 0) currentQuestionIndex = 0;
              let section = sectionMap.get(sectionId)!;
              let startingIndex = currentQuestionIndex;
              currentQuestionIndex += section.question_ids.length;
              return (
                <SectionPanel
                  role={role}
                  sectionData={section}
                  sectionIndex={index}
                  allQuestionInfo={props.allQuestionInfo}
                  startingQuestionIndex={startingIndex}
                  key={index}
                  isReorder={isReorder}
                  setIsReorder={setIsReorder}
                />
              );
            })}
          </div>
        </Col>
      )}
    </>
  );
}

type TSectionPanelProps = {
  role: ERole;
  sectionData: IsectionInfo;
  sectionIndex: number;
  allQuestionInfo: Map<number, questionInfo>;
  startingQuestionIndex: number;
  isReorder: boolean;
  setIsReorder: Dispatch<SetStateAction<boolean>>;
};

function SectionPanel(props: TSectionPanelProps) {
  const { questionMap } = useContext<TAssessmentContext>(AssessmentContext);
  const { role } = useContext<TAppContext>(AppContext);
  return (
    <div>
      <div
        className="bg-secondary rounded-4 p-3 text-center mt-4"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          color: "white",
        }}
      >
        <div />
        {props.sectionData.name}
        {role === ERole.TEACHER ? (
          <OverlayTrigger
            overlay={<Tooltip id="button-tooltip-2">Reorder questions</Tooltip>}
          >
            <IoReorderFourOutline
              size="25"
              onClick={() => {
                props.setIsReorder(true);
                selectedSectionId = props.sectionData.id;
                currentQuestionIndex = props.startingQuestionIndex;
              }}
            />
          </OverlayTrigger>
        ) : (
          <div />
        )}
      </div>
      <Row className="mt-2" style={{ justifyContent: "center" }}>
        {props.sectionData.question_ids.map((questionId, questionIndex) => {
          let questionButtonText =
            props.startingQuestionIndex + questionIndex + 1;

          const showBadge =
            role === ERole.STUDENT &&
            props.allQuestionInfo.get(questionId)?.questionState ===
              EAnswerType.FLAGGED_ANSWERED;

          return (
            <QuestionButton
              key={questionId}
              className="m-2 px-2"
              color={
                props.role === ERole.STUDENT
                  ? getResponseStatusStudent(
                      props.allQuestionInfo.get(questionId)?.questionState
                    )
                  : getResponseStatusTeacher(
                      questionMap.get(questionId)?.status
                    )
              }
              text={String(questionButtonText)} //text to be displayed
              questionNumber={questionIndex}
              showBadge={showBadge} // For showing of Badge on question Button of panel
              sectionNumber={props.sectionIndex} // question number in the question list. It is needed only when you try to ste data
            />
          );
        })}
      </Row>
    </div>
  );
}
