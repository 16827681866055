import { useContext, Dispatch, SetStateAction } from "react";
import { AppContext, TAppContext } from "../../../../context/AppContext";
import CustomButton from "../../../../components/CustomComponent/CustomButton";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../context/AssessmentContext";
import { saveQuestionData } from "../../../../api/question";
import { IquestionData } from "../../../../models/assessmentmodel";
import { displayErrorToast } from "../../../../utils/ToastUtils";
import QuestionBottomMenuButton from "./QuestionBottomMenuButton/QuestionBottomMenuButton";
import {
  findLastQuestionId,
  handleOnClickNext,
} from "../../../../utils/QuestionNavigation";

export default function TeacherQuestionEditButtonPanel(props: {
  edit: boolean;
  setEdit: Dispatch<SetStateAction<boolean>>;
  updatedQuestionData: IquestionData;
}) {
  const { accessToken } = useContext<TAppContext>(AppContext);
  const {
    selectedAssessment,
    sectionMap,
    questionMap,
    setQuestionMap,
    currentQuestionData,
    setCurrentQuestionData,
    currentQuestionNumber,
    setCurrentQuestionNumber,
    currentSectionNumber,
    setCurrentSectionNumber,
  } = useContext<TAssessmentContext>(AssessmentContext); // Get the data from the context

  const handleSaveQuestion = async () => {
    const response = await saveQuestionData(
      accessToken,
      props.updatedQuestionData
    );
    if (!response) {
      displayErrorToast(
        "There was an error while saving the changes. Please try again after sometime."
      );
      return null;
    }
    setQuestionMap(
      new Map(
        questionMap.set(
          currentQuestionData.question_id,
          props.updatedQuestionData
        )
      )
    );
    setCurrentQuestionData({
      ...props.updatedQuestionData,
    });
  };

  const TeacherEditButtonPanel = (
    <>
      <hr className="mt-auto"></hr>
      <div className="d-flex w-100 flex-row justify-content-between align-items-center p-2 bg-button-panel rounded-4">
        <div>
          <QuestionBottomMenuButton
            className="bg-success"
            onClick={async () => {
              await handleSaveQuestion();
              props.setEdit(false);
            }}
          >
            Save & Close
          </QuestionBottomMenuButton>
          <QuestionBottomMenuButton
            className="bg-warning ms-2"
            onClick={() => {
              props.setEdit(false);
            }}
          >
            Discard Changes
          </QuestionBottomMenuButton>
        </div>
        <QuestionBottomMenuButton
          disabled={
            currentQuestionData.question_id ===
            findLastQuestionId(selectedAssessment, sectionMap)
          }
          onClick={async () => {
            await handleSaveQuestion();
            console.log("Successfully Saved");
            props.setEdit(false);
            handleOnClickNext(
              selectedAssessment,
              currentQuestionData,
              sectionMap,
              currentQuestionNumber,
              setCurrentQuestionNumber,
              currentSectionNumber,
              setCurrentSectionNumber
            );
            props.setEdit(true);
          }}
        >
          Save & Next
        </QuestionBottomMenuButton>
      </div>
    </>
  );

  return TeacherEditButtonPanel;
}
