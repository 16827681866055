import { Dispatch, SetStateAction, useState } from "react";
import CustomMarquee from "react-fast-marquee";

type TProps = {
  children: React.ReactNode;
  disable?: boolean;
  overridePlay?: boolean;
  setOverridePlay?: Dispatch<SetStateAction<boolean>>;
};

export function Marquee({
  children,
  disable,
  overridePlay,
  setOverridePlay,
}: TProps) {
  const [play, setPlay] = useState<boolean>(false);

  return (
    <CustomMarquee
      gradient={false}
      play={!disable && (overridePlay ?? play)}
      pauseOnHover={false}
      speed={60}
      delay={1}
      direction={"left"}
      onCycleComplete={() => {
        setOverridePlay ? setOverridePlay(false) : setPlay(false);
      }}
    >
      <div
        onMouseOver={() => {
          setOverridePlay ?? setPlay(true);
        }}
      >
        {children}
      </div>
    </CustomMarquee>
  );
}
