import { Typography, IconButton } from "@mui/material";
import { Dispatch, FunctionComponent, SetStateAction, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./../MyTests.module.scss";

interface AttemptTestModalProps {
  showAttemptTestModal: boolean;
  setShowAttemptTestModal: Dispatch<SetStateAction<boolean>>;
}

const AttemptTestModal: FunctionComponent<AttemptTestModalProps> = (props) => {
  const [publishCode, setPublishCode] = useState<string>("");
  const history = useHistory();

  const handleAttemptTest = (event) => {
    event.preventDefault();
    history.push({
      pathname: `/home/attempt/${publishCode}/instructions`,
    });
  };

  return (
    <Modal
      contentClassName={styles.modalStyle}
      show={props.showAttemptTestModal}
      onHide={() => props.setShowAttemptTestModal(false)}
      centered
    >
      <Modal.Header>
        <Typography variant="h4">
          <b>Attempt Test</b>
        </Typography>
        <IconButton>
          <CloseIcon onClick={() => props.setShowAttemptTestModal(false)} />
        </IconButton>
      </Modal.Header>

      <Modal.Body>
        <Form noValidate onSubmit={handleAttemptTest}>
          <Form.Group controlId="attemptTest">
            <Form.Label>
              <Typography fontSize={20} color="#9AA0B2">
                <b>Enter Test Code:</b>
              </Typography>
            </Form.Label>
            <Form.Control
              onChange={(e) => setPublishCode(e.target.value)}
              type="string"
              placeholder="Test Code"
              className="ml-2"
              style={{ backgroundColor: "#DBDFF1" }}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="border-0 rounded-2 bg-secondary"
          type="submit"
          onClick={handleAttemptTest}
        >
          <Typography className="m-0 p-0">Attempt Test</Typography>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AttemptTestModal;
