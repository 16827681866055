import axios from "axios";
import { IQuestionBody } from "../models/common";
import { handleUnauthorisedException } from "../utils/apiUtils";
import { baseURL } from "../utils/constants";
import HTTPStatusCode from "../utils/HTTPStatusCode";
import { displayErrorToast } from "../utils/ToastUtils";

export const addComprehension = async (
  accessToken: string,
  assessment_id: number,
  body: IQuestionBody
) => {
  try {
    const comprehension = await axios.post<any>(
      `${baseURL}/api/v1/comprehension/assessment/${assessment_id}`,
      {
        body,
      },
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    return comprehension.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    displayErrorToast("Failed to create comprehension");
    console.log(error);
    return null;
  }
};

export const fetchComprehensionInAssessment = async (
  accessToken: string,
  assessment_id: number
) => {
  try {
    const comprehensions = await axios.get<any>(
      `${baseURL}/api/v1/comprehension/assessment/${assessment_id}`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    return comprehensions.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    displayErrorToast("Failed to fetch comprehension");
    console.log(error);
    return null;
  }
};

export const patchComprehension = async (
  accessToken: string,
  comprehension_id: number,
  body: IQuestionBody
) => {
  try {
    const comprehensions = await axios.patch<any>(
      `${baseURL}/api/v1/comprehension/${comprehension_id}`,
      {
        body,
      },
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    return comprehensions.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    displayErrorToast("Failed to create comprehension");
    console.log(error);
    return null;
  }
};
