import { useEffect, useContext, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Col } from "react-bootstrap";
import AnalyticsQuestion2 from "./Screens/TeacherQuestionStats";
import { AppContext, TAppContext } from "../../context/AppContext";
import { EAnalyticsTab, EAssessmentFormat, ERole } from "../../utils/Enums";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../context/AssessmentContext";
import {
  fetchCreatorAnalytics,
  fetchCreatorHighlights,
} from "../../api/analytics";
import {
  AnalyticsContext,
  TAnalyticsContext,
} from "../../context/AnalyticsContext";
import {
  TAnalyticsAllStudentsStatistics,
  TAnalyticsCreatorQuestionStats,
  TAnalyticsTeacherOverview,
  TAnalyticsTeacherTimed,
  THighlightObject,
} from "../../models/analyticsmodel";
import { parseAnalyticsData } from "../../utils/analyticsUtils";
import { fetchAssessmentData } from "../../api/assessment";
import { createIDMapFromArray } from "../instructionScreen/InstructionScreenRequests";
import { displayErrorToast } from "../../utils/ToastUtils";
import AnalyticsHeader from "./Components/AnalyticsHeader";
import TeacherAnalyticsOverview from "./Screens/TeacherAnalyticsOverview";
import AnalyticsTopnav from "./Components/AnalyticsTopNav";
import TimedAnalysis from "./Screens/TeacherTimedAnalysis";
import Loading from "../../components/Loading/Loading";
import { TAssessmentData } from "../../models/assessmentmodel";
import StudentStatistics from "./Screens/StudentsStatistics";

const TeacherAnalytics = () => {
  const history = useHistory();

  const { accessToken, setRole, setDrawerOpen } =
    useContext<TAppContext>(AppContext);
  const { setSectionMap, setSelectedAssessment } =
    useContext<TAssessmentContext>(AssessmentContext);

  const {
    selectedTab,
    setSelectedTab,
    teacherAnalyticsOverview,
    setTeacherAnalyticsOverview,
    teacherAnalyticsTimed,
    setTeacherAnalyticsTimed,
    teacherQuestionStats,
    setTeacherQuestionStats,
    allStudentsStatistics,
    setAllStudentsStatistics,
  } = useContext<TAnalyticsContext>(AnalyticsContext);

  const [creatorHighlights, setCreatorHighlights] =
    useState<THighlightObject>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedSectionNumber, setSelectedSectionNumber] =
    useState<number>(-1);
  const [sectionIdNameMap, setSectionIdNameMap] = useState<Map<number, string>>(
    new Map([[-1, "All"]])
  );
  const [assessmentData, setAssessmentData] = useState<any>();

  const assessmentId = parseInt(useRouteMatch().params["id"]);
  const publishCode = useRouteMatch().params["publishCode"];

  const analyticsTabEndpointMap = new Map<string, string>([
    [EAnalyticsTab[EAnalyticsTab.OVERVIEW], "overview"],
    [EAnalyticsTab[EAnalyticsTab.STATISTICS], "question_stats"],
    [EAnalyticsTab[EAnalyticsTab.TIMED_ANALYSIS], "timed_analysis"],
    [EAnalyticsTab[EAnalyticsTab.STUDENT_STATISTICS], "student_stats"],
  ]);

  let selectedSectionName = sectionIdNameMap.get(selectedSectionNumber)!!;

  useEffect(() => {
    (async () => {
      const assessmentDataRes = await fetchAssessmentData(
        accessToken,
        assessmentId
      );

      if (!assessmentDataRes) {
        displayErrorToast();
        return history.push(`/home/assessments/${assessmentId}/manage`);
      }

      // TODO: typeset the above return type and avoid the following workaround
      const selectedAssessment: TAssessmentData = assessmentDataRes.assessment;
      setSelectedAssessment(selectedAssessment);

      setAssessmentData(assessmentDataRes);
      const sectionMapTemp = createIDMapFromArray(assessmentDataRes.sections);
      setSectionMap(sectionMapTemp);

      setRole(ERole.TEACHER);
      const sectionNames = ["All"];
      const sectionIdToNameMap = new Map([[-1, "All"]]);

      assessmentDataRes.sections.forEach((el) => {
        sectionNames.push(el.name);
        sectionIdToNameMap.set(parseInt(el.id), el.name);
      });

      setSectionIdNameMap(sectionIdToNameMap);
      setSelectedTab(EAnalyticsTab[EAnalyticsTab.OVERVIEW]);
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      // Fetch assessment analytics for the selected section id
      let sectionId: number | undefined;
      selectedSectionName = sectionIdNameMap.get(selectedSectionNumber)!!;

      if (selectedSectionNumber !== -1) {
        sectionId = selectedSectionNumber;
      }

      const creatorAnalyticsResponse = await fetchCreatorAnalytics(
        accessToken,
        publishCode,
        analyticsTabEndpointMap.get(selectedTab)!!,
        sectionId
      );
      const creatorAnalyticsHighlights = await fetchCreatorHighlights(
        accessToken,
        publishCode
      );
      if (creatorAnalyticsHighlights !== null) {
        setCreatorHighlights(creatorAnalyticsHighlights);
      }

      if (selectedTab === EAnalyticsTab[EAnalyticsTab.OVERVIEW]) {
        if (creatorAnalyticsResponse === null) {
          setTeacherAnalyticsOverview(undefined);
          setIsLoading(false);
          history.push(`/home/assessments/${assessmentId}/manage`);
          return;
        }

        const overviewAnalyticsData: TAnalyticsTeacherOverview =
          parseAnalyticsData(creatorAnalyticsResponse);
        setTeacherAnalyticsOverview(overviewAnalyticsData);
        setIsLoading(false);
      } else if (selectedTab === EAnalyticsTab[EAnalyticsTab.TIMED_ANALYSIS]) {
        if (creatorAnalyticsResponse === null) {
          setTeacherAnalyticsTimed(undefined);
          setIsLoading(false);
          return;
        }

        const timedAnalyticsData: TAnalyticsTeacherTimed = parseAnalyticsData(
          creatorAnalyticsResponse
        );
        setTeacherAnalyticsTimed(timedAnalyticsData);
        setIsLoading(false);
      } else if (selectedTab === EAnalyticsTab[EAnalyticsTab.STATISTICS]) {
        if (creatorAnalyticsResponse === null) {
          setTeacherQuestionStats(undefined);
          setIsLoading(false);
          return;
        }

        const questionResponseData: TAnalyticsCreatorQuestionStats =
          parseAnalyticsData(creatorAnalyticsResponse);

        setTeacherQuestionStats(questionResponseData);
        setIsLoading(false);
      } else if (
        selectedTab === EAnalyticsTab[EAnalyticsTab.STUDENT_STATISTICS]
      ) {
        if (creatorAnalyticsResponse === null) {
          setAllStudentsStatistics(undefined);
          setIsLoading(false);
          return;
        }

        const allStudentsStatisticsData: TAnalyticsAllStudentsStatistics =
          parseAnalyticsData(creatorAnalyticsResponse);

        setAllStudentsStatistics(allStudentsStatisticsData);
        setIsLoading(false);
      }
    })();
  }, [selectedSectionNumber, selectedTab]);

  useEffect(() => {
    setDrawerOpen(false);
    return () => {
      setDrawerOpen(true);
    };
  });

  return isLoading ? (
    <Loading />
  ) : (
    <Col>
      <AnalyticsTopnav />
      <AnalyticsHeader
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        sectionIdNameMap={sectionIdNameMap}
        selectedSectionNumber={selectedSectionNumber}
        setSelectedSectionNumber={setSelectedSectionNumber}
        assessmentName={assessmentData?.assessment.name!!}
        assessmentFormat={assessmentData?.assessment.format!!}
        assessmentCreator={assessmentData?.assessment.created_by!!}
      />

      {selectedTab === EAnalyticsTab[EAnalyticsTab.OVERVIEW] ? (
        teacherAnalyticsOverview !== undefined ? (
          <TeacherAnalyticsOverview
            highlights={creatorHighlights}
            data={teacherAnalyticsOverview}
            selectedSectionName={selectedSectionName}
          />
        ) : (
          <div>No Analytics Avalaible :</div>
        )
      ) : (
        <div />
      )}

      {assessmentData &&
      assessmentData.assessment.format !== EAssessmentFormat.OMR &&
      selectedTab === EAnalyticsTab[EAnalyticsTab.TIMED_ANALYSIS] ? (
        teacherAnalyticsTimed !== undefined ? (
          <TimedAnalysis
            data={teacherAnalyticsTimed}
            selectedSectionName={selectedSectionName}
          />
        ) : (
          <div>No Analytics Avalaible :</div>
        )
      ) : (
        <div />
      )}

      {selectedTab === EAnalyticsTab[EAnalyticsTab.STATISTICS] ? (
        teacherQuestionStats !== undefined ? (
          <AnalyticsQuestion2
            data={teacherQuestionStats}
            selectedSectionName={selectedSectionName}
          />
        ) : (
          <div>No Analytics Avalaible :</div>
        )
      ) : (
        <div />
      )}

      {selectedTab === EAnalyticsTab[EAnalyticsTab.STUDENT_STATISTICS] ? (
        allStudentsStatistics !== undefined ? (
          <StudentStatistics
            data={allStudentsStatistics}
            selectedSectionName={selectedSectionName}
            publishCode={publishCode}
          />
        ) : (
          <div>No Analytics Avalaible :</div>
        )
      ) : (
        <div />
      )}
    </Col>
  );
};

export default TeacherAnalytics;
