import {
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { TAnalyticsStudentQuestion } from "../../../models/analyticsmodel";
import AccuracyCard from "../Components/AccuracyCard";
import chartData from "../Components/chartData";
import Chart from "react-apexcharts";
import { PieChart, Pie, Cell } from "recharts";
import { BsInfoCircle } from "react-icons/bs";
import {
  ACCURACY,
  ACCURACY_PER_DIFF,
  ACCURACY_WITH_TIME,
} from "../../../constants/analyticsInfo";

type TProps = {
  data: TAnalyticsStudentQuestion;
  selectedSectionName: string;
  isLoading: boolean;
};

export default function StudentQuesAnalytics(props: TProps) {
  const isMobile = useMediaQuery("(max-width: 576px)");
  const totalQuestions =
    props.data.accuracy["correct"] +
    props.data.accuracy["incorrect"] +
    props.data.accuracy["unattempted"];

  let pieData: any[] = [];
  pieData = [
    { name: "correct", questions: props.data.accuracy["correct"] },
    { name: "incorrect", questions: props.data.accuracy["incorrect"] },
    { name: "unattempted", questions: props.data.accuracy["unattempted"] },
  ];
  let pieLegendMap: Map<string, string> = new Map([
    ["correct", "#3FB93D"],
    ["incorrect", "#D11919"],
    ["unattempted", "#666666"],
  ]);

  // Initialize the metric with some data
  const accuracy_per_difficulty: Map<string, any> = new Map([
    ["easy", { correct: 0, incorrect: 0, unattempted: 0, total: 0 }],
    ["medium", { correct: 0, incorrect: 0, unattempted: 0, total: 0 }],
    ["hard", { correct: 0, incorrect: 0, unattempted: 0, total: 0 }],
  ]);

  // Populate the metric with the data fetched from the backend
  if (props.data.accuracy_per_difficulty.length !== 0) {
    props.data.accuracy_per_difficulty.forEach((el) => {
      let comparisonData = accuracy_per_difficulty.get(el["difficulty_level"]);
      if (comparisonData !== undefined) {
        comparisonData.correct = el["correct"];
        comparisonData.incorrect = el["incorrect"];
        comparisonData.unattempted = el["unattempted"];
        comparisonData.total = el["total"];
      }
      accuracy_per_difficulty.set(el["difficulty_level"], comparisonData);
    });
  }

  let iconColor = ["#3FB93D87", "#FFBA69DB", "#630718"];

  return (
    <div className="px-0">
      <div className="px-2 px-sm-4">
        <div
          className="px-2 px-sm-4 rounded-4 py-2 py-sm-4 w-100"
          style={{ background: isMobile ? "white" : "#F0F0F0" }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <Typography
              style={{ color: "#75777D", fontWeight: "700" }}
              className="mb-2"
            >
              Accuracy
            </Typography>
            <Tooltip
              enterTouchDelay={0}
              title={ACCURACY_PER_DIFF}
              placement="top-start"
            >
              <IconButton>
                <BsInfoCircle className="mx-2" size={20} />
              </IconButton>
            </Tooltip>
          </div>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 2, sm: 4 }}
            sx={{ my: 1 }}
            className="justify-content-center"
          >
            {Array.from(accuracy_per_difficulty).map((el, index) => {
              return (
                <Grid item md={4} xs={12}>
                  <AccuracyCard
                    data={"Time"}
                    correct={el[1]["correct"]}
                    incorrect={el[1]["incorrect"]}
                    total={el[1]["total"]}
                    cardTitle={el[0]}
                    iconColor={iconColor[index]}
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>

      <div className="px-2 px-sm-4 pb-4 mt-4 ">
        <div className="mt-2 w-100">
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <div
                className={
                  "rounded-4 px-2 px-sm-4 py-2 h-100 " +
                  (isMobile ? "" : "bg-card")
                }
              >
                <div className="d-flex justify-content-between align-items-center py-2 w-100">
                  <Typography color="#75777D">
                    <b>Distribution</b>
                  </Typography>
                  <Tooltip
                    enterTouchDelay={0}
                    title={ACCURACY}
                    placement="top-start"
                  >
                    <IconButton>
                      <BsInfoCircle className="mx-2" size={20} />
                    </IconButton>
                  </Tooltip>
                </div>
                <Divider className="w-100" />
                <div
                  className={
                    "d-flex align-items-center justify-content-between py-3 " +
                    (isMobile ? "flex-column" : "")
                  }
                >
                  <PieChart width={200} height={200}>
                    <Pie data={pieData} dataKey="questions" outerRadius={100}>
                      <Cell key={`cell-0`} fill="#0089AF" />
                      <Cell key={`cell-1`} fill="#FFBA69" />
                      <Cell key={`cell-2`} fill="#817AA4" />
                    </Pie>
                  </PieChart>

                  <div>
                    <Typography>
                      <span
                        style={{
                          color: "#0089AF",
                          fontWeight: "700",
                          fontSize: "20px",
                        }}
                      >
                        {pieData[0].questions + "/" + totalQuestions}
                      </span>
                      <span> Correct</span>
                    </Typography>
                    <Typography>
                      <span
                        style={{
                          color: "#FFBA69",
                          fontWeight: "700",
                          fontSize: "20px",
                        }}
                      >
                        {pieData[1].questions + "/" + totalQuestions}
                      </span>
                      <span> Incorrect</span>
                    </Typography>
                    <Typography>
                      <span
                        style={{
                          color: "#817AA4",
                          fontWeight: "700",
                          fontSize: "20px",
                        }}
                      >
                        {pieData[2].questions + "/" + totalQuestions}
                      </span>
                      <span> Unattempted</span>
                    </Typography>
                  </div>
                </div>
              </div>
            </Grid>

            {isMobile && <Divider className="w-100" />}
            <Grid item xs={12} md={7}>
              <div className="h-100">
                <div
                  className={
                    "rounded-4 px-0 px-sm-4 py-2 h-100 " +
                    (isMobile ? "" : "bg-card")
                  }
                >
                  <div className="d-flex justify-content-between align-items-center py-2 w-100">
                    <Typography color="#75777D">
                      <b>Accuracy with time</b>
                    </Typography>
                    <Tooltip
                      enterTouchDelay={0}
                      title={ACCURACY_WITH_TIME}
                      placement="top-start"
                    >
                      <IconButton>
                        <BsInfoCircle className="mx-2" size={20} />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <Divider className="w-100" />
                  {!props.isLoading && (
                    <Chart
                      {...chartData(
                        props.data.accuracy_level_with_time.data,
                        props.data.accuracy_level_with_time.labels,
                        isMobile,
                        1,
                        [
                          "Phase I",
                          "Phase II",
                          "Phase III",
                          "Phase IV",
                          "Phase V",
                          "Phase VI",
                        ],
                        ["#817AA4", "#24B4F0", "#822A28"]
                      )}
                      type="bar"
                    />
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
