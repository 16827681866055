import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { fetchDummyUserAPICall } from "../../api/accounts";
import {
  fetchAssessmentData,
  handleCreateAssessment,
  handleCreateSectionAPI,
} from "../../api/assessment";
import { fetchOrganization } from "../../api/organizations";
import { fetchUser } from "../../api/user";
import { TAppContext, AppContext } from "../../context/AppContext";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../context/AssessmentContext";
import { IsectionInfo } from "../../models/assessmentmodel";
import { EAssessmentFormat, EAssessmentType, ERole } from "../../utils/Enums";
import AuthScreen from "../login/Components/AuthScreen";
import QuestionView from "../question/QuestionView";

export default function GetStartedPage() {
  const {
    setDrawerOpen,
    setId,
    setAccessToken,
    isLoggedIn,
    isEmailVerified,
    setOrg,
    setDummyAssessmentId,
    showLoginModal,
    setShowLoginModal,
    setRole,
  } = useContext<TAppContext>(AppContext);
  const [loading, setLoading] = useState<boolean>(false);

  const { setSelectedAssessment, setSectionMap, setCurrentSectionNumber } =
    useContext<TAssessmentContext>(AssessmentContext);

  const createDummyUserDetails = async () => {
    setLoading(true);
    // get dummy user access token
    const user = await fetchDummyUserAPICall();
    if (!user) {
      return;
    }
    setAccessToken(user.access_token);
    setRole(ERole.TEACHER);

    const currentUser = await fetchUser(user.access_token);
    setId(currentUser.id);

    // create a new empty assessment for that dummy user
    const assessment = await handleCreateAssessment(
      user.access_token,
      EAssessmentType.ASSIGNMENT,
      EAssessmentFormat.OMR,
      "My First Test",
      ""
    );

    // create a section for this new assessment
    const section = await handleCreateSectionAPI(
      user.access_token,
      "Section 1",
      assessment.id
    );

    const org = await fetchOrganization(user.access_token);
    setOrg(org[0]);

    let newSectionMap = new Map<number, IsectionInfo>();
    newSectionMap.set(section.id, section);
    setSectionMap(newSectionMap);

    const assessmentData = await fetchAssessmentData(
      user.access_token,
      assessment.id
    );
    setDummyAssessmentId(assessment.id);
    setSelectedAssessment(assessmentData.assessment);
    setCurrentSectionNumber(0);
    setLoading(false);
  };

  useEffect(() => {
    if (isLoggedIn) {
      window.location.href = "/home";
      return;
    }
    if (!isEmailVerified && isLoggedIn) {
      setShowLoginModal(true);
    }
    setDrawerOpen(false);
  }, [isEmailVerified]);

  useEffect(() => {
    createDummyUserDetails();
  }, []);

  return loading ? (
    <div></div>
  ) : (
    <>
      <QuestionView />
      <Modal
        centered
        show={showLoginModal}
        onHide={() => (!isLoggedIn ? setShowLoginModal(false) : {})}
      >
        <AuthScreen />
      </Modal>
    </>
  );
}
