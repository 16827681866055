import {
  IoInformationCircleOutline,
  IoLockClosed,
  IoLockOpen,
  IoTrashBin,
} from "react-icons/io5";
import { IquestionData, IsectionInfo } from "../../../models/assessmentmodel";
import QuestionType from "../QuestionType/QuestionType";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import { AppContext, TAppContext } from "../../../context/AppContext";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../context/AssessmentContext";
import { deleteQuestionFromAssessment } from "../../../api/question";
import { displayErrorToast } from "../../../utils/ToastUtils";
import { EAnswerType, ERole, EQuestionType } from "../../../utils/Enums";
import {
  QuestionContext,
  TQuestionContext,
} from "../../../context/QuestionContext";
import {
  AttemptContext,
  TAttemptContext,
} from "../../../context/AttemptContext";
import { setResponse } from "../../../api/responses";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Typography, useMediaQuery } from "@mui/material";
import { parseMapFromJSON } from "../../../utils/hooks/useStickyState";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import EditMarkingScheme from "../../question/Components/EditMarkingScheme/EditMarkingScheme";

type TProps = {
  currentQuestion: IquestionData;
  index: number;
  currentSection: IsectionInfo;
  setCurrentSection: Dispatch<SetStateAction<IsectionInfo>>;
  role: ERole;
  response?: boolean;
  allowChange?: boolean;
};

export default function QuestionResponse(props: TProps) {
  const { accessToken, role, id } = useContext<TAppContext>(AppContext);
  const {
    selectedAssessment,
    sectionMap, // List of sections.
    questionMap,
    currentSectionNumber, // current section to which question belongs to
    setQuestionMap,
    setSectionMap,
    updatedableQuestionMap,
    limits,
    setCurrentQuestionData,
  } = useContext<TAssessmentContext>(AssessmentContext); // Get the data from the context

  const [baseIndex, setBaseIndex] = useState<number>(0);

  useEffect(() => {
    let base = 0;
    selectedAssessment.sections
      .slice(0, currentSectionNumber)
      .forEach(
        (sectionId) => (base += sectionMap.get(sectionId)!.question_ids.length)
      );
    setBaseIndex(base);
  }, [currentSectionNumber, sectionMap]);

  const isDesktopOrLaptop = useMediaQuery("(min-width: 992px)");

  const { assessmentQuestionInfo, setAssessmentQuestionInfo } =
    useContext<TQuestionContext>(QuestionContext);

  const { currentSession } = useContext<TAttemptContext>(AttemptContext);
  const [isCorrect, setIsCorrect] = useState<boolean>(false);
  const [disabled, setIsDisabled] = useState<boolean>(false);
  let answer =
    role === ERole.STUDENT
      ? assessmentQuestionInfo.get(props.currentQuestion.question_id)!!
          .questionAnswer
      : props.currentQuestion.answer;

  useEffect(() => {
    if (!props.allowChange) setIsDisabled(false);
    else if (role === ERole.TEACHER) setIsDisabled(false);
    else setIsDisabled(limits.get(props.currentQuestion.limitId)!!.limit <= 0);
  }, [props.currentQuestion.question_id, answer, limits]);

  const handleDeleteQues = async (questionId: number) => {
    const res = deleteQuestionFromAssessment(
      accessToken,
      selectedAssessment.id,
      props.currentSection.id,
      questionId
    );
    if (!res) return;
    let updatedQuestionMap = new Map(questionMap);
    updatedQuestionMap.delete(questionId);
    let updatedSectionMap = new Map(sectionMap);
    let updatedSection = { ...props.currentSection };
    updatedSection.question_ids = updatedSection.question_ids.filter((item) => {
      return item !== questionId;
    });
    updatedSectionMap.set(
      selectedAssessment.sections[currentSectionNumber]!!,
      updatedSection
    );
    updatedableQuestionMap.delete(questionId);
    setQuestionMap(updatedQuestionMap);
    setSectionMap(updatedSectionMap);
    props.setCurrentSection(updatedSection);
  };

  let qState = assessmentQuestionInfo.get(
    props.currentQuestion.question_id
  )?.questionState;

  const handleLockStatus = async () => {
    if (qState === EAnswerType.FLAGGED_ANSWERED)
      qState = EAnswerType.VISITED_ANSWERED;
    else if (qState === EAnswerType.VISITED_ANSWERED)
      qState = EAnswerType.FLAGGED_ANSWERED;
    else if (qState === EAnswerType.VISITED_UNANSWERED)
      qState = EAnswerType.FLAGGED_UNANSWERED;
    else qState = EAnswerType.VISITED_UNANSWERED;
    let updatedQInfo = new Map(assessmentQuestionInfo);
    let updatedCurrQInfo = assessmentQuestionInfo.get(
      props.currentQuestion.question_id
    )!!;
    updatedCurrQInfo.questionState = qState;
    updatedQInfo.set(props.currentQuestion.question_id, updatedCurrQInfo);
    setAssessmentQuestionInfo(updatedQInfo);
    const success = await setResponse(
      accessToken,
      currentSession.id,
      updatedCurrQInfo,
      parseMapFromJSON(window.localStorage.getItem("unpushedAttempts"))
    );
    if (success) {
      setAssessmentQuestionInfo(updatedQInfo);
    } else {
      displayErrorToast(
        "Something went wrong, you might want to check your local time or internet connection"
      );
    }
  };

  useMemo(() => {
    const candidateAnswer = assessmentQuestionInfo
      .get(props.currentQuestion.question_id)
      ?.questionAnswer.split(",")
      .sort()
      .join(",");
    let correctAnswer = props.currentQuestion.answer || "";
    correctAnswer = correctAnswer.split(",").sort().join(",");
    setIsCorrect(candidateAnswer === correctAnswer);
  }, [
    props.currentQuestion.question_id,
    props.currentQuestion.answer,
    assessmentQuestionInfo,
  ]);

  return (
    <div
      className={
        "d-flex flex-grow-1 flex-row align-items-center justify-content-start py-1 " +
        (isDesktopOrLaptop ? "px-4" : "px-2")
      }
      id={`${props.currentQuestion.question_id}`}
      style={{
        backgroundColor: props.index % 2 === 0 ? "#e5e5e5" : "#fff",
      }}
    >
      <div
        className={
          "d-flex flex-row align-items-center " +
          (isDesktopOrLaptop ? "me-5" : "me-1")
        }
      >
        <div style={{ minWidth: "30px" }}>{baseIndex + props.index + 1}</div>
        <div
          className={
            "justify-content-center " + (isDesktopOrLaptop ? "ms-3" : "ms-1")
          }
        >
          {props.role === ERole.TEACHER ? (
            <div className="d-flex align-items-center ">
              <div>
                <EditMarkingScheme
                  indexForColor={props.index % 2}
                  isOMR={true}
                  currentQuestionDataProps={props.currentQuestion}
                />
              </div>
              <div className="ps-2">
                <IoTrashBin
                  size={30}
                  color="red"
                  onClick={() =>
                    handleDeleteQues(props.currentQuestion.question_id)
                  }
                />
              </div>
            </div>
          ) : (
            !props.response &&
            (qState === EAnswerType.FLAGGED_ANSWERED ||
            qState === EAnswerType.FLAGGED_UNANSWERED ? (
              <IoLockClosed size={30} color="red" onClick={handleLockStatus} />
            ) : (
              <IoLockOpen
                size={30}
                color="#6699FF"
                onClick={handleLockStatus}
              />
            ))
          )}
        </div>
        <div
          className={
            "justify-content-center d-flex align-items-center ms-3" +
            (isDesktopOrLaptop ? "ms-3 ps-3" : "ms-2 ps-2")
          }
        >
          <div>
            {disabled &&
            role === ERole.STUDENT &&
            !(
              assessmentQuestionInfo.get(props.currentQuestion.question_id)
                ?.questionState === EAnswerType.FLAGGED_ANSWERED ||
              assessmentQuestionInfo.get(props.currentQuestion.question_id)
                ?.questionState === EAnswerType.VISITED_ANSWERED
            ) ? (
              <OverlayTrigger
                overlay={
                  <Tooltip id="button-tooltip-2">
                    <p style={{ color: "red" }}>
                      {`You have attempted maximum questions allowed in the range ${
                        limits.get(props.currentQuestion.limitId)
                          ?.start_question_idx
                      } to ${
                        limits.get(props.currentQuestion.limitId)
                          ?.end_question_idx
                      }`}
                    </p>
                    {props.response ? (
                      <p>
                        {EQuestionType[props.currentQuestion.question_type]}
                        <br></br>
                        Correct Answer: {props.currentQuestion.answer} <br></br>
                        Marks: +{props.currentQuestion.marks_if_correct} /
                        {props.currentQuestion.marks_if_incorrect}
                      </p>
                    ) : (
                      <p>
                        {EQuestionType[props.currentQuestion.question_type]}
                        <br></br>
                        Marks: {props.currentQuestion.marks_if_correct}/
                        {props.currentQuestion.marks_if_incorrect}
                      </p>
                    )}
                  </Tooltip>
                }
              >
                <IoInformationCircleOutline size={25} className="text-danger" />
              </OverlayTrigger>
            ) : (
              <OverlayTrigger
                overlay={
                  <Tooltip id="button-tooltip-2">
                    {props.response ? (
                      <p>
                        {EQuestionType[props.currentQuestion.question_type]}
                        <br></br>
                        Correct Answer: {props.currentQuestion.answer} <br></br>
                        Marks: +{props.currentQuestion.marks_if_correct} /
                        {props.currentQuestion.marks_if_incorrect}
                      </p>
                    ) : (
                      <p>
                        {EQuestionType[props.currentQuestion.question_type]}
                        <br></br>
                        Marks: {props.currentQuestion.marks_if_correct}/
                        {props.currentQuestion.marks_if_incorrect}
                      </p>
                    )}
                  </Tooltip>
                }
              >
                <IoInformationCircleOutline size={25} className="text-info" />
              </OverlayTrigger>
            )}
          </div>
          <div className="ps-2">
            {props.response &&
              (assessmentQuestionInfo.get(props.currentQuestion.question_id)
                ?.questionAnswer?.length ? (
                isCorrect ? (
                  <CheckIcon color="success" />
                ) : (
                  <ClearIcon color="warning" />
                )
              ) : (
                <CircleOutlinedIcon color="disabled" />
              ))}
          </div>
        </div>
      </div>

      <QuestionType
        currentQuestion={props.currentQuestion}
        response={props.response}
        isCorrect={isCorrect}
        lockStatus={
          props.response
            ? true
            : qState === EAnswerType.FLAGGED_ANSWERED ||
              qState === EAnswerType.FLAGGED_UNANSWERED
        }
        disabled={disabled}
      />
    </div>
  );
}

QuestionResponse.defaultProps = {
  allowChange: true,
};
