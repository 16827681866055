import { useContext } from "react";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../context/AssessmentContext";
import styles from "../../QuestionScreen.module.scss";
import TipTap from "../../../../utils/TipTap/TipTap";
import "katex/dist/katex.min.css";
import QuestionImageModal from "../QuestionImageModal/QuestionImageModal";

interface IquestionText {
  children: string;
  images?: Array<string>;
  altText?: string;
}

const defaultProps = { altText: "Question" };

export default function QuestionText(
  props: IquestionText & typeof defaultProps
) {
  const { sectionMap } = useContext<TAssessmentContext>(AssessmentContext); // Get the data from the context

  return (
    <div className={styles.QCard}>
      {sectionMap === null ? (
        "Something went wrong"
      ) : (
        <div>
          {props.children === "" && props.images?.length === 0 && props.altText}
          {props.children && <TipTap text={props.children} />}
          {props.images?.map((el) => (
            <QuestionImageModal src={el} />
          ))}
        </div>
      )}
    </div>
  );
}

QuestionText.defaultProps = defaultProps;
