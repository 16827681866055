import { Dispatch, MutableRefObject, SetStateAction, useMemo } from "react";
import { Modal } from "react-bootstrap";
import styles from "../../../batch-management/batch-management.module.scss";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { displaySuccessToast } from "../../../../utils/ToastUtils";
import ReorederableComponent from "../../../../components/ReorderableComponent";
import CustomButton from "../../../../components/CustomComponent/CustomButton";
import { IquestionData } from "../../../../models/assessmentmodel";
import { IQuestionBody } from "../../../../models/common";

//we create an original mapping of the options with the index and then we put those indices in options array and send that array
// of numbers to ReOrderComponent which was reused. and mapping was  done using template strings of JS(or TS 🙃 )
type TProps = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  updatedQuestionsData: MutableRefObject<IquestionData>;
  reRender: boolean;
  setReRender: Dispatch<SetStateAction<boolean>>;
};

export default function ReOrderOptionsModal({
  showModal,
  setShowModal,
  updatedQuestionsData,
  reRender,
  setReRender,
}: TProps) {
  const { optionsMappingToIndices, optionsIndices, optionsMap } =
    useMemo(() => {
      const optionsMappingToIndices = new Map<number, string>();
      const optionsIndices: Array<number> = [];
      const optionsMap = new Map<number, IQuestionBody>();
      updatedQuestionsData.current.options.map((questionData, index) => {
        optionsIndices.push(index);
        const Character = String.fromCharCode(97 + index).toUpperCase();
        optionsMappingToIndices.set(index, `Option ${Character}`);
        optionsMap.set(index, questionData);
      });
      return { optionsMappingToIndices, optionsIndices, optionsMap };
    }, [updatedQuestionsData.current.options.length]);

  const handleReorderOptions = () => {
    optionsIndices.map((elem, index) => {
      updatedQuestionsData.current.options[index] = optionsMap.get(elem)!!;
    });
    setReRender(!reRender);
    setShowModal(false);
    displaySuccessToast("Successfully reordered Options");
  };

  return (
    <Modal
      contentClassName={styles.modalStyle}
      show={showModal}
      centered
      onHide={() => setShowModal(false)}
    >
      <Modal.Header>
        <Typography variant="h4">
          <b>Reorder Options</b>
        </Typography>
        <IconButton>
          <CloseIcon onClick={() => setShowModal(false)} />
        </IconButton>
      </Modal.Header>
      <Modal.Body
        className="form-group mt-3 ms-2"
        style={{ height: "60vh", overflowY: "auto", overflowX: "hidden" }}
      >
        <ReorederableComponent
          reorderableContent={optionsIndices}
          headerMap={optionsMappingToIndices}
        />
      </Modal.Body>
      <Modal.Footer style={{ display: "flex", flexDirection: "row" }}>
        <CustomButton
          onClick={() => {
            setShowModal(false);
          }}
          className="mt-1 mx-2 bg-danger"
        >
          Discard
        </CustomButton>
        <CustomButton
          onClick={handleReorderOptions}
          className="mt-1 mx-2 bg-success"
        >
          Save
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
}
