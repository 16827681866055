import axios from "axios";
import { baseURL } from "../utils/constants";
import { displayErrorToast } from "../utils/ToastUtils";
import { IQuestion, newQuestionObj } from "../models/question";
import HTTPStatusCode from "../utils/HTTPStatusCode";
import { handleUnauthorisedException } from "../utils/apiUtils";
import { IquestionData } from "../models/assessmentmodel";

export const createQuestion = async (
  accessToken: string,
  question: newQuestionObj,
  orgId: number,
  questionCount: number
) => {
  try {
    console.log("Making request for new blank question");
    const res = await axios.post<IQuestion[]>(
      `${baseURL}/api/v1/question?org_id=${orgId}&count=${questionCount}`,
      {
        ...question,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return res.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }

    displayErrorToast("There was an error in question creation");
    console.error(error);
    return null;
  }
};

export const saveQuestionData = async (
  accessToken: string,
  updatedQuestion: IquestionData
) => {
  try {
    let response = await axios.patch(
      `${baseURL}/api/v1/question/${updatedQuestion.question_id}`,
      updatedQuestion,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    displayErrorToast("Unable to save question");
    console.log(error);
    return null;
  }
};

export const flagQuestion = async (
  accessToken: string,
  questionId: number,
  status: number
) => {
  try {
    await axios.patch(
      `${baseURL}/api/v1/question/${questionId}`,
      {
        status: status,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return 1;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    displayErrorToast("Unable to flag question");
    console.log(error);
    return 0;
  }
};

export const editMarkingScheme = async (
  accessToken: string,
  assessmentId: number,
  questionId: number,
  marks_if_correct: number,
  marks_if_incorrect: number,
  marks_if_unattempted: number
) => {
  try {
    await axios.patch(
      `${baseURL}/api/v1/assessment/${assessmentId}/marking_scheme/${questionId}`,
      {
        marks_if_correct: marks_if_correct,
        marks_if_incorrect: marks_if_incorrect,
        marks_if_unattempted: marks_if_unattempted,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return 1;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    displayErrorToast("Unable to edit the marking scheme");
    console.log(error);
    return null;
  }
};

export const deleteQuestionFromAssessment = async (
  accessToken: string,
  assessmentId: number,
  sectionId: number,
  questionId: number
) => {
  try {
    const response = await axios.delete(
      `${baseURL}/api/v1/assessment/${assessmentId}/section/${sectionId}/question/${questionId}`,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    return response.data;
  } catch (error: any) {
    displayErrorToast("Failed to delete question");
    return null;
  }
};
