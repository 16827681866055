import { useCallback, useEffect, useMemo, useRef, useState } from "react";

const useCountDown = (timeToCount = 60 * 1000, interval = 1000 /** ms */) => {
  const [timeLeft, setTimeLeft] = useState(0);
  const [isStarted, setIsStarted] = useState<boolean>(false);
  const timer = useRef({
    started: 0,
    lastInterval: 0,
    timeLeft: 0,
    timeToCount: 0,
    requestId: 0,
  });

  const run = useCallback(
    (ts: number) => {
      if (!timer.current.started) {
        timer.current.started = ts;
        timer.current.lastInterval = ts;
      }

      const localInterval = Math.min(
        interval,
        timer.current.timeLeft || Infinity
      );
      if (ts - timer.current.lastInterval!! >= localInterval) {
        timer.current.lastInterval += localInterval;
        setTimeLeft((timeLeft) => {
          timer.current.timeLeft = timeLeft - localInterval;
          return timer.current.timeLeft;
        });
      }

      if (ts - timer.current.started < timer.current.timeToCount) {
        timer.current.requestId = window.requestAnimationFrame(run);
      } else {
        timer.current = {
          started: 0,
          lastInterval: 0,
          timeLeft: 0,
          timeToCount: 0,
          requestId: 0,
        };
        setTimeLeft(0);
      }
    },
    [interval]
  );

  const start = useCallback(
    (ttc) => {
      window.cancelAnimationFrame(timer.current.requestId);

      const newTimeToCount = ttc !== undefined ? ttc : timeToCount;
      timer.current.started = 0;
      timer.current.lastInterval = 0;
      timer.current.timeToCount = newTimeToCount;
      timer.current.requestId = window.requestAnimationFrame(run);

      setTimeLeft(newTimeToCount);
      setIsStarted(true);
    },
    [run, timeToCount]
  );

  const pause = useCallback(() => {
    window.cancelAnimationFrame(timer.current.requestId);
    timer.current.started = 0;
    timer.current.lastInterval = 0;
    timer.current.timeToCount = timer.current.timeLeft;
  }, []);

  const resume = useCallback(() => {
    if (timer.current.timeLeft > 0) {
      window.cancelAnimationFrame(timer.current.requestId);
      timer.current.requestId = window.requestAnimationFrame(run);
    }
  }, [run]);

  const reset = useCallback(() => {
    if (timer.current.timeLeft > 0) {
      window.cancelAnimationFrame(timer.current.requestId);
      timer.current = {
        started: 0,
        lastInterval: 0,
        timeLeft: 0,
        timeToCount: 0,
        requestId: 0,
      };
      setTimeLeft(0);
    }
  }, []);

  const actions: any = useMemo(
    () => ({ start, pause, resume, reset }),
    [start, pause, resume, reset]
  );

  useEffect(() => {
    return () => window.cancelAnimationFrame(timer.current.requestId);
  }, []);

  return [timeLeft, actions, isStarted];
};

export default useCountDown;
