//The math component inside TipTap editor
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { NodeViewContent, NodeViewWrapper } from "@tiptap/react";
import { MathfieldComponent } from "./MathLive";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { BsFillPencilFill } from "react-icons/bs";

export default function MathWrapperComponent(props) {
  const [show, setShow] = useState(false);
  const [math, setMath] = useState(props.node.attrs.math);
  const editMode = props.node.attrs.editmode;

  // whenever a change in math is detected, update the props
  useEffect(() => {
    // props
    props.updateAttributes({
      math: math,
    });
  }, [math]);

  return (
    <NodeViewWrapper style={{ display: "inline-flex" }}>
      <NodeViewContent>
        <EditMathModal
          show={show}
          setShow={setShow}
          math={math}
          setMath={setMath}
        />
        <span
          className="math-component"
          onClick={() => {
            setShow(editMode);
          }}
        >
          {/* math-component */}
          <MathfieldComponent
            highlight={editMode}
            mathfieldConfig={{ defaultMode: "inline-math", readOnly: true }}
            latex={math}
          />
          {editMode && (
            <BsFillPencilFill
              size={"15px"}
              color="black"
              className="edit-button"
              onClick={() => {
                if (editMode === true) {
                  setShow(true);
                }
              }}
            />
          )}
        </span>
      </NodeViewContent>
    </NodeViewWrapper>
  );
}
interface EditMathModalProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  math: string;
  setMath: Dispatch<SetStateAction<string>>;
}

export const EditMathModal: FunctionComponent<EditMathModalProps> = (props) => {
  const [updatedMath, setUpdatedMath] = useState<string>(props.math);
  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Latex Editor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            props.setMath(updatedMath);
            props.setShow(false);
          }}
        >
          <Form.Group className="mb-3 ps-3" controlId="#" as={Row}>
            <Row className="mb-3">
              <Form.Label column>Edit Latex:</Form.Label>
              <Col className="border border-grey rounded-1 py-2 mx-3">
                <MathfieldComponent
                  initialLatex={updatedMath}
                  latex={updatedMath}
                  mathfieldConfig={{
                    virtualKeyboardMode: "onfocus",
                  }}
                  onChange={(content) => {
                    setUpdatedMath("$" + content + "$");
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Form.Label column>Edit Raw Latex:</Form.Label>
              <Col className="me-4 ">
                <Form.Control
                  value={updatedMath.slice(1, -1)}
                  onChange={(e) => {
                    setUpdatedMath("$" + e.target.value + "$");
                  }}
                  type="text"
                  placeholder="Enter latex"
                  className="bg-light"
                />
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            props.setShow(false);
          }}
        >
          Close
        </Button>
        <Button
          type="submit"
          variant="primary"
          onClick={() => {
            props.setMath(updatedMath);
            props.setShow(false);
          }}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
