import axios from "axios";
import { handleUnauthorisedException } from "../utils/apiUtils";
import { baseURL } from "../utils/constants";
import HTTPStatusCode from "../utils/HTTPStatusCode";
import { displayErrorToast } from "../utils/ToastUtils";

export const fetchProductData = async (
  productCode: string,
  userId?: number
) => {
  try {
    const userQuery = userId ? `?user_id=${userId}` : "";
    const response = await axios.get<any>(
      `${baseURL}/api/v1/product/${productCode}${userQuery}`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      }
    );
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    if (error?.response?.status === HTTPStatusCode.FORBIDDEN) {
      console.error("Forbidden");
      displayErrorToast("You are forbidden from viewing this product");
      return null;
    }
    console.log(error);
    displayErrorToast("Unable to fetch product");
    return null;
  }
};

export const placeOrder = async (
  accessToken: string,
  productCode: string,
  discountedPrice?: number
) => {
  try {
    const response = await axios.post<any>(
      `${baseURL}/api/v1/product/${productCode}/order`,
      discountedPrice !== undefined
        ? { discounted_price: discountedPrice }
        : {},
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.NOT_FOUND) {
      console.error("Unauthorised request");
      displayErrorToast(error?.response?.data?.error);
      return null;
    }
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    if (error?.response?.status === HTTPStatusCode.FORBIDDEN) {
      console.error("Forbidden");
      displayErrorToast("You are forbidden from ordering this product");
      return null;
    }
    if (error?.response?.status === HTTPStatusCode.BAD_REQUEST) {
      displayErrorToast(error?.response?.data?.error);
      return null;
    }
    console.log(error);
    displayErrorToast("Unable to place order");
    return null;
  }
};

export const validateOrder = async (
  accessToken: string,
  orderId: number,
  gateway_order_id: string,
  gateway_payment_id: string,
  gateway_payment_signature: string
) => {
  try {
    const response = await axios.post<any>(
      `${baseURL}/api/v1/order/${orderId}/validate`,
      {
        gateway_order_id: gateway_order_id,
        gateway_payment_id: gateway_payment_id,
        gateway_payment_signature: gateway_payment_signature,
      },
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    if (error?.response?.status === HTTPStatusCode.FORBIDDEN) {
      console.error("Forbidden");
      displayErrorToast("You are forbidden from ordering this product");
      return null;
    }
    console.log(error);
    displayErrorToast("Unable to place order");
    return null;
  }
};

export const fetchAllProductsData = async (userId?: number) => {
  try {
    const userQuery = userId ? `?user_id=${userId}` : "";
    const response = await axios.get<any>(
      `${baseURL}/api/v1/product${userQuery}`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      }
    );
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    if (error?.response?.status === HTTPStatusCode.FORBIDDEN) {
      console.error("Forbidden");
      displayErrorToast("You are forbidden from viewing this product");
      return null;
    }
    console.log(error);
    displayErrorToast("Unable to fetch all products");
    return null;
  }
};

export const patchProductStatus = async (
  accessToken: string,
  productCode: string,
  is_active: boolean
) => {
  try {
    const response = await axios.patch<any>(
      `${baseURL}/api/v1/product/${productCode}`,
      {
        is_active: is_active,
      },
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.NOT_FOUND) {
      console.error("Unauthorised request");
      displayErrorToast(error?.response?.data?.error);
      return null;
    }
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    if (error?.response?.status === HTTPStatusCode.FORBIDDEN) {
      console.error("Forbidden");
      displayErrorToast("You are forbidden from updating this product");
      return null;
    }
    if (error?.response?.status === HTTPStatusCode.BAD_REQUEST) {
      displayErrorToast(error?.response?.data?.error);
      return null;
    }
    console.log(error);
    displayErrorToast("Unable to update this product");
    return null;
  }
};

export const createCoupon = async (
  accessToken: string,
  couponCode: string,
  productCode: string,
  validUpto: string,
  discount: number,
  couponDescription: string
) => {
  try {
    const response = await axios.post<any>(
      `${baseURL}/api/v1/coupon`,
      {
        code: couponCode,
        product_code: productCode,
        valid_upto: validUpto,
        discount: discount,
        description: couponDescription,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (error?.response?.status === HTTPStatusCode.NOT_FOUND) {
      console.error("Unauthorised request");
      displayErrorToast(error?.response?.data?.error);
      return null;
    }
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    if (error?.response?.status === HTTPStatusCode.FORBIDDEN) {
      console.error("Forbidden");
      displayErrorToast("You are forbidden from creating this coupon");
      return null;
    }
    if (error?.response?.status === HTTPStatusCode.BAD_REQUEST) {
      displayErrorToast(error?.response?.data?.error);
      return null;
    }
    console.log(error);
    displayErrorToast("Unable to create this coupon");
    return null;
  }
};

export const fetchCouponData = async (
  accessToken: string,
  couponCode: string,
  productCode: string
) => {
  try {
    const response = await axios.get<any>(
      `${baseURL}/api/v1/coupon/${productCode}/${couponCode}`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.NOT_FOUND) {
      console.error("Unauthorised request");
      displayErrorToast(error?.response?.data?.error);
      return null;
    }
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    if (error?.response?.status === HTTPStatusCode.FORBIDDEN) {
      console.error("Forbidden");
      displayErrorToast("You are forbidden from applying this coupon");
      return null;
    }
    if (error?.response?.status === HTTPStatusCode.BAD_REQUEST) {
      displayErrorToast(error?.response?.data?.error);
      return null;
    }
    console.log(error);
    displayErrorToast("Unable to apply this coupon");
    return null;
  }
};
