import { useMediaQuery } from "@mui/material";
import moment from "moment";
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
} from "react";
import { Button, Navbar } from "react-bootstrap";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useHistory, useLocation } from "react-router-dom";
import CustomButton from "../../../../components/CustomComponent/CustomButton";
import {
  EAssessmentFormat,
  EPublishedAssessmentFormat,
  ERole,
} from "../../../../utils/Enums";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../context/AssessmentContext";
import { AppContext, TAppContext } from "../../../../context/AppContext";
import useCountDown from "../../../../components/CustomComponent/TimerHook";
import { getServerTime } from "../../../../api/user";
import {
  AttemptContext,
  TAttemptContext,
} from "../../../../context/AttemptContext";
import { IoIosMenu } from "react-icons/io";
import { saveResponses } from "../../../../api/responses";
import { displaySuccessToast } from "../../../../utils/ToastUtils";
import { parseMapFromJSON } from "../../../../utils/hooks/useStickyState";

interface QuestionTopNavBarProps {
  assessmentName: string;
  role: ERole;
  showQNavDrawer: boolean;
  setShowQNavDrawer: Dispatch<SetStateAction<boolean>>;
  setShowSubmitModal: Dispatch<SetStateAction<boolean>>;
  proofRead: boolean;
  setProofRead: Dispatch<SetStateAction<boolean>>;
  edit: boolean;
  setEdit: Dispatch<SetStateAction<boolean>>;
  handleSubmit: () => void;
  hasFinishedAttempt?: boolean;
  loading?: boolean;
  setLoading?: Dispatch<SetStateAction<boolean>>;
}

// TODO: Fix the alignments and improve the layout to avoid using proofRead prop to set the flex spacing
const QuestionTopNavBar: FunctionComponent<QuestionTopNavBarProps> = (
  props
) => {
  const history = useHistory();
  const location = useLocation();
  const { role, accessToken } = useContext<TAppContext>(AppContext);
  const { currentSession } = useContext<TAttemptContext>(AttemptContext);
  const { selectedAssessment, publishInfo } =
    useContext<TAssessmentContext>(AssessmentContext);
  const isDesktopOrLaptop =
    useMediaQuery("(min-width: 992px)") && !props.proofRead;
  const randomTime = location.state?.randomTime ?? 0;
  const [timeLeft, { start }, isStarted] = useCountDown(100, 1000);
  useEffect(() => {
    (async () => {
      if (role === ERole.TEACHER || props.hasFinishedAttempt) return;
      const serverTime = await getServerTime(accessToken);
      let newTime: number | undefined = undefined;
      switch (publishInfo.format) {
        case EPublishedAssessmentFormat.FIXED_TIME:
          newTime = moment
            .utc(publishInfo?.end_time)
            .diff(moment.utc(serverTime), "milliseconds");
          break;
        case EPublishedAssessmentFormat.FIXED_DURATION_UNTIMED:
          newTime =
            publishInfo.duration * 60 * 1000 -
            moment
              .utc(serverTime)
              .diff(moment.utc(currentSession.started_on), "milliseconds");
          break;
        case EPublishedAssessmentFormat.FIXED_DURATION:
          const endTime = moment
            .utc(publishInfo?.end_time)
            .diff(moment.utc(serverTime), "milliseconds");
          const durationTime =
            publishInfo.duration * 60 * 1000 -
            moment
              .utc(serverTime)
              .diff(moment.utc(currentSession.started_on), "milliseconds");
          newTime = Math.min(endTime, durationTime);
          break;
      }
      if (!newTime) return;
      newTime = newTime + randomTime;
      start(newTime);
    })();
  }, []);
  useEffect(() => {
    if (
      role === ERole.TEACHER ||
      props.hasFinishedAttempt ||
      publishInfo?.format === EPublishedAssessmentFormat.UNTIMED
    )
      return;
    if (timeLeft === 0 && isStarted) {
      props.handleSubmit();
    }
  }, [timeLeft]);

  const handleSaveChanges = async () => {
    if (props.setLoading) props.setLoading(true);
    const response = await saveResponses(
      accessToken,
      parseMapFromJSON(window.localStorage.getItem("unpushedAttempts"))
    );
    if (response) {
      displaySuccessToast("Saved all your responses");
    }
    if (props.setLoading) props.setLoading(false);
  };

  return (
    <Navbar
      className="d-flex bg-primary py-4 px-2"
      style={{
        height: "8%",
        width: "100%",
        zIndex: 15,
      }}
    >
      <div className="w-100 d-flex flex-row justify-content-between align-items-center">
        {!isDesktopOrLaptop &&
          selectedAssessment.format !== EAssessmentFormat.OMR && (
            <>
              <div>
                <Button
                  className="my-2"
                  onClick={() => props.setShowQNavDrawer(!props.showQNavDrawer)}
                >
                  <IoIosMenu size={"20px"} />
                </Button>
              </div>
            </>
          )}

        {(isDesktopOrLaptop ||
          selectedAssessment.format === EAssessmentFormat.OMR) && (
          <div className="d-flex align-items-center justify-content-center">
            <Button
              onClick={() => {
                // document.exitFullscreen(); //exits fullscreen mode
                if (props.edit && props.setEdit !== undefined) {
                  props.setEdit(false);
                  return;
                }
                history.goBack();
              }}
            >
              <IoArrowBackCircleOutline
                size={"25px"}
                color="white"
                cursor={"pointer"}
              />
            </Button>
            {isDesktopOrLaptop && (
              <h2 className="text-white mb-0">{props.assessmentName}</h2>
            )}
          </div>
        )}

        <div
          onClick={() => {
            props.setProofRead(!props.proofRead);
          }}
          style={{ cursor: "pointer" }}
          className="d-flex fs-4 align-items-center justify-content-center"
        >
          {props.proofRead ? (
            <BsFillEyeSlashFill color="#FFBA69" cursor={"pointer"} />
          ) : (
            <BsFillEyeFill color="#FFBA69" cursor={"pointer"} />
          )}

          <span style={{ color: "#FFBA69" }} className="mx-2">
            PDF
          </span>
        </div>

        {props.role === ERole.STUDENT &&
          props.setShowSubmitModal &&
          !props.hasFinishedAttempt &&
          String(publishInfo.format) !==
            String(EPublishedAssessmentFormat.UNTIMED) && (
            <div className="d-flex flex-row align-items-center justify-content-center">
              {publishInfo.format !== EPublishedAssessmentFormat.UNTIMED && (
                <h5
                  className="mb-0 mx-2"
                  style={{ color: "#C4C4C4", fontSize: "100%" }}
                >
                  {Math.floor(moment.duration(timeLeft).asDays()) > 0 &&
                    Math.floor(moment.duration(timeLeft).asDays()) + " days "}
                  {moment.utc(timeLeft).format("HH:mm:ss")}
                </h5>
              )}
              <div className="mb-2 d-flex flex-row">
                {role === ERole.STUDENT &&
                  selectedAssessment.format === EAssessmentFormat.NTA && (
                    <CustomButton
                      disabled={props.loading}
                      onClick={handleSaveChanges}
                    >
                      Save
                    </CustomButton>
                  )}
                <CustomButton
                  className="bg-danger mx-2"
                  disabled={props.loading}
                  onClick={() => props.setShowSubmitModal!!(true)}
                >
                  Submit
                </CustomButton>
              </div>
            </div>
          )}
      </div>
    </Navbar>
  );
};

export default QuestionTopNavBar;
