import {
  Dispatch,
  FunctionComponent,
  MutableRefObject,
  SetStateAction,
} from "react";

import { IquestionData } from "../../../../models/assessmentmodel";
import { EQuestionType } from "../../../../utils/Enums";
import NumericalEdit from "./Numerical/NumericalEdit";
import { MultipleChoiceEdit } from "./MultipleChoice/MultipleChoiceEdit";

interface QuestionTypeEditProps {
  questionType: EQuestionType;
  handleShow: Function;
  updatedQuestionData: MutableRefObject<IquestionData>;
  reRender: boolean;
  setReRender: Dispatch<SetStateAction<boolean>>;
}

const QuestionTypeEdit: FunctionComponent<QuestionTypeEditProps> = (props) => {
  switch (props.updatedQuestionData.current.question_type) {
    case EQuestionType.MCMCQ:
    case EQuestionType.MCQ:
      return (
        <MultipleChoiceEdit
          updatedQuestionData={props.updatedQuestionData}
          reRender={props.reRender}
          setReRender={props.setReRender}
          handleShow={props.handleShow}
        />
      );
    case EQuestionType.NUMERICAL:
      return <NumericalEdit questionData={props.updatedQuestionData} />;
    default:
      return <></>;
  }
};

export default QuestionTypeEdit;
