import { EQuestionType } from "./Enums";

export const findQuestionType = (qtype: EQuestionType) => {
  switch (qtype) {
    case EQuestionType.MCQ:
      return "MCQ";
    case EQuestionType.MCMCQ:
      return "MMCQ";
    case EQuestionType.NUMERICAL:
      return "Numerical";
  }
};
