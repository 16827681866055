import { Dispatch, SetStateAction } from "react";
import { IsectionInfo, questionInfo } from "../../../../models/assessmentmodel";
import { ERole } from "../../../../utils/Enums";
import QuestionNavigationPanel from "./QuestionNavigationPanel";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import CustomButton from "../../../../components/CustomComponent/CustomButton";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

interface IProps {
  show: boolean;
  setShowHandler: Dispatch<SetStateAction<boolean>>;
  role: ERole;
  sectionMap: Map<number, IsectionInfo>;
  allQuestionInfo: Map<number, questionInfo>;
  proofRead?: boolean;
}

export default function QuestionNavigationDrawer(props: IProps) {
  const history = useHistory();
  const container =
    window !== undefined ? () => window.document.body : undefined;
  const { show, setShowHandler, role, sectionMap, allQuestionInfo, proofRead } =
    props;

  return (
    <Drawer
      container={container}
      variant="temporary"
      open={show}
      onClose={() => setShowHandler(!show)}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        "& .MuiDrawer-paper": {
          width: "75%",
          maxWidth: "500px",
          boxSizing: "border-box",
          zIndex: "10",
          backgroundColor: "#E5E5E5",
        },
      }}
    >
      <div className="d-flex justify-content-between align-items-center p-3">
        <div className="text-center d-flex flex-grow-1 justify-content-center">
          <CustomButton
            onClick={() => {
              history.goBack();
            }}
          >
            <IoArrowBackCircleOutline size={"25px"} /> Back to Instructions
          </CustomButton>
        </div>
        <div>
          <IconButton
            className="mt-2 mx-2"
            onClick={() => setShowHandler(!show)}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </div>
      </div>
      <Divider />
      <QuestionNavigationPanel
        role={role}
        sectionMap={sectionMap}
        allQuestionInfo={allQuestionInfo}
        proofRead={proofRead}
      />
    </Drawer>
  );
}
