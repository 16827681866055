import { useContext, useState, Dispatch, SetStateAction } from "react";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../context/AssessmentContext";
import { Spinner } from "react-bootstrap";
import CustomButton from "../../../components/CustomComponent/CustomButton";
import { AppContext, TAppContext } from "../../../context/AppContext";
import { AddManuallyModal } from "../../question/Components/AddQuestionButton/ManualQuestionModal";
import { saveQuestionData } from "../../../api/question";
import { displaySuccessToast } from "../../../utils/ToastUtils";
import { IsectionInfo } from "../../../models/assessmentmodel";
import { handleUpdateSection } from "../../../api/assessment";
import { ERole } from "../../../utils/Enums";
import { saveResponses } from "../../../api/responses";
import {
  AttemptContext,
  TAttemptContext,
} from "../../../context/AttemptContext";
import {
  Paper,
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery,
} from "@mui/material";
import { IoSaveOutline, IoAddCircleOutline, IoShare } from "react-icons/io5";
import { parseMapFromJSON } from "../../../utils/hooks/useStickyState";
import ReorderIcon from "@mui/icons-material/Reorder";

interface IProps {
  isReordering: boolean;
  setIsReordering: Dispatch<SetStateAction<boolean>>;
  currentSection: IsectionInfo;
  proofRead: boolean;
  isSavingChanges: boolean;
  setIsSavingChanges: Dispatch<SetStateAction<boolean>>;
}

export default function QuestionButtonPanel({
  isReordering = false,
  setIsReordering,
  currentSection,
  isSavingChanges,
  setIsSavingChanges,
}: IProps) {
  const isDesktopOrLaptop = useMediaQuery("(min-width: 992px)");
  const {
    accessToken,
    role,
    isLoggedIn,
    setShowLoginModal,
    setShouldTransferAssessment,
  } = useContext<TAppContext>(AppContext);
  const [showModal, setShowModal] = useState<boolean>(false);
  const {
    currentSectionNumber,
    selectedAssessment,
    updatedableQuestionMap,
    setUpdateableQuestionMap,
    sectionMap,
  } = useContext<TAssessmentContext>(AssessmentContext); // Get the data from the context

  const [loading, setLoading] = useState<boolean>(false);

  const handleAddQuestion = async () => {
    setShowModal(true);
  };

  const handleSaveTeacher = async (supress: boolean) => {
    if (updatedableQuestionMap.size === 0) {
      if (!supress) displaySuccessToast("Saved the questions");
      return;
    }
    setLoading(true);
    setIsSavingChanges(true);
    let unwrappedQuestion: Array<any> = [];
    updatedableQuestionMap.forEach((question, questionId) => {
      unwrappedQuestion.push({
        question_id: questionId,
        question: question,
      });
    });
    const responses: Array<any> = await Promise.all([
      unwrappedQuestion.map((ele) => {
        return saveQuestionData(accessToken, ele.question);
      }),
    ]);
    setLoading(false);
    setIsSavingChanges(false);
    if (responses && !supress) {
      displaySuccessToast("Saved the questions");
    }
    // Clear the questions to be updated such that once if you call save again
    // the calls shouldn't be made
    setUpdateableQuestionMap(new Map());
  };

  const handleSaveStudent = async () => {
    setLoading(true);
    const response = await saveResponses(
      accessToken,
      parseMapFromJSON(window.localStorage.getItem("unpushedAttempts"))
    );
    setLoading(false);
    if (response) {
      displaySuccessToast("Saved the responses");
    }
  };

  const handleDiscardReorder = () => {
    currentSection = sectionMap.get(
      selectedAssessment.sections[currentSectionNumber]
    )!!;
    setIsReordering(false);
  };

  const handleSaveReorder = async () => {
    const response = await handleUpdateSection(
      accessToken,
      currentSection.id,
      selectedAssessment.id,
      undefined,
      currentSection.question_ids
    );
    if (!response) return;
    sectionMap.set(currentSection.id, currentSection);
    setIsReordering(false);
    displaySuccessToast("Successfully reordered");
  };

  const handleSaveChanges = (supress: boolean = false) => {
    if (role === ERole.STUDENT) {
      handleSaveStudent();
      return;
    }
    if (isReordering) {
      handleSaveReorder();
      return;
    }
    handleSaveTeacher(supress);
  };

  const handleButtonClicks = (val: number) => {
    // unifies handling of varies function of diff buttons. The button return their index
    switch (val) {
      case 0:
        // Save Changes. If not logged in then show login modal. otherwise save changes
        if (!isLoggedIn) {
          setShowLoginModal(true);
          setShouldTransferAssessment(true);
        }
        handleSaveChanges(!isLoggedIn);
        return;
      case 1:
        // Add quesitons or discarding save reordering
        if (isReordering) handleDiscardReorder();
        else handleAddQuestion();
        return;
      case 2:
        // Share
        setShouldTransferAssessment(true);
        setShowLoginModal(true);
        return;
      case 3:
        setIsReordering(true);
        return;
      default:
        return;
    }
  };

  const BottomTabMobile = () => {
    return (
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          style={{ backgroundColor: "#292F4D" }}
          value={-1}
          onChange={(event, newValue) => {
            handleButtonClicks(newValue);
          }}
        >
          <BottomNavigationAction
            style={{ color: "white" }}
            label="Save changes"
            icon={<IoSaveOutline />}
          />
          {role === ERole.TEACHER && (
            <BottomNavigationAction
              style={{ color: "white" }}
              label={isReordering ? "Discard" : "Add Questions"}
              icon={<IoAddCircleOutline />}
            />
          )}
          {!isLoggedIn && (
            <BottomNavigationAction
              style={{ color: "white" }}
              label="Share"
              icon={<IoShare />}
            />
          )}
          {role === ERole.TEACHER && !isReordering && (
            <BottomNavigationAction
              style={{ color: "white" }}
              label={"Reorder Questions"}
              icon={<ReorderIcon fontSize="small" />}
            />
          )}
        </BottomNavigation>
      </Paper>
    );
  };

  const BottomTabDesktop = () => {
    return (
      <div
        className="w-100 bg-primary-dark"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "15px",
          flexDirection: "row",
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          (
          <CustomButton
            style={{
              borderRadius: "12px",
            }}
            disabled={loading}
            onClick={() => handleButtonClicks(0)} /**loading={loading}*/
          >
            {isSavingChanges ? (
              <Spinner animation="border" role="status" />
            ) : (
              <>Save Changes</>
            )}
          </CustomButton>
          )
          {role === ERole.TEACHER && (
            <>
              <CustomButton
                style={{
                  borderRadius: "12px",
                }}
                className="mx-2"
                onClick={() => handleButtonClicks(1)}
              >
                {isReordering ? "Discard" : "Add question"}
              </CustomButton>
              {!isReordering && (
                <CustomButton onClick={() => handleButtonClicks(3)}>
                  Reorder Questions
                </CustomButton>
              )}
            </>
          )}
        </div>
        {!isLoggedIn && (
          <>
            <CustomButton
              style={{
                borderRadius: "12px",
              }}
              disabled={loading}
              onClick={() => {
                handleButtonClicks(2);
              }} /**loading={loading}*/
            >
              Share
            </CustomButton>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {isDesktopOrLaptop ? <BottomTabDesktop /> : <BottomTabMobile />}
      <AddManuallyModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
}
