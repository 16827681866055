import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { AppContext, TAppContext } from "../../context/AppContext";
import { MembershipStatus } from "../../utils/Enums";
import { Col, Row } from "react-bootstrap";

// MUI imports
import {
  Autocomplete,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  Tooltip,
  IconButton,
  Drawer,
  Divider,
  Chip,
  Menu,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ClearIcon from "@mui/icons-material/Clear";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InsightsIcon from "@mui/icons-material/Insights";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import { displayErrorToast, displaySuccessToast } from "../../utils/ToastUtils";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  approveOrRejectStudentsInBatch,
  deleteBatch,
  fetchCurrentBatch,
  fetchStudentsFromBatch,
  fetchStudentsRequestsFromBatch,
  removeUsersFromBatch,
} from "../../api/batches";
import {
  BatchManagementContext,
  TBatchManagementContext,
} from "../../context/BatchManagementContext";
import { BsTrash } from "react-icons/bs";

import styles from "../my-tests/MyTests.module.scss";
import AddUserToBatchModal from "./components/AddUserToBatchModal";
import AddMultipleUsersToBatchModal from "./components/AddMultipleUsersToBatchModal";
import { TBatchUser } from "../../models/batches";
import { MdAccountCircle, MdDeleteForever } from "react-icons/md";
import ConfirmationModal from "../../components/ConfirmationModal";
import Loading from "../../components/Loading/Loading";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import copy from "copy-to-clipboard";
import { InviteBatchlink } from "../../constants/inviteLink";
import CreateOrEditBatchModal from "./components/CreateOrEditBatchModal";
import ManageBatchNavBar from "./components/ManageBatchNavBar";

enum ETabs {
  APPROVED = 0,
  REQUESTS = 1,
}

enum ESearchOption {
  NAME = 0,
  EMAIL = 1,
  ALL = 2,
}

const TeacherBatchManagementView: FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const params: any = useParams();

  const isMobile = useMediaQuery("(max-width: 576px)");
  const isDesktopOrLaptop = useMediaQuery("(min-width: 992px)");

  const { accessToken, setDrawerOpen } = useContext<TAppContext>(AppContext);
  const {
    batchUsers,
    setBatchUsers,
    setRequestsBatchUsers,
    requestsBatchUsers,
    forceReRender,
    setForceReRender,
    setSelectedBatchName
  } = useContext<TBatchManagementContext>(BatchManagementContext);

  // TODO: typeset currentBatch
  const [currentBatch, setCurrentBatch] = useState<any>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  const [userToBeRemoved, setUserToBeRemoved] = useState<any>();

  // Util function which tries to first set batches if found locally
  // or otherwise tries to fetch batches from backend and save it.
  // Also finds the currentBatch from all the batches using batch code present in url
  const fetchCurrentBatchDetails = async () => {
    const batch = await fetchCurrentBatch(accessToken, params.code);
    if (!batch) history.push("/home/dashboard");
    setCurrentBatch({
      adminId: batch.admin_id,
      id: batch.id,
      name: batch.name,
      code: batch.code,
      orgId: batch.org_id,
      createdAt: batch.created_at.split("T")[0],
      membershipStatus: batch.membership_status,
      batchDescription: batch.description,
    });
    setSelectedBatchName(batch.name)
  };

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [selectedStudentIds, setSelectedStudentIds] = useState<Array<number>>(
    []
  );

  const [filteredBatchUsers, setFilteredBatchUsers] =
    useState<Array<TBatchUser>>(batchUsers);

  const [selectedSearchOption, setSelectedSearchOption] =
    useState<ESearchOption>(ESearchOption.NAME);

  const [selectedUserDetails, setSelectedUserDetails] =
    useState<TBatchUser | null>(null);

  // Modal Handling State Vars
  const [
    showAddMultipleParticipantsModal,
    setShowAddMultipleParticipantsModal,
  ] = useState<boolean>(false);

  const [showAddParticipantsModal, setShowAddParticipantsModal] =
    useState<boolean>(false);

  // state variables and methods controlling the bottom drawer
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
  const showBottomDrawer = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setDrawerOpen(false);
    return () => setDrawerOpen(true);
  });

  useEffect(() => {
    if (!location.batch) fetchCurrentBatchDetails();
    else setCurrentBatch(location.batch);
  }, []);

  // state variables and methods controlling the menu
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleInviteUser = () => {
    const success = copy(InviteBatchlink(currentBatch));
    if (!success) {
      displayErrorToast("Failed to copy link");
      return;
    }
    displaySuccessToast("Link copied to the clipboard");
  };

  const handleRemoveUsersOnClick = async (
    email: string,
    id: number | string
  ) => {
    const usersToRemove = [
      {
        user_id: typeof id === "number" ? id : null,
        email: email,
      },
    ];
    const status = await removeUsersFromBatch(
      accessToken,
      currentBatch.id,
      usersToRemove
    );

    if (!status) return;

    setForceReRender(!forceReRender);
    setAnchorEl(null);
    displaySuccessToast("Users removed successfully.");
    setShowConfirmationModal(false);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (location.batch && !currentBatch) {
        setCurrentBatch(location.batch);
        return;
      } else if (!currentBatch) {
        fetchCurrentBatchDetails();
        return;
      }
      const approvedUsers = await fetchStudentsFromBatch(
        accessToken,
        currentBatch!!.id
      );
      if (approvedUsers == null) return;
      const requestedUsers = await fetchStudentsRequestsFromBatch(
        accessToken,
        currentBatch!!.id
      );
      if (requestedUsers == null) return;
      setBatchUsers(approvedUsers);
      setFilteredBatchUsers(
        selectedTab === ETabs.APPROVED ? approvedUsers : requestedUsers
      );
      setRequestsBatchUsers(requestedUsers);
      setIsLoading(false);
    })();
  }, [accessToken, forceReRender, currentBatch]);

  const columnsDesktop = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        headerClassName: styles.header,
        flex: 5,
      },
      {
        field: "email",
        headerClassName: styles.header,
        headerName: "Email ID",
        flex: 4,
      },
      {
        field: "phone",
        headerName: "Phone Number",
        headerClassName: styles.header,
        flex: 4,
      },
      {
        field: "actions",
        headerName: "_",
        type: "actions",
        headerClassName: styles.actionHeader,
        flex: 1,
        getActions: (params) => [
          selectedTab === 1 ? (
            <></>
          ) : (
            <GridActionsCellItem
              icon={
                <Tooltip title="More options">
                  <BsTrash />
                </Tooltip>
              }
              label="Remove user"
              onClick={() => {
                setUserToBeRemoved(params.row);
                setShowConfirmationModal(true);
              }}
              showInMenu={true}
            />
          ),
          ,
        ],
      },
    ],
    [isDesktopOrLaptop, filteredBatchUsers]
  );

  const columnsMobile = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        headerClassName: styles.header,
        flex: 5,
      },
      {
        field: "actions",
        headerName: "",
        headerClassName: styles.header,
        width: 25,
        renderCell: (cellValues: any) => (
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={Boolean(anchorEl) ? "long-menu" : undefined}
            aria-expanded={Boolean(anchorEl) ? "true" : undefined}
            aria-haspopup="true"
            onClick={(e) => {
              setSelectedUserDetails(cellValues.row);
              showBottomDrawer(e);
            }}
          >
            <MoreVertIcon />
          </IconButton>
        ),
      },
    ],
    [filteredBatchUsers]
  );

  const approvedRows = useMemo(
    () =>
      filteredBatchUsers.map((el) => {
        return {
          id: el.user_id === -1 ? el.email : el.user_id,
          name: el.name || "NA",
          email: el.email,
          phone: el.phone || "NA",
        };
      }),
    [filteredBatchUsers]
  );

  const requestedRows = useMemo(
    () =>
      filteredBatchUsers.map((el) => {
        return {
          id: el.user_id === -1 ? el.email : el.user_id, // pre-enrolled user cases
          name: el.name || "NA",
          email: el.email,
          phone: el.phone || "NA",
        };
      }),
    [filteredBatchUsers, requestsBatchUsers]
  );

  const filterUsers = (searchString: string) => {
    let users: Array<TBatchUser> = [];
    switch (selectedSearchOption) {
      case 0:
        users =
          selectedTab === ETabs.APPROVED ? batchUsers : requestsBatchUsers;
        return users.filter((user) => user.name.includes(searchString));
      case 1:
        users =
          selectedTab === ETabs.APPROVED ? batchUsers : requestsBatchUsers;
        return users.filter((user) => user.email.includes(searchString));
      default:
        return [...batchUsers];
    }
  };

  const filters = [
    { key: ETabs.APPROVED, label: `Approved (${batchUsers.length})` },
    { key: ETabs.REQUESTS, label: `Requests (${requestsBatchUsers.length})` },
  ];

  const onSelectedSearchOptionChange = (
    e: SelectChangeEvent<ESearchOption>
  ) => {
    setSelectedSearchOption(e.target.value as ESearchOption);
  };

  const handleApproveStudents = () => {
    if (selectedStudentIds.length === 0) return;
    const response = approveOrRejectStudentsInBatch(
      accessToken,
      currentBatch.id,
      MembershipStatus.APPROVED,
      selectedStudentIds
    );
    if (response === null) return;
    setForceReRender(!forceReRender);
    displaySuccessToast(
      "Users approved successfully. Please refresh the page to load approved users"
    );
  };

  const handleRejectStudents = () => {
    if (selectedStudentIds.length === 0) return;
    const response = approveOrRejectStudentsInBatch(
      accessToken,
      currentBatch.id,
      MembershipStatus.REJECTED,
      selectedStudentIds
    );
    if (response === null) return;
    setForceReRender(!forceReRender);
    displaySuccessToast("Users Rejected successfully.");
  };

  const handleDeleteBatch = () => {
    const res = deleteBatch(accessToken, currentBatch.id);
    if (!res) return;
    displaySuccessToast("Successfully deleted the batch");
    history.push("/home/dashboard");
  };

  const handleCSVData = () => {
    let data: Array<Object> = [];
    batchUsers.map((el) => {
      data.push({
        Email: el.email,
        Name: el.name,
        Phone: el.phone,
        Status: "Approved",
      });
    });
    requestsBatchUsers.map((el) => {
      data.push({
        Email: el.email,
        Name: el.name,
        Phone: el.phone,
        Status: "Rejected",
      });
    });
    return data;
  };

  if (isLoading) return <Loading />;
  return (
    <div className="w-100">
      <ManageBatchNavBar
        batchName={currentBatch?.name}
        batchCode={currentBatch?.code}
        batchId={currentBatch?.id}
        handleEditBatch={() => setShowRenameModal(true)}
        handleDownloadParticipants={handleCSVData}
      />
      <Typography className="px-2 px-sm-4 px-lg-5 mt-2" variant="h4">
        <b>Batch Code</b>
      </Typography>
      <div className="d-flex align-items-center">
        <Typography className="ps-2 ps-sm-4 ps-lg-5 mt-0" variant="h6">
          {currentBatch?.code}
        </Typography>

        <ContentCopyIcon
          className="px-1"
          fontSize="large"
          color="info"
          style={{ cursor: "pointer" }}
          onClick={() => handleInviteUser()}
        />
      </div>
      <div className={"d-flex px-xs-4 px-lg-5 mt-2"}>
        <Button
          startIcon={<AddCircleIcon />}
          className={
            "rounded-3 text-white mb-1 mx-1" + (isMobile ? "ms-2" : "")
          }
          style={{ backgroundColor: "#00ADB5" }}
          onClick={(event) => setShowAddParticipantsModal(true)}
        >
          Add Participants
        </Button>
        <Button
          startIcon={<MdDeleteForever />}
          className="rounded-3 text-white mx-1 mb-1"
          style={{ backgroundColor: "#FF6961" }}
          onClick={() => setShowDeleteModal(true)}
        >
          Delete Batch
        </Button>
        <Button
          startIcon={<InsightsIcon />}
          className="rounded-3 text-white mx-1 mb-1"
          style={{
            backgroundColor: "#292F4D",
          }}
          onClick={() => {
            history.push(`/home/batch/${currentBatch?.code}/analytics/`);
          }}
        >
          View Analytics
        </Button>
      </div>
      <hr />
      <div className={"d-flex"} style={{ alignItems: "center" }}>
        <Typography className="px-2 px-sm-4 px-lg-5 mt-0" variant="h4">
          <b>Participants</b>
        </Typography>
      </div>
      <Row className="d-flex px-2 px-sm-4 px-lg-5 py-0 justify-content-between align-items-center">
        <Col xs={12} md={6} className="d-flex flex-row me-lg-5 my-2">
          {filters.map((filterType, idx) => (
            <Chip
              className="mx-2 p-3"
              style={
                idx === selectedTab
                  ? {
                      backgroundColor: "#A1A1A1",
                      color: "white",
                    }
                  : {
                      backgroundColor: "#F0F0F0",
                      color: "black",
                    }
              }
              onClick={() => {
                setFilteredBatchUsers(
                  idx === ETabs.APPROVED ? batchUsers : requestsBatchUsers
                );
                setSelectedTab(idx);
              }}
              label={filterType.label}
            />
          ))}
        </Col>
        <Col className="d-flex flex-row align-items-center">
          <Autocomplete
            className="ms-lg-5 me-2 my-2 ps-xl-5 flex-grow-1"
            freeSolo
            clearIcon={<ClearIcon color="disabled" />}
            options={filteredBatchUsers?.map((user) => {
              return selectedSearchOption === 0 ? user.name : user.email;
            })}
            onChange={(_, value) => {
              if (value) {
                setFilteredBatchUsers(filterUsers(value));
                return;
              }
              setFilteredBatchUsers([...batchUsers]);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  selectedSearchOption === ESearchOption.NAME
                    ? "Search by name"
                    : "Search by email"
                }
                sx={{
                  ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: 20,
                  },
                }}
              />
            )}
          />
          <FormControl>
            <InputLabel>Search by:</InputLabel>
            <Select
              value={selectedSearchOption}
              onChange={onSelectedSearchOptionChange}
              startAdornment={<FilterAltIcon color="disabled" />}
              sx={{ borderRadius: "20px" }}
            >
              <MenuItem value={ESearchOption.NAME}>Name</MenuItem>
              <MenuItem value={ESearchOption.EMAIL}>Email</MenuItem>
            </Select>
          </FormControl>
        </Col>
      </Row>
      <div
        className="px-2 px-sm-4 px-lg-5 py-1"
        style={{ height: 500, width: "100%" }}
      >
        {(selectedTab === ETabs.APPROVED ? approvedRows : requestedRows) && (
          <DataGrid
            checkboxSelection={selectedTab === ETabs.REQUESTS}
            onSelectionModelChange={(e) => {
              setSelectedStudentIds(e.map((id) => Number(id)));
            }}
            sx={{
              border: "none",
              "& .MuiDataGrid-cell": {
                border: "none",
              },
              "& .MuiDataGrid-cell:active": {
                outline: "none",
              },
              "& .MuiDataGrid-cell:hover": {
                outline: "none",
                cursor: "pointer",
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
            }}
            rows={selectedTab === ETabs.APPROVED ? approvedRows : requestedRows}
            columns={isMobile ? columnsMobile : columnsDesktop}
            components={{
              NoRowsOverlay: () => (
                <NoUsersFound
                  selectedTab={selectedTab}
                  batchUsers={batchUsers}
                  setShowAddMultipleParticipantsModal={
                    setShowAddMultipleParticipantsModal
                  }
                  setShowAddParticipantsModal={setShowAddParticipantsModal}
                />
              ),
            }}
            disableColumnSelector
            disableSelectionOnClick
          />
        )}
      </div>
      {selectedTab === ETabs.REQUESTS && (
        <div>
          <Button
            className="rounded-3 text-white"
            style={{
              backgroundColor: "#00ADB5",
              marginLeft: "50px",
              marginRight: "10px",
            }}
            onClick={handleApproveStudents}
          >
            Approve
          </Button>
          <Button
            className="rounded-3 mx-2 text-white"
            style={{ backgroundColor: "#FF6961" }}
            onClick={handleRejectStudents}
          >
            Reject
          </Button>
        </div>
      )}
      {selectedUserDetails && (
        <>
          {isMobile && (
            <Drawer
              PaperProps={{
                elevation: 0,
                style: {
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                },
              }}
              anchor={"bottom"}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <div className="p-3 d-flex flex-row align-items-center">
                <MdAccountCircle size={40} />
                <h5>{selectedUserDetails.name}</h5>
              </div>
              <Divider />
              <div className="p-3 d-flex align-items-center">
                <b>Email</b>: {selectedUserDetails.email}
              </div>
              <div className="p-3 d-flex align-items-center">
                <b>Phone number</b>: {selectedUserDetails.phone}
              </div>
              <Divider />
              <GridActionsCellItem
                icon={
                  <Tooltip title="More options">
                    <BsTrash />
                  </Tooltip>
                }
                label="Delete"
                onClick={() =>
                  handleRemoveUsersOnClick(
                    selectedUserDetails.email,
                    selectedUserDetails.user_id
                  )
                }
                showInMenu={true}
              />
            </Drawer>
          )}
        </>
      )}
      {userToBeRemoved && showConfirmationModal && (
        <ConfirmationModal
          showModal={showConfirmationModal}
          setShowModal={setShowConfirmationModal}
          onConfirm={() => {
            handleRemoveUsersOnClick(
              userToBeRemoved?.email,
              userToBeRemoved?.id
            );

            // handleTestDelete(tests[selectedTestIndex].assessment.id);
          }}
          confirmText={"Remove"}
          modalTitle={"Remove User"}
          modalText={`Are you sure you want to remove the following user: ${userToBeRemoved?.name} ?`}
        />
      )}
      <AddUserToBatchModal
        showModal={showAddParticipantsModal}
        setShowModal={setShowAddParticipantsModal}
        selectedBatch={currentBatch}
      />
      <AddMultipleUsersToBatchModal
        showModal={showAddMultipleParticipantsModal}
        setShowModal={setShowAddMultipleParticipantsModal}
        selectedBatch={currentBatch}
      />
      <ConfirmationModal
        setShowModal={setShowDeleteModal}
        showModal={showDeleteModal}
        onConfirm={handleDeleteBatch}
        confirmText="Delete"
        modalTitle="Delete Batch"
        modalText="Are you sure you want to delete the batch?"
      />
      <CreateOrEditBatchModal
        showModal={showRenameModal}
        setShowModal={setShowRenameModal}
        selectedBatch={currentBatch}
        isEdit={true}
        forceReRender={() => {
          fetchCurrentBatchDetails();
        }}
      />
    </div>
  );
};

export default TeacherBatchManagementView;

interface NoUsersFoundProps {
  selectedTab: ETabs;
  batchUsers: TBatchUser[];
  setShowAddParticipantsModal: Dispatch<SetStateAction<boolean>>;
  setShowAddMultipleParticipantsModal: Dispatch<SetStateAction<boolean>>;
}

const NoUsersFound: FunctionComponent<NoUsersFoundProps> = ({
  selectedTab,
  batchUsers,
  setShowAddParticipantsModal,
  setShowAddMultipleParticipantsModal,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <Stack height="100%" justifyContent={"center"} alignItems={"center"}>
      <h3>
        No {selectedTab === ETabs.APPROVED ? "members" : "pending requests"}{" "}
        found
      </h3>
      {batchUsers.length === 0 && selectedTab === ETabs.APPROVED && (
        <>
          <Typography color="#9AA0B2">Add participants to continue</Typography>
          <div className="d-flex flex-row mt-3">
            <Button
              id="no-users"
              startIcon={<AddCircleIcon />}
              className="rounded-3 mx-2 text-white"
              style={{ backgroundColor: "#00ADB5" }}
              onClick={(event) => {
                console.log(event.currentTarget);
                setMenuAnchorEl(event.currentTarget);
              }}
            >
              Add Participants
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={menuAnchorEl}
              open={Boolean(menuAnchorEl)}
              onClose={() => setMenuAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  setShowAddParticipantsModal(true);
                  setMenuAnchorEl(null);
                }}
              >
                Add Users manually
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setShowAddMultipleParticipantsModal(true);
                  setMenuAnchorEl(null);
                }}
              >
                Upload users
              </MenuItem>
            </Menu>
          </div>
        </>
      )}
    </Stack>
  );
};
