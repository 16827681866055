import { Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import * as yup from "yup";
import { Formik } from "formik";

type TProps = {
  submitHandler: any;
  disableCurrentPassword: boolean;
};

export default function ResetPasswordModal(props: TProps) {
  let schema = yup.object().shape({
    passwordCurrent: yup.string(),
    passwordNew: yup
      .string()
      .required("Please enter your new password")
      .oneOf(
        [yup.ref("passwordNewConfirm"), null],
        "Both Passwords must match"
      ),
    passwordNewConfirm: yup
      .string()
      .required("Please confirm your new password")
      .oneOf([yup.ref("passwordNew"), null], "Both Passwords must match"),
  });

  return (
    <>
      <Formik
        validationSchema={schema}
        onSubmit={(values) =>
          props.submitHandler(values.passwordCurrent, values.passwordNew)
        }
        initialValues={{
          passwordCurrent: "",
          passwordNew: "",
          passwordNewConfirm: "",
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
          <Form
            className="mt-4"
            noValidate
            onSubmit={(event) => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            {!props.disableCurrentPassword && (
              <Form.Group controlId="passwordCurrent" className="mt-3">
                <Form.Label>Current Password</Form.Label>
                <Form.Control
                  className="bg-light"
                  type="password"
                  value={values.passwordCurrent}
                  placeholder="Current Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.passwordCurrent}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.passwordCurrent}
                </Form.Control.Feedback>
              </Form.Group>
            )}

            <Form.Group controlId="passwordNew" className="mt-3">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                className="bg-light"
                type="password"
                value={values.passwordNew}
                placeholder="New Password"
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.passwordNew}
              />
              <Form.Control.Feedback type="invalid">
                {errors.passwordNew}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="passwordNewConfirm" className="mt-3">
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                className="bg-light"
                type="password"
                value={values.passwordNewConfirm}
                placeholder="Confirm New Password"
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.passwordNewConfirm}
              />
              <Form.Control.Feedback type="invalid">
                {errors.passwordNewConfirm}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="text-center">
              <Button
                variant="secondary"
                className="mt-4 rounded-4"
                block
                size="lg"
                type="button"
                style={{ color: "white" }}
                onClick={() => {
                  props.submitHandler(
                    values.passwordCurrent,
                    values.passwordNew
                  );
                }}
                onBlur={handleBlur}
              >
                Continue
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
