import { Modal } from "react-bootstrap";
import CustomButton from "../../../../components/CustomComponent/CustomButton";
import styles from "./AddQuestionButton.module.scss";
import { TModalProps } from "./ManualQuestionHelpers";

export function UploadDocumentModal(props: TModalProps) {
  const { showModal, setShowModal } = props;

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      contentClassName={styles.modalStyle}
    >
      <Modal.Header closeButton>
        <Modal.Title>Upload Document</Modal.Title>
      </Modal.Header>
      <Modal.Body></Modal.Body>
      <Modal.Footer>
        <CustomButton>Submit</CustomButton>
      </Modal.Footer>
    </Modal>
  );
}
