// material-ui
import { Card, Typography, useMediaQuery } from "@mui/material";
import moment from "moment";
import { getFormattedDuration } from "../../../utils/dateUtils";

interface ICard {
  data: string;
  topperData: number | string;
  averageData: number | string;
  cardTitle: string;
  studentData?: number | string;
  icon?: JSX.Element;
  iconbgColor?: string;
  mobileBackground?: string;
}

const ComparisonCard = (props: ICard) => {
  const isMobile = useMediaQuery("(max-width: 576px)");
  return (
    <Card
      className="d-flex align-items-center py-2 ps-2 ps-sm-4 rounded-3"
      style={{
        border: "2px solid #84868D",
        background: isMobile ? props.mobileBackground : "",
      }}
    >
      {isMobile && props.mobileBackground ? (
        <div />
      ) : (
        <div
          style={{
            width: isMobile ? "40px" : "60px",
            height: isMobile ? "40px" : "60px",
            borderRadius: "50%",
            background: props.iconbgColor,
          }}
          className="d-flex justify-content-center align-items-center"
        >
          {props.icon}
        </div>
      )}

      <div className="d-flex flex-column ps-2">
        <Typography
          sx={{
            color: "black",
            fontSize: isMobile && props.mobileBackground ? "16px" : "18px",
            textTransform: "capitalize",
          }}
        >
          <b>{props.cardTitle}</b>
        </Typography>
        {props.studentData !== undefined && (
          <Typography sx={{ color: "grey", mt: 0.25, fontSize: "16px" }}>
            <span style={{ fontWeight: "700" }}>Your {props.data}: </span>
            {props.studentData === "NA" || props.studentData === null
              ? "NA"
              : `${getFormattedDuration(props.studentData)}/question`}
          </Typography>
        )}
        <Typography sx={{ color: "grey", mt: 0.25, fontSize: "16px" }}>
          <span style={{ fontWeight: "700" }}>Median {props.data}: </span>
          {props.averageData === "NA" || props.averageData === null
            ? "NA"
            : `${getFormattedDuration(props.averageData)}/question`}
        </Typography>
        <Typography sx={{ color: "grey", mt: 0.25, fontSize: "16px" }}>
          <span style={{ fontWeight: "700" }}>Topper's {props.data}: </span>
          {props.topperData === "NA" || props.topperData === null
            ? "NA"
            : `${getFormattedDuration(props.topperData)}/question`}
        </Typography>
      </div>
    </Card>
  );
};

export default ComparisonCard;
