import {
  Chip,
  FormControl,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import React, { FunctionComponent } from "react";
import { Dispatch, SetStateAction } from "react";

interface TabBarProps {
  selectedTab: string;
  setSelectedTab: Dispatch<SetStateAction<string>>;
  tabEnumTextMap: Map<string, string>;
}

/**
 * A Custom responsive Tab bar which becomes a dropdown on small screens
 * @param selectedTab `string` The currently selected tab
 * @param setSelectedTab `Dispatch<SetStateAction<string>>` Setter prop for the selectedTab
 * @param tabEnumTextMap `Map<string, string>` maps the enum of the tab element to the text to be rendered in the tab
 */
const TabBar: FunctionComponent<TabBarProps> = ({
  selectedTab,
  setSelectedTab,
  tabEnumTextMap,
}) => {
  const isTablet = useMediaQuery("(max-width: 768px)");

  const [open, setOpen] = React.useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className="w-100">
      {isTablet ? (
        <FormControl sx={{ my: 1, width: "100%" }}>
          <Select
            className={"px-0 px-md-2 rounded-4 w-100"}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={tabEnumTextMap.get(selectedTab)}
            style={{ background: "#A1A1A1", color: "white" }}
            sx={{ textTransform: "capitalize" }}
          >
            {[...Array.from(tabEnumTextMap.keys())].map((el) => {
              return (
                <MenuItem
                  onClick={() => {
                    setSelectedTab(el);
                    handleClose();
                  }}
                  value={tabEnumTextMap.get(el)}
                  sx={{ textTransform: "capitalize" }}
                >
                  {tabEnumTextMap.get(el)?.toLowerCase()}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : (
        Array.from(tabEnumTextMap.keys()).map((el: string) => {
          return (
            <Chip
              onClick={() => setSelectedTab(el)}
              className={"me-2 px-3 px-md-2 rounded-4 "}
              style={{
                border: "none",
                textTransform: "capitalize",
                background: selectedTab === el ? "#A1A1A1" : "#F0F0F0",
                color: selectedTab === el ? "white" : "black",
                fontWeight: "bolder",
              }}
              label={tabEnumTextMap.get(el)?.toLowerCase()}
            />
          );
        })
      )}
    </div>
  );
};

export default TabBar;
