const fromEntries = (entries) => {
  var res = {};
  for (var i = 0; i < entries.length; i++) res[entries[i][0]] = entries[i][1];
  return res;
};
if (!Object.fromEntries) Object.fromEntries = fromEntries;

if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function replaceAll(search, replace) {
    return this.split(search).join(replace);
  };
}

export {};
