import {
  createContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import { TBatch } from "../models/batches";

type TProps = {
  children: ReactNode;
};

export type THomeContext = {
  batches: Array<TBatch>;
  setBatches: Dispatch<SetStateAction<Array<TBatch>>>;
};

const initialContext: THomeContext = {
  batches: [],
  setBatches: (): void => {},
};

export const HomeContext = createContext<THomeContext>(initialContext);

export const HomeContextProvider = ({ children }: TProps): JSX.Element => {
  const [batches, setBatches] = useState<Array<TBatch>>(initialContext.batches);

  return (
    <HomeContext.Provider
      value={{
        batches,
        setBatches,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};
