import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from "react";
import { FullScreenLog, ProctorLog } from "../models/proctorLog";

type TProps = {
  children: ReactNode;
};

export type TProctorContext = {
  visibilityChanges: Array<ProctorLog>;
  setVisibilityChanges: Dispatch<SetStateAction<Array<ProctorLog>>>;
  fullScreenChanges: Array<FullScreenLog>;
  setFullScreenChanges: Dispatch<SetStateAction<Array<FullScreenLog>>>;
};

const initialContext: TProctorContext = {
  visibilityChanges: [],
  setVisibilityChanges: () => {},
  fullScreenChanges: [],
  setFullScreenChanges: () => {},
};

export const ProctorContext = createContext<TProctorContext>(initialContext);

export const ProctorContextProvider = ({ children }: TProps): JSX.Element => {
  const [visibilityChanges, setVisibilityChanges] = useState<Array<ProctorLog>>(
    initialContext.visibilityChanges
  );
  const [fullScreenChanges, setFullScreenChanges] = useState<
    Array<FullScreenLog>
  >(initialContext.fullScreenChanges);

  const clearProctorContext = () => {
    setVisibilityChanges(initialContext.visibilityChanges);
  };

  return (
    <ProctorContext.Provider
      value={{
        visibilityChanges,
        setVisibilityChanges,
        fullScreenChanges,
        setFullScreenChanges,
      }}
    >
      {children}
    </ProctorContext.Provider>
  );
};
