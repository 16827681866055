import { useHistory, useRouteMatch } from "react-router-dom";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  TextField,
  Autocomplete,
} from "@mui/material";
import { AppContext, TAppContext } from "../../../context/AppContext";
import TabBar from "../../../components/CustomComponent/header-tabs/Tabbar";
import SectionDropdown from "./SectionDropdown";
import { EAnalyticsTab, EAssessmentFormat } from "../../../utils/Enums";
import { Box } from "@mui/material";

type TProps = {
  assessmentName: string;
  assessmentFormat?: number;
  assessmentCreator: number;
  sectionIdNameMap: Map<number, string>;
  selectedTab: string;
  setSelectedTab: Dispatch<SetStateAction<string>>;
  selectedSectionNumber: number;
  setSelectedSectionNumber: Dispatch<SetStateAction<number>>;
  studentId?: number;
  allStudentsArray?: Array<any>;
};

export default function AnalyticsHeader(props: TProps) {
  let { id } = useContext<TAppContext>(AppContext);
  const isMobile = useMediaQuery("(max-width: 576px)");
  const publishCode = useRouteMatch().params["publishCode"];
  const history = useHistory();

  if (props.studentId !== undefined) {
    id = props.studentId;
  }

  const [studentName, setStudentName] = useState<String>("");
  useEffect(() => {
    props.allStudentsArray?.forEach((val) => {
      if (Number(val.student_id) === Number(props.studentId)) {
        setStudentName(val.name.toString());
      }
    });
  }, [props.studentId, props.allStudentsArray]);

  const tabEnumTextMap = useMemo(() => {
    let indexMap = new Map([
      [EAnalyticsTab[EAnalyticsTab.OVERVIEW], "Overview"],
      [EAnalyticsTab[EAnalyticsTab.TIMED_ANALYSIS], "Timed Analysis"],
      [EAnalyticsTab[EAnalyticsTab.RESPONSE_ANALYSIS], "Response Analysis"],
      [EAnalyticsTab[EAnalyticsTab.STATISTICS], "Statistics"],
      [EAnalyticsTab[EAnalyticsTab.STUDENT_STATISTICS], "Students Statistics"],
    ]);

    if (props.assessmentCreator !== id) {
      indexMap.delete(EAnalyticsTab[EAnalyticsTab.STUDENT_STATISTICS]);
    }

    if (props.assessmentFormat === EAssessmentFormat.OMR) {
      indexMap.delete(EAnalyticsTab[EAnalyticsTab.TIMED_ANALYSIS]);
    }

    if (props.assessmentCreator === id) {
      indexMap.delete(EAnalyticsTab[EAnalyticsTab.RESPONSE_ANALYSIS]);
    }

    return indexMap;
  }, [props.assessmentFormat, props.assessmentCreator, id]);

  // Just Empty Array needed to for autocomplete component options prop
  let add: Array<any> = [];

  return (
    <div className="py-2">
      <Grid
        container
        xs={12}
        className="d-flex align-items-center mt-2 px-2 px-sm-4"
      >
        <div className="d-flex align-items-center">
          <Typography variant="h5">
            <b>{props.assessmentName}</b>
          </Typography>
        </div>
        {props.studentId && (
          <Box
            style={
              isMobile
                ? { marginTop: "20px", marginBottom: "5px" }
                : { marginLeft: "auto", marginBottom: "5px" }
            }
            sx={{ minWidth: 250 }}
          >
            <Autocomplete
              disablePortal
              value={studentName?.toString()}
              isOptionEqualToValue={(option, value) => {
                if (option.id === props.studentId) {
                  return option.name;
                }
              }}
              options={
                props.allStudentsArray !== undefined
                  ? props.allStudentsArray.map((student) => {
                      return {
                        label: student.name,
                        id: student.student_id,
                      };
                    })
                  : add
              }
              onChange={(_, value) => {
                if (value) {
                  history.replace(
                    `/home/attempt/${publishCode}/analytics/student/${value.id}`
                  );
                  window.location.reload();
                }
              }}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  value={params.id}
                  {...params}
                  variant="outlined"
                  label="Student Name"
                />
              )}
            />
          </Box>
        )}
      </Grid>

      <Divider className="w-100"></Divider>

      <Grid
        container
        className="d-flex justify-content-between align-items-center mt-2"
      >
        <div className="px-2 px-sm-4">
          <TabBar
            selectedTab={props.selectedTab}
            setSelectedTab={props.setSelectedTab}
            tabEnumTextMap={tabEnumTextMap}
          />
        </div>
        <Grid item xs={5} md={2} sm={3}>
          <div className="px-2 px-sm-4">
            <SectionDropdown
              selectedSection={props.selectedSectionNumber}
              setSelectedSection={props.setSelectedSectionNumber}
              sectionIdNameMap={props.sectionIdNameMap!!}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
