import { Dispatch, SetStateAction } from "react";
import { TOrg } from "../../../context/AppContext";
import { fetchBatches } from "../../../api/batches";
import { TBatch } from "../../../models/batches";

// API Call to fetch batches is same for student and teacher
export const fetchAndInitialiseBatches = async (
  org: TOrg | null,
  accessToken: string,
  setBatches: Dispatch<SetStateAction<Array<TBatch>>>
) => {
  if (org === null) {
    return;
  }

  const fetchedBatches = await fetchBatches(accessToken, org.id);
  if (fetchedBatches === null) {
    return;
  }

  setBatches(
    fetchedBatches.map((batch) => {
      return {
        adminId: batch.admin_id,
        id: batch.id,
        name: batch.name,
        code: batch.code,
        orgId: batch.org_id,
        createdAt: batch.created_at.split("T")[0],
        membershipStatus: batch.membership_status,
        batchDescription: batch.description,
      };
    })
  );
};
