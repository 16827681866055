import { useState } from "react";
import { Form } from "react-bootstrap";

interface IProps {
  questionData: any;
}

export default function NumericalEdit(props: IProps) {
  function handleOnChange(item) {
    props.questionData.current.answer = item.target.value;
    setValue(item.target.value);
  }

  const [value, setValue] = useState(props.questionData.current.answer);

  return (
    <>
      <Form
        className="mt-4"
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        <Form.Group controlId="answer">
          <h4>Correct Answer</h4>
          <Form.Control
            type="number"
            value={value}
            placeholder="Type your answer here"
            onChange={handleOnChange}
            className="ml-2 bg-light"
          />
        </Form.Group>
      </Form>
    </>
  );
}
