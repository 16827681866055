import {
  Typography,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { FunctionComponent, useContext, useState } from "react";
import { IsectionInfo } from "../../../../models/assessmentmodel";
import SettingsIcon from "@mui/icons-material/Settings";
import ManageAssessmentBottomDrawer from "./BottomDrawer";
import EditIcon from "@mui/icons-material/Edit";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import TuneIcon from "@mui/icons-material/Tune";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateNewSectionModal from "./CreateNewSectionModal";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import XOfYQuestionModal from "./xOfyQuestionModal";
import EditSectionInstructionsModal from "./EditSectionInstructionsModal";
import { handleDeleteSection } from "../../../../api/assessment";
import {
  TAssessmentContext,
  AssessmentContext,
} from "../../../../context/AssessmentContext";
import { displaySuccessToast } from "../../../../utils/ToastUtils";
import { AppContext, TAppContext } from "../../../../context/AppContext";
interface SectionCardProps {
  section: IsectionInfo;
  onClick?: () => void;
}

const SectionCard: FunctionComponent<SectionCardProps> = ({
  section,
  onClick,
}) => {
  const isMobile = useMediaQuery("(max-width: 576px)");
  const { accessToken } = useContext<TAppContext>(AppContext);
  const {
    selectedAssessment,
    setSectionMap,
    sectionMap,
    setSelectedAssessment,
  } = useContext<TAssessmentContext>(AssessmentContext);

  const [sectionSettingsAnchor, setSectionSettingsAnchor] =
    useState<null | HTMLElement>(null);

  const [showCreateSectionModal, setShowCreateSectionModal] =
    useState<boolean>(false);
  const [showDeleteSectionModal, setShowDeleteSectionModal] =
    useState<boolean>(false);
  const [
    showEditSectionInstructionsModal,
    setShowEditSectionInstructionsModal,
  ] = useState<boolean>(false);
  const [showXOfYQuestionModal, setShowXOfYQuestionModal] =
    useState<boolean>(false);

  const handleClose = () => setSectionSettingsAnchor(null);

  const deleteSection = async () => {
    const status = await handleDeleteSection(
      accessToken,
      section.id,
      selectedAssessment.id
    );
    if (status === "success") {
      let updatedSectionMap = new Map(sectionMap);
      if (updatedSectionMap.delete(section.id)) {
        setSelectedAssessment({
          ...selectedAssessment,
          sections: selectedAssessment.sections.filter(
            (newSection) => newSection !== section.id
          ),
        });
        setSectionMap(updatedSectionMap);
        displaySuccessToast("Section Deleted.");
      }
    }
    setShowDeleteSectionModal(false);
  };

  return (
    <>
      <div
        className="d-flex flex-row rounded-4 justify-content-between my-2"
        onClick={() => {
          if (sectionSettingsAnchor === null && onClick !== undefined) {
            onClick();
          }
        }}
        style={{
          backgroundColor: "#F0F0F0",
          height: "90px",
          cursor: "pointer",
        }}
      >
        <div className="d-flex flex-row">
          <div
            className="rounded-4 d-flex"
            style={{
              backgroundColor: "#FFBA69",
              height: "90px",
              width: "90px",
            }}
          ></div>
          <div className="d-flex flex-column justify-content-center ms-3">
            <Typography fontWeight={"bold"} fontSize={"18px"}>
              {section.name}
            </Typography>
            <Typography
              className="py-2"
              variant="subtitle1"
              fontWeight={"bold"}
              fontSize={"14px"}
              style={{ color: "#A8A8A8" }}
            >
              Section {selectedAssessment.sections.indexOf(section.id) + 1}
            </Typography>
          </div>
        </div>
        <div>
          <IconButton
            style={{ position: "relative", top: "0", right: "0" }}
            onClick={(e) => {
              e.stopPropagation();
              setSectionSettingsAnchor(e.currentTarget);
            }}
          >
            <SettingsIcon />
          </IconButton>
          {!isMobile && (
            <Menu
              anchorEl={sectionSettingsAnchor}
              open={Boolean(sectionSettingsAnchor)}
              onClose={() => {
                handleClose();
              }}
              sx={{
                ".MuiMenu-paper": { backgroundColor: "#F0F0F0" },
              }}
            >
              {/* <MenuItem
                sx={{
                  "&:hover": { backgroundColor: "#292F4D", color: "#FFFFFF" },
                }}
                onClick={() => {
                  setShowEditSectionInstructionsModal(true);
                  handleClose();
                }}
              >
                Edit Instructions
              </MenuItem> */}
              <MenuItem
                sx={{
                  "&:hover": { backgroundColor: "#292F4D", color: "#FFFFFF" },
                }}
                onClick={() => {
                  setShowCreateSectionModal(true);
                  handleClose();
                }}
              >
                Rename Section
              </MenuItem>
              <MenuItem
                sx={{
                  "&:hover": { backgroundColor: "#292F4D", color: "#FFFFFF" },
                }}
                onClick={() => {
                  setShowXOfYQuestionModal(true);
                  handleClose();
                }}
              >
                Optional attempts
              </MenuItem>
              <MenuItem
                sx={{
                  "&:hover": { backgroundColor: "#292F4D", color: "#FFFFFF" },
                }}
                onClick={() => {
                  setShowDeleteSectionModal(true);
                  handleClose();
                }}
              >
                Delete Section
              </MenuItem>
            </Menu>
          )}
        </div>
      </div>
      {isMobile && (
        <ManageAssessmentBottomDrawer
          anchorEl={sectionSettingsAnchor}
          setAnchorEl={setSectionSettingsAnchor}
          assessmentName={section.name}
        >
          <MenuItem
            onClick={() => {
              setShowEditSectionInstructionsModal(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <EditIcon htmlColor="#22343C" />
            </ListItemIcon>
            <ListItemText>Edit Instructions</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              setShowCreateSectionModal(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <DriveFileRenameOutlineIcon color="primary" />
            </ListItemIcon>
            <ListItemText>Rename Section</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              setShowXOfYQuestionModal(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <TuneIcon />
            </ListItemIcon>
            <ListItemText>Optional Attempts</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              setShowDeleteSectionModal(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <DeleteIcon color="error" />
            </ListItemIcon>
            <ListItemText>Delete Section</ListItemText>
          </MenuItem>
        </ManageAssessmentBottomDrawer>
      )}
      <CreateNewSectionModal
        showCreateSectionModal={showCreateSectionModal}
        setShowCreateSectionModal={setShowCreateSectionModal}
        isEdit={true}
        sectionName={section.name}
        sectionId={section.id}
      />
      <EditSectionInstructionsModal
        showEditSectionInstructionsModal={showEditSectionInstructionsModal}
        setShowEditSectionInstructionsModal={
          setShowEditSectionInstructionsModal
        }
        sectionId={section.id}
        sectionInstructions={section.instructions}
      />
      <ConfirmationModal
        showModal={showDeleteSectionModal}
        setShowModal={setShowDeleteSectionModal}
        onConfirm={() => deleteSection()}
        modalText="Are you sure you want to delete this section?"
        confirmText="Delete Section"
        modalTitle="Delete Section"
      />
      <XOfYQuestionModal
        show={showXOfYQuestionModal}
        setShow={setShowXOfYQuestionModal}
        sectionId={section.id}
      />
    </>
  );
};

export default SectionCard;
