import { Dispatch, SetStateAction, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import CustomButton from "../../../../components/CustomComponent/CustomButton";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../context/AssessmentContext";
import { handleUpdateSection } from "../../../../api/assessment";
import { AppContext, TAppContext } from "../../../../context/AppContext";
import ReorederableComponent from "../../../../components/ReorderableComponent";

/**
 * currentQuestionindex is the question number of the first question
 */
type TProps = {
  sectionId: number;
  setIsReorder: Dispatch<SetStateAction<boolean>>;
  currentQuestionIndex: number;
};

export function QuestionNavigationPanelReorder({
  sectionId,
  setIsReorder,
  currentQuestionIndex,
}: TProps) {
  const { accessToken } = useContext<TAppContext>(AppContext);
  const { sectionMap, selectedAssessment } =
    useContext<TAssessmentContext>(AssessmentContext);
  let selectedSection = sectionMap.get(sectionId) || {
    id: 0,
    name: "",
    instructions: "",
    assessment_id: 0,
    question_ids: [],
  };

  const onDragEnd = (result) => {
    // We need to regenrate sectionmap and selectedassessment details as well as questionids
    // The first two doesn't need to be done every single time but only we tap on save.
    if (!result || !result.destination) return;
    const [reorderedItem] = selectedSection?.question_ids.splice(
      result.source.index,
      1
    );
    selectedSection?.question_ids.splice(
      result.destination.index,
      0,
      reorderedItem
    );
  };

  return (
    <Col
      lg={3}
      className="rounded-4 m-3 bg-card px-2 py-2"
      style={{ height: "72vh", overflowY: "auto", overflowX: "hidden" }}
    >
      <div
        className="text-center"
        style={{ display: "flex", flexDirection: "row" }}
      >
        <CustomButton
          style={{ margin: 5 }}
          className="bg-danger"
          onClick={() => {
            setIsReorder(false);
          }}
        >
          Discard
        </CustomButton>
        <CustomButton
          style={{ margin: 5 }}
          className="bg-success"
          onClick={() => {
            const response = handleUpdateSection(
              accessToken,
              selectedSection.id,
              selectedAssessment.id,
              undefined,
              selectedSection?.question_ids
            );
            if (response === null) return;
            // updating sectionmap with updated question id
            // note that we dont have to call setstate in this case as state will be updated
            // thanks to setIsReorder. So let us save ourself some trouble of creating a new map.
            let updatedSectionData = sectionMap.get(selectedSection.id);
            if (updatedSectionData === undefined) return;
            updatedSectionData.question_ids = selectedSection?.question_ids;
            sectionMap.set(selectedSection.id, updatedSectionData);
            setIsReorder(false);
          }}
        >
          Save
        </CustomButton>
      </div>

      {/* Navigation Panel */}
      <div className="flex-grow-1 flex-row px-1">
        <div>
          <p
            className="bg-secondary rounded-4 p-3 text-center mt-4"
            style={{ color: "white" }}
          >
            {selectedSection.name}
          </p>
          <Row className="mt-2" style={{ justifyContent: "center" }}>
            <ReorederableComponent
              onDragEnd={onDragEnd}
              reorderableContent={selectedSection?.question_ids}
              initialNumber={currentQuestionIndex}
              headerText="Question"
            />
          </Row>
        </div>
      </div>
    </Col>
  );
}
