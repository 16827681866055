import { FunctionComponent, useContext, useEffect, useState } from "react";

import { LinearProgress } from "@mui/material";
import TopNavigationBar from "../navigation-bars/TopNavigationBar";
import { AppContext, TAppContext } from "../../context/AppContext";

const Loading: FunctionComponent = () => {
  const [progress, setProgress] = useState<number>(10);
  const { setDrawerOpen } = useContext<TAppContext>(AppContext);

  useEffect(() => {
    setDrawerOpen(false);
    // regularly update `progress` state which controls the Loading bar progress
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        // setting the max progress to 90% so that even if an api call takes longer than expected
        // the loader won't get to 100% which feels un-intuitive that the loading is complete yet no data has rendered yet
        if (oldProgress === 90) {
          return 90;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 90);
      });
    }, 100);

    return () => {
      setDrawerOpen(true);
      clearInterval(timer);
    };
  }, [setDrawerOpen]);

  return (
    <>
      <div className="bg-white w-100">
        <TopNavigationBar />
        <LinearProgress color="error" variant="determinate" value={progress} />
      </div>
    </>
  );
};

export default Loading;
