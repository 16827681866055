import {
  Card,
  CardActions,
  CardContent,
  Typography,
  Stack,
  Button,
  Rating,
  CardMedia,
  Divider,
  Grid,
} from "@mui/material";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  fetchProductData,
  patchProductStatus,
  fetchCouponData,
} from "../../../api/marketplace";
import { AppContext, TAppContext } from "../../../context/AppContext";
import CreateCouponModal from "./CreateCouponModal";
import PaymentButton from "../PaymentButton/PaymentButton";
import HomeIcon from "@mui/icons-material/Home";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LanguageIcon from "@mui/icons-material/Language";
import moment from "moment";
import { TextField } from "@mui/material";
import { Container } from "react-bootstrap";
import { EProductLanguage } from "../../../utils/Enums";
import Loader from "../../../components/Loader";
import { TProduct } from "../../../models/productmodel";
import { TCoupon } from "../../../models/couponmodel";
import { useMediaQuery } from "@mui/material";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../utils/ToastUtils";

interface ProductCardProps {}

const ProductPage: FunctionComponent<ProductCardProps> = () => {
  const history = useHistory();
  const { code } = useParams<{ code: string }>();

  const { accessToken, id } = useContext<TAppContext>(AppContext);
  const [productData, setProductData] = useState<TProduct>();
  const [couponData, setCouponData] = useState<TCoupon>();
  const [couponCode, setCouponCode] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCreateCouponModal, setShowCreateCouponModal] =
    useState<boolean>(false);
  const isDesktopOrLaptop = useMediaQuery("(min-width: 700px)");

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (code !== undefined) {
        const productData = await fetchProductData(code, id);
        if (!productData) {
          history.replace("/home/marketplace");
          return;
        }
        setProductData(productData);
      }
      setIsLoading(false);
    })();
  }, []);

  const getLanguage = (productLanguage) => {
    switch (productLanguage) {
      case EProductLanguage.ENGLISH:
        return "English";
      case EProductLanguage.HINDI:
        return "Hindi";
      case EProductLanguage.HINGLISH:
        return "Hinglish";
      default:
        return "English";
    }
  };

  const toggleProductStatus = async (is_active: boolean) => {
    const responseData = await patchProductStatus(accessToken, code, is_active);

    if (responseData == null) {
      displayErrorToast("There is some error. Please try again later.");
      return;
    }

    displaySuccessToast(
      `Product is ${is_active === true ? "enabled" : "disabled"} succesfully`
    );
    window.location.reload();
    return;
  };

  const applyCoupon = async () => {
    const productCode: string = code;
    const responseData = await fetchCouponData(
      accessToken,
      couponCode,
      productCode
    );
    if (responseData == null) {
      displayErrorToast("There is some error. Please try again later.");
      return;
    }
    setCouponData(responseData);
    displaySuccessToast("Coupon applied successfully");
  };

  const removeCoupon = () => {
    setCouponData(undefined);

    displaySuccessToast("Coupon Removed Successfully");
  };

  return isLoading ? (
    <Loader LoadingMessage="Loading......" />
  ) : (
    <div className="bg-white overflow-auto">
      <Grid style={{ width: "98vw" }} container margin={2} direction="row">
        <Grid item xs={11} sm={11} md={11} lg={8}>
          <Stack direction="column" spacing={3}>
            <div className="d-flex flex-row-between">
              {productData?.tags?.map((el) => {
                return (
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    style={{
                      textTransform: "none",
                      borderRadius: "5px",
                      margin: "2px",
                    }}
                  >
                    {el}
                  </Button>
                );
              })}
            </div>
            <Typography style={{ color: "#0E1133" }} variant="h3">
              <b>{productData?.name}</b>
            </Typography>
            <div>
              {/* stack for showing teacher name, last update and review */}
              <Stack direction="row" spacing={isDesktopOrLaptop ? 25 : 5}>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  {isDesktopOrLaptop && (
                    <div className="pe-2">
                      <img
                        style={{
                          maxWidth: "44px",
                          maxHeight: "44px",
                          objectFit: "cover",
                          borderRadius: "50%",
                        }}
                        alt="img"
                        src={productData?.product_image}
                      />
                    </div>
                  )}
                  <div className="d-flex flex-column">
                    <Typography
                      fontSize={isDesktopOrLaptop ? "medium" : "small"}
                      style={{ color: "#53545B" }}
                    >
                      <b>Teacher</b>
                    </Typography>
                    <Typography
                      fontSize={isDesktopOrLaptop ? "medium" : "small"}
                      style={{ color: "#0E1133" }}
                    >
                      <b>{productData?.creator_name}</b>
                    </Typography>
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <Typography
                    fontSize={isDesktopOrLaptop ? "medium" : "small"}
                    style={{ color: "#53545B" }}
                  >
                    <b>Last Update:</b>
                  </Typography>
                  <Typography
                    fontSize={isDesktopOrLaptop ? "medium" : "small"}
                    style={{ color: "#0E1133" }}
                  >
                    <b>
                      {moment
                        .utc(productData?.last_updated_on ?? moment.now())
                        .format("DD/MM/YYYY")}
                    </b>
                  </Typography>
                </div>
                <div className="d-flex flex-column">
                  <Typography
                    fontSize={isDesktopOrLaptop ? "medium" : "small"}
                    style={{ color: "#53545B" }}
                  >
                    <b>Review:</b>
                  </Typography>{" "}
                  <Rating
                    style={{ textOverflow: "ellipsis" }}
                    size={isDesktopOrLaptop ? "medium" : "small"}
                    name="read-only"
                    value={5}
                    readOnly
                  />
                </div>
              </Stack>
            </div>
            <Container>
              <img
                style={
                  isDesktopOrLaptop
                    ? {
                        maxHeight: "450px",
                        maxWidth: "98%",
                        objectFit: "fill",
                      }
                    : {
                        maxHeight: "85%",
                        maxWidth: "95%",
                        objectFit: "fill",
                      }
                }
                alt="img"
                src={productData?.product_image}
              ></img>
            </Container>
            <div className="d-flex flex-column">
              <Typography
                style={{ color: "#0E1133", fontSize: "26px" }}
                variant="h5"
              >
                <b>Course Overview</b>
              </Typography>
              <Typography
                className="pt-2 px-1"
                style={{ color: "#53545B", whiteSpace: "pre-wrap" }}
              >
                {productData?.description}
              </Typography>
            </div>
            {/* <div style={{ maxWidth: "60vw" }} className="d-flex flex-column">
              <Typography
                style={{ color: "#0E1133", fontSize: "26px" }}
                variant="h5"
              >
                <b>Course Curriculum</b>
              </Typography>
              <div className="pt-2">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Week 1</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse malesuada lacus ex, sit amet blandit leo
                      lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography>Week 2</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse malesuada lacus ex, sit amet blandit leo
                      lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography>Week 3</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse malesuada lacus ex, sit amet blandit leo
                      lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <div>
              <Typography style={{ color: "#0E1133", fontSize: "26px" }}>
                <b>Other Instructors</b>
              </Typography>
              <Stack className="pt-2" direction="row" spacing={20}>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div className="pe-2">
                    <img
                      style={{
                        maxWidth: "44px",
                        maxHeight: "44px",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                      alt="img"
                      src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <Typography style={{ color: "#53545B" }}>
                      <b>Instructor</b>
                    </Typography>
                    <Typography style={{ color: "#0E1133" }}>
                      <b>Eleanor Fant</b>
                    </Typography>
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div className="pe-2">
                    <img
                      style={{
                        maxWidth: "44px",
                        maxHeight: "44px",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                      alt="img"
                      src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <Typography style={{ color: "#53545B" }}>
                      <b>Teacher</b>
                    </Typography>
                    <Typography style={{ color: "#0E1133" }}>
                      <b>Elon Musk</b>
                    </Typography>
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div className="pe-2">
                    <img
                      style={{
                        maxWidth: "44px",
                        maxHeight: "44px",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                      alt="img"
                      src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <Typography style={{ color: "#53545B" }}>
                      <b>Associate</b>
                    </Typography>
                    <Typography style={{ color: "#0E1133" }}>
                      <b>Jonquil Von</b>
                    </Typography>
                  </div>
                </div>
              </Stack>
            </div> */}
            <div></div>
          </Stack>
        </Grid>
        {/* Right card part starts here */}
        <Grid
          item
          xs={11}
          lg={4}
          style={{ marginTop: "58px" }}
          className="d-flex justify-content-center"
        >
          <Card>
            <CardMedia
              component="img"
              alt="green iguana"
              height="250"
              style={{ borderRadius: "10px", objectFit: "contain" }}
              image={productData?.logo_image}
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                style={{ color: "#0E1133" }}
                component="div"
              >
                <b>
                  <span>&#8377;</span>{" "}
                  {couponData
                    ? ((productData?.price ?? 0) -
                        (couponData.discount / 100) *
                          (productData?.price ?? 0)) /
                      100
                    : (productData?.price ?? 0) / 100}
                </b>
              </Typography>
              <Stack marginTop={3} direction="column" spacing={2}>
                <Stack direction="row" spacing={2}>
                  <HomeIcon style={{ color: "#3c5cff" }} />
                  <Typography style={{ color: "#0E1133" }}>
                    Instructor:
                  </Typography>
                  <Typography style={{ color: "#53545B" }}>
                    {productData?.creator_name}
                  </Typography>
                </Stack>
                <Divider />
                <Stack direction="row" spacing={2}>
                  <LibraryBooksIcon style={{ color: "#3c5cff" }} />
                  <Typography style={{ color: "#0E1133" }}>Tests:</Typography>
                  <Typography style={{ color: "#53545B" }}>
                    {productData?.subheading}
                  </Typography>
                </Stack>
                <Divider />
                <Stack direction="row" spacing={2}>
                  <AccessTimeIcon style={{ color: "#3c5cff" }} />
                  <Typography style={{ color: "#0E1133" }}>
                    Duration:
                  </Typography>
                    <Typography style={{ color: "#53545B" }}>
                      {productData?.duration}
                    </Typography>
                </Stack>
                {/* <Divider />
                <Stack direction="row" spacing={2}>
                  <PersonIcon style={{ color: "#3c5cff" }} />
                  <Typography style={{ color: "#0E1133" }}>
                    Enrolled:
                  </Typography>
                  <Typography style={{ color: "#53545B" }}>
                    20 students
                  </Typography>
                </Stack> */}
                <Divider />
                <Stack direction="row" spacing={2}>
                  <LanguageIcon style={{ color: "#3c5cff" }} />
                  <Typography style={{ color: "#0E1133" }}>
                    Language:
                  </Typography>
                  <Typography style={{ color: "#53545B" }}>
                    {getLanguage(productData?.language)}
                  </Typography>
                </Stack>
              </Stack>
            </CardContent>
            {productData?.created_by !== id ? (
              <div>
                {accessToken && (
                  <div className="d-flex flex-row align-items-center mt-2">
                    <TextField
                      className="mx-3"
                      label="Apply Coupon"
                      fullWidth
                      size="medium"
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                      helperText={
                        couponData ? "Coupon applied successfully" : ""
                      }
                      disabled={couponData ? true : false}
                    />
                    <Button
                      className="me-2"
                      onClick={couponData ? removeCoupon : applyCoupon}
                      variant="contained"
                    >
                      {couponData ? "Remove" : "Apply"}
                    </Button>
                  </div>
                )}
                <CardActions>
                  <PaymentButton
                    discountedPrice={
                      couponData && productData
                        ? productData?.price -
                          (couponData.discount / 100) * productData?.price
                        : undefined
                    }
                    productCode={code}
                    disabled={!productData?.is_active}
                  />
                </CardActions>
                {!productData?.is_active && (
                  <div className="d-flex justify-content-center">
                    <Typography color={"red"}>
                      You can no longer purchase this product.
                    </Typography>
                  </div>
                )}
              </div>
            ) : (
              <>
                <CreateCouponModal
                  showModal={showCreateCouponModal}
                  setShowModal={setShowCreateCouponModal}
                  productCode={code}
                />
                <div>
                  <CardActions>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => setShowCreateCouponModal(true)}
                    >
                      Create Coupon for your Product
                    </Button>
                  </CardActions>
                </div>
                <CardActions>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={async () =>
                      toggleProductStatus(!productData?.is_active)
                    }
                  >
                    {productData?.is_active === true
                      ? "Disable Product"
                      : "Enable Product"}
                  </Button>
                </CardActions>
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProductPage;
