import { MutableRefObject, useState } from "react";
import { Row } from "react-bootstrap";
import { IQuestionBody } from "../../../../models/common";
import { uploadFileToS3 } from "../../../../utils/s3Utils";
import AddImageModal from "../AddImageModal/AddImageModal";
import QuestionTextEdit from "../QuestionText/QuestionTextEdit";

type TProps = {
  updatedComprehensionBody: MutableRefObject<IQuestionBody>;
};

export default function ComprehensionSupport(props: TProps) {
  const [reRender, setReRender] = useState<boolean>(false);
  const [show, setShow] = useState(false);

  const handleAddImage = async (idx: number, file: any) => {
    const imageKey = await uploadFileToS3(file, "question-images/");
    if (!imageKey) {
      console.log("error! No image key found. Failed to upload image to S3");
    }
    const imageUrl = `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_S3_REGION}.amazonaws.com/${imageKey}`;

    props.updatedComprehensionBody.current.images?.push(imageUrl);
    setReRender(!reRender);
  };

  return (
    <Row className="m-2" key="comprehension">
      <QuestionTextEdit
        updatedQuestionData={props.updatedComprehensionBody}
        reRender={reRender}
        setReRender={setReRender}
        handleShow={() => setShow(true)}
        isHuge
      />
      <AddImageModal
        idx={0}
        show={show}
        setShow={setShow}
        handleAddImage={handleAddImage}
      />
    </Row>
  );
}
