import moment from "moment";
import {
  EAccessLevel,
  EAnswerType,
  EAssessmentFormat,
  EAssessmentStatus,
  EAssessmentType,
  EPublishedAssessmentFormat,
  EPublishStatus,
  EQuestionStatus,
} from "../utils/Enums";
import { IQuestionBody } from "./common";

export interface Dictionary<T> {
  [Key: number]: T;
}

export type TPublishedAssessmentData = {
  assessment: TAssessmentData;
  publishInfo?: TPublishInfo;
  batchInfo?: TPublishedAssessmentBatchInfo;
};

// stores complete data for one assessment
export type TAssessmentData = {
  id: number;
  type: EAssessmentType;
  format: EAssessmentFormat;
  name: string;
  sections: Array<number>;
  instructions: string;
  status: EAssessmentStatus;
  created_by: string;
  created_at: Date;
  teachers_pdf_url: string;
  solution_pdf_url: string;
  no_solution_pdf_url: string;
};

export type TPublishInfo = {
  id: number;
  publish_code: string;
  start_time: Date;
  end_time: Date;
  created_by: string;
  access_level: EAccessLevel;
  status: EPublishStatus;
  format: EPublishedAssessmentFormat;
  duration: number;
};

export type TPublishedAssessmentBatchInfo = {
  name: string;
  id: number;
  org_id: number;
  created_at: Date;
};

// Contains details about a particular section
export interface IsectionInfo {
  id: number;
  name: string;
  instructions: string;
  question_ids: Array<number>;
  assessment_id: number;
  optional_attempts?: Array<TLimitInfo>;
}

// Contains details about a question.
export interface IquestionData {
  assessment_id: number;
  question_id: number;
  hash: string;
  subject: string;
  body: IQuestionBody;
  options: Array<IQuestionBody>;
  answer?: string;
  solution?: IQuestionBody;
  status: number;
  question_type: number;
  marks_if_correct: number;
  marks_if_incorrect: number;
  marks_if_unattempted: number;
  partial_marking: boolean;
  limitId: number;
  comprehension_id?: number;
}

export interface IPublishHistoryData {
  format: EPublishedAssessmentFormat;
  duration: number;
  id: number;
  publish_code: string;
  start_time: Date;
  end_time: Date;
  status: number;
  created_by: string;
  access_level: EAccessLevel;
  batch?: IBatchInfo;
}

interface IBatchInfo {
  org_id: number;
  name: string;
  id: number;
  admin_id: number;
  created_at: Date;
}

// Details about a particular response
export class individualResponses {
  question_id: number;
  answer_type: EAnswerType | EQuestionStatus;
  /*
    VISITED_UNANSWERED = 0
    VISITED_ANSWERED = 1
    FLAGGED_UNANSWERED = 2
    FLAGGED_ANSWERED = 3
  */
  selected_option: string;

  public constructor() {
    this.question_id = 0;
    this.selected_option = "";
    this.answer_type = EAnswerType.UNVISITED;
  }
}

export interface IResponseData {
  question_id: number;
  answer: string;
  answer_type: EAnswerType;
  time_taken: number;
  last_updated_on: string;
}

// details about all responses
export class responseDataClass {
  responses: Array<Array<individualResponses>>;
  public constructor() {
    this.responses = [];
  }
}

export class questionInfo {
  questionId: number;
  questionState: EAnswerType;
  questionAnswer: string;
  questionTimeSpent: number;
  questionLastUpdatedOn: string;

  public constructor(questionId: number) {
    this.questionId = questionId;
    this.questionState = EAnswerType.UNVISITED;
    this.questionAnswer = "";
    this.questionTimeSpent = 0;
    this.questionLastUpdatedOn = moment.utc().toISOString().slice(0, -1);
  }
}

export type TLimitInfo = {
  start_question_idx: number;
  end_question_idx: number;
  limit: number;
};

export type TComprehension = {
  // comprehension id
  id: number;
  // ID of the assessment comprehension is a part of
  assessment_id: number;
  // Bpdy of the comprehension. Contains text and image urls
  body: IQuestionBody;
};
