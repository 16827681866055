import { useContext } from "react";
import { Button } from "react-bootstrap";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../context/AssessmentContext";
import styles from "./QuestionButton.module.scss";
import { Badge } from "@mui/material";
import { BsCircleFill } from "react-icons/bs";

interface IProps {
  color: string;
  text: string;
  questionNumber?: number; //Optional
  sectionNumber?: number; // Optional
  className?: string;
  showBadge?: boolean;
}

function isColorTooLight(c: string) {
  c = c.substring(1); // strip #
  const rgb = parseInt(c, 16); // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff; // extract red
  const g = (rgb >> 8) & 0xff; // extract green
  const b = (rgb >> 0) & 0xff; // extract blue

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
  return luma > 200;
}

export default function QuestionButton(props: IProps) {
  const {
    currentQuestionNumber,
    currentSectionNumber,
    setCurrentQuestionNumber,
    setCurrentSectionNumber,
    setShowQNavDrawer,
  } = useContext<TAssessmentContext>(AssessmentContext);

  function handleOnClick() {
    // Since both of these are optional, we need to check if they are set.
    if (props.questionNumber != null && props.sectionNumber != null) {
      setCurrentQuestionNumber(props.questionNumber);
      setCurrentSectionNumber(props.sectionNumber);
      setShowQNavDrawer(false);
    }
  }

  return (
    <Button
      className={`${props.className} ${styles.btnCircle}`}
      style={{
        backgroundColor: props.color,
        color: isColorTooLight(props.color) ? "#000000" : "#FFFFFF",
        boxShadow:
          currentQuestionNumber === props.questionNumber &&
          currentSectionNumber === props.sectionNumber
            ? "0 0 0 0.25rem rgba(85, 90, 110, 0.5)"
            : "none",
      }}
      onClick={handleOnClick}
    >
      <Badge
        color="default"
        badgeContent={
          <BsCircleFill
            size={"12px"}
            style={{
              color: "#0bf50b",
              position: "absolute",
              bottom: 5,
              right: -5,
            }}
          />
        }
        invisible={!props.showBadge}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {props.text}
      </Badge>
    </Button>
  );
}
