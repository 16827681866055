import { Formik } from "formik";
import * as yup from "yup";
import { Dispatch, SetStateAction, useContext } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import styles from "../../../views/batch-management/batch-management.module.scss";
import {
  AttemptContext,
  TAttemptContext,
} from "../../../context/AttemptContext";
import { patchAttemptInfo } from "../../../api/attempts";
import { AppContext, TAppContext } from "../../../context/AppContext";
import { displaySuccessToast } from "../../../utils/ToastUtils";
import { Typography } from "@mui/material";

type TProps = {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  rollNo: string;
  name: string;
  handleQuestionScreen: any;
  redirectToQuestionScreen: boolean;
};

export default function EditRollModal({
  show,
  setShow,
  rollNo,
  name,
  handleQuestionScreen,
  redirectToQuestionScreen,
}: TProps) {
  let schema = yup.object().shape({
    name: yup.string().required("Please enter your name"),
    roll_number: yup.string().default("NA"),
  });

  const { accessToken } = useContext<TAppContext>(AppContext);
  const { currentAttempt, setCurrentAttempt } =
    useContext<TAttemptContext>(AttemptContext);

  return (
    <Modal
      contentClassName={styles.modalStyle}
      show={show}
      onHide={() => setShow(false)}
      centered
    >
      <Modal.Header>
        <Typography variant="h4">
          <b>Edit Details</b>
        </Typography>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={schema}
          onSubmit={async (values) => {
            const res = await patchAttemptInfo(accessToken, currentAttempt.id, {
              metadata: { roll_no: values.roll_number, name: values.name },
            });
            if (res === null) return;
            let updatedAttempt = { ...currentAttempt };
            updatedAttempt.metadata = {
              roll_no: values.roll_number,
              name: values.name,
            };
            setCurrentAttempt(updatedAttempt);
            displaySuccessToast("Information updated successfully");
            setShow(false);
            if (redirectToQuestionScreen) {
              handleQuestionScreen();
            }
          }}
          initialValues={{
            roll_number: rollNo,
            name: name,
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
            <Form
              noValidate
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <Form.Group controlId="name">
                <Form.Label>
                  <Typography fontSize={20} color="#9AA0B2">
                    <b>Name</b>
                  </Typography>
                </Form.Label>
                <Form.Control
                  type="attemptees name"
                  value={values.name}
                  placeholder=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="ml-2"
                  isInvalid={!!errors.name}
                  style={{ backgroundColor: "#DBDFF1" }}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="roll_number">
                <Form.Label>
                  <Typography fontSize={20} color="#9AA0B2" marginTop={2}>
                    <b>Roll Number</b>
                  </Typography>
                </Form.Label>
                <Form.Control
                  type="section name"
                  value={values.roll_number}
                  placeholder="Optional"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="ml-2"
                  isInvalid={!!errors.roll_number}
                  style={{ backgroundColor: "#DBDFF1" }}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.roll_number}
                </Form.Control.Feedback>
              </Form.Group>

              <Modal.Footer>
                <Button
                  className="border-0 rounded-2 px-3 bg-primary"
                  type="submit"
                >
                  {redirectToQuestionScreen ? "Continue" : "Save"}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
