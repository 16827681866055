import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

type TProps = {
  NotFoundMessage: string;
};

export default function NotFound(props: TProps) {
  return (
    <Box className="d-flex flex-column justify-content-center align-items-center w-100 bg-white">
      <SentimentVeryDissatisfiedIcon
        style={{ fontSize: 200, color: "#150336" }}
      />
      <Typography color={"black"} fontSize={25} className={"mt-4"}>
        {props.NotFoundMessage}
      </Typography>
    </Box>
  );
}
