import { Card, Grid, useMediaQuery } from "@mui/material";
// project imports
import {
  TAnalyticsStudentOverview,
  THighlightObject,
} from "../../../models/analyticsmodel";
import StudentStatisticsBox from "../Components/StudentStatisticsBox";
import ScoreCard from "../Components/ScoreCard";
import Highlights from "../Components/Highlights";

type TProps = {
  data: TAnalyticsStudentOverview;
  highlights?: THighlightObject;
  selectedSectionName: string;
};

export default function StudentAnalyticsOverview(props: TProps) {
  const isMobile = useMediaQuery("(max-width: 576px)");

  return (
    <div className="px-0">
      <div className="px-2 px-sm-4">
        <StudentStatisticsBox
          data={props.data}
          selectedSectionName={props.selectedSectionName}
          title="Summary"
        />
      </div>
      <div className={"pb-4 mt-4 px-2 px-sm-4 " + (isMobile ? "mx-0" : "")}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            {props.highlights !== undefined ? (
              <Highlights highlights={props.highlights} />
            ) : (
              "No Highlights to show"
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <ScoreCard isLoading={false} data={props.data} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
