/**
 * An util to return all the props required to style and populate the `react-apexcharts` Chart Component
 * @param data The data that is rendered on the chart. Should be a `number[][]`.
 * The first array of the data prop must be the X-Axis labels
 * The arrays following should be the values of a series of a type that is to be rendered at the corresponding
 * X-axis label of the first array.
 *
 * `Example:`
 *
 * data = [
 *
 * ['0-2', '2-4', '4-6', '6-8', '8-10'],
 *
 * [3, 2, 1, 4, 5],
 *
 * [5, 4, 3, 2, 1]
 *
 * ]
 *
 * will return a chart that has X axis labels as `'0-2'`, `'2-4'`, and so on.
 * The chart will have two bar graphs, first bar will have length of `3` units corresponding to `'0-2`' on the X-axis
 * whereas, The second bar will have a length of `5` units corresponding to `'0-2`' on the X-axis. And so on
 *
 * @param legend The legend of the multi-series charts. Must be a `string[]`
 * @param isMobile Based on this `boolean`, Mobile and Desktop chart is conditionally rendered
 * @returns The props that are to be passed in the Chart component
 */
function chartData(
  data,
  legend: string[],
  isMobile?: boolean,
  tabNumber?: number,
  xaxisLabels?: string[],
  colors?: string[]
) {
  return {
    height: isMobile ? 350 : 250,
    type: "bar",
    stacked: true,
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
    },
    options: {
      colors: colors !== undefined ? colors : ["#817AA4", "#24B4F0", "#822A28"],
      chart: {
        id: "bar",
      },
      xaxis: {
        categories: xaxisLabels !== undefined ? xaxisLabels : data[0],
        labels: {
          style: {
            colors: "#7F828A",
            fontSize: "14px",
          },
        },
      },
      yaxis: {
        title: {
          text: "",
        },
        floating: true,
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "14px",
          colors: ["#000"],
        },
      },
      grid: {
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      annotations: {
        yaxis: [
          {
            y: 0,
            strokeDashArray: 0,
            borderColor: "#333",
            fillColor: "#c2c2c2",
            opacity: 0.8,
            offsetX: 0,
            offsetY: 0,
          },
        ],
      },
      plotOptions: {
        bar: {
          dataLabels: {
            horizontal: false,
            position: "bottom",
          },
        },
      },
      legend: {
        show: true,
      },
    },
    series: legend.slice(1).map((el, idx) => {
      return {
        name: el,
        data: data[idx + 1].map((el) => el.toFixed(2)),
      };
    }),
  };
}
export default chartData;
