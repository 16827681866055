import { useEffect, useContext, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { AppContext, TAppContext } from "../../context/AppContext";
import { EAnalyticsTab, EAssessmentFormat } from "../../utils/Enums";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../context/AssessmentContext";
import {
  fetchAttemptorAnalytics,
  fetchAttemptorHighlights,
} from "../../api/analytics";
import {
  AnalyticsContext,
  TAnalyticsContext,
} from "./../../context/AnalyticsContext";
import {
  TAnalyticsStudentOverview,
  TAnalyticsStudentQuestion,
  TAnalyticsStudentTimed,
  THighlightObject,
} from "../../models/analyticsmodel";
import StudentAnalyticsOverview from "./Screens/StudentAnalyticsOverview";
import { parseAnalyticsData } from "../../utils/analyticsUtils";
import { fetchPublishedAssessmentData } from "../../api/assessment";
import { createIDMapFromArray } from "../instructionScreen/InstructionScreenRequests";
import { displayErrorToast } from "../../utils/ToastUtils";
import AnalyticsHeader from "./Components/AnalyticsHeader";
import AnalyticsTopnav from "./Components/AnalyticsTopNav";
import StudentTimedAnalysis from "./Screens/StudentTimedAnalysis";
import StudentQuesAnalytics from "./Screens/StudentQuesAnalytics";
import Loading from "../../components/Loading/Loading";
import StudentQuestionStats from "./Screens/StudentQuestionStats";
import { TAssessmentData } from "../../models/assessmentmodel";

const StudentAnalyticsForTeacher = () => {
  const history = useHistory();
  const { accessToken, setDrawerOpen } = useContext<TAppContext>(AppContext);
  const { setSectionMap, setSelectedAssessment } =
    useContext<TAssessmentContext>(AssessmentContext);
  const {
    selectedTab,
    setSelectedTab,
    studentAnalyticsOverview,
    setStudentAnalyticsOverview,
    studentAnalyticsTimed,
    setStudentAnalyticsTimed,
    studentAnalyticsQuestion,
    setStudentAnalyticsQuestion,
    studentQuestionStats,
    setStudentQuestionStats,
  } = useContext<TAnalyticsContext>(AnalyticsContext);

  const [attemptorHighlights, setAttemptorHighlights] =
    useState<THighlightObject>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedSectionNumber, setSelectedSectionNumber] =
    useState<number>(-1);
  const [sectionIdNameMap, setSectionIdNameMap] = useState<Map<number, string>>(
    new Map([[-1, "All"]])
  );
  const [assessmentData, setAssessmentData] = useState<any>();

  const publishCode = useRouteMatch().params["publishCode"];
  const studentId = useRouteMatch().params["studentId"];

  const analyticsTabEndpointMap = new Map<string, string>([
    [EAnalyticsTab[EAnalyticsTab.OVERVIEW], "overview"],
    [EAnalyticsTab[EAnalyticsTab.STATISTICS], "question_stats"],
    [EAnalyticsTab[EAnalyticsTab.TIMED_ANALYSIS], "timed_analysis"],
    [EAnalyticsTab[EAnalyticsTab.RESPONSE_ANALYSIS], "question_analysis"],
  ]);

  let selectedSectionName = sectionIdNameMap.get(selectedSectionNumber)!!;

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      // Fetch assessment analytics for the selected section id
      let sectionId: number | undefined;
      selectedSectionName = sectionIdNameMap.get(selectedSectionNumber)!!;

      if (selectedSectionNumber !== -1) {
        sectionId = selectedSectionNumber;
      }

      const attemptorAnalyticsResponse = await fetchAttemptorAnalytics(
        accessToken,
        publishCode,
        analyticsTabEndpointMap.get(selectedTab)!!,
        studentId,
        sectionId
      );

      const studentAnalyticsHighlights: THighlightObject | null =
        await fetchAttemptorHighlights(accessToken, publishCode, studentId);
      if (studentAnalyticsHighlights !== null) {
        setAttemptorHighlights(studentAnalyticsHighlights);
      }

      // fethching and setting dat for Overview Screen
      if (selectedTab === EAnalyticsTab[EAnalyticsTab.OVERVIEW]) {
        if (attemptorAnalyticsResponse === null) {
          setStudentAnalyticsOverview(undefined);
          setIsLoading(false);
          history.push(`/home/attempt/${publishCode}/instructions`);
          return;
        }

        const overviewAnalyticsData: TAnalyticsStudentOverview =
          parseAnalyticsData(attemptorAnalyticsResponse);
        setStudentAnalyticsOverview(overviewAnalyticsData);
        setIsLoading(false);
      } else if (selectedTab === EAnalyticsTab[EAnalyticsTab.TIMED_ANALYSIS]) {
        if (attemptorAnalyticsResponse === null) {
          setStudentAnalyticsTimed(undefined);
          setIsLoading(false);
          return;
        }

        const timedAnalyticsData: TAnalyticsStudentTimed = parseAnalyticsData(
          attemptorAnalyticsResponse
        );
        setStudentAnalyticsTimed(timedAnalyticsData);
        setIsLoading(false);
      } else if (
        selectedTab === EAnalyticsTab[EAnalyticsTab.RESPONSE_ANALYSIS]
      ) {
        if (attemptorAnalyticsResponse === null) {
          setStudentAnalyticsQuestion(undefined);
          setIsLoading(false);
          return;
        }

        const questionAnalyticsData: TAnalyticsStudentQuestion =
          parseAnalyticsData(attemptorAnalyticsResponse);
        setStudentAnalyticsQuestion(questionAnalyticsData);
        setIsLoading(false);
      } else if (selectedTab === EAnalyticsTab[EAnalyticsTab.STATISTICS]) {
        if (attemptorAnalyticsResponse === null) {
          setStudentQuestionStats(undefined);
          setIsLoading(false);
          return;
        }

        setStudentQuestionStats(parseAnalyticsData(attemptorAnalyticsResponse));
        setIsLoading(false);
      }
    })();
  }, [selectedTab, selectedSectionNumber]);

  useEffect(() => {
    (async () => {
      const publishedAssessmentData = await fetchPublishedAssessmentData(
        accessToken,
        publishCode
      );

      if (!publishedAssessmentData) {
        displayErrorToast();
        return history.push(`/home/attempt/${publishCode}/instructions`);
      }

      setAssessmentData(publishedAssessmentData);

      let selectedAssessment: TAssessmentData =
        publishedAssessmentData.assessment;
      setSelectedAssessment(selectedAssessment);

      const sectionMap = createIDMapFromArray(publishedAssessmentData.sections);
      setSectionMap(sectionMap);

      let sectionNames = ["All"];
      let sectionIdToNameMap = new Map([[-1, "All"]]);

      publishedAssessmentData.sections.forEach((el) => {
        sectionNames.push(el.name);
        sectionIdToNameMap.set(el.id, el.name);
      });
      setSectionIdNameMap(sectionIdToNameMap);
      setSelectedTab(EAnalyticsTab[EAnalyticsTab.OVERVIEW]);

      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    setDrawerOpen(false);
    return () => {
      setDrawerOpen(true);
    };
  });

  return isLoading ? (
    <Loading />
  ) : (
    <div className="h-100 d-flex flex-column flex-grow-1">
      <AnalyticsTopnav />
      <AnalyticsHeader
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        selectedSectionNumber={selectedSectionNumber}
        sectionIdNameMap={sectionIdNameMap}
        setSelectedSectionNumber={setSelectedSectionNumber}
        assessmentFormat={assessmentData?.assessment.format!!}
        assessmentName={assessmentData?.assessment.name!!}
        assessmentCreator={assessmentData?.assessment.created_by!!}
        studentId={studentId}
        allStudentsArray={studentAnalyticsOverview?.leaderboard}
      />

      {selectedTab === EAnalyticsTab[EAnalyticsTab.OVERVIEW] ? (
        studentAnalyticsOverview !== undefined ? (
          <StudentAnalyticsOverview
            highlights={attemptorHighlights}
            data={studentAnalyticsOverview}
            selectedSectionName={selectedSectionName}
          />
        ) : (
          <div>No Analytics Avalaible :</div>
        )
      ) : (
        <div />
      )}

      {assessmentData &&
        assessmentData.assessment.format !== EAssessmentFormat.OMR &&
        (selectedTab === EAnalyticsTab[EAnalyticsTab.TIMED_ANALYSIS] ? (
          studentAnalyticsTimed !== undefined ? (
            <StudentTimedAnalysis
              data={studentAnalyticsTimed}
              isLoading={isLoading}
              selectedSectionName={selectedSectionName}
            />
          ) : (
            <div>No Analytics Avalaible :</div>
          )
        ) : (
          <div />
        ))}

      {selectedTab === EAnalyticsTab[EAnalyticsTab.RESPONSE_ANALYSIS] ? (
        studentAnalyticsQuestion !== undefined ? (
          <StudentQuesAnalytics
            isLoading={isLoading}
            data={studentAnalyticsQuestion}
            selectedSectionName={selectedSectionName}
          />
        ) : (
          <div>No Analytics Avalaible :</div>
        )
      ) : (
        <div />
      )}

      {selectedTab === EAnalyticsTab[EAnalyticsTab.STATISTICS] ? (
        studentQuestionStats !== undefined ? (
          <StudentQuestionStats
            data={studentQuestionStats}
            selectedSectionName={selectedSectionName}
          />
        ) : (
          <div>No Analytics Avalaible :</div>
        )
      ) : (
        <div />
      )}
    </div>
  );
};

export default StudentAnalyticsForTeacher;
