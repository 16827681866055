import {
  createContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import {
  TAssessmentData,
  IPublishHistoryData,
  IquestionData,
  IsectionInfo,
  TPublishInfo,
  TPublishedAssessmentBatchInfo,
  TLimitInfo,
  TComprehension,
} from "../models/assessmentmodel";
import {
  EAccessLevel,
  EPublishedAssessmentFormat,
  EPublishStatus,
} from "../utils/Enums";

import { useStickyState } from "../utils/hooks/useStickyState";

type TProps = {
  children: ReactNode;
};

export type TAssessmentContext = {
  selectedAssessment: TAssessmentData;
  setSelectedAssessment: Dispatch<SetStateAction<TAssessmentData>>;

  publishInfo: TPublishInfo;
  setPublishInfo: Dispatch<SetStateAction<TPublishInfo>>;

  // TODO: Kept this as is while refactoring, but this can probably be removed
  // since within the instruction screen we don't need batch info any more
  publishedAssessmentBatchInfo: TPublishedAssessmentBatchInfo;
  setPublishedAssessmentBatchInfo: Dispatch<
    SetStateAction<TPublishedAssessmentBatchInfo>
  >;

  sectionMap: Map<number, IsectionInfo>;
  setSectionMap: Dispatch<SetStateAction<Map<number, IsectionInfo>>>;

  currentQuestionNumber: number;
  setCurrentQuestionNumber: Dispatch<SetStateAction<number>>;

  currentSectionNumber: number;
  setCurrentSectionNumber: Dispatch<SetStateAction<number>>;

  currentQuestionData: IquestionData;
  setCurrentQuestionData: Dispatch<SetStateAction<IquestionData>>;

  questionMap: Map<number, IquestionData>;
  setQuestionMap: Dispatch<SetStateAction<Map<number, IquestionData>>>;

  updatedableQuestionMap: Map<number, IquestionData>;
  setUpdateableQuestionMap: Dispatch<
    SetStateAction<Map<number, IquestionData>>
  >;

  publishHistory: Array<IPublishHistoryData>;
  setPublishHistory: Dispatch<SetStateAction<Array<IPublishHistoryData>>>;

  showQNavDrawer: boolean;
  setShowQNavDrawer: Dispatch<SetStateAction<boolean>>;

  limits: Map<number, TLimitInfo>;
  setLimits: Dispatch<SetStateAction<Map<number, TLimitInfo>>>;

  comprehensionMap: Map<number, TComprehension>;
  setComprehensionMap: Dispatch<SetStateAction<Map<number, TComprehension>>>;

  isQuestionScreenActive: boolean;
  setIsQuestionScreenActive: Dispatch<SetStateAction<boolean>>;
};

export const initialContext: TAssessmentContext = {
  selectedAssessment: {
    id: 0,
    type: 1,
    format: 0,
    name: "",
    instructions: "",
    status: 0,
    sections: [],
    created_by: "",
    created_at: new Date(),
    teachers_pdf_url: "",
    solution_pdf_url: "",
    no_solution_pdf_url: "",
  },
  setSelectedAssessment: (): void => {},

  publishInfo: {
    id: 0,
    publish_code: "AAAAAAAA",
    start_time: new Date(),
    end_time: new Date(),
    created_by: "",
    access_level: EAccessLevel.PUBLIC,
    status: EPublishStatus.UNPUBLISHED,
    duration: 0,
    format: EPublishedAssessmentFormat.FIXED_DURATION,
  },
  setPublishInfo: (): void => {},

  publishedAssessmentBatchInfo: {
    id: 0,
    name: "",
    org_id: 0,
    created_at: new Date(),
  },
  setPublishedAssessmentBatchInfo: (): void => {},

  sectionMap: new Map(),
  setSectionMap: (): void => {},

  currentQuestionNumber: 0,
  setCurrentQuestionNumber: (): void => {},

  currentSectionNumber: 0,
  setCurrentSectionNumber: (): void => {},

  currentQuestionData: {
    question_id: 0,
    hash: "",
    subject: "",
    body: {
      text: "",
      images: [],
    },
    options: [],
    answer: "",
    solution: {
      text: "",
      images: [],
    },
    status: 0,
    question_type: 0,
    assessment_id: 0,
    marks_if_correct: 0,
    marks_if_incorrect: 0,
    marks_if_unattempted: 0,
    partial_marking: false,
    limitId: -1,
  },
  setCurrentQuestionData: (): void => {},

  questionMap: new Map(),
  setQuestionMap: (): void => {},

  updatedableQuestionMap: new Map(),
  setUpdateableQuestionMap: (): void => {},

  publishHistory: [],
  setPublishHistory: (): void => {},

  showQNavDrawer: false,
  setShowQNavDrawer: (): void => {},

  limits: new Map(),
  setLimits: (): void => {},

  comprehensionMap: new Map(),
  setComprehensionMap: (): void => {},

  isQuestionScreenActive: false,
  setIsQuestionScreenActive: (): void => {},
};

export const AssessmentContext =
  createContext<TAssessmentContext>(initialContext);

export const AssessmentContextProvider = ({
  children,
}: TProps): JSX.Element => {
  const [selectedAssessment, setSelectedAssessment] =
    useStickyState<TAssessmentData>(
      initialContext.selectedAssessment,
      "selectedAssesment"
    );

  const [publishInfo, setPublishInfo] = useStickyState<TPublishInfo>(
    initialContext.publishInfo,
    "publishInfo"
  );

  const [publishedAssessmentBatchInfo, setPublishedAssessmentBatchInfo] =
    useStickyState<TPublishedAssessmentBatchInfo>(
      initialContext.publishedAssessmentBatchInfo,
      "publishedAssessmentBatchInfo"
    );

  const [sectionMap, setSectionMap] = useStickyState<Map<number, IsectionInfo>>(
    initialContext.sectionMap,
    "sectionMap"
  );
  const [currentQuestionNumber, setCurrentQuestionNumber] =
    useStickyState<number>(
      initialContext.currentQuestionNumber,
      "currentQuestionNumber"
    );
  const [currentSectionNumber, setCurrentSectionNumber] =
    useStickyState<number>(
      initialContext.currentSectionNumber,
      "currentSectionNumber"
    );

  const [currentQuestionData, setCurrentQuestionData] =
    useStickyState<IquestionData>(
      initialContext.currentQuestionData,
      "currentQuestionData"
    );
  const [questionMap, setQuestionMap] = useStickyState<
    Map<number, IquestionData>
  >(initialContext.questionMap, "questionMap");
  const [updatedableQuestionMap, setUpdateableQuestionMap] = useStickyState<
    Map<number, IquestionData>
  >(initialContext.updatedableQuestionMap, "updateableQuestionMap");
  const [publishHistory, setPublishHistory] = useStickyState<
    Array<IPublishHistoryData>
  >(initialContext.publishHistory, "publishHistory");

  const [showQNavDrawer, setShowQNavDrawer] = useState(false);

  const [limits, setLimits] = useStickyState<Map<number, TLimitInfo>>(
    initialContext.limits,
    "limits"
  );

  const [comprehensionMap, setComprehensionMap] = useStickyState<
    Map<number, TComprehension>
  >(initialContext.comprehensionMap, "comprehensionMap");

  const [isQuestionScreenActive, setIsQuestionScreenActive] =
    useStickyState<boolean>(
      initialContext.isQuestionScreenActive,
      "isQuestionScreenActive"
    );

  return (
    <AssessmentContext.Provider
      value={{
        selectedAssessment,
        setSelectedAssessment,
        publishInfo,
        setPublishInfo,
        publishedAssessmentBatchInfo,
        setPublishedAssessmentBatchInfo,
        sectionMap,
        setSectionMap,
        currentQuestionNumber,
        setCurrentQuestionNumber,
        currentSectionNumber,
        setCurrentSectionNumber,
        currentQuestionData,
        setCurrentQuestionData,
        questionMap,
        setQuestionMap,
        updatedableQuestionMap,
        setUpdateableQuestionMap,
        publishHistory,
        setPublishHistory,
        showQNavDrawer,
        setShowQNavDrawer,
        limits,
        setLimits,
        comprehensionMap,
        setComprehensionMap,
        isQuestionScreenActive,
        setIsQuestionScreenActive,
      }}
    >
      {children}
    </AssessmentContext.Provider>
  );
};
