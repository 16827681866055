import { Col, Container } from "react-bootstrap";
import { IquestionData } from "../../models/assessmentmodel";
import { useContext, useEffect, useRef, useState } from "react";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../context/AssessmentContext";
import AddImageModal from "./Components/AddImageModal/AddImageModal";
import { HeaderEdit } from "./Components/Header/HeaderEdit";
import QuestionTextEdit from "./Components/QuestionText/QuestionTextEdit";
import { uploadFileToS3 } from "../../utils/s3Utils";
import QuestionTypeEdit from "./Components/QuestionType/QuestionTypeEdit";
import TeacherQuestionEditButtonPanel from "./Components/QuestionButtonPanel/TeacherQuestionEditButtonPanel";
import SolutionEdit from "./Components/Solution/SolutionEdit";
import { displayErrorToast } from "../../utils/ToastUtils";
import { IQuestionBody } from "../../models/common";

export default function TeacherQuestionEditScreen(props) {
  const { currentQuestionData } =
    useContext<TAssessmentContext>(AssessmentContext);
  //JSON.parse(JSON.stringify(...)) is not highly performant. Revisit to create a shallow copy.
  const currentQuestionEditedData = JSON.parse(
    JSON.stringify(currentQuestionData)
  );

  const updatedQuestionData = useRef<IquestionData>(currentQuestionEditedData);
  const updatedQuestionDataBody = useRef<IQuestionBody>(
    currentQuestionEditedData.body
  );
  const [reRender, setReRender] = useState<boolean>(false);

  const [show, setShow] = useState(false);
  const [idx, setIdx] = useState<number>(-3);

  const handleShow = (idx: number) => {
    setIdx(idx);
    setShow(true);
  };

  const handleAddImage = async (idx: number, file: any) => {
    const imageKey = await uploadFileToS3(file, "question-images/");
    if (!imageKey) {
      console.log("error!");
    }
    const imageUrl = `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_S3_REGION}.amazonaws.com/${imageKey}`;

    if (idx === -1) {
      updatedQuestionData.current.body.images?.push(imageUrl);
    } else if (idx === -2) {
      if (
        updatedQuestionData.current.solution &&
        updatedQuestionData.current.solution.images
      ) {
        updatedQuestionData.current.solution.images.push(imageUrl);
      } else {
        displayErrorToast(
          "There is some error in this question. Please create a new question and delete this one"
        );
      }
    } else {
      updatedQuestionData.current.options[idx].images?.push(imageUrl);
    }
    setReRender(!reRender);
  };

  useEffect(() => {
    setReRender(!reRender);
  }, [updatedQuestionData]);

  return props.isLoading ? null : (
    <Container
      fluid
      className="p-0 d-flex justify-content-center"
      style={{ height: "92%" }}
    >
      <Col className="m-4 d-flex flex-column" style={{ height: "85vh" }}>
        {/*  Image add modal */}
        <AddImageModal
          idx={idx ?? -3}
          show={show}
          setShow={setShow}
          handleAddImage={handleAddImage}
        />
        {/* QuestionEditScreen */}
        <HeaderEdit
          updatedQuestionData={updatedQuestionData}
          reRender={reRender}
          setReRender={setReRender}
          proofRead={props.proofRead}
          setProofRead={props.setProofRead}
        />
        <hr className="mt-1"></hr>
        <div style={{ height: "75vh", overflowY: "auto", overflowX: "hidden" }}>
          {/* Question Text */}
          <QuestionTextEdit
            updatedQuestionData={updatedQuestionDataBody}
            handleShow={handleShow}
            reRender={reRender}
            setReRender={setReRender}
          />
          {/* Option Text */}
          <QuestionTypeEdit
            updatedQuestionData={updatedQuestionData}
            handleShow={handleShow}
            reRender={reRender}
            setReRender={setReRender}
            questionType={currentQuestionData.question_type}
          />
          <h3 className="ms-1"> Solution </h3>
          {/* Solution edit; */}
          <SolutionEdit
            updatedQuestionData={updatedQuestionData}
            reRender={reRender}
            setReRender={setReRender}
            handleShow={handleShow}
          />
        </div>
        <TeacherQuestionEditButtonPanel
          edit={props.edit}
          setEdit={props.setEdit}
          updatedQuestionData={updatedQuestionData.current}
        />
      </Col>
    </Container>
  );
}
