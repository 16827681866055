import { useContext, Dispatch, SetStateAction, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import styles from "../../../batch-management/batch-management.module.scss";
import { AppContext, TAppContext } from "../../../../context/AppContext";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../context/AssessmentContext";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../../utils/ToastUtils";
import { handleUpdateSection } from "../../../../api/assessment";
import { TLimitInfo } from "../../../../models/assessmentmodel";
import CustomButton from "../../../../components/CustomComponent/CustomButton";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

type TProps = {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  sectionId: number;
};

type TRowProps = {
  range: string;
  required: string;
};

const Row = (element: TRowProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
      }}
      className="bg-white"
    >
      <div>{element.range}</div>
      <div>{element.required}</div>
      <div>{/**For moar buttons */}</div>
    </div>
  );
};

export default function XOfYQuestionModal(props: TProps) {
  const { show, setShow, sectionId } = props;

  const { selectedAssessment, sectionMap } =
    useContext<TAssessmentContext>(AssessmentContext);
  const { accessToken, org } = useContext<TAppContext>(AppContext);

  let currentSection = sectionMap.get(sectionId);
  if (currentSection!!.optional_attempts === null)
    currentSection!!.optional_attempts = [];

  const [sectionFrom, setSectionFrom] = useState<number>(1);
  const [sectionTo, setSectionTo] = useState<number>(1);
  const [sectionLimit, setSectionLimit] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updateLimits = async (start: number, end: number, required: number) => {
    if (start > end) {
      displayErrorToast("Start limit cannot be greater than end limit");
      return;
    } else if (required > end - start + 1) {
      displayErrorToast("Required questions cannot be greater than the range");
      return;
    }
    setIsLoading(true);
    let optionalLimits: Array<TLimitInfo> =
      currentSection?.optional_attempts || [];
    optionalLimits.push({
      start_question_idx: start,
      end_question_idx: end,
      limit: required,
    });
    const res = await handleUpdateSection(
      accessToken,
      currentSection?.id || 0,
      selectedAssessment.id,
      undefined,
      undefined,
      optionalLimits
    );
    if (!res) return;
    displaySuccessToast("Successfully added the required limit");
    currentSection!!.optional_attempts = optionalLimits;
    setIsLoading(false);
  };

  const handleClear = async () => {
    const res = await handleUpdateSection(
      accessToken,
      currentSection?.id || 0,
      selectedAssessment.id,
      undefined,
      undefined,
      []
    );
    if (!res) return;
    displaySuccessToast("Successfully cleared all limit");
    currentSection!!.optional_attempts = [];
    // This is to rerender the modal once limits are cleared
    setShow(false);
    setShow(true);
  };

  return (
    <Modal
      size="lg"
      contentClassName={styles.modalStyle}
      show={show}
      onHide={() => setShow(false)}
    >
      <h3 className="ms-2 mt-2">{currentSection?.name}</h3>
      <hr />
      <div className="form-group mt-3 ms-2">
        {currentSection!!.optional_attempts?.length === 0 ? (
          <div style={{ textAlign: "center" }}>
            No optional attempts have been added.
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
            className="bg-primary text-white"
          >
            <div>Question Range</div>
            <div>Required</div>
            <div>{/**For moar buttons */}</div>
          </div>
        )}
        {currentSection?.optional_attempts?.map((element, index) => {
          return Row({
            range: `${element.start_question_idx} - ${element.end_question_idx}`,
            required: element.limit.toString(),
          });
        })}
        <h3 className="mt-5">Add new</h3>
        <div
          className="bg-warning py-2 px-1 rounded-4 mx-4"
          style={{ textAlign: "center" }}
        >
          Please note Question 1 here represents first question in the section
        </div>
        <br />

        <div className="d-flex justify-content-between">
          <FormControl sx={{ m: 1, minWidth: "30%" }}>
            <InputLabel id="sectionFrom">From</InputLabel>
            <Select
              labelId="sectionFrom"
              id="sectionFrom"
              value={sectionFrom}
              label="Age"
              onChange={(item) => {
                setSectionFrom(Number(item.target.value));
              }}
            >
              {currentSection?.question_ids.map((item, idx) => (
                <MenuItem value={idx + 1}>{idx + 1}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: "30%" }}>
            <InputLabel id="sectionTo">To</InputLabel>
            <Select
              labelId="sectionTo"
              id="sectionTo"
              value={sectionTo}
              label="Age"
              onChange={(item) => {
                setSectionTo(Number(item.target.value));
              }}
            >
              {currentSection?.question_ids.map((item, idx) => {
                if (idx < sectionFrom - 1) return;
                return <MenuItem value={idx + 1}>{idx + 1}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: "30%" }}>
            <InputLabel id="sectionTo">Limit</InputLabel>
            <Select
              labelId="sectionTo"
              id="sectionTo"
              value={sectionLimit}
              label="Age"
              onChange={(item) => {
                setSectionLimit(Number(item.target.value));
              }}
            >
              {[
                ...Array(
                  sectionTo - sectionFrom + 1 > 0
                    ? sectionTo - sectionFrom + 1
                    : 1
                ),
              ].map((item, idx) => (
                <MenuItem value={idx + 1}>{idx + 1}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="d-flex justify-content-between mx-3">
          <Button
            className="border border-none px-4 bg-secondary rounded-4"
            onClick={handleClear}
          >
            Clear
          </Button>
          <Button
            className="border border-none px-4 bg-primary rounded-4"
            onClick={() => updateLimits(sectionFrom, sectionTo, sectionLimit)}
            disabled={isLoading}
          >
            Add
          </Button>
        </div>
      </div>
    </Modal>
  );
}
