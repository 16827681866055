import {
  IPublishHistoryData,
  IquestionData,
  IResponseData,
  IsectionInfo,
  questionInfo,
  TLimitInfo,
} from "../../models/assessmentmodel";
import { Dispatch, SetStateAction } from "react";
import { displaySuccessToast } from "../../utils/ToastUtils";
import {
  EAccessLevel,
  EAnswerType,
  EPublishedAssessmentFormat,
  EPublishStatus,
} from "../../utils/Enums";
import { fetchOrCreateLatestSession } from "../../api/attempts";
import { fetchResponsesMap, saveResponses } from "../../api/responses";
import { IresponseBackend, TSession } from "../../models/responsemodel";
import {
  fetchPastPublishesOfAssessment,
  patchPublishedAssessment,
  publishAssessment,
} from "../../api/assessment";

// Fetches past publications of an assessment
export const getPastPublishes = async (
  accessToken: string,
  assessmentId: number,
  setPublishHistory: Dispatch<SetStateAction<IPublishHistoryData[]>>
) => {
  const pastPublishes = await fetchPastPublishesOfAssessment(
    accessToken,
    assessmentId
  );
  if (!pastPublishes) return null;
  setPublishHistory(pastPublishes);
  console.log("FETCHING PAST PUBLISHES", pastPublishes);
  return 1;
};

export const getSession = async (
  accessToken: string,
  publishCode: string,
  setAlreadyAttempted: Dispatch<SetStateAction<boolean>>,
  setCurrentSession: Dispatch<SetStateAction<TSession>>,
  createNew: boolean = true
) => {
  const session = await fetchOrCreateLatestSession(
    accessToken,
    publishCode,
    createNew
  );
  if (!session) return null;
  setCurrentSession({
    attempt_id: session.attempt_id,
    started_on: session.started_on,
    id: session.session_id,
    ended_on: session.ended_on,
  });
  if (session.ended_on !== null) {
    setAlreadyAttempted(true);
    displaySuccessToast(
      "You have already attempted this test. Analytics will be available after the deadline."
    );
  }
  return session;
};

export const storeAndFetchResponses = async (
  accessToken: string,
  sessionId: number,
  questionMap: Map<number, IquestionData>,
  setAssessmentQuestionInfo: Dispatch<SetStateAction<Map<number, questionInfo>>>
) => {
  const responsesMap = await fetchResponsesMap(accessToken, sessionId);
  if (!responsesMap) return null;
  await assignQuestionInfo(
    questionMap,
    setAssessmentQuestionInfo,
    responsesMap
  );
  return true;
};

// Move to Utils folder.
const assignQuestionInfo = async (
  questionMap: Map<number, IquestionData>,
  setAssessmentQuestionInfo: Dispatch<
    SetStateAction<Map<number, questionInfo>>
  >,
  fetchedQuestionInfo: Map<number, IResponseData>
) => {
  // create a new response and integrate with the backend. and maybe move it to a class?
  let allQuestionInfo: Map<number, questionInfo> = new Map();
  questionMap.forEach((question) => {
    let qInfo: questionInfo = new questionInfo(question.question_id);
    const currentQuestionInfo = fetchedQuestionInfo.get(question.question_id);
    if (currentQuestionInfo!!) {
      qInfo.questionState = currentQuestionInfo.answer_type;
      qInfo.questionAnswer = currentQuestionInfo.answer;
      qInfo.questionTimeSpent = currentQuestionInfo.time_taken;
      qInfo.questionLastUpdatedOn = currentQuestionInfo.last_updated_on;
    }
    allQuestionInfo.set(question.question_id, qInfo);
  });
  setAssessmentQuestionInfo(allQuestionInfo);
};

// Move to Utils folder
export const getQuestionMap = (questions) => {
  let questionMap = new Map();
  questions.forEach((el) => {
    questionMap.set(el.question_id, el);
  });
  return questionMap;
};

export const createIDMapFromArray = (array) => {
  if (!array) return new Map();
  let map = new Map();
  array.forEach((el) => {
    map.set(el.id, el);
  });
  return map;
};

export const publishAssessmentHandler = async (
  accessToken: string,
  setPublishHistory: Dispatch<SetStateAction<IPublishHistoryData[]>>,
  assessmentId: number,
  assessmentLength: number,
  access_level: EAccessLevel,
  batchId: number,
  format: EPublishedAssessmentFormat,
  duration: number,
  start_date: string,
  start_time: string,
  end_date?: string,
  end_time?: string,
  publishId?: number,
  status?: EPublishStatus
) => {
  // Following check was commented as questionMap gets set in the assessment context in instruction screen,
  // This causes the question map of the previously opened assessment to be used here instead of the current one

  // if(assessmentLength === 0) {
  //   displayErrorToast("Please add atleast one question to publish the assessment.");
  //   return;
  // }

  const startTime = new Date(Date.parse(start_date + " " + start_time));
  const startTimeISO = startTime.toISOString().slice(0, -1);
  let endTime: Date | undefined = undefined;
  let endTimeISO = "";
  if (end_time === "Invalid date") end_time = undefined;
  if (end_date === "Invalid date") end_date = undefined;
  if (!!end_date && !!end_time) {
    endTime = new Date(Date.parse(end_date + " " + end_time));
    endTimeISO = endTime.toISOString().slice(0, -1);
  }

  let response: any;
  if (!publishId) {
    response = await publishAssessment(
      accessToken,
      assessmentId,
      EPublishStatus.PUBLISHED,
      access_level,
      format,
      startTimeISO,
      duration,
      endTimeISO === "" ? undefined : endTimeISO,
      batchId
    );
  } else {
    response = await patchPublishedAssessment(publishId, accessToken, {
      start_time: startTimeISO,
      end_time: endTimeISO === "" ? undefined : endTimeISO,
      format,
      duration,
      batch_id: batchId,
      access_level,
      ...(status && { status: status }),
    });
  }

  if (!response) return null;
  displaySuccessToast("Successfully modified publish status");
  await getPastPublishes(accessToken, assessmentId, setPublishHistory);
  return response;
};

export const evaluateLimits = (
  sectionData: Array<IsectionInfo>,
  questionMap: Map<number, IquestionData>,
  setQuestionMap: Dispatch<SetStateAction<Map<number, IquestionData>>>,
  assessmentQInfo: Map<number, questionInfo>
) => {
  let limits: Map<number, TLimitInfo> = new Map();
  sectionData.forEach((section) => {
    if (!section.optional_attempts) {
      section.optional_attempts = [
        {
          start_question_idx: 1,
          end_question_idx: section.question_ids.length,
          limit: section.question_ids.length + 1,
        },
      ];
    }
    section.optional_attempts?.forEach((limit, idx) => {
      const limitId = limits.size;
      let permissibleLimit = limit.limit;
      for (let i = limit.start_question_idx; i <= limit.end_question_idx; i++) {
        let updatedQuestion = questionMap.get(section.question_ids[i - 1]);
        let qInfo = assessmentQInfo.get(updatedQuestion!!.question_id)!!;
        if (!qInfo) {
          qInfo = new questionInfo(updatedQuestion!!.question_id);
        }
        if (
          qInfo.questionState === EAnswerType.FLAGGED_ANSWERED ||
          qInfo.questionState === EAnswerType.VISITED_ANSWERED
        )
          permissibleLimit--;
        updatedQuestion!!.limitId = limitId;
        questionMap.set(updatedQuestion!!.question_id, updatedQuestion!!);
      }
      limits.set(limitId, {
        start_question_idx: limit.start_question_idx,
        end_question_idx: limit.end_question_idx,
        limit: permissibleLimit,
      });
    });
  });
  let updatedQuestionMap = new Map();
  questionMap.forEach((question, id) => {
    if (question.limitId === null || question.limitId === undefined) {
      question.limitId = -1;
    }
    updatedQuestionMap.set(id, question);
  });
  setQuestionMap(updatedQuestionMap);
  limits.set(-1, {
    start_question_idx: 1,
    end_question_idx: questionMap.size + 1,
    limit: questionMap.size + 1,
  });
  return limits;
};
