import { useContext, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { EAnswerType, EQuestionType, ERole } from "../../../../utils/Enums";
import styles from "../../omr.module.scss";
import { IquestionData } from "../../../../models/assessmentmodel";
import { findSelectedOptions } from "../../../../utils/assessment";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../context/AssessmentContext";
import { AppContext, TAppContext } from "../../../../context/AppContext";
import {
  QuestionContext,
  TQuestionContext,
} from "../../../../context/QuestionContext";
import { setResponse } from "../../../../api/responses";
import {
  AttemptContext,
  TAttemptContext,
} from "../../../../context/AttemptContext";
import { displayErrorToast } from "../../../../utils/ToastUtils";
import { parseMapFromJSON } from "../../../../utils/hooks/useStickyState";
import moment from "moment";

interface MultipleChoiceProps {
  currentQuestion: IquestionData;
  lockStatus: boolean;
  disabled: boolean;
  response?: boolean;
  isCorrect?: boolean;
}

export default function MultipleChoice({
  currentQuestion,
  lockStatus,
  disabled,
  response,
  isCorrect,
}: MultipleChoiceProps) {
  const {
    questionMap,
    setQuestionMap,
    updatedableQuestionMap,
    setUpdateableQuestionMap,
    limits,
    setLimits,
  } = useContext<TAssessmentContext>(AssessmentContext);
  const { assessmentQuestionInfo, setAssessmentQuestionInfo } =
    useContext<TQuestionContext>(QuestionContext);

  const { role, accessToken } = useContext<TAppContext>(AppContext);
  const { currentSession } = useContext<TAttemptContext>(AttemptContext);
  const [selectedOptions, setSelectedOptions] = useState<Array<number>>([]);
  let answer =
    role === ERole.STUDENT
      ? assessmentQuestionInfo.get(currentQuestion.question_id)!!.questionAnswer
      : currentQuestion.answer;

  useEffect(() => {
    setSelectedOptions(findSelectedOptions(answer));
  }, [currentQuestion.question_id, answer]);

  async function handleOptionClick(option: string) {
    let updatedQuestionInfo = new Map(assessmentQuestionInfo);
    const qInfo = updatedQuestionInfo.get(currentQuestion.question_id)!!;
    console.log(lockStatus, disabled);
    if (
      lockStatus ||
      (disabled &&
        qInfo.questionState !== EAnswerType.VISITED_ANSWERED &&
        qInfo.questionState !== EAnswerType.FLAGGED_ANSWERED)
    )
      return;
    /*
        If the question is a MCQ type, just set answer to selected option
        Otherwise the question is MCMCQ type.
          Now if the option is already seleted, remove it from the answer. Otherwise add it to the answer.
          We also need to update the selected options array to reflect the necessary changes
    
          While adding the option, lets say option C we append C, to the answer and add C to the array
          This logic belongs to teacher
        */
    if (role === ERole.STUDENT) {
      const stateChangeMap = new Map([
        [EAnswerType.UNVISITED, EAnswerType.VISITED_ANSWERED],
        [EAnswerType.FLAGGED_ANSWERED, EAnswerType.FLAGGED_ANSWERED],
        [EAnswerType.VISITED_ANSWERED, EAnswerType.VISITED_ANSWERED],
        [EAnswerType.FLAGGED_UNANSWERED, EAnswerType.FLAGGED_ANSWERED],
        [EAnswerType.VISITED_UNANSWERED, EAnswerType.VISITED_ANSWERED],
      ]);
      if (currentQuestion.question_type === EQuestionType.MCQ) {
        if (qInfo.questionAnswer === option) {
          qInfo.questionAnswer = "";
        } else qInfo.questionAnswer = option;
      } else if (qInfo.questionAnswer?.includes(option)) {
        qInfo.questionAnswer = qInfo.questionAnswer?.replace(option + ",", "");
      } else {
        qInfo.questionAnswer = qInfo.questionAnswer + option + ",";
      }

      if (
        qInfo.questionState === EAnswerType.UNVISITED ||
        qInfo.questionState === EAnswerType.FLAGGED_UNANSWERED ||
        qInfo.questionState === EAnswerType.VISITED_UNANSWERED
      ) {
        let updatedLimits = new Map(limits);
        let updatedLimit = limits.get(currentQuestion.limitId)!!;
        updatedLimit.limit -= 1;
        updatedLimits.set(currentQuestion.limitId, updatedLimit);
        setLimits(updatedLimits);
      }

      qInfo.questionState = stateChangeMap.get(qInfo.questionState)!!;
      if (
        qInfo.questionAnswer === "" &&
        qInfo.questionState === EAnswerType.VISITED_ANSWERED
      ) {
        let updatedLimits = new Map(limits);
        let updatedLimit = limits.get(currentQuestion.limitId)!!;
        updatedLimit.limit += 1;
        updatedLimits.set(currentQuestion.limitId, updatedLimit);
        setLimits(updatedLimits);
        qInfo.questionState = EAnswerType.VISITED_UNANSWERED;
      } else if (
        qInfo.questionAnswer === "" &&
        qInfo.questionState === EAnswerType.FLAGGED_ANSWERED
      ) {
        let updatedLimits = new Map(limits);
        let updatedLimit = limits.get(currentQuestion.limitId)!!;
        updatedLimit.limit += 1;
        updatedLimits.set(currentQuestion.limitId, updatedLimit);
        setLimits(updatedLimits);
        qInfo.questionState = EAnswerType.FLAGGED_UNANSWERED;
      }
      qInfo.questionLastUpdatedOn = moment
        .utc(moment.now())
        .toISOString()
        .slice(0, -1);
      updatedQuestionInfo.set(currentQuestion.question_id, qInfo);
      const success = await setResponse(
        accessToken,
        currentSession.id,
        qInfo,
        parseMapFromJSON(window.localStorage.getItem("unpushedAttempts"))
      );
      if (success) {
        setSelectedOptions(findSelectedOptions(qInfo.questionAnswer));
        setAssessmentQuestionInfo(updatedQuestionInfo);
      } else {
        displayErrorToast(
          "Something went wrong, you might want to check your local time or internet connection"
        );
      }

      return;
    }
    if (currentQuestion.question_type === EQuestionType.MCQ) {
      currentQuestion.answer = option;
    } else if (currentQuestion.answer?.includes(option)) {
      currentQuestion.answer = currentQuestion.answer?.replace(
        option + ",",
        ""
      );
    } else {
      currentQuestion.answer = currentQuestion.answer + option + ",";
    }
    let newQuestionMap = new Map(questionMap);
    newQuestionMap.set(currentQuestion.question_id, currentQuestion);
    setQuestionMap(newQuestionMap);
    let updatedQuestionMap = new Map(updatedableQuestionMap);
    updatedQuestionMap.set(currentQuestion.question_id, currentQuestion);
    setUpdateableQuestionMap(updatedQuestionMap);
  }

  return (
    <>
      <div
        className={`flex-grow-1 flex-row d-flex ms-2 align-items-center justify-content-start`}
      >
        {currentQuestion.options.map((val, idx) => {
          return (
            <div className={"d-flex flex-row align-items-center mx-auto"}>
              <Button
                variant="light"
                className={`
                  border rounded-pill 
                  ${styles.optionName} 
                  text-center 
                  d-flex 
                  align-items-center 
                  justify-content-center
                `}
                style={{
                  backgroundColor: response
                    ? selectedOptions.includes(idx)
                      ? isCorrect
                        ? "#9FE710"
                        : "#F67210"
                      : "#e0e0e0"
                    : selectedOptions.includes(idx)
                    ? "#005c29"
                    : "#e0e0e0",
                  color: selectedOptions.includes(idx) ? "white" : "black",
                }}
                onClick={() => {
                  handleOptionClick(String.fromCharCode(65 + idx));
                }}
              >
                {String.fromCharCode(65 + idx)}
              </Button>
            </div>
          );
        })}
      </div>
    </>
  );
}
