import { useContext } from "react";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../context/AssessmentContext";
import { AppContext, TAppContext } from "../../../../context/AppContext";
import {
  QuestionContext,
  TQuestionContext,
} from "../../../../context/QuestionContext";
import { setResponse } from "../../../../api/responses";
import {
  AttemptContext,
  TAttemptContext,
} from "../../../../context/AttemptContext";
import { Form } from "react-bootstrap";
import { IquestionData } from "../../../../models/assessmentmodel";
import { EAnswerType, ERole } from "../../../../utils/Enums";
import { displayErrorToast } from "../../../../utils/ToastUtils";
import { parseMapFromJSON } from "../../../../utils/hooks/useStickyState";
import moment from "moment";

interface NumericalProps {
  currentQuestion: IquestionData;
  lockStatus: boolean;
  disabled: boolean;
}

export default function Numerical({
  currentQuestion,
  lockStatus,
  disabled,
}: NumericalProps) {
  const {
    questionMap,
    setQuestionMap,
    updatedableQuestionMap,
    limits,
    setLimits,
    setUpdateableQuestionMap,
  } = useContext<TAssessmentContext>(AssessmentContext); // Get the data from the context
  const { assessmentQuestionInfo, setAssessmentQuestionInfo } =
    useContext<TQuestionContext>(QuestionContext);
  const { role, accessToken } = useContext<TAppContext>(AppContext);
  const { currentSession } = useContext<TAttemptContext>(AttemptContext);

  const handleOnChange = async (item) => {
    let updatedQuestionInfo = new Map(assessmentQuestionInfo);
    const qInfo = updatedQuestionInfo.get(currentQuestion.question_id)!!;
    if (
      lockStatus ||
      (disabled &&
        qInfo.questionState !== EAnswerType.VISITED_ANSWERED &&
        qInfo.questionState !== EAnswerType.FLAGGED_ANSWERED)
    ) {
      return;
    }
    let updatedLimits = new Map(limits);
    let updatedLimit = limits.get(currentQuestion.limitId)!!;
    if (role === ERole.STUDENT) {
      if (
        item.target.value !== "" &&
        (qInfo.questionState === EAnswerType.VISITED_UNANSWERED ||
          qInfo.questionState === EAnswerType.UNVISITED)
      ) {
        updatedLimit.limit -= 1;
        updatedLimits.set(currentQuestion.limitId, updatedLimit);
        setLimits(updatedLimits);
        qInfo.questionState = EAnswerType.VISITED_ANSWERED;
      } else if (
        item.target.value !== "" &&
        qInfo.questionState === EAnswerType.FLAGGED_UNANSWERED
      ) {
        updatedLimit.limit -= 1;
        updatedLimits.set(currentQuestion.limitId, updatedLimit);
        setLimits(updatedLimits);
        qInfo.questionState = EAnswerType.FLAGGED_ANSWERED;
      } else if (
        item.target.value === "" &&
        qInfo.questionState === EAnswerType.VISITED_ANSWERED
      ) {
        updatedLimit.limit += 1;
        updatedLimits.set(currentQuestion.limitId, updatedLimit);
        setLimits(updatedLimits);
        qInfo.questionState = EAnswerType.VISITED_UNANSWERED;
      } else if (
        item.target.value === "" &&
        qInfo.questionState === EAnswerType.FLAGGED_ANSWERED
      ) {
        updatedLimit.limit += 1;
        updatedLimits.set(currentQuestion.limitId, updatedLimit);
        setLimits(updatedLimits);
        qInfo.questionState = EAnswerType.FLAGGED_UNANSWERED;
      }
      qInfo.questionAnswer = item.target.value;
      qInfo.questionLastUpdatedOn = moment
        .utc(moment.now())
        .toISOString()
        .slice(0, -1);
      const success = await setResponse(
        accessToken,
        currentSession.id,
        qInfo,
        parseMapFromJSON(window.localStorage.getItem("unpushedAttempts"))
      );
      if (success) {
        updatedQuestionInfo.set(currentQuestion.question_id, qInfo);
        setAssessmentQuestionInfo(updatedQuestionInfo);
      } else {
        displayErrorToast(
          "Something went wrong, you might want to check your local time or internet connection and please try again"
        );
      }
    }

    // This logic corresponds to how a teachers side should look like
    currentQuestion.answer = item.target.value;
    let newQuestionMap = new Map(questionMap);
    newQuestionMap.set(currentQuestion.question_id, currentQuestion);
    setQuestionMap(newQuestionMap);
    let updatedQuestionMap = new Map(updatedableQuestionMap);
    updatedQuestionMap.set(currentQuestion.question_id, currentQuestion);
    setUpdateableQuestionMap(updatedQuestionMap);
  };

  return (
    <Form
      className="ms-2 flex-grow-1"
      noValidate
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      <Form.Group controlId="answer">
        <Form.Control
          type="number"
          value={
            role === ERole.STUDENT
              ? assessmentQuestionInfo.get(currentQuestion.question_id)
                  ?.questionAnswer
              : currentQuestion.answer
          }
          placeholder="Type your answer here"
          onChange={handleOnChange}
          className="ml-2 bg-light"
        />
      </Form.Group>
    </Form>
  );
}
