import {
  Dispatch,
  FunctionComponent,
  MutableRefObject,
  SetStateAction,
  useContext,
  useRef,
  useState,
} from "react";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import {
  RiAddCircleLine,
  RiDeleteBinLine,
  RiImageAddFill,
} from "react-icons/ri";
import TipTap from "../../../../../utils/TipTap/TipTap";
import { ImSigma } from "react-icons/im";
import { EQuestionType } from "../../../../../utils/Enums";
import { IquestionData } from "../../../../../models/assessmentmodel";
import { EditImage } from "../../EditImage/EditImage";
import styles from "../../../QuestionScreen.module.scss";
import { findSelectedOptions } from "../../../../../utils/assessment";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import ReOrderOptionsModal from "../../ReOrderOptionsModal/ReOrderOptionsModal";
interface MultipleChoiceEditProps {
  updatedQuestionData: MutableRefObject<IquestionData>;
  handleShow: Function;
  reRender: boolean;
  setReRender: Dispatch<SetStateAction<boolean>>;
}

export const MultipleChoiceEdit = (props: MultipleChoiceEditProps) => {
  const [showReOrderModal, setShowReOrderModal] = useState<boolean>(false);
  const [correctAnswer, setCorrectAnswer] = useState<Array<number>>(
    findSelectedOptions(props.updatedQuestionData.current.answer!!)
  );

  const handleMCQAnswerEdit = (selectedAnswer: string) => {
    if (props.updatedQuestionData.current.question_type === EQuestionType.MCQ) {
      props.updatedQuestionData.current.answer = selectedAnswer;
      setCorrectAnswer([selectedAnswer.charCodeAt(0) - 65]);
      return;
    }

    if (props.updatedQuestionData.current.answer?.includes(selectedAnswer)) {
      setCorrectAnswer(
        correctAnswer.filter(
          (answer) => answer !== selectedAnswer.charCodeAt(0) - 65
        )
      );
      props.updatedQuestionData.current.answer =
        props.updatedQuestionData.current.answer?.replace(
          selectedAnswer + ",",
          ""
        );
    } else {
      setCorrectAnswer([...correctAnswer, selectedAnswer.charCodeAt(0) - 65]);
      props.updatedQuestionData.current.answer =
        props.updatedQuestionData.current.answer?.concat(
          selectedAnswer.concat(",")
        );
    }
  };

  const handleAddOption = () => {
    props.updatedQuestionData.current.options.push({
      images: [],
      text: "",
    });
    props.setReRender(!props.reRender);
  };

  return (
    <>
      <Col className="mt-4">
        {/* Single Correct Option Editor */}
        {props.updatedQuestionData.current.options.map((option, idx) => {
          return (
            <MultipleChoiceOptionEdit
              idx={idx}
              optionText={option.text}
              images={option.images}
              updatedQuestionData={props.updatedQuestionData}
              handleShow={props.handleShow}
              reRender={props.reRender}
              setReRender={props.setReRender}
            />
          );
        })}
      </Col>
      <Col>
        {/* Add Option Button */}
        <span className="d-flex justify-content-start px-4">
          <Button
            className="d-flex flex-row justify-content-between align-items-center"
            variant="light"
            onClick={() => handleAddOption()}
          >
            <RiAddCircleLine size="25px" />
            <h5 className="d-flex flex-row align-items-center mb-0 mx-2">
              Add Option
            </h5>
          </Button>
          <Button
            className="d-flex flex-row justify-content-between align-items-center"
            variant="light"
            onClick={() => {
              setShowReOrderModal(true);
            }}
          >
            <MenuIcon />
            <h5 className="d-flex flex-row align-items-center mb-0 mx-2">
              Reorder Options
            </h5>
          </Button>
        </span>
        {/* Edit Correct Answer Component */}
        <h4 className="p-2 d-flex align-items-center">
          <Row>
            <Col>Correct Answer:</Col>
            {props.updatedQuestionData.current.options.map((option, idx) => {
              return (
                <Col>
                  <Button
                    style={{
                      backgroundColor: correctAnswer.includes(idx)
                        ? "green"
                        : "white",
                      color: correctAnswer.includes(idx) ? "white" : "black",
                    }}
                    variant="light"
                    className={`border rounded-pill text-center d-flex align-items-center justify-content-center`}
                    onClick={() => {
                      handleMCQAnswerEdit(String.fromCharCode(idx + 65));
                    }}
                  >
                    {String.fromCharCode(65 + idx)}
                  </Button>
                </Col>
              );
            })}
          </Row>
        </h4>
      </Col>
      <ReOrderOptionsModal
        showModal={showReOrderModal}
        setShowModal={setShowReOrderModal}
        updatedQuestionsData={props.updatedQuestionData}
        reRender={props.reRender}
        setReRender={props.setReRender}
      />
    </>
  );
};

interface MultipleChoiceOptionEditProps {
  idx: number;
  optionText: string;
  images?: Array<string>;
  updatedQuestionData: MutableRefObject<IquestionData>;
  handleShow: Function;
  reRender: boolean;
  setReRender: Dispatch<SetStateAction<boolean>>;
}

const MultipleChoiceOptionEdit: FunctionComponent<
  MultipleChoiceOptionEditProps
> = (props) => {
  const isDesktopOrLaptop = useMediaQuery("(min-width: 992px)");

  const images = props.updatedQuestionData.current.options[props.idx].images!!;
  const cursorPosition = useRef<number>(0);

  const handleMCQOptionEdit = (idx: number, content: string) => {
    props.updatedQuestionData.current.options[idx].text = content;
  };

  const handleRemoveOption = (idx: number) => {
    props.updatedQuestionData.current.options =
      props.updatedQuestionData.current.options.filter(
        (_, index) => index !== idx
      );
    props.setReRender(!props.reRender);
  };

  const handleAddMath = (idx: number) => {
    let a: string;
    const re: RegExp = /\\\(.*?\\\)/g;
    let latexArray: RegExpMatchArray | null;
    if (cursorPosition === undefined) {
      props.updatedQuestionData.current.options[idx].text += "\\( math \\)";
      props.setReRender(!props.reRender);
      return;
    }

    latexArray = props.updatedQuestionData.current.options[idx].text.match(re);

    a = props.updatedQuestionData.current.options[idx].text.replaceAll(re, "R");

    a = `${a.substring(0, cursorPosition.current)} \\( math \\) ${a.substring(
      cursorPosition.current,
      a.length
    )}`;

    if (latexArray) {
      latexArray.forEach((el) => (a = a.replace("R", el)));
    }

    props.updatedQuestionData.current.options[idx].text = a;
    props.setReRender(!props.reRender);
  };

  return (
    <>
      <div className={`mx-1 my-2 border border-dark rounded-4`}>
        {/* Option Label */}
        <div
          className={`d-flex align-items-center rounded-4 justify-content-between`}
        >
          <div
            className={`${styles.optionName} text-center d-flex align-items-center justify-content-center m-2 p-4`}
            style={{
              cursor: "default",
              height: isDesktopOrLaptop ? "60px" : "40px",
              minWidth: isDesktopOrLaptop ? "60px" : "40px",
            }}
          >
            {String.fromCharCode(65 + props.idx)}
          </div>
          {/* Option Editor */}
          <div className="d-flex flex-grow-1">
            <TipTap
              editMode
              onChange={(idx: number, content: string) =>
                handleMCQOptionEdit(idx, content)
              }
              setCursorPosition={(pos: number) => {
                cursorPosition.current = pos;
              }}
              text={props.optionText}
              idx={props.idx}
            />
          </div>
          {/* Add math, image button group */}
          <div className="d-flex align-items-center justify-content-end me-2">
            <ButtonGroup>
              <Button className="px-2" variant="light">
                <RiImageAddFill
                  color="#9AA0B2"
                  size="35px"
                  onClick={() => {
                    props.handleShow(props.idx);
                  }}
                  cursor="pointer"
                />
              </Button>
              <Button className="px-2" variant="light">
                <ImSigma
                  color="#9AA0B2"
                  size="25px"
                  onClick={() => {
                    handleAddMath(props.idx);
                  }}
                  cursor="pointer"
                />
              </Button>
              <Button
                className="px-2"
                variant="light"
                onClick={() => handleRemoveOption(props.idx)}
              >
                <RiDeleteBinLine color="#9AA0B2" size="35px" cursor="pointer" />
              </Button>
            </ButtonGroup>
          </div>
          {/* Images */}
        </div>
        <Row>
          {images.map((image, idx) => (
            <EditImage
              src={image}
              onDelete={() => {
                props.updatedQuestionData.current.options[props.idx].images =
                  props.updatedQuestionData.current.options[
                    props.idx
                  ].images?.filter((_, index) => index !== idx);
                props.setReRender(!props.reRender);
              }}
            />
          ))}
        </Row>
      </div>
    </>
  );
};
