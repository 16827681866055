import {
  useContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import { Button, Col, Row } from "react-bootstrap";

import { AppContext, TAppContext } from "../../../../context/AppContext";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../context/AssessmentContext";

import { flagQuestion } from "../../../../api/question";

import { EQuestionStatus } from "../../../../utils/Enums";
import { DeleteQuestionFromAssessment } from "../DeleteQuestionFromAssessment";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import {
  findFirstQuestionId,
  findLastQuestionId,
  handleOnClickNext,
  handleOnClickPrevious,
} from "../../../../utils/QuestionNavigation";
import { useMediaQuery } from "@mui/material";
import QuestionBottomMenuButton from "./QuestionBottomMenuButton/QuestionBottomMenuButton";

let firstQuestionId = 0;
let lastQuestionId = 0;

type TProps = {
  edit: boolean;
  setEdit: Dispatch<SetStateAction<boolean>>;
};

export default function TeacherQuestionButtonPanel(props: TProps) {
  const { accessToken } = useContext<TAppContext>(AppContext);
  const {
    questionMap,
    setQuestionMap,
    currentQuestionData,
    setCurrentQuestionData,
    selectedAssessment,
    sectionMap,
    currentQuestionNumber,
    setCurrentQuestionNumber,
    currentSectionNumber,
    setCurrentSectionNumber,
  } = useContext<TAssessmentContext>(AssessmentContext); // Get the data from the context

  // TODO: Change this to Enum to denote QuestionState
  const [isFlagged, setIsFlagged] = useState(false);
  const isMobile = useMediaQuery("(max-width: 576px)");

  useEffect(() => {
    setIsFlagged(currentQuestionData.status === EQuestionStatus.FLAGGED);
  }, [currentQuestionData]);

  useEffect(() => {
    /**
     * We need to calculate the first question and make sure that when we are viewing that question,  the button is disabled
     * Also if the default that is currentquestionnumber = 0 and currentsectionnumber = 0 is not indeed the first question,
     * then set the first question accordingly
     */

    firstQuestionId = findFirstQuestionId(selectedAssessment, sectionMap);
    lastQuestionId = findLastQuestionId(selectedAssessment, sectionMap);
  }, [selectedAssessment, sectionMap, questionMap]);

  const handleFlagSet = async () => {
    const newStatus = isFlagged
      ? EQuestionStatus.APPROVED
      : EQuestionStatus.FLAGGED;
    const success = await flagQuestion(
      accessToken,
      currentQuestionData.question_id,
      newStatus
    );
    if (!success) return null;
    currentQuestionData.status = newStatus;
    questionMap.set(currentQuestionData.question_id, currentQuestionData);
    setCurrentQuestionData({ ...currentQuestionData });
    setQuestionMap(new Map(questionMap));
    setIsFlagged(!isFlagged);
  };

  const TeacherButtonPanel = (
    <Col className="d-flex flex-column justify-content-end">
      <hr className="mt-auto"></hr>
      <Row className="p-2 bg-button-panel rounded-4 d-flex flex-row justify-content-center align-items-center">
        <Col className="px-0 d-flex flex-row justify-content-start align-items-center">
          <Button
            className="rounded-circle bg-button border-light"
            disabled={currentQuestionData.question_id === firstQuestionId}
            onClick={() =>
              handleOnClickPrevious(
                selectedAssessment,
                currentQuestionData,
                sectionMap,
                currentQuestionNumber,
                setCurrentQuestionNumber,
                currentSectionNumber,
                setCurrentSectionNumber
              )
            }
          >
            <IoChevronBack size={isMobile ? "20px" : "25px"} color="white" />
          </Button>
        </Col>

        <Col className="px-1 d-flex flex-row justify-content-center align-items-center">
          <QuestionBottomMenuButton
            className="bg-warning"
            onClick={() => {
              props.setEdit!!(!props.edit);
            }}
          >
            {!props.edit ? "Edit" : "Discard Changes"}
          </QuestionBottomMenuButton>
        </Col>

        <Col className="px-1 d-flex flex-row justify-content-center align-items-center">
          <DeleteQuestionFromAssessment className="bg-danger">
            Delete
          </DeleteQuestionFromAssessment>
        </Col>

        <Col className="px-1 d-flex flex-row justify-content-center align-items-center">
          <QuestionBottomMenuButton onClick={handleFlagSet}>
            {isFlagged ? "Unflag" : "Flag"}
          </QuestionBottomMenuButton>
        </Col>

        <Col className="px-0 d-flex flex-row justify-content-end align-items-center">
          <Button
            className="rounded-circle bg-button border-light"
            disabled={lastQuestionId === currentQuestionData.question_id} // check if it is the last question or not
            onClick={() =>
              handleOnClickNext(
                selectedAssessment,
                currentQuestionData,
                sectionMap,
                currentQuestionNumber,
                setCurrentQuestionNumber,
                currentSectionNumber,
                setCurrentSectionNumber
              )
            }
          >
            <IoChevronForward size={isMobile ? "20px" : "25px"} color="white" />
          </Button>
        </Col>
      </Row>
    </Col>
  );

  return TeacherButtonPanel;
}
