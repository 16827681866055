import { Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { IQuestionV2 } from "../../../models/question";
import QuestionText from "../../question/Components/QuestionText/QuestionText";

interface AnalyticsQuestionPreviewScreenProps {}

const AnalyticsQuestionPreviewScreen: FunctionComponent<
  AnalyticsQuestionPreviewScreenProps
> = () => {
  const location = useLocation();

  const questionData: IQuestionV2 | undefined = location.state?.questionData;

  if (questionData === undefined)
    return <Redirect to={location.pathname.split("question")[0]}></Redirect>;

  console.log(questionData);
  return (
    <div className="d-flex flex-column p-3">
      {/* Question */}
      <Typography variant="h5">Question: </Typography>
      <QuestionText images={questionData.body.images}>
        {questionData.body.text}
      </QuestionText>

      {/* Options */}
      {questionData.options.map((el, idx) => (
        <>
          <div className="d-flex flex-row align-items-center">
            <div className="pb-2">
              <b style={{ padding: "10px" }}>
                {String.fromCharCode(65 + idx)}.
              </b>{" "}
            </div>
            &nbsp;
            <QuestionText
              images={el.images}
              altText={`Option ${String.fromCharCode(65 + idx)}`}
            >
              {el.text}
            </QuestionText>
          </div>
        </>
      ))}

      {/* Answer */}
      <Typography variant="h5" className="mt-3">
        Answer: {questionData.answer}
      </Typography>

      {/* Solution */}
      <Typography variant="h5" className="mt-4">
        Solution:{" "}
      </Typography>
      <QuestionText
        altText="No solution provided"
        images={questionData.solution.images}
      >
        {questionData.solution.text ?? "No solution provided"}
      </QuestionText>
    </div>
  );
};

export default AnalyticsQuestionPreviewScreen;
