import { useContext, Dispatch, SetStateAction, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import styles from "../../../batch-management/batch-management.module.scss";
import { AppContext, TAppContext } from "../../../../context/AppContext";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../context/AssessmentContext";
import { displaySuccessToast } from "../../../../utils/ToastUtils";
import { handleUpdateSectionInstructions } from "../../../../api/assessment";

type TProps = {
  showEditSectionInstructionsModal: boolean;
  setShowEditSectionInstructionsModal: Dispatch<SetStateAction<boolean>>;
  sectionId: number;
  sectionInstructions: string;
};

export default function EditSectionInstructionsModal(props: TProps) {
  const {
    showEditSectionInstructionsModal,
    setShowEditSectionInstructionsModal,
  } = props;

  const { selectedAssessment, sectionMap, setSectionMap } =
    useContext<TAssessmentContext>(AssessmentContext);
  const { accessToken } = useContext<TAppContext>(AppContext);
  const updateSectionInstructions = async (
    accessToken: string,
    sectionInstructions: string,
    sectionID: number,
    assessmentID: number
  ) => {
    const status = await handleUpdateSectionInstructions(
      accessToken,
      sectionInstructions,
      sectionID,
      assessmentID
    );

    if (status === "success") {
      let sectionData = sectionMap.get(sectionID);
      if (sectionData!!) {
        let updatedSectionMap = new Map(sectionMap);
        sectionData.instructions = sectionInstructions;
        updatedSectionMap.set(sectionID, sectionData);
        setSectionMap(updatedSectionMap);
        displaySuccessToast("Instructions Updated.");
      }
    }
    setShowEditSectionInstructionsModal(false);
  };

  return (
    <Modal
      contentClassName={styles.modalStyle}
      show={showEditSectionInstructionsModal}
      onHide={() => setShowEditSectionInstructionsModal(false)}
      centered
    >
      <h3 className="ms-2 mt-2">Edit Section Instructions</h3>
      <div className="form-group mt-3 ms-2">
        <Formik
          onSubmit={(values) => {
            updateSectionInstructions(
              accessToken,
              values.sectionInstructions,
              props.sectionId,
              selectedAssessment.id
            );
          }}
          initialValues={{
            sectionInstructions: props.sectionInstructions,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            isSubmitting,
          }) => (
            <Form
              className="mt-4"
              noValidate
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <Form.Group controlId="sectionInstructions">
                <Form.Label>Section Instructions:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={20}
                  type="section name"
                  value={values.sectionInstructions}
                  placeholder=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="ml-2 bg-light"
                  isInvalid={!!errors.sectionInstructions}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.sectionInstructions}
                </Form.Control.Feedback>
              </Form.Group>

              <Modal.Footer>
                <Button
                  className="border-0 rounded-4 bg-secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Update Instructions
                </Button>{" "}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
