// material-ui
import { Card, Typography, useMediaQuery } from "@mui/material";
import moment from "moment";
import { getFormattedDuration } from "../../../utils/dateUtils";

interface ICard {
  data: string;
  correct: number;
  incorrect: number;
  total: number;
  cardTitle: string;
  icon?: JSX.Element;
  iconColor?: string;
  background?: string;
}

const AccuracyCard = (props: ICard) => {
  const isMobile = useMediaQuery("(max-width: 576px)");

  return (
    <Card
      className="d-flex align-items-center py-2 ps-2 ps-sm-4 rounded-3"
      style={{
        border: "2px solid #84868D",
        background: isMobile ? props.background : "",
      }}
    >
      {isMobile && props.background ? (
        <div />
      ) : (
        <div
          style={{
            width: isMobile ? "40px" : "60px",
            height: isMobile ? "40px" : "60px",
            borderRadius: "50%",
            background: props.iconColor,
          }}
          className="d-flex justify-content-center align-items-center"
        >
          {props.icon}
        </div>
      )}

      <div className="d-flex flex-column ps-2">
        <Typography
          sx={{
            color: "black",
            fontSize: isMobile && props.background ? "16px" : "18px",
            textTransform: "capitalize",
          }}
        >
          <b>{props.cardTitle}</b>
        </Typography>
        <Typography sx={{ color: "grey", mt: 0.25, fontSize: "16px" }}>
          <span style={{ fontWeight: "700" }}>Correct: </span>
          {props.correct} questions
        </Typography>
        <Typography sx={{ color: "grey", mt: 0.25, fontSize: "16px" }}>
          <span style={{ fontWeight: "700" }}>Incorrect: </span>
          {props.incorrect} questions
        </Typography>
        <Typography sx={{ color: "grey", mt: 0.25, fontSize: "16px" }}>
          <span style={{ fontWeight: "700" }}>Total: </span>
          {props.total} questions
        </Typography>
      </div>
    </Card>
  );
};

export default AccuracyCard;
