import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
import { Dispatch, SetStateAction, useContext } from "react";

type TProps = {
  selectedSection: number;
  setSelectedSection: Dispatch<SetStateAction<number>>;
  sectionIdNameMap: Map<number, string>;
};

export default function SectionDropdown(props: TProps) {
  const [open, setOpen] = React.useState(false);

  const handleChange = (event: SelectChangeEvent<number>) => {
    let val: number;
    if (typeof event.target.value === "string")
      val = parseInt(event.target.value);
    else val = event.target.value;
    props.setSelectedSection(val);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <div>
      <FormControl sx={{ px: 1, width: "100%" }}>
        <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={props.selectedSection}
          onChange={handleChange}
          defaultValue={-1}
          style={{ background: "#00ADB5BA", color: "white" }}
          className="rounded-4"
          sx={{ outline: "0" }}
        >
          {Array.from(props.sectionIdNameMap.keys()).map((el, idx) => {
            return (
              <MenuItem
                onClick={() => {
                  props.setSelectedSection(el);
                  handleClose();
                }}
                value={el}
              >
                {props.sectionIdNameMap.get(el)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
