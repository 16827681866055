import { Button, Col, Row } from "react-bootstrap";
import { useContext, useState, useEffect } from "react";
import { EAnswerType } from "../../../../utils/Enums";
import { AppContext, TAppContext } from "../../../../context/AppContext";
import QuestionBottomMenuButton from "./QuestionBottomMenuButton/QuestionBottomMenuButton";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../context/AssessmentContext";
import {
  QuestionContext,
  TQuestionContext,
} from "../../../../context/QuestionContext";
import {
  AttemptContext,
  TAttemptContext,
} from "../../../../context/AttemptContext";
import { setResponse } from "../../../../api/responses";
import { displayErrorToast } from "../../../../utils/ToastUtils";
import {
  findFirstQuestionId,
  findLastQuestionId,
  handleOnClickNext,
  handleOnClickPrevious,
} from "../../../../utils/QuestionNavigation";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import useMediaQuery from "@mui/material/useMediaQuery";
import { parseMapFromJSON } from "../../../../utils/hooks/useStickyState";

let firstQuestionId = 0;
let lastQuestionId = 0;

/**
 *
 * @param param0 hasDeadLinePassed which tells if the deadline has passed or not for the given exam
 * @returns
 *
 * Note there is a certain nuaissance in the code. hasFinishedAttempt has been overused to the point that it is to be
 * treated as a variable which decides if the current screen is studentresponsescreen and not the attempt screen
 * This is a fair assumption to make considering the student should be able to visit studentRepsonseScreen only if the
 * deadline has passed.
 * As a result various UI elements specific to the studentResponseScreen have been conditionally rendered based on this var
 */

export default function StudentQuestionButtonPanel({
  hasFinishedAttempt,
  proofRead,
}: {
  hasFinishedAttempt?: boolean;
  proofRead?: boolean;
}) {
  const isDesktopOrLaptop = useMediaQuery("(min-width: 992px)");
  const { accessToken } = useContext<TAppContext>(AppContext);
  const {
    currentQuestionData,
    setCurrentQuestionData,
    selectedAssessment,
    sectionMap,
    currentQuestionNumber,
    setCurrentQuestionNumber,
    currentSectionNumber,
    setCurrentSectionNumber,
    questionMap,
    limits,
    setLimits,
  } = useContext<TAssessmentContext>(AssessmentContext); // Get the data from the context
  const { assessmentQuestionInfo, setAssessmentQuestionInfo } =
    useContext<TQuestionContext>(QuestionContext);
  const { currentSession } = useContext<TAttemptContext>(AttemptContext);

  // TODO: Change this to Enum to denote QuestionState
  const [isUnderReview, setIsUnderReview] = useState(false);

  useEffect(() => {
    setIsUnderReview(
      assessmentQuestionInfo.get(currentQuestionData.question_id)
        ?.questionState === EAnswerType.FLAGGED_ANSWERED ||
        assessmentQuestionInfo.get(currentQuestionData.question_id)
          ?.questionState === EAnswerType.FLAGGED_UNANSWERED
    );
  }, [currentQuestionData]);

  useEffect(() => {
    /**
     * We need to calculate the first question and make sure that when we are viewing that question,  the button is disabled
     * Also if the default that is currentquestionnumber = 0 and currentsectionnumber = 0 is not indeed the first question,
     * then set the first question accordingly
     */

    firstQuestionId = findFirstQuestionId(selectedAssessment, sectionMap);
    lastQuestionId = findLastQuestionId(selectedAssessment, sectionMap);
  }, [selectedAssessment, sectionMap, questionMap]);

  async function handleReviewButton() {
    const stateChangeMap = new Map([
      [EAnswerType.UNVISITED, EAnswerType.FLAGGED_UNANSWERED],
      [EAnswerType.FLAGGED_ANSWERED, EAnswerType.VISITED_ANSWERED],
      [EAnswerType.VISITED_ANSWERED, EAnswerType.FLAGGED_ANSWERED],
      [EAnswerType.FLAGGED_UNANSWERED, EAnswerType.VISITED_UNANSWERED],
      [EAnswerType.VISITED_UNANSWERED, EAnswerType.FLAGGED_UNANSWERED],
    ]);
    let updatedQuestionInfo = new Map(assessmentQuestionInfo);
    const qInfo = updatedQuestionInfo.get(currentQuestionData.question_id)!!;
    const newReviewState = stateChangeMap.get(qInfo.questionState)!!;
    qInfo.questionState = newReviewState;
    const success = await setResponse(
      accessToken,
      currentSession.id,
      qInfo,
      parseMapFromJSON(window.localStorage.getItem("unpushedAttempts"))
    );
    if (success) {
      updatedQuestionInfo.set(currentQuestionData.question_id, qInfo);
      setAssessmentQuestionInfo(updatedQuestionInfo);
      setIsUnderReview(!isUnderReview);
    } else {
      displayErrorToast(
        "Something went wrong, you might want to check your local time or internet connection"
      );
    }
  }

  async function handleClearResponse() {
    const stateChangeMap = new Map([
      [EAnswerType.UNVISITED, EAnswerType.VISITED_UNANSWERED],
      [EAnswerType.FLAGGED_ANSWERED, EAnswerType.FLAGGED_UNANSWERED],
      [EAnswerType.VISITED_ANSWERED, EAnswerType.VISITED_UNANSWERED],
      [EAnswerType.FLAGGED_UNANSWERED, EAnswerType.FLAGGED_UNANSWERED],
      [EAnswerType.VISITED_UNANSWERED, EAnswerType.VISITED_UNANSWERED],
    ]);
    let updatedQuestionInfo = new Map(assessmentQuestionInfo);
    const qInfo = updatedQuestionInfo.get(currentQuestionData.question_id)!!;
    const newReviewState = stateChangeMap.get(qInfo.questionState)!!;
    qInfo.questionState = newReviewState;
    qInfo.questionAnswer = "";

    if (
      newReviewState === EAnswerType.FLAGGED_UNANSWERED ||
      newReviewState === EAnswerType.VISITED_UNANSWERED
    ) {
      let updatedLimits = new Map(limits);
      let updatedLimit = limits.get(currentQuestionData.limitId)!!;
      updatedLimit.limit += 1;
      updatedLimits.set(currentQuestionData.limitId, updatedLimit);
      setLimits(updatedLimits);
    }

    const success = await setResponse(
      accessToken,
      currentSession.id,
      qInfo,
      parseMapFromJSON(window.localStorage.getItem("unpushedAttempts"))
    );
    if (success) {
      updatedQuestionInfo.set(currentQuestionData.question_id, qInfo);
      setAssessmentQuestionInfo(updatedQuestionInfo);
    } else {
      displayErrorToast(
        "Something went wrong, you might want to check your local time or internet connection"
      );
    }
  }

  const StudentButtonPanel = (
    <Col
      xs={12}
      lg={proofRead ? 12 : 8}
      className={
        `p-2 pt-3 ${!hasFinishedAttempt && "bg-button-panel"} ` +
        (isDesktopOrLaptop && !proofRead ? "ms-4" : "")
      }
      style={
        !isDesktopOrLaptop
          ? {
              position: "fixed",
              bottom: "0",
              left: "0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "8%",
            }
          : {
              position: "fixed",
              bottom: "0",
              left: "0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "0",
              height: "8%",
            }
      }
    >
      <div>
        <Button
          size="sm"
          style={{ backgroundColor: "#373D54" }}
          className="rounded-circle border-light me-2"
          disabled={currentQuestionData.question_id === firstQuestionId}
          onClick={() =>
            handleOnClickPrevious(
              selectedAssessment,
              currentQuestionData,
              sectionMap,
              currentQuestionNumber,
              setCurrentQuestionNumber,
              currentSectionNumber,
              setCurrentSectionNumber
            )
          }
        >
          <IoChevronBack size="15px" color="white" />
        </Button>
        {!hasFinishedAttempt && (
          <QuestionBottomMenuButton
            style={{
              border: "2px solid #C4C4C4",
            }}
            className="bg-transparent text-black"
            disabled={
              assessmentQuestionInfo.get(currentQuestionData.question_id)
                ?.questionAnswer!! === ""
            }
            onClick={() => {
              handleClearResponse();
            }}
          >
            Clear
          </QuestionBottomMenuButton>
        )}
      </div>

      <div>
        {!hasFinishedAttempt && (
          <QuestionBottomMenuButton
            style={{ backgroundColor: isUnderReview ? "#373D54" : "#383874" }}
            onClick={handleReviewButton}
          >
            {isUnderReview ? "Unmark" : "Mark for Review"}
          </QuestionBottomMenuButton>
        )}

        <Button
          size="sm"
          style={{ backgroundColor: "#373D54" }}
          className="rounded-4 border-light ms-2"
          disabled={lastQuestionId === currentQuestionData.question_id} // check if it is the last question or not
          onClick={() =>
            handleOnClickNext(
              selectedAssessment,
              currentQuestionData,
              sectionMap,
              currentQuestionNumber,
              setCurrentQuestionNumber,
              currentSectionNumber,
              setCurrentSectionNumber
            )
          }
        >
          <IoChevronForward size="15px" color="white" />
        </Button>
      </div>
    </Col>
  );

  return StudentButtonPanel;
}
