import { GridRowId } from "@mui/x-data-grid";
import {
  createContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import { TBatchUser } from "../models/batches";

type TProps = {
  children: ReactNode;
};

//TODO: Verify most if not all of this is obsolete and can be removed
export type TBatchManagementContext = {
  // This corresponds to the information about the users in batch with
  // id = selectedBatchId(^), it always gets updated via a useEffect
  // in TeacherBatchManagementTable whenever selectedBatchId is updated.

  // Note: This may be obselete and could be removed after checking it out
  batchUsers: Array<TBatchUser>;
  setBatchUsers: Dispatch<SetStateAction<Array<TBatchUser>>>;

  requestsBatchUsers: Array<TBatchUser>;
  setRequestsBatchUsers: Dispatch<SetStateAction<Array<TBatchUser>>>;

  // This is to be used as setReRender(!reRender)
  // To be used in cases where you want to reload the useEffect hook but don't want any of the existsing state/contexto be affected.
  // This is a hack but works. Currently needed when a user is added to a batch
  // In that case we need to pull in all the data from the server again. But none of the context variables are supposed to be updtaed
  forceReRender: boolean;
  setForceReRender: Dispatch<SetStateAction<boolean>>;

  // Set selected batch name as well to show on top
  selectedBatchName: string;
  setSelectedBatchName: Dispatch<SetStateAction<string>>;

  // Stores the list if "ID" column values (see below columns) that were
  // selected by the checkbox in the UI.
  selectedRowIds: Array<GridRowId>;
  setSelectRowIds: Dispatch<SetStateAction<Array<GridRowId>>>;
};

const initialContext: TBatchManagementContext = {
  selectedBatchName: "",
  setSelectedBatchName: (): void => {},

  batchUsers: [],
  setBatchUsers: (): void => {},

  requestsBatchUsers: [],
  setRequestsBatchUsers: (): void => {},

  forceReRender: false,
  setForceReRender: (): void => {},

  selectedRowIds: [],
  setSelectRowIds: (): void => {},
};

export const BatchManagementContext =
  createContext<TBatchManagementContext>(initialContext);

export const BatchManagementContextProvider = ({
  children,
}: TProps): JSX.Element => {
  const [batchUsers, setBatchUsers] = useState<Array<TBatchUser>>(
    initialContext.batchUsers
  );
  const [requestsBatchUsers, setRequestsBatchUsers] = useState<
    Array<TBatchUser>
  >(initialContext.requestsBatchUsers);
  const [forceReRender, setForceReRender] = useState<boolean>(
    initialContext.forceReRender
  );

  const [selectedBatchName, setSelectedBatchName] = useState<string>(
    initialContext.selectedBatchName
  );

  const [selectedRowIds, setSelectRowIds] = useState<Array<GridRowId>>(
    initialContext.selectedRowIds
  );

  return (
    <BatchManagementContext.Provider
      value={{
        batchUsers,
        setBatchUsers,
        requestsBatchUsers,
        setRequestsBatchUsers,
        forceReRender,
        setForceReRender,
        selectedBatchName,
        setSelectedBatchName,
        selectedRowIds,
        setSelectRowIds,
      }}
    >
      {children}
    </BatchManagementContext.Provider>
  );
};
