import { Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { FunctionComponent } from "react";
import { TSalesAnalyticsOverviewData } from "../../../models/analyticsmodel";
import chartData from "../../analytics/Components/chartData";
import SalesStudentStatisticsBox from "../Components/SalesStatisticsBox";
import Chart from "react-apexcharts";
import SalesLeaderboard from "../Components/SalesLeaderboard";
import { salesAnalysisAllTimeOptionValue } from "../salesAnalyticsConstants";

interface SalesAnalyticsOverviewProps {
  data: TSalesAnalyticsOverviewData;
  days: number;
  loading: boolean;
}

const SalesAnalyticsOverview: FunctionComponent<SalesAnalyticsOverviewProps> = (
  props
) => {
  const isMobile = useMediaQuery("(max-width: 576px)");

  const numberOfUsersAddedSinceBatchCreationData = [
    ["Users added since creation of batch", " "],
    [props.data.number_of_users_added_over_time.data[1][0], 0],
  ];

  return props.loading ? (
    <></>
  ) : (
    <>
      <div className="px-0">
        {/* 
        `1. Number of users added recently
         2. Number of recent responses
         3. Number of active users
        */}
        <div className="px-2 px-sm-4">
          <SalesStudentStatisticsBox data={props.data} title="Summary" />
        </div>

        {/* Number of users added over time */}
        <div className="px-2 px-sm-4 pb-4 my-3">
          <div className="mt-2 w-100">
            <Grid container spacing={3}>
              <Grid item xs={12} md={7}>
                <div className="h-100">
                  <div
                    className={
                      "rounded-4 px-0 px-sm-4 py-2 h-100 " +
                      (isMobile ? "" : "bg-card")
                    }
                  >
                    <div className="d-flex justify-content-between align-items-center py-2 w-100">
                      <Typography color="#75777D">
                        <b>Number of users added over time</b>
                      </Typography>
                    </div>
                    <Divider className="w-100" />
                    {props.days === salesAnalysisAllTimeOptionValue ? (
                      // Display only one bar in the chart when the analysis period is set for all time
                      <Chart
                        {...chartData(
                          numberOfUsersAddedSinceBatchCreationData,
                          ["", ""],
                          isMobile,
                          1,
                          undefined,
                          ["#ADDDD0"]
                        )}
                        type="bar"
                      />
                    ) : (
                      <Chart
                        {...chartData(
                          props.data.number_of_users_added_over_time.data,
                          props.data.number_of_users_added_over_time.labels,
                          isMobile,
                          1,
                          undefined,
                          ["#ADDDD0", "#87A2FB", "#6F38C5"]
                        )}
                        type="bar"
                      />
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <SalesLeaderboard data={props.data} />
              </Grid>
            </Grid>
          </div>
        </div>

        {/* Leaderboard */}
      </div>
    </>
  );
};

export default SalesAnalyticsOverview;
