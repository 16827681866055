import { useMemo } from "react";
import { Grid, ListItem, Paper, Stack, Typography } from "@mui/material";
import "./index.scss";

export default function QuestionPaperTemplate(props) {
  /**
   * SectionLengths stores the number of questions present till last section
   * lets say there are 3 sections with [3, 2, 4] questions then sectionLengths will be [0, 3, 5]
   */
  const [sectionLengths, totalMarks] = useMemo(() => {
    let totalMarks = 0;
    let sectionLengths = [];
    let totalQuestions = 0;
    Object.entries(props.renderContent).map((obj) => {
      sectionLengths.push(totalQuestions);
      totalQuestions += obj[1].length;
      obj[1].map((questionObject) => {
        totalMarks += questionObject.marks_if_correct;
      });
    });
    return [sectionLengths, totalMarks];
  }, [props.renderContent]);

  return (
    <Paper
      variant={`outlined`}
      elevation={`2`}
      style={{
        margin: "10px 10px",
        borderWidth: "3px",
        borderColor: "black",
      }}
    >
      <Typography
        style={{
          textAlign: "right",
          fontSize: "25px",
          fontWeight: "800",
          marginRight: "12px",
        }}
      >
        Max Marks: {totalMarks}
      </Typography>
      <Typography
        style={{
          textAlign: "center",
          fontSize: "25px",
          fontWeight: "800",
        }}
      >
        {props.assessmentData.name}
        <hr style={{ color: "black", height: "2px", margin: "0 8px" }} />
        <div>
          {props.assessmentData.instructions !== "" && (
            <Typography
              style={{
                textAlign: "left",
                fontWeight: "700",
                fontSize: "25px",
                paddingLeft: "20px",
              }}
            >
              Instructions:{" "}
            </Typography>
          )}
          <Typography
            style={{
              fontSpacing: "2px",
              fontSize: "17px",
              textAlign: "left",
              paddingLeft: "20px",
            }}
          >
            {props.assessmentData.instructions
              ? props.assessmentData.instructions
              : ``}
          </Typography>
        </div>
      </Typography>
      <hr style={{ color: "black", height: "2px", margin: "0 8px" }} />
      {Object.entries(props.renderContent).map(
        ([section, questionList], sectionNumber) => {
          return (
            <div style={{ marginLeft: "16px", pageBreakAfter: "always" }}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  marginTop: "30px",
                  textAlign: "center",
                }}
              >
                {section}
              </Typography>
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  marginTop: "3px",
                }}
              >
                <Typography
                  display={`inline`}
                  dangerouslySetInnerHTML={{
                    __html: props.instructionForSection[section],
                  }}
                />
              </Typography>
              <>
                {questionList.map((question, index) => {
                  return (
                    <div className={`printComponent `}>
                      <div style={{ display: "flex" }}>
                        <Stack>
                          <Typography
                            style={{ fontSize: "26px", fontWeight: "700" }}
                          >
                            Question {index + 1 + sectionLengths[sectionNumber]}{" "}
                            <Typography
                              style={{
                                display: "inline",
                                fontSize: "19px",
                                color: "#c4c4c4",
                              }}
                            >
                              {props.questionType[question.question_type]}
                            </Typography>
                          </Typography>
                          <Typography
                            variant={`inline`}
                            style={{
                              fontWeight: "400",
                              flexGrow: "1",
                              marginTop: "2px",
                            }}
                          >
                            <Typography
                              display={`inline`}
                              dangerouslySetInnerHTML={{
                                __html: question.body.text,
                              }}
                            />
                          </Typography>
                        </Stack>
                        <div
                          style={{
                            flexGrow: "1",
                            textAlign: "right",
                            marginRight: "20px",
                            color: "#c4c4c4",
                            display: "inline",
                          }}
                        >
                          <Typography
                            style={{
                              fontWeight: "700",
                            }}
                          >
                            Marks +{question.marks_if_correct}/
                            {question.marks_if_incorrect}
                          </Typography>
                        </div>
                      </div>
                      <img
                        src={question.body.images[0]}
                        style={{
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginTop: "20px",
                          marginBottom: "30px",
                          maxWidth: "500px",
                        }}
                      />
                      <Typography style={{ marginLeft: "10px" }}>
                        <Grid container spacing={`50px`}>
                          {props.questionType[question.question_type] !==
                            `NUMERICAL` &&
                            question.options.map((options, index) => {
                              return (
                                <Grid item xs={6}>
                                  <Typography style={{ fontWeight: "300" }}>
                                    {index + 1} ){" "}
                                    <Typography
                                      display={`inline`}
                                      dangerouslySetInnerHTML={{
                                        __html: options.text,
                                      }}
                                    />
                                  </Typography>
                                  <div style={{ margin: "0 auto" }}>
                                    <img
                                      src={options.images[0]}
                                      style={{
                                        maxWidth: "300px",
                                        maxHeight: "300px",
                                      }}
                                    />
                                  </div>
                                </Grid>
                              );
                            })}
                        </Grid>
                        {props.printPaperWithAns && (
                          <Grid item xs style={{ paddingTop: "30px" }}>
                            <Typography style={{ fontWeight: "900" }}>
                              Answer:{" "}
                              <Typography
                                display={`inline`}
                                dangerouslySetInnerHTML={{
                                  __html: question.answer,
                                }}
                              />
                            </Typography>
                          </Grid>
                        )}
                        {props.printPaperWithSolution && (
                          <Stack style={{ marginLeft: "-16px" }}>
                            <ListItem>
                              <Grid>
                                <Typography style={{ fontWeight: "900" }}>
                                  Solution:
                                </Typography>
                                <Typography
                                  display={`inline`}
                                  dangerouslySetInnerHTML={{
                                    __html: question.solution.text,
                                  }}
                                />
                              </Grid>
                            </ListItem>
                            <ListItem>
                              <Stack>
                                {question.solution.images.map(
                                  (imgSrc, index) => {
                                    return (
                                      <ListItem>
                                        <img
                                          src={imgSrc}
                                          style={{
                                            maxWidth: "300px",
                                            maxHeight: "300px",
                                          }}
                                        />
                                        ;
                                      </ListItem>
                                    );
                                  }
                                )}
                              </Stack>
                            </ListItem>
                          </Stack>
                        )}
                      </Typography>
                      {index + 1 !== questionList.length && (
                        <hr style={{ marginLeft: "-16px" }} />
                      )}
                    </div>
                  );
                })}
              </>
            </div>
          );
        }
      )}
    </Paper>
  );
}
