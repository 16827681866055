import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { verifyEmail } from "../../api/accounts";
import { fetchUser } from "../../api/user";
import Loader from "../../components/Loader";
import NotFound from "../../components/NotFound";
import { AppContext, TAppContext } from "../../context/AppContext";
import { displaySuccessToast } from "../../utils/ToastUtils";

export default function VerifyEmail() {
  const [loading, setLoading] = useState<boolean>(true);
  const searchParams = useLocation().search;
  const verificationToken = new URLSearchParams(searchParams).get("token");
  const history = useHistory();

  const {
    setId,
    setName,
    setEmail,
    setAccessToken,
    setIsLoggedIn,
    setIsEmailVerified,
  } = useContext<TAppContext>(AppContext);

  const setUserDetails = async (access_token) => {
    console.log(access_token);
    setAccessToken(access_token);
    const userInfoResponse = await fetchUser(access_token);
    if (userInfoResponse === null) {
      return;
    }
    setEmail(userInfoResponse.email);
    setIsEmailVerified(userInfoResponse.is_verified);
    setName(userInfoResponse.name);
    setId(userInfoResponse.id);

    setIsLoggedIn(true);
    displaySuccessToast("Successfully logged in!");
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await verifyEmail(verificationToken!);
      if (response) {
        await setUserDetails(response["access_token"]);
        return history.push("/home/dashboard");
      }
      setLoading(false);
    })();
  }, []);

  return loading ? (
    <Loader LoadingMessage={"Please wait while we verify your email..."} />
  ) : (
    <NotFound
      NotFoundMessage={
        "There was some error in verifying your email. Please try again after sometime."
      }
    />
  );
}
