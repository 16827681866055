import axios from "axios";
import { baseURL } from "../utils/constants";
import { displayErrorToast } from "../utils/ToastUtils";
import HTTPStatusCode from "../utils/HTTPStatusCode";
import { handleUnauthorisedException } from "../utils/apiUtils";
import { Moment } from "moment";

export const fetchUser = async (accessToken: string) => {
  try {
    const response = await axios.get<any>(`${baseURL}/api/v1/user`, {
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error: any) {
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    console.log(error);
    // displayErrorToast("Failed to fetch User Info");
    return null;
  }
};

export const updateUserAdditionalInfoAPICall = async (
  name: string,
  city: string,
  phone: string,
  dob: any,
  accessToken: string
) => {
  try {
    const response = await axios.patch<any>(
      `${baseURL}/api/v1/user`,
      {
        name,
        city,
        phone,
        date_of_birth: dob,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.log(error);
    displayErrorToast("Unable to edit user information");
    return null;
  }
};

export const updatePasswordAPICall = async (
  currentPassword: string,
  newPassword: string,
  accessToken: string
) => {
  try {
    let response = await axios.patch<any>(
      `${baseURL}/api/v1/user/password`,
      {
        current_password: currentPassword,
        new_password: newPassword,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.log(error?.response);
    switch (error?.response?.status) {
      case HTTPStatusCode.UNAUTHORIZED:
        displayErrorToast(error?.response?.data?.error ?? "Invalid e-mail.");
        break;
      default:
        displayErrorToast();
    }
    return null;
  }
};

export const getServerTime = async (
  accessToken: string
): Promise<Date | Moment | null> => {
  if (accessToken === "") return null;
  try {
    const response = await axios.get<any>(`${baseURL}/api/v1/time`, {
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error: any) {
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    console.log(error);
    // displayErrorToast("Failed to fetch User Info");
    return null;
  }
};
