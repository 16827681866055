import { useContext, Dispatch, SetStateAction } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";

import { AppContext, TAppContext } from "../../../context/AppContext";
import { HomeContext, THomeContext } from "../../../context/HomeContext";

import { createBatch, updateBatch } from "../../../api/batches";
import {
  displaySuccessToast,
  displayErrorToast,
} from "../../../utils/ToastUtils";
import HTTPStatusCode from "../../../utils/HTTPStatusCode";

import styles from "../batch-management.module.scss";
import { TBatch } from "../../../models/batches";

type TProps = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  selectedBatch: TBatch;
  isEdit?: boolean;
  forceReRender: () => void;
};

// We currently use the same code for Creating a new batch and editing it's name
export default function CreateOrEditBatchModal(props: TProps) {
  const { showModal, setShowModal, isEdit, selectedBatch } = props;

  const { accessToken, org } = useContext<TAppContext>(AppContext);
  const { batches, setBatches } = useContext<THomeContext>(HomeContext);
  const handleCreateBatchSubmit = async (
    batchName: string,
    batchDescription: string
  ) => {
    setShowModal(false);

    if (org === null) {
      displayErrorToast("An error occured. Batch creation failed");
      return;
    }

    const createBatchData = await createBatch(
      accessToken,
      batchName,
      batchDescription,
      org.id
    );
    if (createBatchData === null) {
      displayErrorToast("An error occured. Batch creation failed");
      return;
    }

    // Add the current batch at the end of the Batch List in Left nav
    setBatches([
      ...batches,
      {
        id: createBatchData.id,
        name: createBatchData.name,
        batchDescription: createBatchData.description,
        code: createBatchData.code,
        orgId: createBatchData.org_id,
        createdAt: createBatchData.created_at,
        adminId: createBatchData.admin_id,
      },
    ]);

    displaySuccessToast("Batch Created Successfully!");
  };

  const handleUpdateBatchSubmit = async (
    batchName: string,
    batchDescription: string
  ) => {
    if (org === null || selectedBatch.id === 0) {
      displayErrorToast("An error occured. Batch updation failed");
      return;
    }

    const status = await updateBatch(
      accessToken,
      selectedBatch.id,
      batchName,
      batchDescription
    );
    if (status !== HTTPStatusCode.OK) {
      displayErrorToast("An error occured. Batch updation failed");
      return;
    }

    props.forceReRender();
    setShowModal(false);
    displaySuccessToast("Batch Updated Successfully!");
  };

  const schema = yup.object().shape({
    batchName: yup.string().required("Please enter a batch name"),
  });

  return (
    <Modal
      contentClassName={styles.modalStyle}
      show={showModal}
      onHide={() => setShowModal(false)}
    >
      <h3 className="ms-2 mt-2">
        {isEdit ? "Edit Batch Details" : "Add a new Batch"}
      </h3>
      <div className="form-group mt-3 ms-2">
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            isEdit
              ? handleUpdateBatchSubmit(
                  values.batchName,
                  values.batchDescription
                )
              : handleCreateBatchSubmit(
                  values.batchName,
                  values.batchDescription
                );
          }}
          initialValues={{
            batchName: selectedBatch?.name || "",
            batchDescription: selectedBatch?.batchDescription || "",
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
            <Form
              className="mt-4"
              noValidate
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <Form.Group controlId="batchName">
                <Form.Label>Batch Name:</Form.Label>
                <Form.Control
                  type="batch name"
                  value={values.batchName}
                  placeholder=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="ml-2 mb-2 bg-light"
                  isInvalid={!!errors.batchName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.batchName}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="batchDescription">
                <Form.Label>Batch Description:</Form.Label>
                <Form.Control
                  type="batch Description"
                  value={values.batchDescription}
                  placeholder=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="ml-2 bg-light"
                  isInvalid={!!errors.batchDescription}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.batchDescription}
                </Form.Control.Feedback>
              </Form.Group>

              <Modal.Footer>
                <Button
                  className="border-0 rounded-4 bg-secondary"
                  type="submit"
                >
                  {isEdit ? "Update Batch" : "Save Batch"}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
