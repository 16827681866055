import { IconButton, TextField, Typography } from "@mui/material";
import { useContext, Dispatch, SetStateAction, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { createBatch, requestJoinBatchCode } from "../../../api/batches";
import CustomButton from "../../../components/CustomComponent/CustomButton";
import { TAppContext, AppContext } from "../../../context/AppContext";
import { displaySuccessToast } from "../../../utils/ToastUtils";
import CloseIcon from "@mui/icons-material/Close";

import styles from "../batch-management.module.scss";

type TProps = {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
};

type TBatchCardProps = {
  heading: string;
  summary: string;
  onClick?: any;
  isSelected?: boolean;
  isPro?: boolean;
};

const BatchCard = ({
  heading,
  summary,
  isSelected = false,
  isPro = false,
  onClick,
}: TBatchCardProps) => {
  return (
    <div
      className="rounded-2 p-3 mx-1 w-50"
      style={{
        backgroundColor: isSelected ? "#47B5FF" : "white",
        color: isSelected ? "white" : "black",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <h4 className="font-weight-bold">{heading}</h4>
      <p>{summary}</p>
    </div>
  );
};

export default function JoinOrCreateBatchModal(props: TProps) {
  const { show, setShow } = props;
  const { accessToken, org } = useContext<TAppContext>(AppContext);
  const [batchCode, setBatchCode] = useState<string>("");
  const [selected, setSelected] = useState<number>(1);
  const [batchDescription, setBatchDescription] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleRequestJoinBatch = async () => {
    const response = await requestJoinBatchCode(accessToken, batchCode);
    if (response === null) return;
    displaySuccessToast("Successfully raised a request for you");
    setBatchCode("");
    setShow(false);
  };

  const handleCreateBatch = async () => {
    setLoading(true);
    const response = await createBatch(
      accessToken,
      batchCode,
      batchDescription,
      org!!.id
    );
    if (response === null) return;
    displaySuccessToast("Successfully Created a batch");
    setBatchCode("");
    setBatchDescription("");
    setShow(false);
    setLoading(false);
  };

  return (
    <Modal
      contentClassName={styles.modalStyle}
      show={show}
      onHide={() => setShow(false)}
      centered
    >
      <Modal.Header>
        <Typography variant="h4">
          <b>Batch options</b>
        </Typography>
        <IconButton>
          <CloseIcon onClick={() => setShow(false)} />
        </IconButton>
      </Modal.Header>
      <Modal.Body>
        <Typography fontSize={20} color="#9AA0B2">
          <b>Choose options: </b>
        </Typography>

        <div style={{ display: "flex", flexDirection: "row" }}>
          {
            <BatchCard
              heading="Join Batch:"
              summary="Use Batch Code from pre-existsing batch to view uploaded notes"
              onClick={() => {
                setSelected(1);
                setBatchCode("");
              }}
              isSelected={selected === 1}
            />
          }
          <BatchCard
            heading="Create Batch:"
            summary="Make a new batch and publish test privately to your students"
            isPro
            onClick={() => {
              setSelected(2);
              setBatchCode("");
            }}
            isSelected={selected === 2}
          />
        </div>
        {selected === 1 && (
          <>
            <div
              className="mt-3"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Typography fontSize={20} color="#9AA0B2">
                <b>Enter Batch Code:</b>
              </Typography>
              <TextField
                className="mx-4"
                sx={{ backgroundColor: "#DBDFF1" }}
                id="filled-basic"
                label="Code"
                variant="filled"
                value={batchCode}
                onChange={(e) => setBatchCode(e.target.value)}
              />
            </div>
            <Modal.Footer>
              <Button
                className="mt-2 w-100 border-0 rounded-2 bg-secondary"
                onClick={handleRequestJoinBatch}
              >
                <Typography className="m-0 p-0">Join Batch</Typography>
              </Button>
            </Modal.Footer>
          </>
        )}
        {selected === 2 && (
          <>
            <div
              className="mt-3"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Typography
                style={{ marginRight: "46px" }}
                fontSize={17}
                color="#9AA0B2"
              >
                <b>Enter Batch Name:</b>
              </Typography>
              <TextField
                className="mx-2"
                sx={{ backgroundColor: "#DBDFF1" }}
                id="filled-basic"
                label="Batch name"
                variant="filled"
                value={batchCode}
                onChange={(e) => setBatchCode(e.target.value)}
              />
            </div>
            <div
              className="mt-4"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Typography fontSize={17} color="#9AA0B2">
                <b>Enter Batch description:</b>
              </Typography>
              <TextField
                className="mx-2"
                sx={{ backgroundColor: "#DBDFF1" }}
                id="filled-basic"
                label="Batch description"
                variant="filled"
                value={batchDescription}
                onChange={(e) => setBatchDescription(e.target.value)}
              />
            </div>
            <Modal.Footer>
              <CustomButton
                className="mt-2 w-100 border-0 rounded-2 bg-secondary"
                loading={loading}
                onClick={handleCreateBatch}
              >
                <Typography className="m-0 p-0">Create Batch</Typography>
              </CustomButton>
            </Modal.Footer>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
