import { Dispatch, SetStateAction } from "react";
import { EQuestionStatus, EQuestionType } from "../../../../utils/Enums";
import { displayErrorToast } from "../../../../utils/ToastUtils";
import { TOrg } from "../../../../context/AppContext";
import { createQuestion } from "../../../../api/question";
import {
  IQuestionMarkingScheme as IAddQuestionToSectionInput,
  newQuestionObj,
} from "../../../../models/question";
import {
  IquestionData,
  IsectionInfo,
  TAssessmentData,
  TComprehension,
} from "../../../../models/assessmentmodel";
import { addQuestionToSection } from "../../../../api/assessment";

export type TModalProps = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setEdit?: Dispatch<SetStateAction<boolean>>;
};

export type TContextProps = {
  sectionMap: Map<number, IsectionInfo>;
  setSectionMap: Dispatch<SetStateAction<Map<number, IsectionInfo>>>;
  questionMap: Map<number, IquestionData>;
  setQuestionMap: Dispatch<SetStateAction<Map<number, IquestionData>>>;
  setCurrentSectionNumber: Dispatch<SetStateAction<number>>;
  setCurrentQuestionNumber: Dispatch<SetStateAction<number>>;
  setComprehensionMap: Dispatch<SetStateAction<Map<number, TComprehension>>>;
  updatedableQuestionMap?: Map<number, IquestionData>;
};

export type TQuestionProps = {
  marksIfCorrect: string;
  marksIfIncorrect: string;
  selectedSectionId: number;
  selectedAssessment: TAssessmentData;
  questionType: EQuestionType;
  questionCount: number;
  optionCount: number;
  // Not technically question properties, but needed and have to be sent from a react function/hook
  org: TOrg | null;
  accessToken: string;
  modalProps: TModalProps;
  contextProps: TContextProps;
  comprehension_id?: number;
};

export const QuestionTypeStringToEnumMap = new Map([
  ["MCQ", EQuestionType.MCQ],
  ["Numerical", EQuestionType.NUMERICAL],
  ["MCMCQ", EQuestionType.MCMCQ],
]);

export const QuestionTypeEnumToString = new Map([
  [EQuestionType.MCQ, "MCQ"],
  [EQuestionType.NUMERICAL, "Numerical"],
  [EQuestionType.MCMCQ, "MCMCQ"],
]);

// Creates a blank question in the section, updates it with the
// marks assigned, and (TODO) updates questionMap to include it
export const submitManualQuestion = async function (props: TQuestionProps) {
  console.log("Submit question button has been pressed.");

  const { showModal, setShowModal } = props.modalProps;

  if (
    isNaN(Number(props.marksIfCorrect)) ||
    isNaN(Number(props.marksIfIncorrect))
  ) {
    displayErrorToast("Please enter a valid number for marks.");
    return;
  }

  const selectedSection: IsectionInfo | undefined =
    props.contextProps.sectionMap.get(props.selectedSectionId);
  if (selectedSection === undefined) {
    console.log("Selected section did not work");
    displayErrorToast("There was an error");
    return;
  }

  const assessmentId = props.selectedAssessment.id;
  const createQuestionObj = new newQuestionObj(
    EQuestionStatus.APPROVED,
    props.questionType,
    props.optionCount,
    props.comprehension_id
  );

  setShowModal(false);

  // API Call to create empty question
  const addedQuestions = await createQuestion(
    props.accessToken,
    createQuestionObj,
    props.org!.id, // TODO better error handling
    props.questionCount
  );

  if (addedQuestions === null) {
    return false;
  }
  console.log("Question data:", addedQuestions, typeof addedQuestions);
  // const questionId = addedQuestions.id;

  // Also update sectionList
  const addQuestionToSectionInputs: IAddQuestionToSectionInput[] =
    [] as IAddQuestionToSectionInput[];

  addedQuestions.forEach((questionObj) => {
    const newQuestionInput: IAddQuestionToSectionInput = {
      question_id: questionObj.id,
      marks_if_correct: Number(props.marksIfCorrect),
      marks_if_incorrect: Number(props.marksIfIncorrect),
      marks_if_unattempted: 0,
      partial_marking: false,
    };
    addQuestionToSectionInputs.push(newQuestionInput);
  });

  const success = await addQuestionToSection(
    props.accessToken,
    assessmentId,
    props.selectedSectionId,
    addQuestionToSectionInputs
  );
  console.log("Added question to section");
  if (success === null) {
    return false;
  }

  return true;
};
