import { CSSProperties, ReactNode, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { deleteQuestionFromAssessment } from "../../../api/question";
import { AppContext, TAppContext } from "../../../context/AppContext";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../context/AssessmentContext";
import CustomButton from "../../../components/CustomComponent/CustomButton";
import { displaySuccessToast } from "../../../utils/ToastUtils";
import {
  handleOnClickNext,
  handleOnClickPrevious,
} from "../../../utils/QuestionNavigation";
import ConfirmationModal from "../../../components/ConfirmationModal";
import QuestionBottomMenuButton from "./QuestionButtonPanel/QuestionBottomMenuButton/QuestionBottomMenuButton";

type TProps = {
  style?: CSSProperties | Object;
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
};

export function DeleteQuestionFromAssessment(props: TProps) {
  const { accessToken } = useContext<TAppContext>(AppContext);
  const {
    questionMap,
    setQuestionMap,
    currentQuestionData,
    selectedAssessment,
    currentQuestionNumber,
    currentSectionNumber,
    setCurrentQuestionNumber,
    setCurrentSectionNumber,
    sectionMap,
    setSectionMap,
  } = useContext<TAssessmentContext>(AssessmentContext);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleStepsAfterQuestionDeletion = () => {
    let currentSection = sectionMap.get(
      selectedAssessment.sections[currentSectionNumber]
    );
    if (currentQuestionNumber == currentSection!.question_ids.length - 1) {
      let wasNavigationSuccess = handleOnClickNext(
        selectedAssessment,
        currentQuestionData,
        sectionMap,
        currentQuestionNumber,
        setCurrentQuestionNumber,
        currentSectionNumber,
        setCurrentSectionNumber
      );
      if (!wasNavigationSuccess) {
        handleOnClickPrevious(
          selectedAssessment,
          currentQuestionData,
          sectionMap,
          currentQuestionNumber,
          setCurrentQuestionNumber,
          currentSectionNumber,
          setCurrentSectionNumber
        );
      }
    }

    // create a new map for the remaining question and set it for questionMap
    let updatedQuestionMap = new Map();
    questionMap.forEach((question, question_id) => {
      if (question_id !== currentQuestionData.question_id) {
        updatedQuestionMap.set(question_id, question);
      }
    });
    setQuestionMap(updatedQuestionMap);

    // Now updating the same for a given section
    let updatedSectionMap = new Map();
    selectedAssessment.sections.forEach((sectionId) => {
      let updatedQuestion = sectionMap.get(sectionId);
      updatedQuestion!!.question_ids = updatedQuestion!!.question_ids.filter(
        (questionId) => {
          return questionId !== currentQuestionData.question_id;
        }
      );
      updatedSectionMap.set(sectionId, updatedQuestion);
    });
    setSectionMap(updatedSectionMap);
  };

  const handleDeleteQuestion = async () => {
    const sectionId = selectedAssessment.sections[currentSectionNumber];
    const deleteQuestionFromAssessmentRes = await deleteQuestionFromAssessment(
      accessToken,
      selectedAssessment.id,
      sectionId,
      sectionMap.get(sectionId)!.question_ids[currentQuestionNumber]
    );
    if (deleteQuestionFromAssessmentRes != null) {
      handleStepsAfterQuestionDeletion();
      setShowDeleteModal(false);
      displaySuccessToast("Successfully deleted question");
    }
  };

  return (
    <>
      <QuestionBottomMenuButton
        className={`${props.className || "bg-danger"}`}
        style={{
          ...props.style,
        }}
        disabled={props.disabled}
        onClick={() => {
          setShowDeleteModal(true);
        }}
      >
        {props.children}
      </QuestionBottomMenuButton>
      <ConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        confirmText="Delete"
        modalText="Are you sure you want to delete this question?"
        onConfirm={handleDeleteQuestion}
        modalTitle="Delete question"
      />
    </>
  );
}
