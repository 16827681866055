import { Dispatch, SetStateAction, useContext } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { displayErrorToast, displaySuccessToast } from "../../utils/ToastUtils";
import { AppContext, TAppContext } from "../../context/AppContext";
import * as yup from "yup";
import { Formik } from "formik";
import { loginUserAPICall, socialLogin } from "../../api/login";
import { fetchUser } from "../../api/user";
import Divider from "@mui/material/Divider";
import { GoogleLogin } from "@react-oauth/google";
import { EAuthMode } from "./Components/AuthScreen";
import { handleTransferAssessment } from "../../api/assessment";
import { VERSION_NUMBER } from "../../utils/constants";

interface LoginProps {
  setAuthMode?: Dispatch<SetStateAction<EAuthMode>>;
}

export default function Login(props: LoginProps) {
  const history = useHistory();
  const {
    setId,
    setName,
    setEmail,
    setAccessToken,
    setIsLoggedIn,
    setIsEmailVerified,
    shouldTransferAssessment,
    dummyAssessmentId,
    showLoginModal,
  } = useContext<TAppContext>(AppContext);

  let schema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Please enter an email"),
    password: yup.string().required("Please enter your password"),
  });

  const setUserDetails = async (access_token) => {
    setAccessToken(access_token);
    const userInfoResponse = await fetchUser(access_token);
    if (userInfoResponse === null) {
      return;
    }
    setEmail(userInfoResponse.email);
    setIsEmailVerified(userInfoResponse.is_verified);
    setName(userInfoResponse.name);
    setId(userInfoResponse.id);

    setIsLoggedIn(true);
    displaySuccessToast("Successfully logged in!");
  };

  const handleEmailPasswordLogin = async (values: any) => {
    let loginResponse = await loginUserAPICall(values.email, values.password);
    if (!loginResponse) {
      return;
    }
    await setUserDetails(loginResponse.access_token);
    if (shouldTransferAssessment)
      await handleTransferAssessment(
        loginResponse.access_token,
        dummyAssessmentId
      );
    if (props.setAuthMode === undefined) {
      history.push("/home");
    }
  };

  const handleGoogleLoginSuccess = async (res) => {
    let loginResponse = await socialLogin(res.credential);
    if (!loginResponse) {
      return;
    }
    await setUserDetails(loginResponse.access_token);
    if (shouldTransferAssessment)
      await handleTransferAssessment(
        loginResponse.access_token,
        dummyAssessmentId
      );
    if (props.setAuthMode === undefined) {
      history.push("/home");
    }
  };

  const handleGoogleLoginFailure = () => {
    displayErrorToast();
  };

  return (
    <div className="container d-flex justify-content-center w-100 p-0">
      <div
        className={
          "col-sm-8 col-md-8 col-lg-6 col-11 " +
          (showLoginModal ? "w-100" : null)
        }
      >
        <div
          className="login shadow-lg rounded-2"
          style={{
            paddingLeft: "4%",
            paddingRight: "4%",
            paddingTop: "4%",
            paddingBottom: "4%",
            marginTop: showLoginModal ? 0 : "10%",
            backgroundColor: "#eeeeee",
          }}
        >
          <h2 className="d-flex justify-content-center">
            <strong>Log in to Quriate</strong>
          </h2>
          <hr className="mt-4 text-primary" style={{ width: "100%" }} />
          <Formik
            validationSchema={schema}
            onSubmit={(values) => handleEmailPasswordLogin(values)}
            initialValues={{
              email: "",
              password: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
              isSubmitting,
            }) => (
              <Form
                className="mt-4"
                noValidate
                onSubmit={(event) => {
                  event.preventDefault();
                  handleSubmit();
                }}
              >
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    value={values.email}
                    placeholder="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="ml-2 bg-light"
                    isInvalid={!!errors.email}
                    data-testid="emailInput"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="password" className="mt-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={values.password}
                    placeholder="Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.password}
                    className="bg-light"
                    data-testid="passwordInput"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="mt-2">
                  <Link to="/forgot-password">Forgot Password?</Link>
                </div>
                <div className="text-center">
                  <Button
                    variant="secondary"
                    className="mt-4 rounded-2 p-2"
                    block
                    size="lg"
                    type="submit"
                    disabled={isSubmitting}
                    style={{ color: "white", width: "150px" }}
                    data-testid="submitLogin"
                  >
                    {isSubmitting ? (
                      <Spinner animation="border" role="status" />
                    ) : (
                      "Log in"
                    )}
                  </Button>
                </div>
                <Divider className="mt-2">or</Divider>
                <div className="d-flex flex-column align-items-center justify-content-center mt-2">
                  <GoogleLogin
                    onSuccess={handleGoogleLoginSuccess}
                    onError={handleGoogleLoginFailure}
                    text={"signin_with"}
                  />
                </div>
                <div className="mt-4 d-flex justify-content-center">
                  <div>
                    Don't have an account?&nbsp;
                    <b
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (props.setAuthMode === undefined) {
                          return history.replace("/signup");
                        }
                        props.setAuthMode(EAuthMode.SIGNUP);
                      }}
                    >
                      Sign up
                    </b>
                  </div>
                </div>
                <br></br>
                <span className="fw-light d-flex justify-content-center">
                  Version: {VERSION_NUMBER}
                </span>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
