import { FunctionComponent, useState } from "react";
import { Button, Card, Col, Modal, Image } from "react-bootstrap";
import { RiDeleteBinLine } from "react-icons/ri";

interface EditImageProps {
  src: string;
  onDelete: () => void;
}
export const EditImage: FunctionComponent<EditImageProps> = (props) => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <Col xs={12} md={6} lg={4}>
      <Modal size="xl" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton />
        <Modal.Body>
          <Image src={props.src}></Image>
        </Modal.Body>
      </Modal>

      <Card>
        <Card.Header className="p-0 d-flex justify-content-end align-items-start">
          <Button
            className="border border-none outline-none shadow-none"
            style={{ backgroundColor: "transparent" }}
            onClick={() => {
              props.onDelete();
            }}
          >
            <RiDeleteBinLine color="red" size="35px" />
          </Button>
        </Card.Header>
        <Card.Body>
          <Image
            fluid
            src={props.src}
            style={{ cursor: "pointer", zIndex: 1 }}
            onClick={() => {
              setShow(true);
            }}
          />
        </Card.Body>
      </Card>
    </Col>
  );
};
